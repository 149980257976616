// TODO: check if that should/can be a mixin !
export function makeResetFieldQueuedWatcher(antFormName, queueName) {
	return {
		handler ( nevVal ) {
			const {settings} = nevVal || {};
			const queue = this[queueName];
			if(settings && queue.length > 0) {
				this[antFormName].resetFields(queue)
				this[queueName] = [];
			}
		},
		deep: true
	}
}
