<template>
    <section style="padding: 20px">
        <!--
        <a-transfer
            :data-source="sizes"
            :render="i => ({ label: i.name, value: i.id })"
            :show-select-all="false"
            :show-search="true"
            :list-style="{
                height: '300px',
            }"
            :filter-option="
                (inputValue, item) => item.name.includes(inputValue)
            "
            :operations="['move to desktop', 'move to mobile']"
            :titles="['Mobile CTA (arrow)', 'Desktop CTA']"
        >
            <template
                #children="{
                    props: { direction, selectedKeys, filteredItems },
                    on: { itemSelect  },
                }"
            >
                <a-list size="large" :data-source="filteredItems" rowKey="id">
                    <a-list-item slot="renderItem" slot-scope="item, index">
                        <a-checkbox
                            style="width: 100%"
                            @change="
                                ({ target }) =>
                                    itemSelect(String(item.id), target.checked)
                            "
                        >
                            {{ index + 1 }}.{{ item.name }}
                            <a-button>x</a-button>
                        </a-checkbox>
                    </a-list-item>
                </a-list>
            </template>
        </a-transfer>
        -->
        <a-row :gutter="30" style="margin-top: 30px">
            <a-col :span="8">
                <h3>Mobile CTA (arrow)</h3>
                <a-divider />
                <inline-preloader v-if="isLoading" />
                <a-button
                    v-for="size in mobileSizes"
                    :key="size.id"
                    size="large"
                    style="margin-right: 12px; margin-bottom: 12px"
                    @click="openCtaModal(size)"
                >
                    {{ size.size }}
                </a-button>
            </a-col>
            <a-col :span="8">
                <h3>Desktop CTA</h3>
                <a-divider />
                <inline-preloader v-if="isLoading" />
                <a-button
                    v-for="size in desktopSizes"
                    :key="size.id"
                    size="large"
                    style="margin-right: 12px; margin-bottom: 12px"
                    @click="openCtaModal(size)"
                >
                    {{ size.size }}
                </a-button>
            </a-col>
            <a-col :span="8">
                <div style="display: flex; justify-content: end;">
                    <a-button
                        type="primary"
                        @click="openCtaModal()"
                        :loading="isLoading"
                    >
                        <a-icon type="plus" v-if="!isLoading" />
                        New CTA size
                    </a-button>
                </div>
            </a-col>
        </a-row>
        <new-or-update-cta-size-modal
            v-if="newOrUpdateCtaSizeModal"
            :cta-size="chosenCta"
            @closeCtaSizeModal="newOrUpdateCtaSizeModal = false"
            @newCtaSize="handleCtaSizeCreate($event)"
            @updateCtaSize="handleCtaSizeUpdate($event)"
            @removeCtaSize="handleCtaSizeRemove($event)"
        />
    </section>
</template>

<script>
import InlinePreloader from '@/components/project/components/InlinePreloader.vue'
import NewOrUpdateCtaSizeModal from '@/components/settings/cta-sizes/NewOrUpdateCtaSizeModal.vue'
import { sizesWithCtaService } from '@/components/settings/cta-sizes/cta-sizes.service'
import { mapActions } from 'vuex'

export default {
    name: 'CtaSizesSettingsPage',
    components: { NewOrUpdateCtaSizeModal, InlinePreloader },
    data() {
        return {
            sizes: [],
            isLoading: true,
            newOrUpdateCtaSizeModal: false,
            chosenCta: null,
        }
    },
    computed: {
        mobileSizes() {
            return this.sizes.filter(s => s.type === 'MOBILE')
        },
        desktopSizes() {
            return this.sizes.filter(s => s.type !== 'MOBILE')
        },
    },
    mounted() {
        this.loadData()
    },
    methods: {
        ...mapActions(['showAlertSuccess', 'showAlertError']),
        async loadData() {
            this.isLoading = true
            try {
                this.sizes = await sizesWithCtaService.getAll()
            } catch {
                //..
            } finally {
                this.isLoading = false
            }
        },
        openCtaModal(chosenCta = null) {
            this.chosenCta = chosenCta
            this.newOrUpdateCtaSizeModal = true
        },
        async handleCtaSizeUpdate(ctaSize) {
            try {
                await sizesWithCtaService.update(ctaSize.id, ctaSize)
                this.newOrUpdateCtaSizeModal = false
                this.showAlertSuccess(
                    `CTA size: ${ctaSize.width}x${ctaSize.height} updated!`
                )
                await this.loadData()
            } catch (e) {
                this.showAlertError(e.message)
            }
        },
        async handleCtaSizeCreate(ctaSize) {
            try {
                await sizesWithCtaService.create(ctaSize)
                this.newOrUpdateCtaSizeModal = false
                this.showAlertSuccess(
                    `CTA size: ${ctaSize.width}x${ctaSize.height} created!`
                )
                await this.loadData()
            } catch (e) {
                this.showAlertError(e.message)
            }
        },
        async handleCtaSizeRemove({ id, size }) {
            try {
                await sizesWithCtaService.remove(id)
                this.newOrUpdateCtaSizeModal = false
                this.showAlertSuccess(`CTA size: ${size} removed!`)
                await this.loadData()
            } catch (e) {
                this.showAlertError(e.message)
            }
        },
    },
}
</script>

<style scoped lang="less"></style>
