
<template>
    <div>
        <div class="listHeader">
            <div class="listHeader__left">
                <h2>Projects ({{allProjectCounter}})</h2>
                <a-dropdown>
                    <a class="ant-dropdown__link" @click="e => e.preventDefault()">
                        <p class="listHeader__sort">Sort by <a-icon type="caret-down" /></p>
                    </a>
                    <a-menu slot="overlay">
                        <a-menu-item @click="sortBy('name')">
                            <p>Name <sort-icon fieldName="name" :currentField="orderField" :direction="orderDir"></sort-icon></p>
                        </a-menu-item>
                        <a-menu-item @click="sortBy('created_at')">
                            <p>Creation date <sort-icon fieldName="created_at" :currentField="orderField" :direction="orderDir"></sort-icon> </p>
                        </a-menu-item>
                        <a-menu-item @click="sortBy('updated_at')">
                            <p>Edition date <sort-icon fieldName="updated_at" :currentField="orderField" :direction="orderDir"></sort-icon></p>
                        </a-menu-item>
                    </a-menu>
                </a-dropdown>
            </div>
            <a-button-group class="btn-deselect-group" v-if="selectedProjectsCount > 1">
              <a-button @click="handleEmptySelectionClick()">
                <a-icon type="switcher" />
              </a-button>
              <a-button class="btn-many-remove" @click="handleManyRemoveClick()">
                <a-icon type="delete" :style="{ width: '16px'}"/> Delete selected ({{selectedProjectsCount}})
              </a-button>
            </a-button-group>
            <a-pagination
                class="project-pagination"
                :style="{ marginLeft: selectedProjectsCount > 1 ? '' : 'auto' }"
                :hideOnSinglePage="true"
                :defaultPageSize="pageSize"
                :total="selectedNoOfItems"
                :current="selectedPage"
                @change="handlePaginationChange"
            />
            <a-button @click.native="showAddProjectModal = true" type="primary">
                <a-icon type="plus" :style="{ width: '16px'}"/> New Project
            </a-button>
        </div>
      <a-tabs v-model="currentTab" @change="handleTabChange" >
        <template v-for="({title, type, icon, elements, total}, idx) of projectLists">
          <a-tab-pane :key="idx" v-if="elements.length || idx === 0" style="margin-top: 0"  >
            <div slot="tab">
              <h3 class="project-list__subtitle">
                <a-icon style="margin-right: .45em" :type="icon" />
                {{title}}
                <a-badge
                    class="badge"
                    :count="total"
                    :overflow-count="Infinity"
                    :number-style="badgeStyle"
                />
                <span v-if="type === 0 && hasAnyNewDuplicatedProjects">
                  <a-icon class="bell" type="bell" theme="filled" />
                </span>
              </h3>
            </div>
            <a-row class="scroll"
                   :key="title"
                   :gutter="[16,16]"
                   v-if="elements.length > 0"
                   style="height: 73vh; overflow-x: hidden; margin: 0"
            >
              <a-col v-for="project in elements" :key="project.id" :span="6">
                <project-card
                    :project="project"
                    :show-new-indicator="!project.was_opened"
                    :is-selected="selectedProjectIds.includes(project.id)"
                    @select="handleProjectSelected($event)"
                    @open="handleEditProject(project)"
                    @editProject="handleEditProject(project)"
                    @copyProject="handleCopyProject(project)"
                    @duplicateProject="handleDuplicationRequest(project)"
                    @deleteProject="handleProjectDelete(project)"
                    @infoProject="showProjectInfoModal(project)"
                    @renameProject="handleRenameProject(project)"
                    @makrFavourite="handleMarkFavourite(project.id, $event)"
                />
              </a-col>
              <a-col v-if="!searchQuery && type === 1" :span="6">
                <empty-card @click.native="showAddProjectModal = true"/>
              </a-col>
            </a-row>
          </a-tab-pane>
        </template>
      </a-tabs>
      <s-empty v-if="notFound" :size="'xl'"/>
      <rename-modal
          title="Rename project"
          fieldLabel="Project name"
          v-if="showRenameModal"
          :currentName="chosenProject && chosenProject.name"
          :renameRule="(name) => name.length >= 3"
          @closeRenameModal="showRenameModal = false"
          @whenNewName="handleNewNameGiven($event)"
      >
        <p class="project-info">(Name must have at least 3 characters)</p>
      </rename-modal>

      <duplicate-project-modal
          v-if="showDuplicateModal"
          :projectName="chosenProject.name"
          @close="showDuplicateModal = false"
          @duplicate="handleDuplicateProject($event)"
      />

      <add-project-modal
        v-if="showAddProjectModal"
        @projectAdded="handleProjectAdd($event)"
        @closeAddProductModal="showAddProjectModal = false"
      />
    </div>
</template>

<script>

import DuplicateProjectModal from '@/components/projects/components/DuplicateProjectModal';
import { mapState, mapActions, mapGetters } from 'vuex'
import { mapActions as mapPActions } from 'pinia'
import ProjectCard from "./components/ProjectCard";
import EmptyCard from "./components/EmptyCard";
import Empty from "../layout/Empty";
import SortIcon from '@/components/shared/SortIcon'
import RenameModal from '@/components/main/components/RenameModal'
import AddProjectModal from '@/components/project/components/AddProjectModal';
import {useGalleryCache} from "@/store/modules/gallery-cache.store";

const TABS = {
  ACTIVE: 0, // Indexes matters !
  DUPLICATED: 1,
  FAVOURITE: 2
}

export default {
  name: 'ProjectsPage',
  components: {
    SEmpty: Empty,
    ProjectCard,
    EmptyCard,
    SortIcon,
    RenameModal,
    DuplicateProjectModal,
    AddProjectModal
  },
  data() {
    return {
      showAddProjectModal: false,
      showRenameModal: false,
      showDuplicateModal: false,
      chosenProject: null,
      selectedPage: 0,
      selectedNoOfItems: 0,
      currentTab: 0,
      selectedProjects: []
    }
  },
  computed: {
    ...mapState('projects', ['projects', 'searchQuery']),
    ...mapGetters('projects', [
        'orderField', 'orderDir', 'projectsLength', 'totalItems', 'pageSize', 'currentPage',
        'duplicatedProjects', 'duplicatedProjectsLength', 'totalDuplicatedItems', 'currentDuplicatedPage',
        'favouriteProjects', 'currentFavouritePage', 'totalFavouriteItems',
        'allProjectCounter', 'allProjectIds'
    ]),
    notFound() {
      return this.searchQuery && this.projectsLength === 0;
    },
    hasAnyNewDuplicatedProjects() {
       return !this.duplicatedProjects.every(p => p.was_opened)
    },
    projectLists() {
      // ORDER OF THOSE MATTERS a lot ! see: TABS.
      return [
        {title: 'Active', type: 1, icon: 'snippets', total: this.totalItems, elements: this.projects },
        {title: 'Duplicated from others', type: 0, icon: 'usergroup-add', total: this.totalDuplicatedItems, elements: this.duplicatedProjects },
        {title: 'Favourites', type: 2, icon: 'star', total: this.totalFavouriteItems, elements: this.favouriteProjects },
      ]
    },
    isActiveProjectTabSelected() {
       return this.currentTab === 0;
    },
    badgeStyle() {
      return {
        color: '#ccc',
        border: '1px solid #ffffff44',
        transform: 'scale(1.1)'
      }
    },
    selectedProjectIds() {
      return this.selectedProjects.map(p => p.id);
    },
    selectedProjectsCount() {
      return this.selectedProjects.length;
    }
	},
	methods: {
    ...mapActions('uiState', ['showProjectInfoModal', 'showRemoveModalConfirm', 'setCurrentProject']),
    ...mapActions('projects', ['initProjects', 'setOrder', 'removeProject', 'renameProject', 'duplicateProject', 'changePage', 'changeDuplicatedPage', 'changeFavouritePage', 'duplicateProjectForOtherUser', 'markFavourite']),
    ...mapActions('connectionTemplate', ['emptyConnections']),
    ...mapPActions(useGalleryCache, ['loadAllExpiredItemsByProjectIds']),
    handleRenameProject(project) {
      this.chosenProject = project;
      this.showRenameModal = true;
    },
    handleMarkFavourite(projectId, isFavourite) {
      this.markFavourite({projectId, isFavourite})
    },
    handleNewNameGiven(name) {
      const {id} = this.chosenProject;
      this.renameProject({id, name}).then(() => {
        this.showRenameModal = false;
      }).catch(() => {})
    },
    sortBy(fieldName) {
      const toggleDir = this.orderDir === 'asc' ? 'desc' : 'asc';
      this.setOrder({fieldName, dir: toggleDir});
    },
    handleProjectAdd(project) {
      this.showAddProjectModal = false;
      this.handleEditProject(project);
    },
    handleEditProject(project) {
      this.$router.push('/projects/' + project.id)
    },
    handleCopyProject(project) {
      this.duplicateProject(project.id)
    },
    handleDuplicationRequest(project) {
      this.showDuplicateModal = true
      this.chosenProject = project;
    },
    handleDuplicateProject({userEmails, userRoles}) {
      this.duplicateProjectForOtherUser({projectId: this.chosenProject.id, userEmails, userRoles});
    },
    handleProjectDelete(project) {
      this.showRemoveModalConfirm({
        title: 'Delete project?',
        content: 'If you delete project you will lose all graphics and bindings.',
        okCallback: () => {
          this.removeProject(project.id);
          this.deselectProject(project.id);
          this.reloadProjects();
        }
      })
    },
    handleProjectSelected({project, isSelected}) {
      if(!isSelected) {
        this.selectedProjects.push(project)
      } else {
        this.deselectProject(project.id)
      }
    },
    handleManyRemoveClick() {
      this.showRemoveModalConfirm({
        title: 'Delete many projects?',
        content: (
            <div>
              You are about to delete those projects:
              <ul style="margin: 10px 0;">
                {this.selectedProjects.map(p => <li>- {p.name}</li>)}
              </ul>
              You will lose all theirs graphics and bindings.
              <div>Are you sure?</div>
            </div>
        ),
        okCallback: async () => {
          for(const project of this.selectedProjects) {
            await this.removeProject(project.id)
          }
          this.handleEmptySelectionClick();
          this.reloadProjects();
        }
      })
    },
    handleEmptySelectionClick() {
      this.selectedProjects = [];
    },
    handleTabChange(tabNo) {
      const controlledPage = {
        [TABS.ACTIVE]: [this.currentPage, this.totalItems],
        [TABS.DUPLICATED]: [this.currentDuplicatedPage, this.totalDuplicatedItems],
        [TABS.FAVOURITE]: [this.currentFavouritePage, this.totalFavouriteItems]
      }
      const [page, noOfItems] = controlledPage[tabNo];
      this.selectedPage = page || 0;
      this.selectedNoOfItems = noOfItems || 0;
    },
    handlePaginationChange(pageNo){
      const pageChange = {
        [TABS.ACTIVE]: this.changePage,
        [TABS.DUPLICATED]: this.changeDuplicatedPage,
        [TABS.FAVOURITE]: this.changeFavouritePage,
      }
      this.selectedPage = pageNo;
      const fireChangePage = pageChange[this.currentTab];
      if(fireChangePage){
        fireChangePage(pageNo)
      }
    },
    deselectProject(projectId) {
      const idx = this.selectedProjects.findIndex(({id}) => projectId === id);
      if(idx > -1) {
        this.selectedProjects.splice(idx, 1);
      }
    },
    reloadProjects() {
      this.handlePaginationChange(this.selectedPage)
    }
	},
  async mounted(){
    await this.initProjects({searchText: ''}).catch(() => {});
    this.emptyConnections();
    this.selectedPage =  this.currentPage;
    this.selectedNoOfItems =  this.totalItems;

    // Watchers of crucial properties in Vuex Store (Refresh pagination)
    this.$watch(vm => [
        vm.currentPage, vm.currentDuplicatedPage, vm.currentFavouritePage,
        vm.totalItems, vm.totalDuplicatedItems, vm.totalFavouriteItems
    ], () => {
         this.handleTabChange(this.currentTab);
    })
  },
  watch: {
    async allProjectIds(ids) {
      // connect and retrieve all expired gallery items for project:
      if(ids.length) {
        await this.loadAllExpiredItemsByProjectIds(ids)
      }
    }
  }
}
</script>

<style scoped lang="less">
.card {
  min-height: 350px;
}
  .bell {
    position: relative;
    z-index: 1;
  }
  .project-list {
    &__subtitle {
      color: @gray-9;
      font-size: 1rem;
    }
  }
  .project-pagination {
    margin-right: 2em
  }
  .btn-many-remove {
    margin-right: 2em
  }
  .btn-deselect-group {
    margin-left: auto;
  }
  .project-info {
    color: rgba(255,255,255,.4);
    margin-top: 3px;
    margin-left: 12px;
    font-size: .75rem;
    padding: 0;
  }
  .scroll {
    overflow-y: auto;
    max-height: calc(100vh - 100px);
    overflow-x: hidden;
  }
</style>
