import { favouriteTemplatesService } from '@/services/favourites/favourite-templates';
import { makeCallWithPreloader } from '@/store/action-helpers'
import { templateService } from '@/services/template.service'
import Vue from 'vue'

export default {
    state: {
        templates: [],
        favouriteTemplateIds: [],
        order: ['name', 'asc'],
        searchQuery: ''
    },
    namespaced: true,
    getters: {
        orderField: state => state.order[0],
        orderDir: state => state.order[1],
        templatesLength: state => state.templates.length,
        favouriteTemplates: state => state.templates.filter(t => state.favouriteTemplateIds.includes(t.id)),
        isFavourite: (state) => (templateId) => state.favouriteTemplateIds.includes(templateId)
    },
    mutations: {
        setTemplates(state, templates) {
            state.templates = templates;
        },
        setSearchQuery(state, query) {
            state.searchQuery = query;
        },
        setOrderBy(state, {fieldName, dir}) {
            state.order = [fieldName, dir];
        },
        SET_FAVOURITE_IDS(state, templateIds){
            state.favouriteTemplateIds = templateIds;
        },
        UPDATE_FAVOURITE(state, {templateId, isFavourite}){
            if(isFavourite) {
               state.favouriteTemplateIds = [...state.favouriteTemplateIds, templateId];
            } else {
               state.favouriteTemplateIds = state.favouriteTemplateIds.filter(id => id !== templateId);
            }
        },
        UPDATE_FIELD(state, {field, template}){
            const stateTemplate = state.templates.find(t => t.id === template.id);
            if (stateTemplate) {
                Vue.set(stateTemplate, field, template[field]);
            }
        },
        SWAP_TEMPLATE(state, template) {
            const idx = state.templates.findIndex(t => t.id === template.id);
            if (idx > -1) {
                Vue.set(state.templates, idx, template);
            }
        }
    },
    actions: {
        async initTemplates({ commit, dispatch, state }, searchQuery) {
            const { order } = state;
            let queryFor = searchQuery;
            if(searchQuery === undefined) {
                // if searchQuery not defined use one form the store!
                queryFor = state.searchQuery
            } else {
                commit('setSearchQuery', searchQuery);
            }
            const query = queryFor ? {name: queryFor} : {};
            const templates = await makeCallWithPreloader(dispatch, () => templateService.allWithInactive(query, order));
            commit('setTemplates', templates);
            dispatch('showAlertSuccess', 'Templates loaded', {root: true});
            dispatch('initFavouriteTemplates')
            return templates;
        },
        async renameTemplate( {commit, dispatch}, template){
            validateTemplateName(dispatch, template.name);
            const updatedTemplate = await makeCallWithPreloader(dispatch, () => templateService.rename(template.id, template.name));
            commit('UPDATE_FIELD', {field: 'name', template: updatedTemplate});
            dispatch('showAlertSuccess', 'Template name successfully updated', {root: true});
            return updatedTemplate;
        },
        async initFavouriteTemplates({commit, state}) {
            const templates = await favouriteTemplatesService.getAll(state.searchQuery)
            commit('SET_FAVOURITE_IDS', (templates || []).map(t => t.id))
        },
        async markFavourite({commit}, {templateId, isFavourite}) {
            await favouriteTemplatesService.markFavourite({templateId, isFavourite});
            commit('UPDATE_FAVOURITE', {templateId, isFavourite})
        },
        updateTemplate({commit, dispatch}, template) {
            commit('SWAP_TEMPLATE', template);
            dispatch('showAlertSuccess', `Template ${template.name}, successfully updated`, {root: true});
        },
        setOrder({commit, dispatch, state}, {fieldName, dir}) {
            const { order } = state;
            const [currentField, currentDirection] = order;
            // Update orderBy field or direction only if changed !!!
            if(currentField !== fieldName || currentDirection !== dir) {
                commit('setOrderBy', {fieldName, dir})
                dispatch('initTemplates')
            }
        }
    }

};


function validateTemplateName(dispatch, name) {
    if(!name) {
        const TEMPLATE_NAME_ERROR = 'Template name is required';
        dispatch('showAlertWarning', TEMPLATE_NAME_ERROR, {root: true});
        throw new Error(TEMPLATE_NAME_ERROR);
    }
}
