/**
 * Sort strings: (width)x(height) like: 200x300
 * by width ASCENDING,
 * then by height ASCENDING
 *
 * ASCENDING order. 0, 1, 2, 3,
 *
 * example output: [100x200, 100x300, 120x10, 120x300] etc...
 * */
export function sizeAscSorter(sizeA = '0x0', sizeB = '0x0') {
    const [wA = 0, hA = 0] = (sizeA || '').split('x').map(Number)
    const [wB = 0, hB = 0] = (sizeB || '').split('x').map(Number)
    if (wA === wB) {
        // sort by height ASC then:
        return hA - hB
    }
    return wA - wB
}

/**
 * Sort creation list by connection id and sizes objects (Size model from back-end):
 * 1. first connectionId by ASCENDING
 * 2. then by width ASCENDING,
 * 3. then by height ASCENDING
 * */
export function sortByConnectionAndSize(creationA = {}, creationB = {}) {
    const { size: sA = {}, connectionId: cIdA = 0 } = creationA || {}
    const { size: sB = {}, connectionId: cIdB = 0 } = creationB || {}
    if (cIdA === cIdB) {
        const { width: wA = 0, height: hA = 0 } = sA || {}
        const { width: wB = 0, height: hB = 0 } = sB || {}
        if (wA === wB) {
            return hA - hB
        }
        return wA - wB
    }
    return cIdA - cIdB
}
