<template>
  <a-icon v-if="showIcon" :type="iconType" />
</template>

<script>
export default {
  name: "SortIcon",
  props: {
    fieldName: String,
    currentField: String,
    direction: String
  },
  computed: {
    showIcon() {
      return this.fieldName === this.currentField;
    },
    iconType() {
      return "sort-" + (this.direction === "asc" ? "ascending" : "descending")
    }
  }
}
</script>
