import { settingsCascade } from './settings-cascade'
import { TemplateModel } from './TemplateModel'
import {isNotEmpty} from "@/view-helpers/object-utils";

export class CreationModel {
	id = 0
	size = {}
	width = 0
	height = 0
	name = ''
	format = 'mobile'
	template = new TemplateModel()
	productId = 0
	connectionId = 0
	baseSettings = {}
	settings = {}
	mergedSettings = {}
	connectionSettings = {}
	cloneIndex = 0
	isDirty = false;

	constructor (name = '', id = 0, size = {}) {
		this.name = name
		this.id = id
		const {cta, width, height} = size
		this.size = size
		this.width = width
		this.height = height
		this.format =  cta ? 'mobile' : 'desktop'
		this.cloneIndex = size?.clone_index || 0;
	}

	setSettings(settings = {}) {
		// [!] .freeze settings to avoid being observed by Vue
		this.baseSettings = Object.freeze(settings || {});
	}

	applySettings(connectionSettings = {}) {
		this.connectionSettings = connectionSettings;
		this.mergedSettings = settingsCascade(this.template.cleanTemplateSettings, connectionSettings);
		this.settings = settingsCascade(this.mergedSettings, this.baseSettings);
	}

	updateSettings(settings) {
		this.setSettings(settings);
		this.settings = settingsCascade(this.mergedSettings, this.baseSettings);
		this.setDirty();
	}

	// @Business:
	// In order to immediately Reflect changes, we need to reset setting props on client side
	// Connection and Creation variants:
	removeConnectionSetting({type, id, key}) {
		delete this.connectionSettings[type]?.[id]?.[key];
		this.applySettings(this.connectionSettings);
	}

	removeCreationSetting({type, id, key}) {
		delete this.baseSettings[type]?.[id]?.[key];
		this.applySettings(this.connectionSettings);
	}

	setDirty() {
		this.isDirty = true;
	}

	get cssVariables() {
		const {cssVariables = {}} =  this.baseSettings || {};
		return Object.values(cssVariables).filter(isNotEmpty).length ? cssVariables : {};
	}
}
