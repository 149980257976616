
const storage = localStorage;
const USER_LOG_IN_MUTATION = 'user/AUTHORIZE_USER';
const USER_LOG_IN_ACTION = 'user/logIn';
const preserveSession = process.env.VUE_APP_PRESERVE_SESSION;

export const userSessionService = {
	observeLogIn(vuexStore) {
		if (preserveSession) {
			const token = storage.getItem('showBox.token');
			const roles = storage.getItem('showBox.roles') ? storage.getItem('showBox.roles').split(',') : '';
			const permissions = storage.getItem('showBox.permissions') ? storage.getItem('showBox.permissions').split(',') : '';
			const id = Number(storage.getItem('showBox.id')) || 0;
			const email = storage.getItem('showBox.email');

			if (token) {
				vuexStore.dispatch(USER_LOG_IN_ACTION, {id, token, email, roles, permissions }).then(() => {})
			}
		}

		vuexStore.subscribe((mutation, {user}) => {
			const { type } = mutation;
			if(type === USER_LOG_IN_MUTATION) {
				const {id, roles, token, email, permissions} = user;
				if(preserveSession) {
					storage.setItem('showBox.id', id);
					storage.setItem('showBox.email', email);
					storage.setItem('showBox.roles', roles);
					storage.setItem('showBox.permissions', permissions);
					storage.setItem('showBox.token', token);
				}
			}
		})
	}
}
