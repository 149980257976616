<template>
    <li @click="selectProduct">
        <div class="products__info" :class="{'products__info--disabled': !canSelect}" :title="product.name">
            <img class="products__image" :src="product.image_url" :alt="`image of ${product.name}`" />
            <p v-if="!editMode" v-ellipsis>{{ product.name }}</p>
            <a-input
                v-else
                v-model="productName"
                @blur="editMode = false"
                @keyup.enter="handleNewNameConfirm()"
                placeholder="Product name"
            />
        </div>
        <div style="margin-left: auto">
          <locale-picker
              v-if="product.lang && product.lang !== LANG_PL"
              display-type="flag-only"
              v-model="product.lang"
              :show-label="false"
              :read-only="true"
          />
          <div style="font-size: 10px">{{product.currency}}</div>
        </div>
        <a-dropdown>
            <a class="ant-dropdown-link" @click.prevent="">
                <a-icon type="more"/>
            </a>
            <a-menu slot="overlay">
                <a-menu-item @click="handleRename()">
                    <span><a-icon type="edit" /> Rename</span>
                </a-menu-item>
                <a-menu-item @click="$emit('updateProduct')">
                  <span><a-icon type="reload" /> Update from Allegro API</span>
                </a-menu-item>
                <a-menu-item @click="$emit('deleteProduct')">
                    <span><a-icon type="delete" /> Delete</span>
                </a-menu-item>
            </a-menu>
        </a-dropdown>
    </li>
</template>

<script>
import LocalePicker from '@/components/shared/locale-picker/LocalePicker'
import { LANG_LOCALES } from '@/components/shared/supported-languages';

export default {
    name: 'ProductListItem',
    components: {
      LocalePicker
    },
    props: {
      product: Object,
      canSelect: {
        type: Boolean,
        default: true
      }
    },
    data(){
        return {
          editMode: false,
          productName: this.product?.name
        }
    },
    computed: {
        LANG_PL() {
          return LANG_LOCALES.pl;
        }
    },
    methods: {
      selectProduct(){
          if(!this.editMode && this.canSelect){
              this.$emit('selectProduct')
          }
      },
      handleRename() {
        this.editMode = true
        this.productName = this.product?.name || ''
      },
      handleNewNameConfirm() {
        this.$emit('renameProduct', {
          name: this.productName,
          onComplete: () => {
            this.editMode = false;
          }
        })
      }
    }
}
</script>

<style scoped>
  .products__info--disabled {
    color: #666060;
  }
</style>
