import { FontExtractor } from '@/templating/fonts/FontExtractor'
import { TemplateBorder } from '@/templating/TemplateBorder'
import { TemplateCssVars } from '@/templating/TemplateCssVars'
import { sanitizeUrl } from '@/templating/templating-helpers'
import { TemplateText } from '@/templating/TemplateText'
import { TemplateBackground } from '@/templating/TemplateBackground'
import { TemplateImage } from '@/templating/TemplateImage'
import { TemplatePhoto } from '@/templating/TemplatePhoto'
import { TemplateClasses } from '@/templating/TemplateClasses'
import { TemplateProduct } from '@/templating/TemplateProduct'
import { TemplateCta } from '@/templating/TemplateCta'
import { TemplateBadges } from '@/templating/TemplateBadges'
import { TemplateMoveable } from '@/templating/TemplateMoveable'
import { TemplateMask } from '@/templating/TemplateMask'
import { TControl } from '@/templating/TControl'
import { VariableBinder } from '@/templating/variables/VariableBinder'

const DEFAULT_FETCHER = (url) => fetch(url).then((response) => response.text())

export class Template {
    static fileName = 'banner.html'
    static $template = '[ref="template"]'
    static $style = 'style'
    static $templateClass = 'template'

    template = document.createElement('section')
    style = document.createElement('style')
    controls = {}
    controlsLoaded = false
    computeCssVariables = false
    templateId = 0
    url = ''
    creationModel = {}
    mountState = 'before-mount'
    currentVariablesSettings = {}
    assureMountedOnDOM = async () => {}
    static MOUNTING_SAFE_TIME_MS = 110 // Time to pass after assureMountedOnDOM to resolve all DOM reordering

    constructor(templateURL, id = 0) {
        this.url = templateURL
        this.templateId = id
    }

    isMounted() {
        return this.mountState === 'mounted'
    }

    isNotMounted() {
        return this.mountState === 'before-mount'
    }

    async load(template = '', loaderFn = DEFAULT_FETCHER) {
        if (template) {
            return this.makeTemplateFrom(template)
        }
        const bannerPath = [this.url, Template.fileName].join('/')
        this.templateTxt = await loaderFn(bannerPath)
        this.makeTemplateFrom(this.templateTxt)
        // Attach Styles to DOM:
        const styleElement = this.templateHolder.querySelector(Template.$style)
        if (!styleElement) {
            console.warn(
                'TEMPLATE-WARN: Template does not provide style! - R U sure ?'
            )
        } else {
            this.attachStyles(styleElement.textContent)
            this.template.classList.replace(
                Template.$templateClass,
                this.getTemplateClass()
            )
        }
        // Update URLs to images (absolute paths):
        this.applyBaseUrlsFromServerSideToImages()
    }

    setTemplate(templateRef) {
        this.template = templateRef
    }

    getTemplate() {
        return this.template
    }

    getTemplateClass() {
        return Template.$templateClass + '_' + this.templateId
    }

    applyBaseUrlsFromServerSideToStyles(stylesStr) {
        let computeStyles = stylesStr
        // #Fix: repair logic to offset indexes (with matchAll)
        // (solves problem when same path exist twice in stylesheet)
        const urlsFromCss =
            computeStyles.matchAll(/url\((["']?)(.+?)\1\)/g) || []
        let offset = 0
        for (const { 2: found, index } of urlsFromCss) {
            const sanitizedUrl = sanitizeUrl(found, this.url)
            const offsetIndex = index + offset
            computeStyles =
                computeStyles.slice(0, offsetIndex) +
                computeStyles.slice(offsetIndex).replace(found, sanitizedUrl)
            // push to offset number of diff chars
            offset += sanitizedUrl.length - found.length
        }
        return computeStyles
    }

    applyBaseUrlsFromServerSideToImages() {
        this.templateHolder.querySelectorAll('img[src]').forEach((img) => {
            const src = img.getAttribute('src')
            const absoluteUrl = sanitizeUrl(src, this.url)
            img.setAttribute('src', absoluteUrl)
        })
    }

    requestCssVariablesComputation(currentVariablesSettings) {
        this.computeCssVariables = true
        this.currentVariablesSettings = currentVariablesSettings
    }

    attachStyles(stylesTxt) {
        this.style = document.createElement('style')
        const adaptStyles = stylesTxt.replaceAll(
            '.' + Template.$templateClass,
            '.' + this.getTemplateClass()
        )
        this.style.innerHTML =
            this.applyBaseUrlsFromServerSideToStyles(adaptStyles)
        this.style.setAttribute('id', this.getTemplateClass())
        // Assure only single template <style> with this #id will be mounted:
        document
            .querySelectorAll(`#${this.getTemplateClass()}`)
            .forEach((e) => e.remove())
        document.head.appendChild(this.style)
        return this.style
    }

    extractFontInfo() {
        return new FontExtractor(this.style.sheet).extractFonts()
    }

    detachStyles() {
        if (this.style) {
            this.style.remove()
        }
    }

    makeTemplateFrom(template) {
        this.templateHolder = document.createElement('div')
        this.templateHolder.innerHTML = template
        this.template = this.templateHolder.querySelector(Template.$template)
        if (!this.template) {
            throw new Error('Template not found in file!')
        }
    }

    $tmp(query, all = '' /*, resource = 'unknown'*/) {
        if (all === 'all') {
            const found = this.template.querySelectorAll(query)
            if (found.length === 0) {
                //console.warn( `TEMPLATE-WARN: Not found any dynamic placeholder for ${resource} (with: ${query})!` )
            }
            return found
        }
        const single = this.template.querySelector(query)
        if (!single) {
            //console.warn( `TEMPLATE-WARN: Not found dynamic placeholder for ${resource} (with: ${query})!` )
        }
        return single
    }

    makeControls() {
        const textRefs = this.$tmp(TemplateText.$selector, 'all', 'texts')
        const imgRefs = this.$tmp(TemplateImage.$selector, 'all', 'images')
        const imgBgRefs = this.$tmp(
            TemplatePhoto.$selector,
            'all',
            'background images'
        )
        const bgsRefs = this.$tmp(
            TemplateBackground.$selector,
            'all',
            'backgrounds'
        )
        const moveablesRefs = this.$tmp(
            TemplateMoveable.$selector,
            'all',
            'moveables'
        )
        const borderRefs = this.$tmp(TemplateBorder.$selector, 'all', 'borders')
        const maskRefs = this.$tmp(TemplateMask.$selector, 'all')

        //
        const productRef = this.$tmp(TemplateProduct.$selector, '', 'product')
        const ctaRef = this.$tmp(TemplateCta.$selector, '', 'call to action')
        const badgesRef = this.$tmp(TemplateBadges.$selector, '', 'badges')
        const classesRef = this.$tmp(TemplateClasses.$selector, '', 'classes')

        const controlsFrom = (refs, factory) =>
            Array.from(refs).reduce((o, ref) => {
                const control = factory(ref)
                // @Business: controls can have same id since v17:
                if (!o[control.id]) {
                    o[control.id] = []
                }
                o[control.id].push(control)
                return o
            }, {})

        const makeCssVariables = (controls) => {
            const vars = {}
            if (this.computeCssVariables && this.currentVariablesSettings) {
                vars.cssVariables = new TemplateCssVars(
                    this.style,
                    this.getTemplateClass(),
                    new VariableBinder(controls, this.currentVariablesSettings)
                )
            }
            return vars
        }
        const classControl = classesRef ? new TemplateClasses(classesRef) : null
        const basicControls = {
            cta: { cta: ctaRef ? new TemplateCta(ctaRef) : null },
            badges: {
                badges: badgesRef ? new TemplateBadges(badgesRef) : null,
            },
            product: {
                product: productRef
                    ? new TemplateProduct(productRef, this.url, classControl)
                    : null,
            },
            classes: { class: classControl },
            image: controlsFrom(
                imgRefs,
                (ref) => new TemplateImage(ref, this.url)
            ),
            photo: controlsFrom(
                imgBgRefs,
                (ref) => new TemplatePhoto(ref, this.url, this.template)
            ),
            text: controlsFrom(
                textRefs,
                (ref) => new TemplateText(ref, this.template)
            ),
            moveable: controlsFrom(
                moveablesRefs,
                (ref) => new TemplateMoveable(ref, this.template)
            ),
            background: controlsFrom(
                bgsRefs,
                (ref) => new TemplateBackground(ref, this.template)
            ),
            border: controlsFrom(
                borderRefs,
                (ref) => new TemplateBorder(ref, this.template)
            ),
            mask: controlsFrom(
                maskRefs,
                (ref) => new TemplateMask(ref, this.url)
            ),
        }

        this.controls = Object.freeze({
            ...makeCssVariables(basicControls),
            ...basicControls,
        })
        this.controlsLoaded = true

        return this.controls
    }

    setValues(values) {
        // Dummy clone - to avoid Observable object being transferred here as values
        const allValues = JSON.parse(JSON.stringify(values))
        const isControlPresent = ([k]) => Boolean(this.controls[k])
        // TODO: optimize and refactor !!
        Object.entries(allValues)
            .filter(isControlPresent)
            .forEach(([type, object]) => {
                Object.entries(object || {}).forEach(([id, valueObj]) => {
                    Object.entries(valueObj || {}).forEach(([key, value]) => {
                        this.updateControl({ type, id, key, value })
                    })
                })
            })
    }

    setupReactivity(frozenFields = {}) {
        this.getAllControlsInstances().forEach((c) => {
            if (frozenFields?.moveable) {
                if (frozenFields.moveable?.[c.id]?.move) {
                    // @business: do not settle down reactivity for FROZEN moveable fields.
                    return
                }
            }
            c.setupReactivity()
        })
    }

    getControls() {
        return this.controls
    }

    getAllControlsInstances() {
        return Object.values(this.controls || {})
            .flatMap((holder) =>
                holder instanceof TControl
                    ? holder
                    : Object.values(holder || {})
            )
            .flat() // since from v17 we can have same ids, and controls might be inside Array itself
            .filter((ctrl) => ctrl instanceof TControl)
    }

    getSettings() {
        return JSON.parse(JSON.stringify(this.controls))
    }

    getTemplateHolderAsTxt() {
        return this.templateHolder.innerHTML
    }

    mount(baseElement) {
        this.mountState = 'mounting'
        return new Promise((resolve) => {
            const wrapper = document.createElement('div')
            wrapper.innerHTML = this.template.outerHTML
            const tmp = wrapper.querySelector('[ref="template"]')
            baseElement.appendChild(tmp)
            setTimeout(() => {
                this.setTemplate(tmp)
                this.makeControls()
                wrapper.remove()
                this.mountState = 'mounted'
                resolve()
            }, 20)
        })
    }

    destroy() {
        // Cleanup controls
        this.getAllControlsInstances().forEach((ctrl) => {
            ctrl.onDestroy()
        })
        // Remove templateHolder from DOM:
        this.getTemplate()?.remove()
        this.template = null
        // Remove styles from DOM:
        if (this.style) {
            this.style.remove()
            this.style = null
        }
        // Remove controls (Memory issue):
        this.controls = {}
        if (this.templateHolder) {
            this.templateHolder.remove()
            this.templateHolder = null
        }
        // Remove ref to observable creationModel
        this.creationModel = null
        // clean closure
        this.assureMountedOnDOM = null
    }

    setCreationModel(creationModel) {
        // The CREATION MODEL from here should be Observable by Vue:
        this.creationModel = creationModel
    }

    getCreationModel() {
        return this.creationModel
    }

    changeBadgesLangSideEffect(lang) {
        this.getAllControlsInstances().forEach((c) => {
            c.changeLanguage(lang)
        })
    }

    updateControl({ type, id, key, value }) {
        if (!this.controlsLoaded) {
            // Controls are not present yet...
            return
        }
        if (type === 'cssVariables' && !this.computeCssVariables) {
            // The cssVariables are present only if computation requested.
            // @Business: But they should be updated for the single template (creation):
            this.setLocalCssVariables({ [id]: { value } })
            return
        }
        if (type === 'badges' && key === 'lang') {
            this.changeBadgesLangSideEffect(value)
        }
        if (!this.controls?.[type]?.[id]) {
            console.warn(
                `[updateControl]: There is no control: [${type}][${id}] on template - this {${key}: ${value}} will not be applied!`,
                this.controls?.[type],
                this.controls?.[type]?.[id]
            )
            return //silent error
        }
        // @Business: controls can have same id since v17:
        const controlById = this.controls[type][id]
        const controlArray = Array.isArray(controlById)
            ? controlById
            : [controlById]
        for (const c of controlArray) {
            if (typeof c[key] === 'function') {
                c[key](value)
            } else {
                c[key] = value
            }
        }
    }

    setLocalCssVariables(cssVars) {
        if (!cssVars) {
            return
        }
        for (const [variable, { value }] of Object.entries(cssVars)) {
            this.template.style.setProperty(variable, value)
        }
    }
}
