import { TControl } from '@/templating/TControl'

export class TemplateBackground extends TControl {

  static $selector = '[data-background]';

  constructor (ref, container) {
    super(ref, 'background');
    this.id = this.ref.getAttribute(TemplateBackground.$selector.slice(1, -1));
    this.container = container;
    this.background = getComputedStyle(ref).backgroundColor;
  } 

  get background() {
    return this.ref.style.backgroundColor;
  }
  set background(value) {
    return this.ref.style.backgroundColor = value;
  }
}
