<template>
  <div class="reports">
    <h2 class="reports__title"> Reports </h2>
    <section class="reports__filters">
      <div style="display: flex; align-items: center; gap: 16px">
        <a-range-picker
            :class="{'selected': chosenPresetKey === 0}"
            @change="handleDateRangeChange"
            style="margin: 10px 0;"
            v-model="timeRange"/>
        <a-button-group>
          <a-button
              v-for="preset in timePresets"
              :key="preset.key"
              @click="preset.action(); chosenPresetKey = preset.key"
              :type="chosenPresetKey === preset.key ? 'primary' : ''"
              style="padding: 2px 16px"
          > {{preset.name}} </a-button>
        </a-button-group>
        <a-button-group>
          <a-button
              v-for="preset in quarterPresets"
              :key="preset.key"
              @click="preset.action(); chosenPresetKey = preset.key"
              :type="chosenPresetKey === preset.key ? 'primary' : ''"
              style="padding: 2px 28px"
          > {{preset.name}} </a-button>
        </a-button-group>
      </div>
      <div style="display: flex; align-items: flex-end; gap: 24px; margin-top: 24px;">
        <div>
          <p style="margin-bottom: 8px">Present data</p>
          <a-button-group>
            <a-button
                v-for="types in reportTypes"
                :key="types.key"
                :type="types.key === reportType ? 'primary' : ''"
                @click="changeReportType(types.key)"
            >{{types.display}}</a-button>
          </a-button-group>
        </div>
        <div>
          <p style="margin-bottom: 8px">Filter by users</p>
          <a-select
              style="width: 100%;
              min-width: 300px"
              mode="multiple"
              placeholder="Select"
              v-model="filterByUserIds"
              @change="handleUserFilterChange"
          >
            <a-select-option v-for="{id, email, name, last_name} in userFilter" :key="id" :value="id" >
              {{email}} {{ name ? `(${name} ${last_name})` : '' }}
            </a-select-option>
          </a-select>
        </div>
        <div style="margin-left: auto">
          <a-button @click="handleDownloadAsCSV" :loading="isCSVLoading">
            <a-icon type="download" v-if="!isCSVLoading" /> Download CSV
          </a-button>
        </div>
      </div>
    </section>
    <section class="reports__container">
      <div class="reports__preloader" v-if="isLoading">
        <inline-preloader text="Loading report statistics..."></inline-preloader>
      </div>
      <div v-else-if="errorMessage">
        <a-alert
            :message="errorMessage"
            type="error"
            show-icon
            style="margin-top: 15px;"
        />
      </div>
      <template v-else>

        <a-table
            v-if="reportType === 'byCreation'"
            :scroll="{ y: 'calc(100vh - 430px)' }"
            :columns="byCreationColumns"
            :data-source="reportCollection"
            :pagination="{ pageSize: 50 }"
            :row-key="record => record.id"
        >
          <template slot="user" slot-scope="users" >
            <div v-html="renderUsers(users)" />
          </template>
          <template slot="templateNameAndPic" slot-scope="{id, name, thumbnail, updated_at}">
            <div style="display: flex">
              <div
                  class="template_thumbnail"
                  :style="{ backgroundImage: resolveTemplateGraphicUrl(id, thumbnail, updated_at)} ">
              </div>
              <div class="template_title">{{name}}</div>
            </div>
          </template>
        </a-table>
        <a-table
            v-else
            :scroll="{ y: 'calc(100vh - 430px)' }"
            :columns="byTemplateColumns"
            :data-source="reportCollection"
            :pagination="{ pageSize: 50 }"
            :row-key="record => record.id"
        >
          <template slot="templateId" slot-scope="{id, creations}">
            {{ id }} | ({{creations.length || '-'}})
          </template >
          <template slot="templateNameAndPic" slot-scope="{id, name, thumbnail, updated_at}">
            <div style="display: flex">
              <div
                  class="template_thumbnail"
                  :style="{ backgroundImage: resolveTemplateGraphicUrl(id, thumbnail, updated_at)} ">
              </div>
              <div class="template_title">{{name}}</div>
            </div>
          </template>
          <template slot="expandedRowRender" slot-scope="{id, creations}">
            <s-empty v-if="creations.length === 0"></s-empty>
            <a-table
                v-else
                :columns="byTemplateExpandedCreationColumns"
                :data-source="creations"
                :pagination="{ pageSize: 50 }"
                :row-key="record => record.id"
            >
              <template slot="user" slot-scope="users" >
                <div v-html="renderUsers(users)" />
              </template>
            </a-table>
          </template>
        </a-table>
        <div class="reports__stats">
            <div>Downloads:<strong style="margin-left: 8px">{{downloadStats.disc}}</strong></div>
            <div>CIM exports:<strong style="margin-left: 8px">{{downloadStats.cim}}</strong></div>
            <div>Total:<strong style="margin-left: 8px">{{downloadStats.total}}</strong></div>
        </div>
      </template>
    </section>
  </div>
</template>

<script>
import Empty from '@/components/layout/Empty'
import InlinePreloader from '@/components/project/components/InlinePreloader'
import { reportService } from '@/services/report.service'
import { resolveTemplateGraphicUrl } from '@/view-helpers/template-preview-resolver'
import { getDaysInMonth, setDate, setQuarter, endOfQuarter, startOfQuarter } from 'date-fns'
import { mapActions } from 'vuex'
import {dateFormatter} from "@/view-helpers/date-formatter";

const REPORT_TYPE = {
  BY_CREATION: 'byCreation',
  BY_TEMPLATE: 'byTemplate',
}

const today = new Date();
const formatDate = d => dateFormatter(d, dateFormatter.SHORT_DATE_FORMAT)
const q1 = [startOfQuarter(setQuarter(today, 1)), endOfQuarter(setQuarter(today, 1))].map(formatDate);
const q2 = [startOfQuarter(setQuarter(today, 2)), endOfQuarter(setQuarter(today, 2))].map(formatDate);
const q3 = [startOfQuarter(setQuarter(today, 3)), endOfQuarter(setQuarter(today, 3))].map(formatDate);
const q4 = [startOfQuarter(setQuarter(today, 4)), endOfQuarter(setQuarter(today, 4))].map(formatDate);
const year = [q1[0], q4[1]];

export default {
  name: 'ReportsPage',
  components: {
    InlinePreloader,
    SEmpty: Empty
  },
  data() {
    return {
      isLoading: false,
      isCSVLoading: false,
      errorMessage: '',
      reportCollection: [],
      totalStats: {
        cimExport: 0,
        discDownload: 0
      },
      reportType: REPORT_TYPE.BY_CREATION,
      timeRange: [],
      userFilter: [],
      filterByUserIds: [],
      chosenPresetKey: 1
    }
  },
  computed: {
    reportTypes() {
      return [
        {key: REPORT_TYPE.BY_CREATION, display: 'By Creation'},
        {key: REPORT_TYPE.BY_TEMPLATE, display: 'By Template'},
      ]
    },
    fistDayOfThisMonth() {
       return formatDate(setDate(today, 1));
    },
    lastDayOfThisMonth() {
      const days = getDaysInMonth(today);
      return formatDate(setDate(today, days));
    },
    timePresets() {
      return [
        {key: 1, action: () => this.setTimeRange(this.fistDayOfThisMonth, this.lastDayOfThisMonth), name: 'This month'},
        {key: 6, action: () => this.setTimeRange(year[0], year[1]), name: 'This year'},
      ]
    },
    quarterPresets() {
      return [
        {key: 2, action: () => this.setTimeRange(q1[0], q1[1]), name: 'Q I'},
        {key: 3, action: () => this.setTimeRange(q2[0], q2[1]), name: 'Q II'},
        {key: 4, action: () => this.setTimeRange(q3[0], q3[1]), name: 'Q III'},
        {key: 5, action: () => this.setTimeRange(q4[0], q4[1]), name: 'Q IV'},
      ]
    },
    byTemplateColumns() {
      return [
        { title: 'Id', width: '10%', key: 'id', scopedSlots: { customRender: 'templateId' }, },
        { title: 'Name', scopedSlots: { customRender: 'templateNameAndPic' }, },
        { title: 'Type', dataIndex: 'type', },
        { title: 'Category', dataIndex: 'category', },
        { title: 'CIM Exports', dataIndex: 'total.cimExport', },
        { title: 'disc downloads', dataIndex: 'total.discDownload', },
      ]
    },
    byTemplateExpandedCreationColumns(){
       return [
         { title: 'Id', dataIndex: 'id', key: 'id', width: '6%' },
         { title: 'Size', dataIndex: 'size', width: '6%' },
         { title: 'User',
           dataIndex: 'downloadsSummary.users',
           scopedSlots: { customRender: 'user' }
         },
         { title: 'CIM Exports', dataIndex: 'downloadsSummary.cimExport', },
         { title: 'Disc downloads', dataIndex: 'downloadsSummary.discDownload', },
       ]
    },
    byCreationColumns() {
      return [
        { title: 'Id', dataIndex: 'id', key: 'id', width: '6%' },
        { title: 'Size', dataIndex: 'size', width: '6%' },
        { title: 'Used template', dataIndex: 'template', scopedSlots: { customRender: 'templateNameAndPic' } },
        { title: 'Type', dataIndex: 'template.type', width: '6%' },
        { title: 'User',
          dataIndex: 'downloadsSummary.users',
          scopedSlots: { customRender: 'user' }
        },
        { title: 'Category', dataIndex: 'template.category', },
        { title: 'CIM Exports', dataIndex: 'downloadsSummary.cimExport' },
        { title: 'Disc downloads', dataIndex: 'downloadsSummary.discDownload' },
      ]
    },
    downloadStats() {
      const {cimExport = 0, discDownload = 0} = this.totalStats || {};
      const total = cimExport + discDownload;
      return {
        disc: discDownload,
        cim: cimExport,
        total
      }
    }
  },
  methods: {
    ...mapActions(["showAlertError"]),
    resolveTemplateGraphicUrl: (id, fileUrl, updatedAt) => resolveTemplateGraphicUrl(id, fileUrl, updatedAt),
    handleDateRangeChange( date, [startDate, endDate]) {
      if(!startDate && !endDate) {
        this.setTimeRange(this.fistDayOfThisMonth, this.lastDayOfThisMonth);
      } else {
        this.reloadReports()
        this.chosenPresetKey = 0;
      }
    },
    renderUsers(users) {
      const pluckUser = u => `${u.email} ${ u.name ? `(${u.name} ${u.last_name || ''})` : '' }`;
      return users.map(pluckUser).join('<br>')
    },
    changeReportType(type) {
      this.reportType = type;
      this.reloadReports()
    },
    setTimeRange(start, end) {
      this.timeRange = [start, end];
      this.reloadReports();
    },
    async reloadReports() {
      const [startDate, endDate] = this.timeRange.map(m => m.format ? m.format('yyyy-MM-DD') : m)
      try {
        this.isLoading =  true;
        this.errorMessage = '';
        const response = await reportService.getAll({
          startDate,
          endDate,
          type: this.reportType,
          filterUserIds: this.filterByUserIds
        });
        this.reportCollection = response.result;
        this.totalStats = response.total;
      } catch (e) {
        this.errorMessage = `Cannot load reports: ${e.message}`;
      } finally {
        this.isLoading = false
      }
    },
    async handleDownloadAsCSV() {
      const [startDate, endDate] = this.timeRange.map(m => m.format ? m.format('yyyy-MM-DD') : m);
      try {
        this.isCSVLoading =  true;
        await reportService.downloadAsCSV( {
          startDate,
          endDate,
          type: this.reportType,
          filterUserIds: this.filterByUserIds
        })
      } catch (e) {
        this.showAlertError(e.message)
      } finally {
        this.isCSVLoading = false
      }
    },
    handleUserFilterChange() {
      this.reloadReports();
    }
  },
  async mounted () {
    this.timeRange = [this.fistDayOfThisMonth, this.lastDayOfThisMonth]
    try {
      this.userFilter = await reportService.getFilterByUsers()
    } catch ( e )  {
       console.error(e);
    }
    await this.reloadReports();
  }
}
</script>
<style scoped lang="less">
   .reports {
     margin-top: 24px;

     &__title {
       margin-bottom: 24px;
     }

     &__filters {
       margin-bottom: 28px;
     }

     &__container {
       position: relative;
     }

     &__preloader {
       display: flex;
       justify-content: center;
       font-size: 1.5em
     }

     &__stats {
       margin: 24px;
       display: flex;
       gap: 24px;
       position: absolute;
       bottom: 0;
     }
   }
   .scroll {
     overflow-y: auto;
     max-height: calc(100vh - 420px);
     overflow-x: hidden;
   }
   .flex-container{
     display: flex;
     justify-content: space-between;
   }
   .template_thumbnail {
     width: 120px;
     height: 80px;
     background-size: contain;
     background-repeat: no-repeat;
     background-position: 50%;
   }
   .template_title {
     line-height: 80px;
     margin-left: 10px;
   }
</style>
