<template>
	<div id="app">
		<template v-if="isAuth">
			<s-main />
		</template>
		<template v-else>
      <router-view></router-view>
		</template>
  </div>
</template>

<script>
import Main from "./components/main/Main";
import { mapGetters } from "vuex"

export default {
	name: "App",
	components: {
		SMain: Main
	},
  computed: {
    ...mapGetters("user", ["isAuth"])
  }
}
</script>


