<template>
  <div class="movable-controls">
    <a-button @click="handleMovableFramesToggle" title="Toggle all movable frames">
      <a-icon type="edit" style="color: #4af" /> <span class="movable-controls__icon" v-html="checkAllIcon"/>
    </a-button>
    <a-button @click="handleBackgroundPhotoFramesToggle" title="Toggle all background frames">
      <a-icon type="edit" style="color: #9a0d0d" /> <span class="movable-controls__icon" v-html="checkAllIcon"/>
    </a-button>
  </div>
</template>

<script>
import { MOVABLE_BTN_SELECTOR, MOVABLE_CONTROL_BTN } from '@/templating/movables/control-types';

const CheckAllSvg = `
<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16" height="16" viewBox="0 0 24 24" style="fill:currentColor;">
  <path d="M 4 2 C 2.895 2 2 2.895 2 4 L 2 16 C 2 17.105 2.895 18 4 18 L 16 18 C 17.105 18 18 17.105 18 16 L 18 4 C 18 2.895 17.105 2 16 2 L 4 2 z M 4 4 L 16 4 L 16 16 L 4 16 L 4 4 z M 20 6 L 20 20 L 6 20 L 6 22 L 20 22 C 21.105 22 22 21.105 22 20 L 22 6 L 20 6 z M 13.292969 6.2929688 L 9 10.585938 L 6.7070312 8.2929688 L 5.2929688 9.7070312 L 9 13.414062 L 14.707031 7.7070312 L 13.292969 6.2929688 z"></path>
</svg>
`

export default {
  name: 'BannerMovableControls',
  computed: {
    checkAllIcon() {
      return CheckAllSvg;
    }
  },
  methods: {
    handleMovableFramesToggle() {
       document.querySelectorAll(`button[${MOVABLE_BTN_SELECTOR}="${MOVABLE_CONTROL_BTN.movableFrameToggle}"]`)
               .forEach(e => e.click())
    },
    handleBackgroundPhotoFramesToggle() {
      document.querySelectorAll(`button[${MOVABLE_BTN_SELECTOR}="${MOVABLE_CONTROL_BTN.photoBackgroundFrameToggle}"]`)
          .forEach(e => e.click())
    }
  }
};
</script>

<style scoped lang="less">
  .movable-controls {
    display: flex;
    margin-right: .5em;
    margin-left: .8em;
    &::after {
      content: ' ';
      padding-left: 1.5em;
      border-right: .1em solid @gray-6;
    }

    &__icon {
      display: inline-block;
      padding-top: .3em;
    }

    button {
      margin-left: .5em;
    }
  }
</style>
