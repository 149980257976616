import { PossibleValuesExtractor } from '@/components/project/components/mirror-fields/possible-values-extractor';

const MIRROR_TYPE = {
	text: 'text',
	image: 'image',
	photo: 'photo',
	cssVariables: 'cssVariables',
}


export class MirrorFields {

	/**
	 * @type {TemplateModel[]}
	 */
	templateModels = [];

	mirrors = {
		[MIRROR_TYPE.text]: {},
		[MIRROR_TYPE.image]: {},
		[MIRROR_TYPE.photo]: {},
		[MIRROR_TYPE.cssVariables]: {}
	};
	// Fields to consider by TYPE when check frozen values:
	frozenValueCheck = {
		[MIRROR_TYPE.text]: ['text'],
		[MIRROR_TYPE.image]: ['upload'],
		[MIRROR_TYPE.photo]: ['upload'],
		[MIRROR_TYPE.cssVariables]: ['variable'],
	}
	// Fields to consider by TYPE when check possible values:
	possibleValuesCheck = {
		[MIRROR_TYPE.text]: 'value',
		[MIRROR_TYPE.image]: 'url',
		[MIRROR_TYPE.photo]: 'url',
		[MIRROR_TYPE.cssVariables]: 'value',
	}


	/**
	 * @param templateModels {TemplateModel[]}
	 */
	constructor (templateModels = []) {
		this.templateModels = templateModels;
		this.prepareMirrors();
	}

	checkFieldIsFrozen({type, key, frozenFields} = {}) {
		const frozenTypes = frozenFields[type];
		if(!frozenTypes) {
			return false;
		}
		const frozenField = frozenTypes[key];
		if(!frozenField) {
			return false;
		}
		return this.frozenValueCheck[type].some((objectKey) => {
			// Check if any frozen key is present in frozen fields.
			return frozenField[objectKey]
		})
	}

	checkPossibleValuesExists({type, key, pVE}) {
		return Boolean(pVE.getPossibleValuesForKey(type, key, this.possibleValuesCheck[type]))
	}

	prepareMirrors() {
		if(this.templateModels.length < 0) {
			// Do not make any computations if there is only one template attached.
			return;
		}
		const MIRROR_KEYS = Object.keys(this.mirrors);
		for(const MIRROR_TYPE of MIRROR_KEYS) {
			for(const {id, settings, frozenFields, possibleValues, badgesLangToken } of this.templateModels) {
				const pVE = new PossibleValuesExtractor(possibleValues, badgesLangToken);
				Object.entries(settings[MIRROR_TYPE] || {}).forEach(([key, value]) => {
					// Skip field if it is frozen
					if(this.checkFieldIsFrozen({type: MIRROR_TYPE, key, frozenFields})){
						return;
					}
					// Skip field if it has possible values
					if(this.checkPossibleValuesExists({type: MIRROR_TYPE, key, pVE})) {
						return;
					}
					if(!this.mirrors[MIRROR_TYPE][key]) {
						this.mirrors[MIRROR_TYPE][key] = {};
					}
					this.mirrors[MIRROR_TYPE][key][id] = value
				})
			}
			Object.entries(this.mirrors[MIRROR_TYPE]).forEach(([key, value]) => {
				if(Object.keys(value || {}).length < 2) {
					delete this.mirrors[MIRROR_TYPE][key]
				}
			})
		}
	}

	get hasMirrorFields() {
		return this.hasTextMirrors || this.hasImageMirrors || this.hasPhotoMirrors || this.hasCssVarsMirrors;
	}

	get hasCssVarsMirrors() {
		return this.getCssVarsMirrors().length > 0;
	}

	get hasTextMirrors() {
		return this.getTextMirrors().length > 0;
	}

	get hasImageMirrors() {
		return this.getImageMirrors().length > 0;
	}

	get hasPhotoMirrors() {
		return this.getPhotoMirrors().length > 0;
	}

	getTemplateCssVarsTriggers(templateId, variableKey = '') {
		return this.getTemplateModelById(templateId)?.cssVariables?.[variableKey]?.triggers
	}

	/**
	 * @return ({fieldName: string, templateIds: number[]})[]
	 * */
	getMirrorsOfType(mirrorType = MIRROR_TYPE.text) {
		return Object.entries(this.mirrors[mirrorType])
			.map(([key, entry]) => ({fieldName: key, templateIds: Object.keys(entry).map(Number)}))
	}

	/**
	 * @return ({fieldName: string, templateIds: number[]})[]
	 * */
	getTextMirrors() {
		return this.getMirrorsOfType(MIRROR_TYPE.text)
	}

	/**
	 * @return ({fieldName: string, templateIds: number[]})[]
	 * */
	getImageMirrors() {
		return this.getMirrorsOfType(MIRROR_TYPE.image)
	}

	/**
	 * @return ({fieldName: string, templateIds: number[]})[]
	 * */
	getPhotoMirrors() {
		return this.getMirrorsOfType(MIRROR_TYPE.photo)
	}

	/**
	 * In css vars - we also need action triggers.
	 * Without them only "css var" is updated not the value of controlled (trigger) fields!
	 *
	 * @return ({fieldName: string, templateIds: number[], variableActionTriggers: {[key: number]: string[]}}})[]
	 * */
	getCssVarsMirrors() {
		return this.getMirrorsOfType(MIRROR_TYPE.cssVariables)
				.map(entry => ({
					...entry,
					variableActionTriggers: Object.fromEntries(entry.templateIds.map(id => [
						id,
						this.getTemplateCssVarsTriggers(id, entry.fieldName)
					]))
				}))
	}

	getCombinedValue(fieldName, {mirrorType = MIRROR_TYPE.text, valueKey = 'value'} = {}) {
		return Object.entries(this.mirrors[mirrorType][fieldName])
			// eslint-disable-next-line no-unused-vars
									.map(([templateId, fieldValue]) => `"${fieldValue[valueKey]}"`).join(',')
	}

	getTemplateModelById(templateId) {
		const model = this.templateModels.find(t => t.id === templateId)
		if(model) {
			return model;
		}
	}
}
