import { favouriteProjectService } from '@/services/favourites/favourite-projects';
import Vue from 'vue';
import { projectService } from '@/services/project.service'
import { makeCallWithPreloader } from '@/store/action-helpers'

function validateProjectName(dispatch, name) {
    if(!name) {
        const PROJECT_NAME_ERROR = 'Project must have a name';
        dispatch('showAlertWarning', PROJECT_NAME_ERROR, {root: true});
        throw new Error(PROJECT_NAME_ERROR);
    }
}

const PROJECT_PER_PAGE = 100;

export default {
    state: {
        projects: [],
        duplicatedProjects: [],
        favouriteProjects: [],
        order: ['updated_at', 'desc'],
        searchQuery: '',
        currentPage: 0,
        totalItems: 0,
        currentDuplicatedPage: 0,
        totalDuplicatedItems: 0,
        currentFavouritePage: 0,
        totalFavouriteItems: 0
    },
    namespaced: true,
    getters: {
        orderField: state => state.order[0],
        orderDir: state => state.order[1],
        projectsLength: state => state.projects.length,
        totalItems: state => state.totalItems,
        currentPage: state => state.currentPage + 1,

        duplicatedProjects: state => state.duplicatedProjects,
        duplicatedProjectsLength: state => state.duplicatedProjects.length,
        totalDuplicatedItems: state => state.totalDuplicatedItems,
        // We do not count favourites (those are part of both)
        allProjectCounter: ({totalItems, totalDuplicatedItems}) => totalItems + totalDuplicatedItems,
        allProjectIds: ({projects, duplicatedProjects}) => Array.from(new Set([...projects, ...duplicatedProjects].map(({id}) => id))),
        currentDuplicatedPage: state => state.currentDuplicatedPage + 1,
        favouriteProjects: state => state.favouriteProjects,
        currentFavouritePage: state => state.currentFavouritePage + 1,
        totalFavouriteItems: state => state.totalFavouriteItems,
        pageSize: () => PROJECT_PER_PAGE
    },
    mutations: {
        SET_PROJECTS(state, {total, results}) {
            state.projects = results;
            state.totalItems = total;
        },
        SET_DUPLICATED_PROJECTS(state, {total, results}) {
            state.duplicatedProjects = results;
            state.totalDuplicatedItems = total;
        },
        SET_FAVOURITE_PROJECTS(state, {total, results}) {
            state.favouriteProjects = results.map(p => {
                p.favourite = [];
                return p;
            });
            state.totalFavouriteItems = total;
        },
        setSearchQuery(state, query) {
            state.searchQuery = query;
        },
        setOrderBy(state, {fieldName, dir}) {
            state.order = [fieldName, dir];
        },
        addProject(state, project){
            state.projects.unshift(project);
            state.totalItems++;
        },
        removeProject(state, project){
            const index = state.projects.findIndex(p => p.id === project.id);
            if (index !== -1) {
                state.projects.splice(index, 1);
            }
        },
        duplicateProject(state, project){
            state.projects.push(project)
        },
        SET_CURRENT_PAGE(state, pageNo){
            state.currentPage = pageNo;
        },
        SET_CURRENT_DUPLICATED_PAGE(state, pageNo){
            state.currentDuplicatedPage = pageNo;
        },
        SET_CURRENT_FAVOURITE_PAGE(state, pageNo){
            state.currentFavouritePage = pageNo;
        },
        UPDATE_FIELD(state, {field, project}){
            const stateProject = state.projects.find(p => p.id === project.id);
            if (stateProject) {
                Vue.set(stateProject, field, project[field]);
            }
        },
        MARK_FAVOURITE(state, {projectId, favourite}){
            let stateProject = state.projects.find(p => p.id === projectId);
            if (!stateProject) {
                stateProject = state.duplicatedProjects.find(p => p.id === projectId);
            }
            if (stateProject) {
                Vue.set(stateProject, 'favourite', favourite);
            }
        }
    },
    actions: {
        async initProjects({ commit, dispatch }, {searchText, restartPagination = true} = {}) {
            if(searchText !== undefined) {
                if(restartPagination) {
                   commit('SET_CURRENT_PAGE', 0);
                   commit('SET_CURRENT_DUPLICATED_PAGE', 0);
                   commit('SET_CURRENT_FAVOURITE_PAGE', 0);
                }
                commit('setSearchQuery', searchText);
            }
            await makeCallWithPreloader(dispatch, () => Promise.all([
              dispatch('loadProjects'), dispatch('loadDuplicatedProjects'), dispatch('loadFavouriteProjects')
            ]));
            dispatch('showAlertSuccess', 'Projects loaded', {root: true});

            return [];
        },
        async loadProjects({ commit, state }) {
            const { searchQuery, order, currentPage } = state;
            const response = await projectService.all(searchQuery, order, currentPage, PROJECT_PER_PAGE);
            commit('SET_PROJECTS', response);
        },
        async loadDuplicatedProjects({ commit, state }) {
            const { searchQuery, order, currentDuplicatedPage } = state;
            const response = await projectService.all(searchQuery, order, currentDuplicatedPage, PROJECT_PER_PAGE, 'duplicated');
            commit('SET_DUPLICATED_PROJECTS', response);
        },
        async loadFavouriteProjects({ commit, state }) {
            const { searchQuery, order, currentFavouritePage } = state;
            const response = await  favouriteProjectService.getAll(searchQuery, order, currentFavouritePage, PROJECT_PER_PAGE);
            commit('SET_FAVOURITE_PROJECTS', response);
        },
        setOrder({commit, dispatch, state}, {fieldName, dir}) {
           const { order } = state;
           const [currentField, currentDirection] = order;
           // Update orderBy field or direction only if changed !!!
           if(currentField !== fieldName || currentDirection !== dir) {
               commit('setOrderBy', {fieldName, dir})
               dispatch('initProjects')
           }
        },
        async addProject({commit, dispatch}, project) {
           validateProjectName(dispatch, project);
           const newProject = await makeCallWithPreloader(dispatch, () => projectService.create(project.name));
           commit('addProject', newProject);
           dispatch('showAlertSuccess', 'New project has been created', {root: true});
           return newProject;
        },
        async renameProject( {commit, dispatch}, project){
            validateProjectName(dispatch, project.name);
            const updatedProject = await makeCallWithPreloader(dispatch, () => projectService.rename(project.id, project.name));
            commit('UPDATE_FIELD', {field: 'name', project: updatedProject});
            dispatch('showAlertSuccess', 'Project name successfully updated', {root: true});
            return updatedProject;
        },
        async setProjectDescription( {commit, dispatch}, {id, description}){
            const updatedProject = await makeCallWithPreloader(dispatch, () => projectService.setDescription(id, description));
            commit('UPDATE_FIELD', {field: 'description', project: updatedProject});
            dispatch('showAlertSuccess', 'Project description successfully updated', {root: true});
            return updatedProject;
        },
        async removeProject({commit, dispatch}, projectId){
            const removedProject = await makeCallWithPreloader(dispatch, () => projectService.remove(projectId));
            commit('removeProject', removedProject);
            dispatch('showAlertSuccess', 'Project deleted', {root: true});
        },
        async duplicateProject({commit, dispatch}, projectId){
            const project = await makeCallWithPreloader(dispatch, () => projectService.duplicate(projectId));
            commit('duplicateProject', project);
            dispatch('showAlertSuccess', `Project duplicated as: ${project.name}`, {root: true});
        },
        async duplicateProjectForOtherUser({dispatch}, {projectId, userEmails, userRoles}){
            await makeCallWithPreloader(dispatch, () => projectService.duplicateForOthers(projectId, {
                userEmails, userRoles
            }));
            dispatch('showAlertSuccess', `Project ${projectId} duplicated for chosen users`, {root: true});
        },
        async markFavourite({commit, dispatch}, {projectId, isFavourite}) {
            const result = await favouriteProjectService.markFavourite({projectId, isFavourite});
            const favourite = isFavourite ? result : null;
            commit('MARK_FAVOURITE', {projectId, favourite});
            return dispatch('loadFavouriteProjects')
         },
        updateProjectThumbnailInfo({commit}, {projectId, thumbnail, creationId}) {
            commit('UPDATE_FIELD', {
                field: 'thumbnail',
                project: { id: projectId, thumbnail }
            })
            commit('UPDATE_FIELD', {
                field: 'thumbnail_creation_id',
                project: { id: projectId, thumbnail_creation_id: creationId },
            })
        },
        changePage({commit, dispatch}, pageNo) {
           commit('SET_CURRENT_PAGE', pageNo - 1)
           dispatch('initProjects', {restartPagination: false})
        },
        changeDuplicatedPage({commit, dispatch}, pageNo) {
            commit('SET_CURRENT_DUPLICATED_PAGE', pageNo - 1)
            dispatch('initProjects', {restartPagination: false})
        },
        changeFavouritePage({commit, dispatch}, pageNo) {
            commit('SET_CURRENT_FAVOURITE_PAGE', pageNo - 1)
            dispatch('initProjects', {restartPagination: false})
        }
    }
};
