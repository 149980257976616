import { cacheResolver } from "@/view-helpers/cache-resolver";

const makeBadgeStyle = (background) => background ? `style="background: ${background};"` : '';
const makeBadgeIcon = (icon_url) => icon_url ? `<img class="badges__logo" src="${cacheResolver.assureUrlWithVersionQuery(icon_url)}" alt="badge" />` : '';
const makeBadgeTextColor = (text_color) => text_color ? `color: ${text_color};` : 'color: #fff;';

export function makeStandardBadge({id, label, background, icon_url, color}) {
	return `
		<div data-badge-id="${id || label?.toLowerCase()}" class="badges__item" ${makeBadgeStyle(background)}>
      <div class="badges__content">
         <div style="${makeBadgeTextColor(color)}">
						${ label || '' } ${ makeBadgeIcon(icon_url) }
				</div>
      </div>
    </div>
	`
}

const langInBulkMap = {
	pl: 'W HURCIE',
	en: 'IN BULK'
}

export function makeDiscountBadge({id, label, label_two}, lang = 'pl') {
	// @Special badge buy in bulk:
	const inBulk = id && typeof id === 'string' && id.endsWith('_in_bulk');
	return `
    <div data-badge-id="${id || label?.toLowerCase()}" class="badges__item badges__item--discount">
       <div class="badges__content">
          <span data-product-price="" style="text-decoration: line-through;" class="strikethrough">${label || ''}*</span> 
          <span data-product-discount="">
								${label_two || ''} ${
								inBulk ? 
									'<span class="discount__in__bulk">' + (langInBulkMap[lang] || langInBulkMap['en']) + '</span>' 
									: 
									''}
          </span>
       </div>
    </div>
	`
}

