import Purifier from 'html-purify';
import { marked } from 'marked';

// @Business: keep in-sync with back-end template.purifier
const DOMAIN_WHITELIST = [
	'a.allegroimg.com',
	'allegro.pl',
	'allegrolokalnie.pl',
	'figma.com',
	'google.com',
	'docs.google.com',
	'drive.google.com',
	'localhost',
	'jira.allegrogroup.com'
]

function idDomainValid(link) {
	if(link.match(/^http:\/\//)) {
		// only https:// !
		return false;
	}
	const baseURL = link.match(/^https:\/\//) ? undefined : window.location.href
	try {
		const url = new URL(link, baseURL);
		return DOMAIN_WHITELIST.includes(url.hostname)
	} catch (e) {
		console.warn('Failed to make markup link', e?.message)
		return false;
	}
}

function purifyDomains( html ) {
	return html.replace(
		/(src|href)=["'](.*?)["']/gi,
		(fullMatch, attribute, urlValue) => idDomainValid(urlValue) ? fullMatch : ''
	)
}

export const parsedMarkdownPurifier = new Purifier({
	whitelistTags: ['p', 'em', 'strong', 'br', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'ol', 'li', 'a', 'code', 'blockquote', 'hr', 'img', 'pre', 'del', 'input'],
	whitelistAttributes: ['class', 'style', 'href', 'target', 'rel', 'src', 'alt', 'title', 'checked', 'disabled', 'type', 'width', 'height']
})

marked.setOptions({
	headerIds: false
})

marked.use({
	renderer: {
		link(href, title, text) {
			const hrefAttr = idDomainValid(href) ? ` href="${href}"` : ''
			return `<a${hrefAttr} target="_blank" rel="noopener noreferrer">${text}</a>`
		},
		image(href, title, text) {
			const srcAttr = idDomainValid(href) ? ` src="${href}"` : ''
			return `<img${srcAttr} alt="${text}">`
		}
	}
})

export const markupParser = {
	parse(inputText) {
		const htmlAfterMarked = marked.parse(inputText)
		const purified = parsedMarkdownPurifier.purify(htmlAfterMarked);
		return purifyDomains(purified)
	}
}
