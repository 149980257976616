export const isNotEmpty = value => {
    if (!value && value !== 0) {
        return false
    }
    if (Array.isArray(value)) {
        return value.length > 0
    }
    if (value instanceof Date || value instanceof RegExp) {
        return true
    }
    if (typeof value === 'object') {
        return Object.values(value).length > 0
    }
    return true
}
