
// RegExp WARNING [!]
// Color RegExp matcher, MUST BE wrapped in grouping (....) to worki well with .split() logic !!!

// color in Hex notations (With alpha)
const REGEXP_COLOR_HEX = /(#[0-9a-f]{8}|#[0-9a-f]{6}|#[0-9a-f]{3})/gi

// color as rgb string
const REGEXP_COLOR_RGB = /(rgb\(\s*\d{1,3}\s*,\s*\d{1,3}\s*,\s*\d{1,3}\s*\))/gi

// color as rgba string
const REGEXP_COLOR_RGBA = /(rgba\(\s*\d{1,3}\s*,\s*\d{1,3}\s*,\s*\d{1,3}\s*,\s*\d\s*\))/gi

export const REGEXP_COLORS_MATCH_ALL = new RegExp([
	REGEXP_COLOR_HEX.source,
	REGEXP_COLOR_RGB.source,
	REGEXP_COLOR_RGBA.source,
].join('|'), 'gi')

// it is better to use .match in case of /g Regexp state gotcha!
export const hasNoColorValue = (possibleColor = '') => possibleColor.match(REGEXP_COLORS_MATCH_ALL) === null
