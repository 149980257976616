<template>
    <li @click="selectTemplate" :class="{'links_item--disabled': !canSelect}">
        <p v-if="!editMode">{{ template.name }} ({{ type }})</p>
        <a-input
            v-else
            v-model="templateName"
            @blur="editMode = false"
            @keyup.enter="handleNewNameConfirm()"
            placeholder="Template name"
        />
        <div v-if="isTest || isArchived" style="margin-left: auto;" >
          <a-tag v-if="isTest" style="padding: 0 4px" color="red" title="TEST Template">T</a-tag>
          <a-tag v-if="isArchived" style="padding: 0 4px" color="gray" title="Archived Template">A</a-tag>
        </div>
        <a-dropdown>
            <a class="ant-dropdown-link" @click.prevent="">
                <a-icon type="more"/>
            </a>
            <a-menu slot="overlay">
<!--                <a-menu-item @click="handleRename()">-->
<!--                    <span><a-icon type="edit" /> Rename</span>-->
<!--                </a-menu-item>-->
                <a-menu-item @click="$emit('deleteTemplate')">
                    <span><a-icon type="delete" /> Delete</span>
                </a-menu-item>
            </a-menu>
        </a-dropdown>
    </li>

</template>


<script>

export default {
    name: 'TemplateListItem',
    props: {
      template: Object,
      canSelect: {
        type: Boolean,
        default: true
      }
    },
    data(){
      return {
        editMode: false,
        templateName: this.template?.name
      }
    },
    computed: {
       type() {
          return this.template.type === 'product' ? 'Product' : 'Generic'
       },
       isTest() {
         return Boolean(this.template?.is_test)
       },
       isArchived() {
         return this.template?.status === 'INACTIVE';
       }
    },
    methods: {
      selectTemplate(){
          if(!this.editMode && this.canSelect){
              this.$emit('selectTemplate')
          }
      },
      handleNewNameConfirm() {
        this.$emit('renameTemplate', {
          name: this.templateName,
          onComplete: () => {
            this.editMode = false;
          }
        })
      }
    }
}
</script>
<style scoped>
  .links_item--disabled {
    color: #666060;
  }
</style>
