<template>
  <div class="campaign-card" @click="$emit('campaignChosen', campaign)">
    <expiration-marker class="campaign-card_marker" :expired-at="campaign.expires_at" />
    <img class="campaign-card_img" :src="coverUrl"/>
    <div class="campaign-card_title">
      {{campaign.name}}
    </div>
    <div class="campaign-card_tags">
      <a-tag color="#108ee9" v-for="{name} of campaign.tags" :key="name">
        {{name}}
      </a-tag>
    </div>
  </div>
</template>

<script>

import ExpirationMarker from "@/components/gallery/components/ExpirationMarker.vue";
import {galleryService} from "@/services/gallery.service";

export default {
  name: "CampaignCard",
  components: {ExpirationMarker},
  props: {
    campaign: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    coverUrl() {
      const {cover_url} = this.campaign;
      return cover_url ? galleryService.getGalleryItemUrl(cover_url) : 'placeholder.png'
    }
  }
}
</script>

<style scoped lang="less">
    .campaign-card {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      min-height: 300px;
      border: solid 2px @gray-4;
      border-radius: 6px;
      cursor: pointer;
      &:hover {
        border-color: @gray-6;
      }

      &_marker {
        margin: 1em;
        position: absolute;
        z-index: 10;
      }

      &_img {
        max-height: 180px;
        width: 100%;
        background-size: cover;
        object-fit: scale-down;
        background: #0c0c0c;
      }
      &_title {
        padding: 10px 25px;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
      &_tags {
        padding: 0 25px;
      }
    }
</style>
