export const CTA_TYPE = {
	NONE: 'none',
	SEE_MORE: 'see-more',
	MOBILE_ARROW: 'mobile-arrow'
}

export const CTA_MAPPER = {
	[CTA_TYPE.NONE]: '',
	[CTA_TYPE.SEE_MORE]: 'CTA',
	[CTA_TYPE.MOBILE_ARROW]: 'mobile CTA',
}
