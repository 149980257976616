import { axInstance } from './ax-instance'

const makeFrozenFieldsURI = templateId => `/templates/${templateId}/frozen-fields`;

export const templateFrozenFieldsService = {
	getValues(templateId) {
		return axInstance.get(makeFrozenFieldsURI(templateId))
	},
	saveValues(templateId, values) {
		return axInstance.patch(makeFrozenFieldsURI(templateId), {values})
	}
}
