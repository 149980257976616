import { fileDownloadService } from '@/services/file-download.service'
import { format } from 'date-fns'
import { axInstance } from './ax-instance'

const BASE_REPORT_URI = '/reports';

function getAll({startDate, endDate, type, downloadAs, filterUserIds} = {}) {
	return axInstance.get(BASE_REPORT_URI, { params: {startDate, endDate, type, downloadAs, filterUserIds}})
}

function downloadAsCSV({startDate, endDate, type, filterUserIds} = {}) {
	return getAll({startDate, endDate, type, filterUserIds, downloadAs: 'csv'})
		.then((content) => {
			const date = format(new Date(), 'yyyy-MM-dd-HHmmss')
			fileDownloadService.saveFile(content, `showbox_report_from${startDate}_to${endDate}__${date}`, 'csv', 'text/csv;charset=utf-8');
		})
}

function getFilterByUsers() {
	return axInstance.get(`${BASE_REPORT_URI}/filter-by-users`)
}

export const reportService = {
	getAll,
	downloadAsCSV,
	getFilterByUsers
}
