var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"clearfix"},[_c('a-row',[_vm._l((_vm.unwrappedFileList),function(file){return _c('a-col',{key:file.uid,attrs:{"xs":_vm.colSpaceForImage}},[_c('div',{staticClass:"img_preview",attrs:{"title":file.computedName,"set":(_vm.fileStatus = _vm.getFileStatus(file))},on:{"mouseenter":function($event){_vm.showImgOverflowUID = file.uid},"mouseleave":function($event){_vm.showImgOverflowUID = ''}}},[(
                        file.computedName && _vm.showImgOverflowUID === file.uid
                    )?_c('div',{staticClass:"img_preview-filename"},[_vm._v(" "+_vm._s(file.computedName)+" ")]):_vm._e(),(_vm.showImgOverflowUID === file.uid)?_c('div',{staticClass:"img_overflow"},[(
                            _vm.fileStatus !== 'EXPIRED' &&
                                !file.isArchived &&
                                _vm.canSelect
                        )?_c('a-button',{staticClass:"img_overflow__button",attrs:{"type":"ghost","icon":"check","title":"Select"},on:{"click":function($event){return _vm.handleChoose(file)}}}):_vm._e(),(
                            _vm.canChangeFocalPoint &&
                                _vm.fileStatus !== 'EXPIRED' &&
                                !file.isArchived &&
                                _vm.canSelect
                        )?_c('a-button',{staticClass:"img_overflow__button",attrs:{"type":"ghost","icon":"pic-center","title":"Focal Point"},on:{"click":function($event){return _vm.handleFocalPointOpen(file)}}}):_vm._e(),(!file.isNotRemovable)?_c('a-button',{staticClass:"img_overflow__button",attrs:{"type":"ghost","icon":"delete","title":"Remove"},on:{"click":function($event){return _vm.handleRemove(file)}}}):_vm._e()],1):_vm._e(),_c('img',{style:(_vm.imgComputedStyle),attrs:{"src":file.url,"alt":`Image ${file.computedName || ''} preview`}}),_c('div',{staticClass:"img_icon_type"},[(file.type === 'GALLERY_ITEM')?_c('a-icon',{attrs:{"type":"picture"}}):_vm._e(),(file.type === 'FILE')?_c('a-icon',{attrs:{"type":"edit"}}):_vm._e()],1),_c('div',{staticClass:"img_icon_expires"},[_c('icon-expiration-for-file',{attrs:{"file":file}})],1)])])}),(_vm.canUpload && _vm.canUploadMore)?_c('a-col',{attrs:{"xs":_vm.colSpaceForImage}},[_c('a-upload',{ref:"uploader",attrs:{"list-type":"picture-card","multiple":true,"file-list":[],"customRequest":() => {},"accept":"image/jpeg,image/png,image/svg+xml"},on:{"change":({ file }) => _vm.$emit('uploaded', file)}},[_c('div',[_c('a-icon',{attrs:{"type":"upload"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v(" Upload ")])],1)])],1):_vm._e(),(_vm.canAddFromGallery)?_c('a-col',{staticStyle:{"padding":"10px","height":"104px","display":"flex","align-items":"center","gap":"10px","justify-content":"center"},attrs:{"gutter":[16, 16],"xs":_vm.colSpaceForGalleryBtn}},[(_vm.canUpload && _vm.canUploadMore)?_c('span',[_vm._v("or")]):_vm._e(),_c('a-button',{on:{"click":function($event){_vm.chosenCampaignId = 0
                    _vm.showFormFotosUploaderAddFromGalleryModal = true}}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" Add from gallery ")],1)],1):_vm._e()],2),(_vm.showFormFotosUploaderAddFromGalleryModal)?_c('form-fotos-uploader-add-from-gallery-modal',{attrs:{"selection-type":_vm.gallerySelectionType,"campaign-id":_vm.chosenCampaignId,"gallery-title":_vm.galleryTitle},on:{"addItemsFromGallery":function($event){return _vm.handleAddFromGallery($event)},"addCampaigns":function($event){return _vm.handleAddCampaigns($event)}},model:{value:(_vm.showFormFotosUploaderAddFromGalleryModal),callback:function ($$v) {_vm.showFormFotosUploaderAddFromGalleryModal=$$v},expression:"showFormFotosUploaderAddFromGalleryModal"}}):_vm._e(),(_vm.showFormFotosUploaderPreviewAndFocalPointModal)?_c('form-fotos-uploader-preview-and-focal-point-modal',{attrs:{"file":_vm.chosenFile,"h-flip-photo":_vm.hFlipPhotos},on:{"applyFocalPoint":function($event){return _vm.handleApplyFocalPoint($event)}},model:{value:(_vm.showFormFotosUploaderPreviewAndFocalPointModal),callback:function ($$v) {_vm.showFormFotosUploaderPreviewAndFocalPointModal=$$v},expression:"showFormFotosUploaderPreviewAndFocalPointModal"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }