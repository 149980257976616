export const SHAPE = {
    cssVar: 'css--var',
    text: 'text',
    color: 'color',
    alphaColor: 'alphaColor',
    image: 'image',
    font: 'font',
}

export const SHAPE_GROUP_KEYS_MAPPER = {
    cssVariables: makeGroupKey('-- CSS Variables --', {
        value: SHAPE.cssVar,
    }),
    text: makeGroupKey('-- Text Values --', {
        value: SHAPE.text,
        color: SHAPE.color,
        font: SHAPE.font,
    }),
    photo: makeGroupKey('-- Background image --', {
        url: SHAPE.image,
    }),
    image: makeGroupKey('-- Image / Icons --', {
        url: SHAPE.image,
    }),
    background: makeGroupKey('-- Background Colors --', {
        background: SHAPE.alphaColor,
    }),
    border: makeGroupKey('-- Border Colors --', {
        color: SHAPE.alphaColor,
    }),
    mask: makeGroupKey('-- Svg Masks --', {
        url: SHAPE.image,
    }),
    moveable: makeGroupKey('-- Moveable --', {}),
}

export const SHAPE_GROUP_GROUP_KEYS = Object.keys(SHAPE_GROUP_KEYS_MAPPER)
export const SHAPE_GROUP_KEY_MAP = Object.fromEntries(
    SHAPE_GROUP_GROUP_KEYS.map(k => [k, k])
) // { text: 'text', photo: 'photo' ...}

function makeGroupKey(name, dataShape = { value: SHAPE.text }) {
    return { name, dataShape }
}
