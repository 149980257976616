import { render, staticRenderFns } from "./ConfirmUserRemoval.vue?vue&type=template&id=68feaa10&scoped=true"
import script from "./ConfirmUserRemoval.vue?vue&type=script&lang=js"
export * from "./ConfirmUserRemoval.vue?vue&type=script&lang=js"
import style0 from "./ConfirmUserRemoval.vue?vue&type=style&index=0&id=68feaa10&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68feaa10",
  null
  
)

export default component.exports