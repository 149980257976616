<template>
  <section>
    <gallery-page-sub-header
      :sort-by-options="sortBy"
      @sortBy="handleSortBy($event)"
      @searchText="handleSearchText($event)"
      @fileUploadSuccess="handleFileUploadSuccess($event)"
      @addNewCampaign="newCampaignModal = true"
      @addNewGalleryItem="handleItemAdd()"
    />
    <router-view
        :searchText="filterValue"
        :sortBy="sortBy"
        :newUploadedFile="newUploadedFile"
        :newCreatedCampaign="newCreatedCampaign"
        @itemEdit="handleItemEdit($event)"
        @itemUnarchive="handleItemUnarchive($event)"
    />
    <new-or-update-campaign-modal
        v-if="newCampaignModal"
        @closeModal="newCampaignModal = false"
        @newCampaignAdded="newCreatedCampaign = $event"
    />
    <new-or-update-gallery-item-modal
        v-if="newItemGalleryModal"
        :edit-model="itemToEdit"
        :edit-strategy="editStrategy"
        @closeModal="newItemGalleryModal = false"
        @fileUploadSuccess="handleFileUploadSuccess($event)"
        @galleryItemUpdated="handleItemUpdate($event)"
        @newItemAdded="newItemGalleryModal = $event"
    />
  </section>
</template>

<script>
import GalleryPageSubHeader from '@/components/gallery/components/GalleryPageSubHeader.vue';
import NewOrUpdateCampaignModal from "@/components/gallery/components/NewOrUpdateCampaignModal.vue";
import {mapActions} from "vuex";
import NewOrUpdateGalleryItemModal from "@/components/gallery/components/NewOrUpdateGalleryItemModal.vue";
import {mapGalleryItemToEditModel} from "@/components/gallery/model/gallery-item";

const EDIT_STRATEGY_UPDATE = 'UPDATE'
const EDIT_STRATEGY_UNARCHIVE = 'UPDATE_AND_UNARCHIVE'

export default {
  name: 'GalleryPage',
  components: {
    NewOrUpdateGalleryItemModal,
    NewOrUpdateCampaignModal,
    GalleryPageSubHeader
  },
  data() {
    return {
      newCampaignModal: false,
      newItemGalleryModal: false,
      itemToEdit: null,
      filterValue: '',
      newUploadedFile: {},
      newCreatedCampaign: {},
      editStrategy: EDIT_STRATEGY_UPDATE,
      sortBy: {
        current: 'created_at',
        direction: 'asc'
      }
    }
  },
  methods: {
    ...mapActions(['showAlertError', 'showAlertSuccess']),
    handleSortBy(options) {
      this.sortBy = options;
    },
    handleSearchText(filterValue) {
       this.filterValue = filterValue;
    },
    async handleFileUploadSuccess(antFile) {
       this.newUploadedFile = antFile
    },
    async handleItemUpdate(galleryItem) {
      this.newUploadedFile = galleryItem
      if(this.editStrategy === EDIT_STRATEGY_UNARCHIVE) {
        await this.onUnarchiveConfirm();
      }
    },
    handleItemAdd() {
      this.itemToEdit = null;

      this.newItemGalleryModal = true
    },
    handleItemEdit(galleryItem) {
      this.itemToEdit = mapGalleryItemToEditModel(galleryItem);
      this.editStrategy = EDIT_STRATEGY_UPDATE;
      this.newItemGalleryModal = true
    },
    handleItemUnarchive({galleryItem, onConfirm}) {
      this.itemToEdit = mapGalleryItemToEditModel(galleryItem);
      this.editStrategy = EDIT_STRATEGY_UNARCHIVE;
      this.onUnarchiveConfirm = onConfirm
      this.newItemGalleryModal = true
    }
  }
}
</script>

<style scoped lang="less">

</style>
