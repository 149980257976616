import { axInstance, cancelableRequest } from './ax-instance'
import { useGalleryCache } from '@/store/modules/gallery-cache.store'

const BASE_GALLERY_URI = '/gallery'
const BASE_GALLERY_CAMPAIGN_URI = '/gallery/campaigns'

function updateGalleryCacheHook(data) {
    useGalleryCache().addGalleryItems(data?.results || [])
    return data
}
function updateCampaignsCacheHook(data) {
    useGalleryCache().addCampaigns(data?.results || [])
    return data
}
function forceUpdateGalleryFromServerCache(
    itemOrCampaignId,
    isCampaign = false
) {
    return async data => {
        if (isCampaign) {
            await galleryService.getCampaignsFromCache([itemOrCampaignId])
        } else {
            await galleryService.getItemsFromCache([itemOrCampaignId])
        }
        return data
    }
}

export const galleryService = {
    getAllCampaigns(
        query = '',
        { page = 0, limit = 100 } = {},
        { orderBy = 'created_at', orderDir = 'asc' } = {}
    ) {
        return cancelableRequest(({ cancelToken }) =>
            axInstance
                .get(BASE_GALLERY_CAMPAIGN_URI, {
                    params: { page, limit, query, orderBy, orderDir },
                    cancelToken,
                })
                .then(updateCampaignsCacheHook)
        )
    },
    getAllForCampaign(campaignId, page = 0, limit = 100) {
        return cancelableRequest(({ cancelToken }) =>
            axInstance
                .get(`${BASE_GALLERY_CAMPAIGN_URI}/${campaignId}`, {
                    params: { page, limit },
                    cancelToken,
                })
                .then(updateGalleryCacheHook)
        )
    },
    getAll(
        query = '',
        { page = 0, limit = 100, campaignId = undefined } = {},
        { orderBy = 'created_at', orderDir = 'asc' } = {}
    ) {
        return cancelableRequest(({ cancelToken }) =>
            axInstance
                .get(BASE_GALLERY_URI, {
                    params: {
                        page,
                        limit,
                        query,
                        orderBy,
                        orderDir,
                        campaignId,
                    },
                    cancelToken,
                })
                .then(updateGalleryCacheHook)
        )
    },
    getAllArchived(
        query = '',
        { page = 0, limit = 100 } = {},
        { orderBy = 'created_at', orderDir = 'asc' } = {}
    ) {
        return cancelableRequest(({ cancelToken }) =>
            axInstance
                .get(`${BASE_GALLERY_URI}/inactive`, {
                    params: { page, limit, query, orderBy, orderDir },
                    cancelToken,
                })
                .then(updateGalleryCacheHook)
        )
    },
    async uploadItem(
        galleryItemFile,
        { campaignId, name, tags, expiresAt, neverExpires, expirationType } = {}
    ) {
        const photoData = new FormData()
        photoData.append('file', galleryItemFile)
        photoData.append('expirationType', expirationType)
        campaignId && photoData.append('campaignId', campaignId)
        name && photoData.append('name', name)
        tags && photoData.append('tags', JSON.stringify(tags))
        expiresAt instanceof Date &&
            photoData.append('expiresAt', JSON.stringify(expiresAt))
        neverExpires && photoData.append('neverExpires', neverExpires)
        return axInstance.post(`${BASE_GALLERY_URI}/upload`, photoData, {
            headers: {
                'content-type': 'multipart/form-data',
            },
        })
    },
    patchItem(itemId, updateData) {
        return axInstance
            .patch(`${BASE_GALLERY_URI}/${itemId}`, updateData)
            .then(forceUpdateGalleryFromServerCache(itemId))
    },
    archiveGalleryCampaignItem(itemId) {
        return axInstance
            .patch(`${BASE_GALLERY_URI}/${itemId}/archive`, {
                status: 'INACTIVE',
            })
            .then(forceUpdateGalleryFromServerCache(itemId))
    },
    unArchiveGalleryCampaignItem(itemId) {
        return axInstance
            .patch(`${BASE_GALLERY_URI}/${itemId}/archive`, {
                status: 'ACTIVE',
            })
            .then(forceUpdateGalleryFromServerCache(itemId))
    },
    getGalleryItemUrl(filePath) {
        return `${process.env.VUE_APP_API_URL}/templates/gallery-files/${filePath}`
    },
    makeCampaign(campaign) {
        return axInstance.post(BASE_GALLERY_CAMPAIGN_URI, campaign)
    },
    removeCampaign(campaignId) {
        return axInstance
            .delete(`${BASE_GALLERY_CAMPAIGN_URI}/${campaignId}`)
            .then(forceUpdateGalleryFromServerCache(campaignId, true))
    },
    patchCampaign(campaignId, updateData) {
        return axInstance
            .patch(`${BASE_GALLERY_CAMPAIGN_URI}/${campaignId}`, updateData)
            .then(forceUpdateGalleryFromServerCache(campaignId, true))
    },
    getAllPossibleTags() {
        return axInstance.get(`${BASE_GALLERY_URI}/tags`)
    },
    removeItem(itemId) {
        return axInstance
            .delete(`${BASE_GALLERY_URI}/${itemId}`)
            .then(forceUpdateGalleryFromServerCache(itemId))
    },
    getCampaignsFromCache(ids) {
        return axInstance
            .get(`${BASE_GALLERY_URI}/cache`, {
                params: { type: 'campaign', ids },
            })
            .then(results => updateCampaignsCacheHook({ results }))
            .catch(e => {
                console.error(
                    `getting campaigns from cache error: ${e.message}`
                )
            })
    },
    getItemsFromCache(ids) {
        return axInstance
            .get(`${BASE_GALLERY_URI}/cache`, {
                params: { type: 'items', ids },
            })
            .then(results => updateGalleryCacheHook({ results }))
            .catch(e => {
                console.error(
                    `getting gallery items from cache error: ${e.message}`
                )
            })
    },
    searchItemUrlsFromCache(urls) {
        return axInstance
            .put(`${BASE_GALLERY_URI}/cache`, { urls })
            .then(results => updateGalleryCacheHook({ results }))
            .catch(e => {
                console.error(
                    `getting gallery items from cache error: ${e.message}`
                )
            })
    },
}
