import { axInstance } from '@/services/ax-instance'

const BASE_SETTINGS_CTA_SIZES_URI = '/settings/cta-sizes'

export const sizesWithCtaService = {
    getAll() {
        return axInstance.get(BASE_SETTINGS_CTA_SIZES_URI)
    },
    create(safeAreaData) {
        return axInstance.post(BASE_SETTINGS_CTA_SIZES_URI, safeAreaData)
    },
    update(areaId, safeAreaData) {
        return axInstance.patch(
            `${BASE_SETTINGS_CTA_SIZES_URI}/${areaId}`,
            safeAreaData
        )
    },
    remove(areaId) {
        return axInstance.delete(`${BASE_SETTINGS_CTA_SIZES_URI}/${areaId}`)
    },
}
