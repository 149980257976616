<template>
  <div class="users_page">
    <div class="users_page__header">
        <h2>
          Users management<span class="users_page__total">({{totalItems}})</span>
        </h2>
        <a-input-search
            class="users_page__search_bar"
            :allow-clear="true"
            @input="handleSearchInput()"
            v-model="searchPhrase"
        >

        </a-input-search>
        <a-button
            @click="handleDownloadUsersAsCSV"
            :loading="isCSVLoading"
        >
          <a-icon type="file" v-if="!isCSVLoading" /> Download all as CSV
        </a-button>
        <a-button
            v-if="hasPermission(allPermissions.USER_CREATE)"
            @click="handleNewUserAddClick()"
            type="primary"
        >
          <a-icon type="plus" class="users_page__btn-icon"/>
          New user
        </a-button>
    </div>
    <section style="margin: 10px 0">
      <div v-if="isLoading" class="users_page__preloader">
        <inline-preloader text="Loading users..."></inline-preloader>
      </div>
      <div v-else-if="errorMessage">
        <a-alert
            style="margin-top: 15px;"
            type="error"
            :show-icon="true"
            :message="errorMessage"
        />
      </div>
      <template v-else>
        <div style="margin: 10px 0" class="scroll users_page__scroll-panel">
          <a-table
              class="users_page__table"
              :columns="userColumns"
              :data-source="usersCollection"
              :pagination="{ pageSize, total: totalItems, current: currentPage + 1, position: 'both' }"
              :row-key="record => record.id"
              size="small"
              @change="handlePaginationChange"
          >
            <template #action="user" >
              <confirm-user-removal
                  v-if="hasPermission(allPermissions.USER_DELETE) && currentUserId !== user.id"
                  :confirmation-email="user.email"
                  @removalConfirmed="handleRemove(user.id)"
              >
                <a> Delete </a>
              </confirm-user-removal>
              <a v-if="hasPermission(allPermissions.USER_EDIT)" @click="handleUserEdit(user)"> Edit </a>
            </template>
          </a-table>
        </div>
      </template>
    </section>
    <new-or-update-user-modal
      v-if="showNewUserModal"
      :permission-list="permissionList"
      :edited-user-data="userEditionData"
      :user-edition-mode="editMode"
      @closeNewUserModal="showNewUserModal = false"
      @userCreationSuccess="handleUserCreated()"
      @userEditionSuccess="handleUserCreated()"
    />
  </div>
</template>

<script>
import InlinePreloader from '@/components/project/components/InlinePreloader'
import ConfirmUserRemoval from '@/components/users/components/ConfirmUserRemoval';
import NewOrUpdateUserModal from '@/components/users/components/NewOrUpdateUserModal';
import { userService } from '@/services/user.service'
import { MAPPED_USER_ROLES } from '@/store/modules/user';
import { debounce } from '@/view-helpers/debouncer';
import { mapActions, mapGetters, mapState } from 'vuex';

const USER_STATUS_MAPPER = {
  ACTIVE: 'Active',
  BAN: 'Banned',
  RESET_PASS: 'Password reset',
  INVITED: 'Pending invitation',
  TO_CONFIRM: 'Signup confirmation',
}

const USER_PER_PAGE_LIMIT = 20;

export default {
  name: 'UsersPage',
  components: {
    InlinePreloader,
    NewOrUpdateUserModal,
    ConfirmUserRemoval
  },
  data() {
    return {
      isLoading: false,
      errorMessage: '',
      usersCollection: [],
      permissionList: {},
      showNewUserModal: false,
      currentPage: 0,
      totalItems: 0,
      userEditionData: {},
      editMode: false,
      isCSVLoading: false,
      searchPhrase: ''
    }
  },
  computed: {
    ...mapGetters('user', ['hasPermission', 'allPermissions']),
    ...mapState('user', { currentUserId: 'id' }),
    userColumns() {
      return [
        { title: 'Id', width: '10%', key: 'id', dataIndex: 'id' },
        { title: 'E-mail', dataIndex: 'email', },
        { title: 'Name', dataIndex: 'name', },
        { title: 'Last name', dataIndex: 'last_name', },
        {
          title: 'Status',
          dataIndex: 'status',
          customRender: (status) => USER_STATUS_MAPPER[status]  },
        {
          title: 'Roles',
          dataIndex: 'roles',
          customRender: (roles) => (roles||[]).map(r => MAPPED_USER_ROLES[r.name]).filter(Boolean).join(', ')
        },
        {
          title: 'Action',
          scopedSlots: { customRender: 'action' }
        }
      ]
    },
    pageSize() {
      return USER_PER_PAGE_LIMIT;
    }
  },
  methods: {
    ...mapActions(['showAlertError', 'showAlertSuccess']),
    handleUserCreated() {
      this.showNewUserModal = false;
      this.reloadUsers();
    },
    async handleRemove(userId){
      try {
        await userService.remove(userId);
        this.reloadUsers();
        this.showAlertSuccess('User successfully deleted')
      } catch( e ) {
         this.showAlertError(e.message);
      }
    },
    handleUserEdit(userData) {
      this.editMode = true;
      this.userEditionData = userData;
      this.showNewUserModal = true;
    },
    async reloadUsers() {
      this.isLoading = true;
      try {
        const response = await userService.getAll(this.currentPage, this.pageSize, this.searchPhrase);
        this.usersCollection = response.results;
        this.totalItems = response.total;
        this.permissionList = await userService.getPermissionList();
      } catch( e ) {
        this.errorMessage = e.message;
      } finally {
        this.isLoading = false;
      }
    },
    handlePaginationChange({ current }) {
      this.currentPage = current - 1;
      this.reloadUsers()
    },
    handleNewUserAddClick() {
      this.editMode = false;
      this.userEditionData = undefined;
      this.showNewUserModal = true;
    },
    handleSearchInput: debounce(function(timer) {
      this.timeout = timer;
      // #Fix: reset pagination when start searching:
      this.currentPage = 0;
      this.reloadUsers()
    }),
    async handleDownloadUsersAsCSV() {
      try {
        this.isCSVLoading =  true;
        await userService.downloadAsCSV();
      } catch( e ) {
        this.showAlertError(e.message)
      } finally {
        this.isCSVLoading = false
      }
    }
  },
  mounted() {
    this.reloadUsers();
  },
  beforeDestroy() {
    if( this.timeout ) {
      clearTimeout(this.timeout);
    }
  }
}
</script>
<style lang="less">
  .users_page {
    &__table .ant-table {
      border: 0;
    }
    &__search_bar .ant-input-suffix {
      top: 18px;
    }
  }
</style>
<style scoped lang="less">
   .users_page {

     &__btn-icon {
       width: 16px;
     }

     &__header {
       margin-top: 2em;
       margin-bottom: 2em;
       display: flex;
       gap: 1em;
       justify-content: space-between;
     }

     &__total {

       margin-left: 8px;
       font-size: 16px;
       font-weight: 600;
     }

     &__search_bar {
       margin-left: auto;
       width: 20%;
     }

     &__preloader {
       display: flex;
       justify-content: center;
       font-size: 1.5em
     }

     &__scroll-panel {
       overflow-y: auto;
       max-height: calc(100vh - 200px);
       overflow-x: hidden;
     }
   }
</style>
