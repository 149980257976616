import {
    modifyTemplateCloneIndex,
    modifyTemplateOrientationClass,
    modifyTemplateSize,
} from '@/components/project/model/creation-on-dom-modifiers'
import { axInstance, debounceRequest } from '@/services/ax-instance'
import { templateHoldersService } from '@/services/template-holders.service'
import store from '../store'

const baseProjectURI = projectId => `/projects/${projectId}/thumbnail-preview`
const staticProjectURI = projectId =>
    `${process.env.VUE_APP_API_URL}/projects/${projectId}/thumbnail`

// #Fix: this went to "circular import dependency" axInstance is requested by store, but not present yet when
// debounce requested. That is why it need to be a "task" here and for test purposes.
let debouncedPut = () => {}
let debouncedDelete = () => {}
setTimeout(() => {
    debouncedPut = debounceRequest(axInstance.put)
    debouncedDelete = debounceRequest(axInstance.delete)
})

function requestThumbnailRegeneration(
    projectId,
    { creationId, width, height, html, styles }
) {
    return debouncedPut(baseProjectURI(projectId), {
        creationId,
        width,
        height,
        html,
        styles,
    })
}

function requestThumbnailRemoval(projectId) {
    return debouncedDelete(baseProjectURI(projectId))
}

function resolveProjectThumbnailUrl(projectId, cacheControlFileName) {
    return (
        staticProjectURI(projectId) +
        (cacheControlFileName ? `?check=${cacheControlFileName}` : '')
    )
}

async function virtuallyMountTemplateHolderForCreationHTMLSnapshot(
    creationModel,
    templateHolder
) {
    // SIMULATE MOUNTING:
    const { width, height, cloneIndex, settings, template } = creationModel
    templateHolder.makeTemplateFrom(template.baseHTML)
    await templateHolder.mount(document.createElement('div'))

    const htmlTemplate = templateHolder.getTemplate()
    modifyTemplateSize(htmlTemplate, { width, height })
    modifyTemplateOrientationClass(htmlTemplate, { width, height })
    modifyTemplateCloneIndex(htmlTemplate, cloneIndex)
    templateHolder.setValues(settings)
    console.warn('templateHolder virtual mount')
}

async function reGenerateProjectThumbnail(
    projectId,
    creationModel,
    templateHolder
) {
    const { id: creationId, width, height } = creationModel
    let destroyTH = false
    if (templateHolder.isNotMounted()) {
        await virtuallyMountTemplateHolderForCreationHTMLSnapshot(
            creationModel,
            templateHolder
        )
        destroyTH = true
    }
    const styles = await templateHoldersService.getGlobalStylesByTemplateId(
        templateHolder.getTemplateClass()
    )
    const html = templateHoldersService.getTemplateHolderHTML(templateHolder)
    if (destroyTH) {
        // cleanup memory !!!
        templateHolder.destroy()
    }
    return requestThumbnailRegeneration(projectId, {
        creationId,
        width,
        height,
        html,
        styles,
    })
}

async function calculateIfProjectThumbnailGenerationNeeded({
    projectId,
    thumbnailCreationId,
    firstCreationInProjectId,
    creationModel,
    templateHolder,
} = {}) {
    const projectHasNoCreations = firstCreationInProjectId === null
    const projectHasCreationsButDoesNotHaveThumbnail =
        thumbnailCreationId === null &&
        creationModel.id === firstCreationInProjectId
    const projectHasThumbnailButItNeedToBeRefreshed =
        creationModel.isDirty && creationModel.id === thumbnailCreationId

    let response
    if (projectHasNoCreations) {
        // removeProjectThumbnail // this needs to be debounced !!!
        response = await requestThumbnailRemoval(projectId)
    } else if (
        projectHasCreationsButDoesNotHaveThumbnail ||
        projectHasThumbnailButItNeedToBeRefreshed
    ) {
        response = await reGenerateProjectThumbnail(
            projectId,
            creationModel,
            templateHolder
        )
    }

    if (response) {
        await store.dispatch(
            'currentProject/updateThumbnailCreationId',
            response.creationId
        )
        await store.dispatch('projects/updateProjectThumbnailInfo', {
            projectId,
            ...response,
        })
    }
    return response
}

async function generateNewProjectThumbnail({
    projectId,
    creationModel,
    templateHolder,
}) {
    // Update values before response (to avoid request duplication)...
    await store.dispatch(
        'currentProject/updateThumbnailCreationId',
        creationModel.id
    )
    const response = await reGenerateProjectThumbnail(
        projectId,
        creationModel,
        templateHolder
    )
    await store.dispatch('projects/updateProjectThumbnailInfo', {
        projectId,
        ...response,
    })
}

export const projectPreviewService = {
    calculateIfProjectThumbnailGenerationNeeded,
    generateNewProjectThumbnail,
    resolveProjectThumbnailUrl,
}
