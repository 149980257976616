<template>
    <div class="settings">
        <h2 class="settings__title">Settings</h2>
        <section class="settings__panel">
            <a-row>
                <a-col
                    :xs="8"
                    :md="6"
                    :xl="3"
                    style="height: calc(100vh - 182px);"
                >
                    <a-menu
                        mode="vertical"
                        theme="dark"
                        style="height: 100%; border-top-left-radius: 10px; padding-top: 10px; background-color: #151515; border-right: solid 1px #303030;"
                        @click="handleMenuClick"
                        v-model="chosenMenuKeys"
                    >
                        <a-menu-item key="safe-areas" title="Navigation One">
                            <a-icon type="border-bottom" />
                            Safe areas
                        </a-menu-item>
                        <a-menu-item key="cta-sizes" title="Navigation two">
                            <a-icon type="pic-center" />
                            Sizes with CTA
                        </a-menu-item>
                    </a-menu>
                </a-col>
                <a-col :xs="16" :md="18" :xl="21" style="padding: 15px">
                    <router-view />
                </a-col>
            </a-row>
        </section>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'SettingsPage',
    components: {},
    data() {
        return {
            chosenMenuKeys: [],
        }
    },
    computed: {},
    methods: {
        ...mapActions(['showAlertError']),
        handleMenuClick(ev) {
            this.chosenMenuKeys = [ev.key]
            this.$router.push(`/settings/${ev.key}`)
        },
    },
    async mounted() {
        if (!this.chosenMenuKeys.length) {
            this.chosenMenuKeys = [this.$route.name || 'safe-areas']
        }
    },
}
</script>
<style scoped lang="less">
.settings {
    margin-top: 24px;

    &__title {
        margin-bottom: 24px;
    }

    &__panel {
        background: #171717;
        border: solid 1px #303030;
        border-radius: 10px;
        height: calc(100vh - 180px);
    }
}
</style>
