<template>
    <div class="add-template__content">
        <div class="add-template__temps scroll">
            <ul class="add-template__list" v-if="templates.length">
                <temp-card
                    v-for="template in templates"
                    :key="template.id"
                    :template="template"
                    :is-selected="checkSelection(template.id)"
                    :is-favourite="isFavourite(template.id)"
                    @select="$emit('selected', template)"
                />
            </ul>
            <s-empty v-else class="add-template__temps-empty" />
        </div>
        <div class="add-template__preview scroll">
            <p class="add-template__preview-empty" v-if="!lastSelectedTemplate">
                Select a template to preview
            </p>
            <template v-else>
                <div
                    class="add-template__preview-img"
                    v-for="preview in lastSelectedTemplate.previews"
                    :key="preview"
                >
                    <img
                        :src="
                            resolveTemplateGraphicSrc(
                                lastSelectedTemplate.id,
                                preview
                            )
                        "
                        alt=""
                    />
                </div>
                <a-divider />
                <div style="margin-bottom: 6px">Supported sizes:</div>
                <div v-if="lastSelectedTemplate.sizes">
                    <div
                        v-for="[lang, sizes] in sortSizesByLang(
                            lastSelectedTemplate.sizes
                        )"
                        :key="lang"
                    >
                        <locale-picker
                            style="display: inline-block; margin-left: 6px"
                            v-if="lang !== 'a'"
                            :value="lang"
                            display-type="flag-only"
                            value-range="lang-only"
                            :show-label="false"
                            read-only
                        />
                        <a-tag
                            v-for="size of sizes"
                            :key="size.id"
                            class="size_tag"
                            style="margin-bottom: 10px"
                        >
                            {{ size.name }}
                        </a-tag>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { resolveTemplateGraphicSrc } from '@/view-helpers/template-preview-resolver'
import TempCard from '@/components/links/components/TempCard.vue'
import Empty from '@/components/layout/Empty.vue'
import LocalePicker from '@/components/shared/locale-picker/LocalePicker.vue'
import { sizeAscSorter } from '@/view-helpers/size-asc-sorter'

// here `a` key is fallback (to be on top for the list):
const makeLangKey = (l) => (l ? l : 'a')

export default {
    name: 'TemplatePreview',
    components: {
        LocalePicker,
        TempCard,
        SEmpty: Empty,
    },
    props: {
        lastSelectedTemplate: Object,
        showPreview: Boolean,
        selectedTemplates: {
            type: Array,
            default: () => [],
        },
        templates: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        ...mapGetters('templates', ['isFavourite']),
    },
    methods: {
        resolveTemplateGraphicSrc,
        checkSelection(id) {
            return this.selectedTemplates.includes(id)
        },
        sortSizesByLang(sizes = []) {
            const sizesCollection = {
                a: [], // a is fallback language
            }
            for (const size of sizes) {
                const key = makeLangKey(size.lang)
                if (!sizesCollection[key]) {
                    sizesCollection[key] = []
                }
                sizesCollection[key].push(size)
            }
            return Object.entries(sizesCollection).map(([lang, sizes]) => [
                lang,
                sizes.sort((a, b) => sizeAscSorter(a.name, b.name)),
            ])
        },
    },
}
</script>

<style scoped>
.size_tag {
    margin: 5px;
    color: black;
    line-height: 2em;
    font-size: 0.9em;
    font-weight: bolder;
    background-color: #e58f62;
    border-color: #8a3608;
}
</style>
