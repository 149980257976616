<template>
    <a-input
        class="renewable-input"
        ref="renewableInput"
        @change="$emit('change', $event)"
        @input="$emit('input', $event.target.value)"
        @blur="$emit('blur', $event)"
        :placeholder="placeholder"
        v-model="controlledValue"
    >
        <template slot="suffix">
            <a-button
                v-if="showMarkToggle"
                class="renewable-input__iconbtn"
                title="Toggle appla <mark/>"
                style="margin-right: 2pt;"
                @click="handleMarkToggle()"
            >
                <a-icon type="column-width" />
            </a-button>
            <a-button
                v-if="showBoldToggle"
                class="renewable-input__iconbtn"
                title="Toggle bold <b/> tag on text"
                style="margin-right: 2pt;"
                @click="handleBoldToggle()"
            >
                <a-icon type="bold" />
            </a-button>
            <a-button
                v-if="!hideResetBtn"
                class="renewable-input__iconbtn"
                title="Restore default value"
                @click="$emit('reset')"
            >
                <a-icon type="undo" />
            </a-button>
        </template>
    </a-input>
</template>

<script>
function searchForTag(name) {
    return new RegExp(`^(<${name}>)(.*)(<\\/${name}>$)`, 'g')
}

export default {
    name: 'RenewableInput',
    props: {
        value: {
            type: [String, Number],
            required: false,
        },
        placeholder: {
            type: String,
            required: false,
        },
        hideResetBtn: {
            type: Boolean,
            default: false,
        },
        showMarkToggle: {
            type: Boolean,
            default: false,
        },
        showBoldToggle: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            controlledValue: this.value,
        }
    },
    methods: {
        handleMarkToggle() {
            this.toggleTheTag('mark')
        },
        handleBoldToggle() {
            this.toggleTheTag('b', true)
        },
        toggleTheTag(surroundingTag = '', fromSelection = false) {
            const { renewableInput } = this.$refs
            let text = this.controlledValue.trim()
            if (fromSelection) {
                const $input = renewableInput.$el.querySelector('input')
                const { selectionStart, selectionEnd } = $input
                const hasSelection = selectionStart !== selectionEnd
                const start = hasSelection ? selectionStart : 0
                const end = hasSelection ? selectionEnd : text.length
                const firstPartOfTheText = text.substring(0, start)
                const secondPartOfTheText = text.substring(end)
                const replaceMe = this.controlledValue.substring(start, end)
                const markRegExp = searchForTag(surroundingTag)
                const replacedText = markRegExp.test(replaceMe)
                    ? replaceMe.replace(markRegExp, '$2')
                    : `<${surroundingTag}>${replaceMe}</${surroundingTag}>`
                this.controlledValue =
                    firstPartOfTheText + replacedText + secondPartOfTheText
                this.$nextTick(() => {
                    $input.focus()
                    $input.selectionStart = firstPartOfTheText.length
                    $input.selectionEnd =
                        firstPartOfTheText.length + replacedText.length
                })
            } else {
                const markRegExp = searchForTag(surroundingTag)
                this.controlledValue = markRegExp.test(text)
                    ? text.replace(markRegExp, '$2')
                    : `<${surroundingTag}>${text}</${surroundingTag}>`
            }
            this.$emit('input', this.controlledValue)
            this.$emit('change', { target: { value: this.controlledValue } })
        },
    },
    watch: {
        value: {
            handler(newValue) {
                this.controlledValue = newValue
            },
            immediate: true,
        },
    },
}
</script>

<style scoped lang="less">
.renewable-input {
    &__iconbtn {
        height: 25px;
        padding: 2px;
        border: 0;
    }
}
</style>
