const objectToMove = {
    ref: null,
    moveCallback: () => {},
}

const PIXEL = 1
const KEYS_Y_AXIS = {
    ArrowUp: -PIXEL,
    ArrowDown: PIXEL,
}
const KEYS_X_AXIS = {
    ArrowLeft: -PIXEL,
    ArrowRight: PIXEL,
}
const moveBy = keyCode => {
    const x = KEYS_X_AXIS[keyCode] || 0
    const y = KEYS_Y_AXIS[keyCode] || 0
    return [x, y]
}

setTimeout(() => {
    document.addEventListener('keydown', ev => {
        if (objectToMove?.ref?.moveEnabled) {
            const [x, y] = moveBy(ev.code)
            objectToMove.moveCallback(x, y)
        }
    })
})

export function selectCurrentToMoveByArrows(moveableControl, moveCallback) {
    objectToMove.ref = moveableControl
    objectToMove.moveCallback = moveCallback
}
