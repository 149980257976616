import { templatePossibleValuesService } from '@/services/template-possible-values.service';
import { TControl } from '@/templating/TControl';
import { TemplateBackground } from '@/templating/TemplateBackground';
import Vue from 'vue';
/**
 * Mixin stuff used to bind possibleValues with proper Form*.vue Element,
 * eg. FormBackground, FormText, etc...
 *
 * WARNING: those elements MUST have exact name: templateControl prop value present!!!
 *
 * After attach, you should make something like:
 *
 *  possibleFieldValues() {
 *     return this.possibleValues?.[KEY_FIELD] || this.inTemplatePossibleValues?.color || [];
 *  }
 *
 * That will bind server-side values first, inTemplate values second.
 * */
export const possibleValuesMixin = {
	props: {
		serverDefinedPossibleValues: {
			type: Object,
			default:  () => {}
		}
	},
	data() {
		return {
			possibleValues: {},
			inTemplatePossibleValues: {}
		}
	},
	mounted() {
		if(!this.templateControl || !(this.templateControl instanceof TControl)) {
			console.warn('Warn: possibleValuesMixin must have access to templateControl! inside component. No templateControl od class TControl found!')
		} else if(!this.templateControl?.ref) {
			console.warn('Warn: possibleValuesMixin. No templateControl.ref found!')
		} else {
			this.bindWithPossibleValues();
			this.templateControl.ref.addEventListener(TemplateBackground.EVENT_TYPES.LANG_CHANGED, () => this.bindWithPossibleValues())
		}
	},
	methods: {
		bindWithPossibleValues() {
			this.possibleValues = templatePossibleValuesService.getPossibleValuesForShape({
				values: this.serverDefinedPossibleValues,
				lang: this.templateControl.langToken
			});
			this.inTemplatePossibleValues = Vue.observable(this.templateControl?.possibleValues || {})
		}
	}
}
