const TEXT_SINGLE_QUOTES_PRESERVE_TOKEN = '##-@!';
const TEXT_SINGLE_QUOTES_PRESERVE_TOKEN_MATCH = new RegExp(TEXT_SINGLE_QUOTES_PRESERVE_TOKEN, 'g');
const TEXT_SINGLE_QUOTES_ESCAPE_MATCH = /\\'/g;
const PSEUDO_QUOTES_MATCH = /'/g;

export function pseudoJSONToObjectParse(pseudoJSON) {
	try {
		const jsObject = pseudoJSON
			.replace(TEXT_SINGLE_QUOTES_ESCAPE_MATCH, TEXT_SINGLE_QUOTES_PRESERVE_TOKEN)
			.replace(PSEUDO_QUOTES_MATCH, '"')
			.replace(TEXT_SINGLE_QUOTES_PRESERVE_TOKEN_MATCH, '\'');
		return JSON.parse(jsObject)
	} catch(error) {
		console.error('Pseudo JSON parse error', error);
		return {};
	}
}
