import { Modal } from 'ant-design-vue'

export default {
	state: {
		projects: {
			current: {},
			showModal: {
				info: false
			},
			panels: {
				graphics: true,
				edition: true
			}
		}
	},
	namespaced: true,
	getters: {
		isProjectInfoModalShown: state => state.projects.showModal.info,
		currentSelectedProject: state => state.projects.current,
		panelsVisibility: state => state.projects.panels
	},
	mutations: {
		setModalShow(state, {category, type, show, data}) {
			state[category].showModal[type] = show
			if(data) {
				state[category].current = data;
			}
		},
		setCurrent(state, {category, data}) {
			state[category].current = data
		},
		setPanels(state, {category, panel, visibility}) {
			state[category].panels[panel] = Boolean(visibility);
		}
	},
	actions: {
		showProjectInfoModal({commit}, project) {
			commit('setModalShow', { category: 'projects', type: 'info', show: true, data: project } );
		},
		hideProjectInfoModal({commit}) {
			commit('setModalShow', { category: 'projects', type: 'info', show: false } );
		},
		togglePanel({commit}, {panel, visibility}) {
			commit('setPanels', { category: 'projects', panel, visibility })
		},
		showRemoveModalConfirm({dispatch}, {title, content, okCallback}) {
			return dispatch('showModalConfirm', {title, content, okCallback, okText: 'Delete'})
		},
		showModalConfirm(stuff, {title, content, okCallback, okText = 'Confirm', cancelText = 'Cancel'}) {
			Modal.confirm({
				title,
				content,
				cancelText,
				okText,
				icon: 'exclamation-circle',
				onOk: okCallback
			});
		},
		setCurrentProject({commit}, project) {
			commit('setCurrent', { category: 'projects', data: project})
		}
	}
};
