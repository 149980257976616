<template>
    <a-empty class="empty" :image="simpleImage" :class="{'is-xl': isExtraLarge}">
      <span class="empty__text" slot="description">{{text}}</span>
    </a-empty>
</template>

<script>
import { Empty } from 'ant-design-vue';

export default {
    name: 'Empty',
    props: {
        size: {
            type: String,
            default: 'normal'
        },
        text: {
          type: String,
          default: 'No results'
        }
    },
    computed: {
      simpleImage: () => Empty.PRESENTED_IMAGE_SIMPLE,
      isExtraLarge() {
        return this.size === 'xl';
      }
    }
}
</script>

<style lang="less">
    .empty {
        &__text {
            color: @gray-7;
        }

        .ant-empty-image {
            opacity: 0.2;
        }

        &.is-xl {
            .empty__text {
                font-size: 18px;
            }
            .ant-empty-image {
                height: 160px;
                svg {
                    width: 200px;
                    height: 160px;
                }
            }
        }
    }
  </style>
