<template>
    <div class="card">
        <div class="card__states">
          <div>
            <div class="card__icons" v-if="showNewIndicator || isFavourite">
              <a-icon v-if="showNewIndicator" type="bell" theme="filled" />
              <a-icon v-if="isFavourite" type="star" theme="filled" />
            </div>
          </div>

          <div class="card__check-box">
            <a-checkbox :checked="isSelected" @click="handleProjectSelect()"></a-checkbox>
          </div>
        </div>
        <div class="card__top position-relative">
            <img class="card__img" @click="handleProjectOpen()" :src="thumbnailUrl" :alt="altText" />
            <div class="card__gallery_expires_info" v-if="expiredGalleryItem">
              <a-icon type="close-circle" theme="filled" style="color: #D32029"/>
              {{expiredGalleryItem}}
            </div>
        </div>
        <div class="card__bottom" @click="handleProjectOpen()">
            <p class="card__title"> {{ project.name }} </p>
            <p class="card__info">Last edited:</p>
            <p class="card__info"> {{ projectUpdatedAt }}</p>
            <a-dropdown class="card__options">
                <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                    <a-icon type="more"/>
                </a>
                <a-menu slot="overlay">
                    <a-menu-item @click="$emit('makrFavourite', !isFavourite)">
                      <span>
                        <a-icon type="star" :theme="!isFavourite ? 'filled' : 'outlined'" />
                        {{ isFavourite ? 'Unmark' : 'Mark' }} favourite
                      </span>
                    </a-menu-item>
                    <a-menu-item @click="$emit('editProject')">
                        <span><a-icon type="edit" /> Edit</span>
                    </a-menu-item>
                    <a-menu-item @click="$emit('renameProject')">
                        <span><a-icon type="font-colors" /> Rename</span>
                    </a-menu-item>
                    <a-menu-item v-if="canDuplicateForOthers" @click="$emit('copyProject')">
                        <span><a-icon type="copy" /> Duplicate</span>
                    </a-menu-item>
                    <a-menu-item v-if="canDuplicateForOthers" @click="$emit('duplicateProject')">
                      <span><a-icon type="user-add" /> Duplicate for other user(s) </span>
                    </a-menu-item>
                    <a-menu-item @click="$emit('infoProject')">
                        <span ><a-icon type="code" /> Information</span>
                    </a-menu-item>
                    <a-menu-divider />
                    <a-menu-item @click="$emit('deleteProject')">
                        <span class="danger"><a-icon type="delete" /> Delete</span>
                    </a-menu-item>
                </a-menu>
            </a-dropdown>
        </div>
    </div>
</template>

<script>
import { projectPreviewService } from '@/services/project-preview.service'
import { dateFormatter } from '@/view-helpers/date-formatter'
import { templateService } from '@/services/template.service'
import {mapState} from "pinia";
import {useGalleryCache} from "@/store/modules/gallery-cache.store";

export default {
    name: 'ProjectCard',
    props: {
      project: Object,
      canDuplicateForOthers: {
        type: Boolean,
        default: true
      },
      isSelected: {
        type: Boolean,
        default: false
      },
      showNewIndicator: Boolean
    },
    computed: {
      ...mapState(useGalleryCache, ['getExpiredGalleryItemForProject']),
      projectUpdatedAt() {
          return dateFormatter(this.project.updated_at)
      },
      altText() {
          return `preview image for project ${this.project?.name || ''}`
      },
      isFavourite() {
        return Boolean(this.project?.favourite)
      },
      expiredGalleryItem() {
        const expiredPhotos = this.getExpiredGalleryItemForProject(this.project.id);
        if(expiredPhotos) {
          const {noOfPhotos, expiresAt} = expiredPhotos;
          const flavorText = `Photo${noOfPhotos > 1 ? 's' : ''} expired`
          const reason = expiresAt ? dateFormatter(expiresAt, dateFormatter.SHORT_DATE_FORMAT) : 'archived / deleted'
          return `${flavorText}: ${reason}`;
        }
        return '';
      },
      thumbnailUrl() {
        const {thumbnail, thumbnail_creation_id, templates} = this.project || {};
        const hasThumbnail = thumbnail && thumbnail_creation_id;
        if(hasThumbnail) {
          return projectPreviewService.resolveProjectThumbnailUrl(this.project.id, thumbnail)
        }
        const hasAnyTemplate = templates && templates[0];
        return hasAnyTemplate ?
            templateService.resolveTemplateThumbnailUrl(templates[0].id, templates[0].thumbnail)
            :
            'placeholder.png';
      }
    },
    methods: {
      handleProjectOpen() {
        this.$emit('open', this.project)
      },
      handleProjectSelect() {
        this.$emit('select', {project: this.project, isSelected: this.isSelected})
      }
    }
}
</script>

<style scoped lang="less">

.position-relative {
  position: relative;
}

.card__gallery_expires_info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: @gray-9;
  background: @red-1;
  border-top: 1px solid @red-3;
  border-bottom: 1px solid @red-3;
  padding: 0.7em 0.7em 0.7em 1.7em;
}
</style>
