<template>
  <div class="edition__product" v-if="renderIf.hasName">
    <p class="edition__header" > My product </p>
    <a-form :form="productForm" @submit.prevent="" >
      <p class="form__label">Product name</p>
      <a-form-item>
        <renewable-input
            class="edition__input"
            placeholder="Product name"
            v-decorator="['name', {initialValue: productData.name}]"
            @reset="resetValueOf('name')"
        />
      </a-form-item>
      <form-badges
          :lang="badgesLang"
          :currentBadges="badgesList"
          :possibleBadges="loadedBadges"
          :discountBadgeData="discountData"
          :hide-reset-btn="false"
          @change="fireBadgesChange($event)"
          @reset="resetBadges()"
          @langChange="fireBadgesLangChange($event)"
      />
    </a-form>
  </div>
</template>

<script>

import { productFormRenderHelper } from '@/components/project/components/product-form-render-helper';
import { CreationModel } from '@/components/project/model/CreationModel'
import { settingsCascade } from '@/components/project/model/settings-cascade'
import RenewableInput from '@/components/shared/form/RenewableInput'
import { makeResetFieldQueuedWatcher } from '@/view-helpers/watcher-factory'
import {mapActions, mapGetters} from 'vuex'
import FormBadges from "@/components/project/components/form/FormBadges.vue";
import {onlyUniqueProductBadgesFilter} from "@/components/project/model/only-unique-product-badges-filter";
import { TemplateProduct } from '@/templating/TemplateProduct';

const { DATA_FIELDS } = TemplateProduct

export default {
  name: 'BannerProductForm',
  components: {
    FormBadges,
    RenewableInput
  },
  props: {
    productId: Number,
    creation: CreationModel
  },
  data () {
    return {
      productForm: this.$form.createForm( this, {
        name: 'productForm' + this.productId,
        onValuesChange: ( option, values ) => {
          Object.entries(values).forEach(([key, value]) => this.fireChange(key, value))
        }
      }),
      resetFieldsQueue: [],
      initialBadges: [],
    }
  },
  mounted() {
    // Do not TRACK updates of product badges - initialize them just ONCE!
    this.initialiseBadges()
  },
  computed: {
    ...mapGetters('currentProject', ['allProjectBadges']),
    productData() {
      return this.creation?.settings?.product?.product?.productData || {};
    },
    discountData() {
      const productHasDiscount = Object.keys(this.productData || {}).includes(DATA_FIELDS.DISCOUNT);
      if(productHasDiscount) {
        return {label: this.productData.price, label_two: this.productData.discount}
      }
      return null;
    },
    creationId() {
      return this.creation?.id;
    },
    templateProduct() {
      return this.creation?.template?.templateHolderControls?.product?.product
    },
    renderIf() {
      return productFormRenderHelper(this.templateProduct, this.creation?.template?.hasBadges, false)
    },
    badgesList() {
      return this.creation?.settings?.badges?.badges?.list || [];
    },
    badgesLang() {
      return this.creation?.settings?.badges?.badges?.lang
    },
    loadedBadges() {
      // Merge all badges (allProject + product)
      // Filter Project badges to not "duplicate" badges from product
      return [
        ...this.initialBadges,
        ...this.allProjectBadges(this.badgesLang).filter(onlyUniqueProductBadgesFilter(this.initialBadges))
      ]
    }
  },
  methods: {
    ...mapActions('connectionTemplate', ['updateHolderByCreationId']),
    ...mapActions('currentProject', ['convertBadgesToDifferentLang']),
    fireChange(key, value) {
      const action = {type: 'product', id: 'product', key: 'productData', value: { [key]: value } }
      // Needed for inside TemplateHolder updates to work properly
      // When you update "productData" it need to be updated as a whole object.
      const mergedProductData = settingsCascade( this.productData,  { [key]: value } )
      this.$emit('controlUpdated', { action, mergedProductData });
    },
    resetValueOf(key) {
      this.fireChange(key, null);
      this.resetFieldsQueue.push(key);
    },
    fireActionWithChange(id, type, key, value) {
      const action = {id, type, key, value};
      this.$emit('controlUpdated', { action });
      this.updateHolderByCreationId( {...action, creationId: this.creationId} )
    },
    initialiseBadges() {
      this.initialBadges = this.badgesList;
    },
    fireBadgesChange({badges}) {
      this.fireActionWithChange('badges', 'badges', 'list', badges);
    },
    async fireBadgesLangChange({badges, lang}) {
      const mappedBadges = lang === null ? null : await this.convertBadgesToDifferentLang({badges, lang});
      this.fireActionWithChange('cta', 'cta', 'lang', lang)
      // order of this fireChanges matters:
      this.fireActionWithChange('badges', 'badges', 'lang', lang)
      this.fireActionWithChange('badges', 'badges', 'list', mappedBadges)
    },
    async resetBadges() {
      await this.fireBadgesLangChange({badges: null, lang: null});
      this.initialiseBadges()
    }
  },
  watch: {
    creation: makeResetFieldQueuedWatcher('productForm', 'resetFieldsQueue')
  }
}


</script>

<style scoped>

</style>
