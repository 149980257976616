<template>
    <a-modal
        :visible="value"
        :maskClosable="false"
        @cancel="$emit('input', false)"
        width="90vw"
    >
        <section class="img-container" ref="zone">
            <div class="target-cross" ref="targetCross"></div>
            <img
                class="img-container__photo"
                ref="container"
                alt="picture"
                :src="file.url"
                :style="imgComputedStyle"
                @click="handlePlaceFocalPointByClick($event)"
            />
        </section>
        <template #footer>
            <div
                style="display: flex; justify-content: end; align-items: center"
            >
                <div>
                    {{ focalPoint }}
                </div>
                <a-button
                    v-if="isInEditMode"
                    style="margin-left: 20px"
                    @click="resetFocalPoint()"
                >
                    Reset focal point (50% / 50%)
                </a-button>
                <a-button
                    style="margin-left: auto"
                    key="back"
                    type="default"
                    @click="$emit('input', false)"
                >
                    Cancel
                </a-button>
                <a-button
                    v-if="focalMoved"
                    key="submit"
                    type="primary"
                    @click="handleApplyFocalPoint()"
                >
                    Apply focal point
                </a-button>
            </div>
        </template>
    </a-modal>
</template>

<script>
import Moveable from 'moveable'
import { galleryInProjectService } from '@/components/gallery/service/gallery-in-project.service'
import { templateInProjectService } from '@/services/template-in-project.service'

const FOCAL_DOT_RADIUS = 30 // half of the dot.

export default {
    name: 'FormFotosUploaderPreviewAndFocalPointModal',
    components: {},
    props: {
        value: {
            // v-model will handle visibility of the Modal! ($emit('input', false))
            type: Boolean,
        },
        file: {
            type: Object,
            required: true,
        },
        hFlipPhoto: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            focalMoved: false,
            focalPoint: null, // { x: '', y: '' }
        }
    },
    computed: {
        isInEditMode() {
            return this.focalPoint !== null
        },
        imgComputedStyle() {
            return this.hFlipPhoto ? { transform: 'scaleX(-1)' } : {}
        },
    },
    methods: {
        setCrossPosition(focalPoint = null) {
            this.$nextTick(() => {
                const { targetCross, container, zone } = this.$refs
                const {
                    top: cTop,
                    left: cLeft,
                    width: cWidth,
                    height: cHeight,
                } = container.getBoundingClientRect()
                const { top: zTop, left: zLeft } = zone.getBoundingClientRect()
                if (!targetCross) {
                    return
                }
                const { x = '50%', y = '50%' } = focalPoint || {}
                const numX = parseFloat(x)
                const numY = parseFloat(y)
                const leftZero = cLeft - zLeft - FOCAL_DOT_RADIUS
                const leftPush = (numX / 100) * cWidth
                targetCross.style.transform = ''
                targetCross.style.left = `${leftZero + leftPush}px`
                const topZero = cTop - zTop - FOCAL_DOT_RADIUS
                const topPush = (numY / 100) * cHeight
                targetCross.style.top = `${topZero + topPush}px`
                this.focalPoint = focalPoint
            })
        },
        async handleApplyFocalPoint() {
            try {
                const { uid, inProjectId, type } = this.file
                if (type === galleryInProjectService.fileType.GALLERY_ITEM) {
                    await galleryInProjectService.updateFocalPoint(
                        inProjectId,
                        this.focalPoint
                    )
                } else {
                    await templateInProjectService.updateGraphicFocalPoint(
                        uid,
                        this.focalPoint
                    )
                }
                this.$emit('applyFocalPoint', {
                    file: this.file,
                    focalPoint: this.focalPoint,
                })
            } catch (e) {
                console.error('Cannot update focal point:', e)
            }
        },
        handlePlaceFocalPointByClick({ offsetX, offsetY, target }) {
            const { offsetWidth, offsetHeight } = target
            let x = Math.abs(Math.round((offsetX * 100) / offsetWidth))
            let y = Math.abs(Math.round((offsetY * 100) / offsetHeight))
            if (this.hFlipPhoto) {
                x = 100 - x
            }
            this.focalMoved = true
            this.setCrossPosition({ x: `${x}%`, y: `${y}%` })
        },
        resetFocalPoint() {
            this.focalMoved = true
            this.setCrossPosition(null)
        },
    },
    mounted() {
        this.$nextTick(() => {
            const { targetCross, container } = this.$refs
            const { width, height } = container.getBoundingClientRect()
            const movable = new Moveable(container, {
                target: targetCross,
                draggable: true,
                edgeDraggable: false,
                keepRatio: true,
                throttleDrag: 1,
                origin: false,
                snappable: true,
                bounds: {
                    left: -FOCAL_DOT_RADIUS,
                    top: -FOCAL_DOT_RADIUS,
                    right: width + FOCAL_DOT_RADIUS,
                    bottom: height + FOCAL_DOT_RADIUS,
                    position: 'css',
                },
            })
            movable.on({
                drag: props => {
                    props.target.style.transform = props.transform.replace(
                        /(?<=translate\()-?\d+/,
                        foundX => {
                            // Revert x translate when foto flipped:
                            return this.hFlipPhoto ? -Number(foundX) : foundX
                        }
                    )
                    this.focalMoved = true
                    const {
                        top: cTop,
                        left: cLeft,
                        width: cWidth,
                        height: cHeight,
                    } = container.getBoundingClientRect()
                    const {
                        top,
                        left,
                        width,
                        height,
                    } = targetCross.getBoundingClientRect()
                    const pushXPercent = Math.round(
                        (Math.abs(cLeft - (left + width / 2)) * 100) / cWidth
                    )
                    const pushYPercent = Math.round(
                        (Math.abs(cTop - (top + height / 2)) * 100) / cHeight
                    )
                    this.focalPoint = {
                        x: `${pushXPercent}%`,
                        y: `${pushYPercent}%`,
                    }
                },
            })
        })
    },
    watch: {
        file: {
            immediate: true,
            deep: true,
            handler(fileValue) {
                this.setCrossPosition(fileValue.focalPoint)
            },
        },
    },
}
</script>

<style scoped lang="less">
.img-container {
    display: flex;
    justify-content: center;
    max-height: 70vh;
    position: relative;
    &__photo {
        border: 1px solid #3f52a4;
        display: block;
        max-width: 70vw;
        max-height: 70vh;
    }
}

.target-cross {
    width: 60px;
    height: 60px;
    border-radius: 50px;
    background-color: rgba(68, 165, 222, 0.7);
    position: absolute;
    cursor: move;
    --cross-color: #3f52a4;
    --cross-line-size: 4px;
    top: 50%;
    z-index: 9;

    &:hover {
        &:after,
        &:before {
            background-color: #1026ac;
        }
        background-color: rgba(68, 165, 222, 0.4);
    }
    &:after,
    &:before {
        content: '';
        position: absolute;
        background-color: var(--cross-color);
    }
    &:after {
        top: 3px;
        left: 0;
        right: 0;
        margin: auto;
        height: calc(60px - 6px);
        width: var(--cross-line-size);
    }
    &:before {
        left: 3px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: calc(60px - 6px);
        height: var(--cross-line-size);
    }
}
</style>
