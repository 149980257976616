import { axInstance, cancelableRequest, debounceMerge2ndArg } from './ax-instance';

const makeBaseConnectionsURI = (projectId) => `/projects/${projectId}/connections`;
const makeBaseCreationsURI = (projectId) => `/projects/${projectId}/creations`;

const prepareSettings = ({type, id, key, value}) => {
	const settings = {};
	settings[type] = {};
	settings[type][id] = {};
	settings[type][id][key] = value;
	return settings;
}

// Fix for testability for now: (TypeError: Cannot read properties of undefined (reading 'patch'))
// In Future: need to avoid circular module dependency with store -- axInstance (etc.
let specialMergedPatch;
setTimeout(() => {
	// Need to be sure that axInstance is ready
	specialMergedPatch = debounceMerge2ndArg(axInstance.patch)
})

const avoidRacingConditionsGuard = {
	requestMap: new Map(),
	checkToCancelCurrent({projectId, connectionId, creationId, productId, templateId, type, id, key}, cancelableRequest) {
		const uniqueKey = [projectId, 'co', connectionId, 'cr', creationId, productId, templateId, type, id, key].join('.');
		const concurrentRequest = this.requestMap.get(uniqueKey);
		if(concurrentRequest) {
			concurrentRequest.cancel();
		}
		this.requestMap.set(uniqueKey, cancelableRequest);
	}
}

function updateAllToAllSettings({projectId, productId, templateId}, {type, id, key, value }) {
	const settings = prepareSettings({type, id, key, value});
	const cancelableReq = cancelableRequest(({cancelToken}) => specialMergedPatch(`${makeBaseConnectionsURI(projectId)}/all-to-all/settings`, {
		updateType: productId ? 'product' : 'template',
		productId,
		templateId,
		settings
	}, { cancelToken }));
	avoidRacingConditionsGuard.checkToCancelCurrent({projectId, productId, templateId, type, id, key}, cancelableReq);
	return cancelableReq.call()
}

function updateConnectionSettings({projectId, connectionId}, {type, id, key, value }) {
	const settings = prepareSettings({type, id, key, value});
	const cancelableReq = cancelableRequest(({cancelToken}) => specialMergedPatch(`${makeBaseConnectionsURI(projectId)}/${connectionId}/settings`, {settings}, { cancelToken }));
	avoidRacingConditionsGuard.checkToCancelCurrent({projectId, connectionId, type, id, key}, cancelableReq);
	return cancelableReq.call()
}

function resetConnectionSettings({projectId, connectionId}) {
	return axInstance.put(`${makeBaseConnectionsURI(projectId)}/${connectionId}/settings`, {settings: {} })
}

function moveConnectionSettingsToAll2All({projectId, connectionId}) {
	return axInstance.put(`${makeBaseConnectionsURI(projectId)}/all-to-all/settings-copy`, { connectionId })
}

function updateCreationSettings({ projectId, creationId }, {type, id, key, value }) {
	const settings = prepareSettings({type, id, key, value});
	const cancelableReq = cancelableRequest(({cancelToken}) => specialMergedPatch(`${makeBaseCreationsURI(projectId)}/${creationId}/settings`, {settings}, { cancelToken }));
	avoidRacingConditionsGuard.checkToCancelCurrent({projectId, creationId, type, id, key}, cancelableReq);
	return cancelableReq.call()
}

function resetCreationSettings({projectId, creationId}) {
	return axInstance.put(`${makeBaseCreationsURI(projectId)}/${creationId}/settings`, {settings: {} })
}

function renameConnection({projectId, connectionId, name}) {
	return axInstance.patch(`${makeBaseConnectionsURI(projectId)}/${connectionId}`, { name })
}

function renderCreation({ projectId, creationId }, { html, styles, graphicUrl, exportToCim, alt, fileType, quality }, {width, height})  {
	return axInstance.post(
		`${makeBaseCreationsURI(projectId)}/${creationId}/render`,
		{ html, styles, width, height, id: creationId, graphicUrl, exportToCim, alt, fileType, quality },
		{ responseType: 'arraybuffer' }
	)
}

export const connectionService = {
	updateConnectionSettings,
	resetConnectionSettings,
	moveConnectionSettingsToAll2All,
	updateCreationSettings,
	resetCreationSettings,
	updateAllToAllSettings,
	renameConnection,
	renderCreation
}
