import { TemplateProduct } from '@/templating/TemplateProduct';

const { DATA_FIELDS, IMG_FIELDS, priceFromSelector, priceToSelector } = TemplateProduct

/**
 * This factory will create ProductForm helper object to determine if controls
 * should or not be rendered.
 *
 * RULE: when hasMoreThanOneProductTemplates === true, then
 * ALL CONTROLS SHOULD BE RENDERED ANYWAY!
 *
 * @param {TemplateProduct?} templateProduct
 * @param {boolean=false} templateHasBadges
 * @param {boolean=false} hasMoreThanOneProductTemplates
 * */
export function productFormRenderHelper(templateProduct, templateHasBadges = false, hasMoreThanOneProductTemplates = false) {

	const hasData = templateProduct?.hasData;
	const renderIfConditions = {
		get hasImg() {
			return hasData?.[IMG_FIELDS.IMG]
		},
		get hasBadges() {
			return templateHasBadges;
		},
		get hasVendorImg() {
			return hasData?.[IMG_FIELDS.PARTNER_IMG]
		},
		get hasPriceFromTo() {
			const { [priceToSelector]: priceTo, [priceFromSelector]: priceFrom } = hasData || {}
			// For now - we should have at least one of them:
			return priceTo || priceFrom;
		},
		get hasPriceMain() {
			return hasData?.[DATA_FIELDS.PRICE_MAIN]
		},
		get hasPriceRest() {
			return hasData?.[DATA_FIELDS.PRICE_REST]
		},
		get hasDiscount() {
			// In theory, at least price main is required to calculate discount
			// Also we need badges to show them !
			return this.hasPriceMain && templateHasBadges
		},
		get hasName() {
			return hasData?.[DATA_FIELDS.NAME]
		}
	}

	return new Proxy(renderIfConditions, {
		// Override all GETTERS:
		get(target, prop) {
			// @Business: when there is more than one product template, ALWAYS render EVERY field
			if(hasMoreThanOneProductTemplates){
				return true
			}
			return Reflect.get(target, prop)
		}
	})
}
