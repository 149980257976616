import { format } from 'date-fns'

const LONG_DATE_FORMAT = 'yyyy-MM-dd HH:mm'
const SHORT_DATE_FORMAT = 'yyyy-MM-dd'

dateFormatter.LONG_DATE_FORMAT = LONG_DATE_FORMAT;
dateFormatter.SHORT_DATE_FORMAT = SHORT_DATE_FORMAT;

export function dateFormatter(dateOrDateString = '', dateFormat = LONG_DATE_FORMAT) {
	if(!dateOrDateString) {
		return '--';
	}
	const isDate = dateOrDateString instanceof Date;
	const prepareDate = isDate ? dateOrDateString : new Date(dateOrDateString);
	if(prepareDate.toString() === 'Invalid Date') {
		return '--';
	}
	return format(prepareDate, dateFormat);
}
