export async function makeCallWithPreloader(dispatch, ajaxCall){
		dispatch('setWaiting', true, { root: true } )
		try {
			return await ajaxCall();
		} catch (error) {
			dispatch('showAlertError', error.message, { root: true })
			throw error;
		} finally {
			dispatch('setWaiting', false, { root: true } )
		}
}
