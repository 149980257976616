import { PossibleValuesProvider } from '@/templating/values/PossibleValuesProvider';

const DEFAULT_LANG = 'pl';

export class TControl {

	static EVENT_TYPES = {
		DATA_CHANGED: 'data-changed',
		LANG_CHANGED: 'lang-changed'
	}

	constructor (ref, type) {
		this.ref = ref;
		this.type = type;
		this.isReactive = false;
		this.langToken = DEFAULT_LANG;
		this.possibleValuesProvider = new PossibleValuesProvider(ref);
		this.updatePossibleValues();
	}

	dispatch(id, key, value) {
		const detail = {type: this.type, id, key, value};
		this.ref.dispatchEvent(new CustomEvent(TControl.EVENT_TYPES.DATA_CHANGED, {bubbles: true, detail}));
	}

	setupReactivity() {
		this.isReactive = true;
	}

	changeLanguage(lang = DEFAULT_LANG) {
		this.langToken = lang;
		this.updatePossibleValues();
		this.ref.dispatchEvent(new CustomEvent(TControl.EVENT_TYPES.LANG_CHANGED, {bubbles: true, detail: this.langToken}));
	}

	updatePossibleValues() {
		this.possibleValues = this.possibleValuesProvider.computeValues(this.langToken);
	}

	onDestroy() {}

	toJSON() {
		// This is getting data ONLY from getters + remove Vue.observable bindings
		const prototype = Object.getPrototypeOf(this);
		return {
			// Check getters in prototype:
			...extractGettersValue(prototype, this),
			// Check getters in instance object:
			...extractGettersValue(this, this)
		};
	}
}

function extractGettersValue(obj, caller) {
	const allValues = {};
	for (const key of Object.getOwnPropertyNames(obj)) {
		const descriptor = Object.getOwnPropertyDescriptor(obj, key);
		const hasGetter = descriptor && (typeof descriptor.get === 'function');
		if (hasGetter) {
			allValues[key] = descriptor.get.call(caller);
		}
	}
	return allValues;
}
