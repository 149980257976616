<template>
  <a-row>
    <a-col :xs="6" v-for="file in fileList" :key="file.uid">
      <div class="img_preview">
        <a-button v-if="!readOnly" class="img_preview-remove" shape="circle" icon="delete"
                  @click="removeFile(file.uid)"/>
        <img :src="mapFileToUrl(file)" :alt="`Image ${file.computedName || ''} preview`">
        <div class="img_preview-filename" :title="file.computedName">{{ file.computedName }}</div>
      </div>
    </a-col>
    <a-col :xs="6">
      <a-upload
          :multiple="true"
          :file-list="[]"
          :customRequest="() => {}"
          accept="image/jpeg,image/png,image/svg+xml"
          list-type="picture-card"
          @change="handleUpload($event)"
      >
        <div v-if="!readOnly">
          <a-icon type="upload"/>
          <div>Upload</div>
        </div>
      </a-upload>
    </a-col>
  </a-row>
</template>

<script>
import path from "path";

export default {
  name: "GalleryUploader",
  props: {
    namePrefix: {
      type: String,
      default: ''
    },
    value: {
      type: Array,
      default: () => []
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fileList: []
    }
  },
  methods: {
    mapFileToUrl(file) {
      if (file.url) {
        return file.url;
      }
      return URL.createObjectURL(file.originFileObj)
    },
    mapFileName(file, idx) {
      const {name} = file;
      const ext = path.extname(name);
      const itemNo = idx + 1;
      const hasManyFiles = this.fileList.length > 1;
      if (this.namePrefix) {
        return `${this.namePrefix}${hasManyFiles ? '_' + itemNo : ''}${ext}`;
      }
      return name;
    },
    handleUpload({file}) {
      this.fileList.push(file)
      this.updateFileNames();
      this.$emit('input', this.fileList);
    },
    removeFile(uid) {
      this.fileList = this.fileList.filter(f => f.uid !== uid);
      this.updateFileNames();
      this.$emit('input', this.fileList);
    },
    updateFileNames() {
      this.fileList = this.fileList.map((f, idx) => ({...f, computedName: this.mapFileName(f, idx)}));
      this.$emit('input', this.fileList);
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newList) {
        this.fileList = newList;
        // WATCH OUT: prevent infinite loop:
        if(newList && JSON.stringify(this.fileList) !== JSON.stringify(newList)) {
          this.updateFileNames();
        }
      },
    },
    namePrefix: {
      immediate: true,
      handler() {
        this.updateFileNames();
      }
    }
  }
}
</script>


<style scoped lang="less">
.img_preview {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  height: 104px;
  padding: 8px;
  border: 1px solid @gray-5;
  border-radius: 4px;
  margin-bottom: 1em;

  .img_preview-filename {
    position: absolute;
    bottom: 7px;
    background: rgba(0, 0, 0, .7);
    overflow: hidden;
    text-overflow: ellipsis;
    left: 8px;
    right: 8px;
    padding: 6px;
    font-size: 12px;
    line-height: 12px;
    height: 24px;
    white-space: nowrap;
  }

  .img_preview-remove {
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    color: @red-3;
    border: 0;
    text-shadow: @gray-9 4px 4px 4px;
    width: 22px;
    min-width: 22px;
    height: 22px;

    &:hover, &:focus {
      background: @gray-9;
      color: @red-6;
    }
  }

  &:hover {
    border-color: @gray-8;
  }

  img {
    width: 100%;
    height: auto;
    max-height: 88px;
    object-fit: contain;
    border-radius: 2px;
  }
}
</style>
