<template>
    <div>
        <a-badge
            class="items__badge"
            :count="connectionCount"
            :overflow-count="Infinity"
        />
        <span v-if="!editMode" class="items__name" v-ellipsis="80">{{
            name
        }}</span>
        <a-input
            v-else
            v-model="updatedName"
            @blur="editMode = false"
            @keyup.enter="handleNewNameConfirm()"
            placeholder="Binding name"
        />
        <div v-if="isProductType" style="margin-left: auto">
            <locale-picker
                v-if="lang && lang !== LANG_PL"
                display-type="flag-only"
                v-model="lang"
                :show-label="false"
                read-only
            />
            <div style="font-size: 10px">{{ currency }}</div>
        </div>
        <div v-else style="margin-left: auto">
            <locale-picker
                v-if="lang && lang !== LANG_PL"
                display-type="flag-only"
                value-range="lang-only"
                v-model="lang"
                :show-label="false"
                :read-only="true"
            />
        </div>
        <a-dropdown>
            <a class="ant-dropdown-link" @click.prevent="">
                <a-icon type="more" />
            </a>
            <a-menu slot="overlay">
                <a-menu-item @click="$emit('editLink')">
                    <span><a-icon type="caret-right" /> Edit</span>
                </a-menu-item>
                <a-menu-item @click="editMode = true">
                    <span><a-icon type="edit" /> Rename</span>
                </a-menu-item>
                <a-menu-item @click="$emit('resetSettings')">
                    <span class="danger"><a-icon type="sync" /> Reset</span>
                </a-menu-item>
                <a-menu-item @click="$emit('moveSettings')">
                    <span
                        ><a-icon type="to-top" /> Transfer settings to
                        all-to-all</span
                    >
                </a-menu-item>
                <a-menu-item @click="$emit('deleteLink')">
                    <span><a-icon type="delete" /> Delete</span>
                </a-menu-item>
            </a-menu>
        </a-dropdown>
    </div>
</template>

<script>
import LocalePicker from '@/components/shared/locale-picker/LocalePicker'
import { LANG_LOCALES } from '@/components/shared/supported-languages'

export default {
    name: 'ConnectionListItem',
    components: {
        LocalePicker,
    },
    props: {
        connectionCount: Number,
        name: String,
        productId: Number,
        currency: {
            type: String,
            default: 'PLN',
        },
        lang: {
            type: String,
            default: LANG_LOCALES.pl,
        },
    },
    data() {
        return {
            editMode: false,
            updatedName: '',
        }
    },
    computed: {
        isProductType() {
            return Boolean(this.productId)
        },
        LANG_PL() {
            return LANG_LOCALES.pl
        },
    },
    methods: {
        handleNewNameConfirm() {
            this.$emit('renameLink', {
                name: this.updatedName,
                onComplete: () => {
                    this.editMode = false
                },
            })
        },
    },
    watch: {
        name: {
            handler(newName) {
                this.updatedName = newName
            },
            immediate: true,
        },
    },
}
</script>

<style lang="less" scoped>
.ant-checkbox-wrapper {
    display: flex;
    height: 40px;
    width: 100%;
    align-items: center;
}
</style>
