<template>
    <a-modal
        v-model="visible"
        :maskClosable="false"
        @cancel="$emit('closeSafeAreaModal')"
        :width="450"
    >
        <template #title>
            <div class="modal-top">
                {{ isInEditMode ? 'Edit' : 'Add new' }} Safe Area
            </div>
        </template>
        <div style="width: 400px; height: 365px">
            Creation size:
            <a-form-model ref="formRef" :model="area" :rules="rules">
                <a-form-model-item prop="size">
                    <a-input
                        :disabled="isInEditMode"
                        v-model="area.size"
                        @input="handleSizeChanged"
                        style="margin-bottom: 12px"
                    />
                </a-form-model-item>
            </a-form-model>
            <hr />
            <a-row style="position: relative">
                <a-slider
                    range
                    style="margin-left: 30px"
                    :min="0"
                    :max="area.width"
                    v-model="widthRange"
                    :disabled="!isValid"
                    @change="handleWidthChange"
                />
                <div
                    style="position: absolute; top: 40px; display: flex; right: 10px; align-items: center"
                >
                    left:
                    <a-input-number
                        type="number"
                        v-model="area.cuts.left"
                        style="width: 80px; margin-left: 10px; margin-right: 40px"
                        :min="0"
                        :disabled="!isValid"
                    />
                    <a-input-number
                        type="number"
                        v-model="area.cuts.right"
                        style="width: 80px;  margin-right: 10px"
                        :min="0"
                        :disabled="!isValid"
                    />
                    - right
                </div>
                <div style="display: flex; height: 200px; margin-top: 40px">
                    <a-slider
                        vertical
                        range
                        v-model="heightRange"
                        :min="0"
                        :max="area.height"
                        :disabled="!isValid"
                        :reverse="true"
                        @change="handleHeightChange"
                    />
                    <div
                        style="margin-top: 20px; display: flex; flex-direction: column;"
                    >
                        top:
                        <a-input-number
                            type="number"
                            v-model="area.cuts.top"
                            style="width: 80px; margin-bottom: auto"
                            :min="0"
                            :disabled="!isValid"
                        />
                        bottom:
                        <a-input-number
                            type="number"
                            v-model="area.cuts.bottom"
                            style="width: 80px;  margin-right: 10px"
                            :min="0"
                            :disabled="!isValid"
                        />
                    </div>
                </div>

                <div
                    style="position: absolute; top: 160px; left: 150px; font-size: 22px"
                >
                    Safe area: <code>{{ area.safeArea }}</code>
                </div>
            </a-row>
        </div>
        <template #footer>
            <a-button
                key="back"
                type="default"
                @click="$emit('closeSafeAreaModal')"
            >
                Cancel
            </a-button>
            <a-button
                key="submit"
                type="primary"
                @click="handleAddOrEditClick()"
            >
                {{ isInEditMode ? 'Update' : 'Add' }}
            </a-button>
        </template>
    </a-modal>
</template>

<script>
import { makeSizeFormValidators } from '@/components/shared/form/size-form-validators'

function makeInitialArea() {
    return {
        size: '',
        height: 100,
        width: 100,
        cuts: { top: 0, right: 0, bottom: 0, left: 0 },
    }
}

export default {
    name: 'NewOrUpdateSafeAreaModal',
    props: {
        safeArea: {
            type: Object,
        },
    },
    data() {
        return {
            visible: true,
            area: makeInitialArea(),
            widthRange: [0, 0],
            heightRange: [0, 0],
            rules: {
                size: makeSizeFormValidators(),
            },
        }
    },
    computed: {
        isInEditMode() {
            return this.safeArea && Boolean(Object.keys(this.safeArea).length)
        },
        isValid() {
            return this.width > 0 && this.height > 0
        },
        width() {
            const [w = 0] = this.area.size.split('x')
            return Number(w)
        },
        height() {
            const [, h = 0] = this.area.size.split('x')
            return Number(h)
        },
    },
    methods: {
        handleSizeChanged() {
            if (this.isValid) {
                this.area.width = this.width
                this.area.height = this.height
                this.handleChangeCut()
            }
        },
        async handleAddOrEditClick() {
            try {
                const isValid = await this.$refs.formRef.validate()
                if (!this.isValid && isValid) {
                    return
                }
                this.$emit(
                    this.isInEditMode ? 'updateSafeArea' : 'newSafeAra',
                    {
                        id: this.isInEditMode ? this.area.id : undefined,
                        width: this.width,
                        height: this.height,
                        cuts: this.area.cuts,
                    }
                )
            } catch {
                //
            }
        },
        handleWidthChange([left, right]) {
            this.area.cuts.left = left
            this.area.cuts.right = this.area.width - right
        },
        handleHeightChange([top, bottom]) {
            this.area.cuts.top = top
            this.area.cuts.bottom = this.area.height - bottom
        },
        handleChangeCut() {
            this.widthRange = [
                Number(this.area.cuts.left),
                Number(this.area.width - this.area.cuts.right),
            ]
            this.heightRange = [
                Number(this.area.cuts.top),
                Number(this.area.height - this.area.cuts.bottom),
            ]
            const computedW =
                this.area.width -
                Number(this.area.cuts.left) -
                Number(this.area.cuts.right)
            const computedH =
                this.area.height -
                Number(this.area.cuts.bottom) -
                Number(this.area.cuts.top)
            this.area.safeArea = `${computedW}x${computedH}`
        },
    },
    watch: {
        'area.cuts': {
            deep: true,
            handler() {
                this.handleChangeCut()
            },
        },
        safeArea: {
            immediate: true,
            handler(newValue) {
                if (newValue) {
                    this.area = JSON.parse(JSON.stringify(newValue))
                } else {
                    this.area = makeInitialArea()
                }
                this.handleChangeCut()
            },
        },
    },
}
</script>

<style scoped lang="less"></style>
