<template>
  <a-upload-dragger
      name="file"
      :accept="allowFiles"
      :multiple="false"
      :customRequest="customRequest"
      :defaultFileList="defaultFileList"
      :remove="removeFile"
      :disabled="isBusy"
  >
    <p class="ant-upload-drag-icon">
      <a-icon v-if="isBusy" type="loading" :spin="true" />
      <a-icon v-else type="inbox" />
    </p>
    <p class="ant-upload-text">
      {{ isBusy ? busyMessage : dropFileMessage}}
    </p>
  </a-upload-dragger>
</template>

<script>
import { templateService } from '@/services/template.service'
const ALLOW_FILE_TYPE = ['application/zip', 'application/octet-stream', 'application/x-zip-compressed', 'multipart/x-zip', 'text/css'];

export default {
  props: {
    templateId: Number,
    allowFiles: {
      type: String,
      default: '.zip'
    }
  },
  data() {
    return {
      defaultFileList: [],
      fileUploaded: false,
      isBusy: false,
      busyMessage: ''
    };
  },
  async mounted () {
    try {
      this.setLoadingBusy('Checking file saved on the server...');
      const { fileName, fullName } = await this.service().checkDraftFile();
      if(fileName) {
        this.defaultFileList.push( {
          uid: '-1',
          name: fileName,
          serverName: fullName,
          status: 'done'
        })
        this.setLoadingBusy(false, fullName);
      } else {
        this.setLoadingBusy(false);
      }
    } catch {
      this.setLoadingBusy(false);
    }
  },
  computed: {
    isTemplateUpdating() {
      return Boolean(this.templateId)
    },
    dropFileMessage() {
      const readableAllowFiles = this.allowFiles.split(',').join(' lub ');
      return `Click or drag the file: ${readableAllowFiles}`
    }
  },
  methods: {
    service() {
      // @WARN: templateService methods should be used only here [!]
      if(this.isTemplateUpdating) {
        return {
          checkDraftFile: () => templateService.checkTemplateDraftFile(this.templateId),
          removeDraftFile: (fileName) => templateService.removeTemplateDraftFile(this.templateId, fileName),
          sendDraftFile: (file) => templateService.updateDraftFile(this.templateId, file)
        }
      }
      return {
        checkDraftFile: () => templateService.checkDraftFile(),
        removeDraftFile: (fileName) => templateService.removeDraftFile(fileName),
        sendDraftFile: (file) => templateService.sendDraftFile(file)
      }
    },
    setLoadingBusy(value, fileName = '') {
      const isLoading = Boolean(value);
      this.$emit('onLoading', {isLoading, fileName});
      this.isBusy = isLoading;
      this.busyMessage = value || '';
    },
    riseFileError( message, file) {
      this.$nextTick(() => {
        const error = new Error(message);
        file.onError(error, error.message);
        this.setLoadingBusy(false);
      })
    },
    async customRequest ( file ) {
      const hasAnyFilesUploadedYet = (this.defaultFileList.length > 0 && this.defaultFileList.some((e) => e.status === 'done'));
      if(this.fileUploaded || hasAnyFilesUploadedYet) {
        return this.riseFileError('Only one file can be sent', file);
      }
      const fileType = file?.file?.type || '';
      if(!ALLOW_FILE_TYPE.includes(fileType)) {
        return this.riseFileError('Only .zip archive', file);
      }
      try {
        this.setLoadingBusy('Sending a new template...');
        const res = await this.service().sendDraftFile( file );
        file.onSuccess(res);
        this.fileUploaded = true;
        this.setLoadingBusy(false, res.fullName);
      } catch (err) {
        file.onError(err, err.message)
        this.setLoadingBusy(false);
      }
    },
    async removeFile(file) {
      const { serverName, response } = file;
      const responseServerName = response?.fullName;
      if(serverName || responseServerName) {
        // if serverName or responseServerName present - means, file is from server side.
        this.setLoadingBusy('Template file deleting...');
        await this.service().removeDraftFile(serverName || responseServerName).catch(() => {});
        this.fileUploaded = false;
        this.setLoadingBusy(false);
      } else {
        return true;
      }
    },
  }
};
</script>

<style lang="less">
  .ant-upload-list-item-info {
    .anticon {
      color: white
    }
  }
</style>
