import { axInstance, cancelableRequest } from '@/services/ax-instance'
import { galleryService } from '@/services/gallery.service'

const GALLERY_IN_PROJECT_URI = '/gallery/in-project'

export const galleryInProjectService = {
    fileType: {
        GALLERY_ITEM: 'GALLERY_ITEM',
        CAMPAIGN: 'CAMPAIGN',
        FILE: 'FILE',
    },
    extractFileId(galleryItemFileId) {
        const [id] = galleryItemFileId.match(/(?<=__gallery_item\/)\d+/g) || []
        return id
    },
    extractFileCampaignId(campaignFileId) {
        const [id] = campaignFileId.match(/(?<=__gallery_campaign\/)\d+/g) || []
        return id
    },
    addGalleryItems(
        galleryItemIds,
        { connectionId, templateId, fieldType, fieldPlaceholder }
    ) {
        return axInstance.post(GALLERY_IN_PROJECT_URI, {
            connectionId,
            templateId,
            fieldType,
            fieldPlaceholder,
            galleryItemIds,
        })
    },
    getConnectionGraphics(connectionId) {
        return cancelableRequest(({ cancelToken }) =>
            axInstance.get(`${GALLERY_IN_PROJECT_URI}/${connectionId}`, {
                cancelToken,
            })
        )
    },
    removeGalleryItem(galleryGraphicId) {
        return axInstance.delete(
            `${GALLERY_IN_PROJECT_URI}/${galleryGraphicId}`
        )
    },
    mapGalleryGraphicToUidFileInfo({
        id: inProjectId,
        placeholder,
        type,
        campaignItem,
        focal_point,
    }) {
        const { id, name, url, expires_at, status } = campaignItem?.item || {}
        return {
            placeholder,
            type,
            fileInfo: {
                uid: `__gallery_item/${id}`,
                inProjectId,
                name,
                computedName: name,
                status: 'done',
                isArchived: status === 'INACTIVE',
                expiresAt: expires_at,
                type: galleryInProjectService.fileType.GALLERY_ITEM,
                url: galleryService.getGalleryItemUrl(url),
                focalPoint: focal_point,
            },
        }
    },
    mapCampaignItemToFileInfo(campaignItem) {
        return {
            ...galleryInProjectService.mapGalleryGraphicToUidFileInfo({
                id: 0,
                campaignItem,
                placeholder: '',
                type: '',
            }).fileInfo,
            isNotRemovable: true,
        }
    },
    getAllExpiredItemsForProjectIds(projectIds) {
        return axInstance.put(`${GALLERY_IN_PROJECT_URI}/expired`, {
            projectIds,
        })
    },
    updateFocalPoint(galleryGraphicId, focalPoint) {
        return axInstance.patch(
            `${GALLERY_IN_PROJECT_URI}/${galleryGraphicId}`,
            {
                focalPoint,
            }
        )
    },
}
