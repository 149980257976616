<template>
  <transition name="fadeIn" mode="in-out" appear>
    <s-mask>
      <div class="login">
          <a-form :form="loginForm" class="login__box" @submit.prevent="handleSubmit">
              <img class="login__logo" src="../../assets/logo-white.svg">

              <div style="margin: 10px">
                <environment-marker />
              </div>
              <a-alert
                  v-if="errorReason"
                  :message="errorReason"
                  type="error"
                  show-icon
                  style="margin-top: 15px;"
              />

              <p class="login__title">Login</p>

              <p class="login__label">Email</p>
              <a-form-item>
                <a-input class="login__input" placeholder="Email"
                       v-decorator="['email', { rules: [{ required: true, message: 'Invalid e-mail address', }] }]" />
              </a-form-item>
              <div style="display: flex; justify-content: space-between; align-content: end">
                <p class="login__label">Password</p>
                <router-link style="font-size: 13px" :to="remindPasswordLink">
                  <a-icon type="question-circle" /> Forgot Password
                </router-link >
              </div>
              <a-form-item>
                <a-input-password class="login__input" placeholder="Password"
                                  v-decorator="['password', { rules: [{ required: true, message: 'Enter password' }] }]"/>
              </a-form-item>

              <a-alert
                  v-if="errorMessage"
                  :message="errorMessage"
                  type="error"
                  show-icon
                  banner
              />

              <a-button type="primary" html-type="submit" :loading="isLoading">Login </a-button>
              <!-- <a class="login__link" href="">Forgot password?</a> -->
          </a-form>

          <div class="login__bottom">
             <p class="login__info">You don't have account?</p>
             <router-link class="login__link" to="/signup">Register</router-link>
          </div>
      </div>
    </s-mask>
  </transition>

</template>

<script>
import Mask from '@/components/layout/Mask';
import EnvironmentMarker from '@/components/main/components/EnvironmentMarker'
import { userService } from '@/services/user.service'
import { mapActions } from 'vuex'

export default {
    name: 'LoginPage',
    components: {
      EnvironmentMarker,
      SMask: Mask
    },
    data(){
        return {
          loginForm: this.$form.createForm(this, {
            name: 'loginForm',
            onValuesChange: (option, values) => {
              const {email} = values;
              if(email || email === '') {
                this.remindPasswordLink = email ?
                    `/remind-password?email=${email}`
                    :
                    '/remind-password'
              }
            }
          }),
          errorMessage: '',
          isLoading: false,
          req: {
            logIn: userService.logIn('','')
          },
          // cannot be computed due to freaking "v-decorator" logic :P
          remindPasswordLink: '/remind-password'
        }
    },
    mounted () {
      const {email} = this.$route.query;
      if(email) {
        this.loginForm.setFieldsValue({ email })
      }
    },
    beforeDestroy () {
      this.req.logIn.cancel()
    },
    computed: {
       errorReason() {
          return this.$route.query.reason || '';
       },
       afterLoginPath() {
         return this.$route.query.backTo ||  '/projects';
       }
    },
    methods: {
        handleSubmit() {
          this.loginForm.validateFields(async (err, values) => {
            if (!err) {
              const {email, password} = values;
              this.errorMessage = '';
              this.isLoading = true;
              this.req.logIn.cancel();
              this.req.logIn = userService.logIn(email, password);
              try {
                const userTokens = await this.req.logIn.call();
                this.logIn({...userTokens, email})
                await this.$router.push(this.afterLoginPath);
              } catch (err) {
                if(err.status === 422) {
                  const fields = {
                    'email': {value: email, errors: err.errors.email, validateStatus: "error"},
                    'password': {value: password, errors: err.errors.email.password},
                  }
                  this.loginForm.setFields(fields);
                } else {
                  this.errorMessage = err.message;
                }
              } finally {
                this.isLoading = false
              }
            }
          });
      },
      ...mapActions("user", ["logIn"])
    }
}
</script>

<style lang="less">
    .login {

        &__box {
            display: flex;
            flex-direction: column;
            background-color: @gray-2;
            border: solid 1px @gray-4;
            border-radius: 6px;
            padding: 56px 24px;
        }

        &__bottom {
            margin-top: 8px;
            text-align: center;
        }

        &__title {
            font-size: 16px;
            font-weight: 600;
            margin-top: 32px;
            margin-bottom: 16px;
        }

        &__logo {
            display: flex;
            width: 240px;
            margin: auto;
        }

        &__input {
            width: 100%;
            margin-bottom: 16px;
        }

        &__link {
            text-align: center;
            margin-top: 16px;
            text-decoration: underline;

            &:hover {
                text-decoration: underline;
            }
        }

        &__info {
            color: @gray-9;
            margin-bottom: 8px;
        }

        &__label {
            margin-bottom: 4px;
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            color: @gray-9;
        }

        &__check {
            margin-top: 16px;
            color: @gray-9;
        }
    }
</style>
