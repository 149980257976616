<template>
  <div class="edition__label">
    <div class="edition__label-item">
      <div v-if="showCta">
        <p class="form__label">CTA Button</p>
        <div>
          <a-select
              class="edition__select"
              data-testid="cta"
              v-model="cta"
              @change="handleInputChange('cta', cta)"
          >
            <a-select-option v-for="type in ctaClasses" :key="type.class" :disabled="type.disabled">
              {{ type.name }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div v-if="showCtaType">
        <p class="form__label">CTA Color</p>
        <div>
          <a-select
              class="edition__select"
              data-testid="ctaType"
              v-model="ctaType"
              @change="handleInputChange('ctaType', ctaType)"
          >
            <a-select-option v-for="type in ctaTypeClasses" :key="type.class">
              {{ type.name }}
            </a-select-option>
          </a-select>
        </div>
      </div>
    </div>

    <div class="edition__label-item" v-if="showLabelColor">
      <div>
        <p class="form__label">Label color</p>
        <div>
            <a-select
                class="edition__select"
                data-testid="labelType"
                v-model="labelType"
                @change="handleInputChange('labelType', labelType)"
            >
              <a-select-option v-for="type in labelTypeClasses" :key="type.class">
                {{ type.name }}
              </a-select-option>
            </a-select>
        </div>
      </div>
    </div>

    <div class="edition__label-item" v-if="showPriceType">
      <div>
        <p class="form__label">Price type</p>
        <div>
            <a-select
                class="edition__select"
                data-testid="priceType"
                v-model="priceType"
                @change="handleInputChange('priceType', priceType)"
            >
              <a-select-option v-for="type in priceTypes" :key="type.class">
                {{ type.name }}
              </a-select-option>
            </a-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TemplateClasses } from '@/templating/TemplateClasses';

export default {
  name: 'FormClasses',
  props: {
    identifier: {
      type: String,
      default: 'class'
    },
    templateControl: {
      type: TemplateClasses,
      required: true
    },
    settings: {
      default: () => {}
    },
    showCta: Boolean,
    showCtaType: Boolean,
    showLabelColor: Boolean,
    showPriceType: Boolean,
  },
  data() {
    return {
      cta: this.settings?.cta || '',
      ctaType: this.settings?.ctaType || '',
      labelType: this.settings?.labelType || '',
      priceType: this.settings?.priceType || ''
    }
  },
  computed: {
    ctaClasses: () => ([
      { name: 'Unset', class: '' },
      { name: 'Hidden', class: TemplateClasses.CTA.NO_CTA_FORCE},
      { name: 'Desktop', class: TemplateClasses.CTA.CTA_FORCE},
      { name: 'Mobile (arrow)', class: TemplateClasses.CTA.MOBILE_FORCE},
      // Fallback values (set from settings, unable to set by EndUser):
      { name: 'Default (no CTA)', class: TemplateClasses.CTA.NO_CTA, disabled: true },
      { name: 'Default (mobile)', class: TemplateClasses.CTA.MOBILE, disabled: true }
    ]),
    ctaTypeClasses: () => ([
      { name: 'Default', class: '' },
      { name: 'Inverted', class: TemplateClasses.CTA_TYPE.INVERTED }
    ]),
    labelTypeClasses: () => ([
      { name: 'Default', class: '' },
      { name: 'Inverted', class: 'settings--inverted' },
    ]),
    priceTypes: () => ([
      { name: 'Large', class: TemplateClasses.PRICE_TYPES.LARGE },
      { name: 'Small', class: TemplateClasses.PRICE_TYPES.MINI },
      { name: 'Mini', class: TemplateClasses.PRICE_TYPES.MICRO },
      { name: 'Not set', class: '' },
    ]),
  },
  methods: {
    handleInputChange(key, value) {
      this.$emit('change', {type: 'classes', id: this.identifier, key, value})
    }
  },
  watch: {
    settings(newValue) {
      const settingsKey = ['cta', 'ctaType', 'labelType', 'priceType'];
      for(const key of settingsKey) {
        if(key in newValue && this[key] !== newValue[key]){
          this[key] = newValue[key] || '';
        }
      }
    }
  }
};
</script>

<style scoped lang="less">
.edition__label {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.edition__label-item {
  margin-right: 5px; flex: 1; min-width: 150px;
}
</style>
