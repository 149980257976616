import { defineStore } from 'pinia'
import { galleryInProjectService } from '@/components/gallery/service/gallery-in-project.service'
import { galleryService } from '@/services/gallery.service'
import jp from 'jsonpath'

const mapListToIdObject = (list = [], type) =>
    list.reduce((asObject, item) => {
        const isCampaign = type === 'campaign'
        const theItem = (isCampaign ? item : item?.item) || {}
        asObject[item.id] = {
            id: item.id,
            expiresAt: theItem.expires_at,
            status: isCampaign ? '' : theItem.status,
            url: isCampaign ? theItem.cover_url : theItem.url,
            name: theItem.name,
            isArchived:
                theItem.status === 'INACTIVE' || Boolean(theItem.archived_at),
            computedName: theItem.name,
        }
        return asObject
    }, {})

const searchFor = url =>
    url.includes('/gallery-files/') || url.includes('/__gallery_item/')
const mapForURLEndings = url =>
    url.replace(/.*\/gallery-files\//, '').replace(/.*(?=__gallery_item\/)/, '')

function extractImageUrlsFromSettings(settings) {
    const images = jp.query(settings, '$.image..url').filter(searchFor)
    const photos = jp.query(settings, '$.photo..url').filter(searchFor)
    const masks = jp.query(settings, '$.mask..url').filter(searchFor)
    // Do not repeat same url's:
    return Array.from(new Set([...images, ...photos, ...masks]))
}

const PROJECT_BY_ID_TIME_THROTTLE = 1000
let throttleItemsByProjectsTimerId = 0

export const useGalleryCache = defineStore('gallery-cache', {
    state: () => ({
        galleryItems: {},
        campaigns: {},
        itemQuery: [],
        expiredItemsByProjectIds: {},
    }),
    getters: {
        getGalleryOrCampaignItemByFileUid: state => fileUid => {
            const galleryItemId = galleryInProjectService.extractFileId(fileUid)
            if (galleryItemId) {
                return state.galleryItems[galleryItemId]
            }
            const campaignId = galleryInProjectService.extractFileCampaignId(
                fileUid
            )
            if (campaignId) {
                return state.campaigns[campaignId]
            }
            return state.galleryItems[fileUid]
        },
        getGalleryItemById: state => id => state.galleryItems[id],
        getAllCachedGalleryItems: state => Object.values(state.galleryItems),
        getItemsByIds: state => (ids = []) => {
            const result = {}
            for (const fileUid of ids) {
                const galleryItemId = galleryInProjectService.extractFileId(
                    fileUid
                )
                if (state.galleryItems[galleryItemId]) {
                    result[fileUid] = state.galleryItems[galleryItemId]
                    continue
                }
                const campaignId = galleryInProjectService.extractFileCampaignId(
                    fileUid
                )
                if (state.campaigns[campaignId]) {
                    result[fileUid] = state.campaigns[campaignId]
                }
            }
            return result
        },
        getFoundAndNotFoundFiles: state => (imgUrls = []) => {
            const checkValues = state.getAllCachedGalleryItems
            const foundFiles = []
            const notFoundUrls = []
            for (const imgUrl of imgUrls) {
                const file = checkValues.find(
                    ({ url, id }) => imgUrl.includes(url) || imgUrl.includes(id)
                )
                if (file) {
                    foundFiles.push(file)
                } else {
                    notFoundUrls.push(imgUrl)
                }
            }
            return { foundFiles, notFoundUrls }
        },
        getAllArchivedByUrlsFromSettings: state => (settings = {}) => {
            const { foundFiles } = state.getFoundAndNotFoundFiles(
                extractImageUrlsFromSettings(settings)
            )
            return foundFiles.filter(({ isArchived }) => isArchived)
        },
        hasAnyArchivedItem: state => settings => {
            return state.getAllArchivedByUrlsFromSettings(settings).length > 0
        },
        getExpiredGalleryItemForProject: state => projectId => {
            const galleryItems = state.expiredItemsByProjectIds[projectId]
            if (Array.isArray(galleryItems) && galleryItems.length > 0) {
                const [firstItem] = galleryItems || []
                const { expiresAt, isArchived } = firstItem || {}
                return {
                    noOfPhotos: galleryItems.length,
                    expiresAt,
                    isArchived,
                }
            }
            return ''
        },
    },
    actions: {
        removeCache() {
            this.galleryItems = {}
            this.campaigns = {}
        },
        addGalleryItems(collection = []) {
            this.galleryItems = {
                ...this.galleryItems,
                ...mapListToIdObject(collection, 'items'),
            }
        },
        addCampaigns(collection = []) {
            this.campaigns = {
                ...this.campaigns,
                ...mapListToIdObject(collection, 'campaign'),
            }
        },
        async assureFileIdsPresent(urls) {
            if (!Array.isArray(urls) || !urls.length) {
                // Don't cate about empty arrays or non-arrays
                return
            }
            const assureItemsIds = urls
                .map(galleryInProjectService.extractFileId)
                .filter(Boolean)
                .filter(id => {
                    //check not already present:
                    return !this.galleryItems[id]
                })
            const assureCampaignIds = urls
                .map(galleryInProjectService.extractFileCampaignId)
                .filter(Boolean)
                .filter(id => {
                    //check not already present:
                    return !this.campaigns[id]
                })
            if (assureItemsIds.length) {
                await galleryService.getItemsFromCache(assureItemsIds)
            }
            if (assureCampaignIds.length) {
                await galleryService.getCampaignsFromCache(assureCampaignIds)
            }
        },
        /*
         * check form settings of particular creation, if we got anything related to gallery !
         * This action will, be often performed to check if creationModel.settings does (not) have archived items.
         * it cannot be "getter" - because of the side effect that must be performed here.
         * */
        async assureFileUrlsPresentFromSettings(settings) {
            const { notFoundUrls } = this.getFoundAndNotFoundFiles(
                extractImageUrlsFromSettings(settings)
            )
            const urlsToSeek = notFoundUrls
                .map(mapForURLEndings)
                .filter(url => !this.itemQuery.includes(url))
            if (urlsToSeek.length) {
                this.itemQuery.push(...urlsToSeek)
                await galleryService
                    .searchItemUrlsFromCache(urlsToSeek)
                    .then(() => {
                        this.itemQuery = this.itemQuery.filter(
                            url => !urlsToSeek.includes(url)
                        )
                    })
            }
        },
        async loadAllExpiredItemsByProjectIds(projectIds) {
            if (throttleItemsByProjectsTimerId) {
                return
            }
            throttleItemsByProjectsTimerId = setTimeout(() => {
                throttleItemsByProjectsTimerId = 0
            }, PROJECT_BY_ID_TIME_THROTTLE)
            try {
                const result =
                    (await galleryInProjectService.getAllExpiredItemsForProjectIds(
                        projectIds
                    )) || {}
                this.expiredItemsByProjectIds = {
                    ...this.expiredItemsByProjectIds,
                    ...result,
                }
            } catch (e) {
                console.error(e)
            }
        },
    },
})
