<template>
    <div class="edition__template">
        <section
            class="edition__description md-container"
            v-html="parsedHTMLDescription"
        />
        <a-form class="edition__form">
            <form-css-variables
                v-if="hasGlobalVars"
                :compact-ui="isForSingleCreation"
                :css-variables="
                    isForSingleCreation ? cssLocalVariables : cssGlobalVariables
                "
                :template-control="getTemplateControl('cssVariables')"
                :server-defined-possible-values="
                    extractValuesFor('cssVariables')
                "
                :server-defined-frozen-fields="
                    extractFrozenFieldsFor('cssVariables')
                "
                :font-list="templateFontNames"
                @change="handleCssVariableChange($event)"
                @reset="handleCssVariableReset($event)"
            />
            <div v-for="id in getIds('text')" :key="id" class="form__group">
                <form-text
                    :templateControl="getTemplateControl('text', id)"
                    :server-defined-possible-values="
                        extractValuesFor('text', id)
                    "
                    :server-defined-frozen-fields="
                        extractFrozenFieldsFor('text', id)
                    "
                    :identifier="id"
                    :settings="txt(id)"
                    :template-used-fonts="templateUsedFonts"
                    :show-mark-toggle="true"
                    :show-bold-toggle="true"
                    @change="handleControlChange($event)"
                    @reset="handleControlReset($event)"
                />
            </div>
            <div v-if="getIds('background').length > 0" class="form__group">
                <div v-for="id in getIds('background')" :key="id">
                    <form-background
                        :template-control="getTemplateControl('background', id)"
                        :server-defined-possible-values="
                            extractValuesFor('background', id)
                        "
                        :server-defined-frozen-fields="
                            extractFrozenFieldsFor('background', id)
                        "
                        :identifier="id"
                        :settings="bg(id)"
                        @change="handleControlChange($event)"
                        @reset="handleControlReset($event)"
                    />
                </div>
            </div>
            <div v-if="getIds('border').length > 0" class="form__group">
                <div v-for="id in getIds('border')" :key="id">
                    <form-border
                        :template-control="getTemplateControl('border', id)"
                        :server-defined-possible-values="
                            extractValuesFor('border', id)
                        "
                        :server-defined-frozen-fields="
                            extractFrozenFieldsFor('border', id)
                        "
                        :identifier="id"
                        :settings="selBorder(id)"
                        @change="handleControlChange($event)"
                        @reset="handleControlReset($event)"
                    />
                </div>
            </div>

            <form-photos
                v-for="placeholder in getIds('photo')"
                :key="placeholder + '__photo'"
                :loading="isGraphicsLoading"
                :label="placeholder"
                :file-list="photoFilesByPlaceholder[placeholder]"
                :can-reset="true"
                :can-h-flip="true"
                :can-change-focal-point="true"
                :template-url="template.absoluteUrl"
                :template-control="getTemplateControl('photo', placeholder)"
                :server-defined-possible-values="
                    extractValuesFor('photo', placeholder)
                "
                :server-defined-frozen-fields="
                    extractFrozenFieldsFor('photo', placeholder)
                "
                :photo-settings="extractSettingsFor('photo', placeholder)"
                description="Background image"
                loading-text="Background images loading..."
                @switchPhoto="graphicChange($event, 'photo', placeholder)"
                @applyFocal="focalChange($event, 'photo', placeholder)"
                @horizontalFlipPhoto="
                    horizontalFlip($event, 'photo', placeholder)
                "
                @removePhoto="graphicDelete($event, 'photo', placeholder)"
                @uploadPhoto="graphicUpload($event, 'photo', placeholder)"
                @resetPhoto="resetValueOf(placeholder, 'url', 'photo')"
                @emptyPhoto="graphicEmpty('photo', placeholder)"
                @addIdsFromGallery="
                    handleAddIdsFromGallery($event, 'photo', placeholder)
                "
            >
                <div v-if="isForSingleCreation" class="edition__photo">
                    <div class="edition__photo-item">
                        <p class="form__desc">Horizontal - X shift:</p>
                        <renewable-input
                            v-model="pho(placeholder).posX"
                            placeholder="X shift"
                            @blur="
                                fireChange(
                                    'photo',
                                    placeholder,
                                    'posX',
                                    $event.target.value
                                )
                            "
                            @reset="resetValueOf(placeholder, 'posX', 'photo')"
                        />
                    </div>

                    <div class="edition__photo-item">
                        <div class="form__desc">Vertical - Y shift:</div>
                        <renewable-input
                            v-model="pho(placeholder).posY"
                            placeholder="Y shift"
                            @blur="
                                fireChange(
                                    'photo',
                                    placeholder,
                                    'posY',
                                    $event.target.value
                                )
                            "
                            @reset="resetValueOf(placeholder, 'posY', 'photo')"
                        />
                    </div>

                    <div class="edition__photo-item">
                        <div class="form__desc">Width (px):</div>
                        <!-- for now - we need to hide reset btn here, resetFieldsQueue need to be more flexible -->
                        <renewable-input
                            v-model="pho(placeholder).size"
                            placeholder="Width"
                            @blur="
                                fireChange(
                                    'photo',
                                    placeholder,
                                    'size',
                                    $event.target.value
                                )
                            "
                            @reset="resetValueOf(placeholder, 'size', 'photo')"
                        />
                    </div>
                </div>
            </form-photos>

            <form-photos
                v-for="placeholder in getIds('image')"
                :key="placeholder + '__image'"
                :loading="isGraphicsLoading"
                :label="placeholder"
                :file-list="imageFilesByPlaceholder[placeholder]"
                :can-reset="true"
                :can-h-flip="true"
                :template-url="template.absoluteUrl"
                :template-control="getTemplateControl('image', placeholder)"
                :server-defined-possible-values="
                    extractValuesFor('image', placeholder)
                "
                :server-defined-frozen-fields="
                    extractFrozenFieldsFor('image', placeholder)
                "
                :photo-settings="extractSettingsFor('image', placeholder)"
                description="Image / icon"
                loading-text="Images / icons loading..."
                @switchPhoto="graphicChange($event, 'image', placeholder)"
                @horizontalFlipPhoto="
                    horizontalFlip($event, 'image', placeholder)
                "
                @removePhoto="graphicDelete($event, 'image', placeholder)"
                @uploadPhoto="graphicUpload($event, 'image', placeholder)"
                @resetPhoto="resetValueOf(placeholder, 'url', 'image')"
                @emptyPhoto="graphicEmpty('image', placeholder)"
                @addIdsFromGallery="
                    handleAddIdsFromGallery($event, 'image', placeholder)
                "
            ></form-photos>

            <form-photos
                v-for="placeholder in getIds('mask')"
                :key="placeholder + '__mask'"
                :loading="isGraphicsLoading"
                :label="placeholder"
                :file-list="maskFilesByPlaceholder[placeholder]"
                :can-reset="true"
                :template-url="template.absoluteUrl"
                :template-control="getTemplateControl('mask', placeholder)"
                :server-defined-possible-values="
                    extractValuesFor('mask', placeholder)
                "
                :server-defined-frozen-fields="
                    extractFrozenFieldsFor('mask', placeholder)
                "
                :photo-settings="extractSettingsFor('mask', placeholder)"
                description="Mask"
                loading-text="Masks loading..."
                @switchPhoto="graphicChange($event, 'mask', placeholder)"
                @removePhoto="graphicDelete($event, 'mask', placeholder)"
                @uploadPhoto="graphicUpload($event, 'mask', placeholder)"
                @resetPhoto="resetValueOf(placeholder, 'url', 'mask')"
                @emptyPhoto="graphicEmpty('mask', placeholder)"
                @addIdsFromGallery="
                    handleAddIdsFromGallery($event, 'mask', placeholder)
                "
            ></form-photos>

            <form-classes
                v-if="hasCta"
                :template-control="getTemplateControl('classes', 'class')"
                :settings="css('class')"
                :show-cta="isForSingleCreation"
                :show-cta-type="showCtaType"
                :show-label-color="getCta() && !isTemplateGeneric"
                :show-price-type="
                    getCta() && !isTemplateGeneric && isForSingleCreation
                "
                @change="handleControlChange($event)"
            />

            {{ /* Badges or Language (for no badges option in generics) */ }}
            <form-badges
                :lang="badgesLang"
                v-if="isTemplateGeneric && (hasBadges || hasCta)"
                :show-lang-change-only="hasCta && !hasBadges"
                :hide-reset-btn="!isForSingleCreation"
                :possibleBadges="allProjectBadges(badgesLang)"
                :currentBadges="hasBadges ? getBadges().list : []"
                @change="
                    fireChange('badges', 'badges', $event.action, $event.badges)
                "
                @reset="resetBadges()"
                @langChange="fireBadgesLangChange($event)"
            />
        </a-form>
    </div>
</template>

<script>
import Vue from 'vue'
import { templatePossibleValuesService } from '@/services/template-possible-values.service'
import { markupParser } from '@/components/shared/markup/markup.parser'
import FormBackground from '@/components/project/components/form/FormBackground'
import FormBorder from '@/components/project/components/form/FormBorder'
import FormClasses from '@/components/project/components/form/FormClasses'
import FormText from '@/components/project/components/form/FormText'
import RenewableInput from '@/components/shared/form/RenewableInput'
import FormBadges from '@/components/project/components/form/FormBadges'
import FormPhotos from '@/components/project/components/form/photos/FormPhotos.vue'
import FormCssVariables from '@/components/project/components/form/FormCssVariables'
import { TemplateModel } from '@/components/project/model/TemplateModel'
import { CreationModel } from '@/components/project/model/CreationModel'
import { normalizeUrl, sanitizeUrl } from '@/templating/templating-helpers'
import { templateService } from '@/services/template.service'
import { mapActions, mapGetters } from 'vuex'
import { galleryInProjectService } from '@/components/gallery/service/gallery-in-project.service'
import merge from 'deepmerge'

export default {
    name: 'TemplateForm',
    components: {
        RenewableInput,
        FormCssVariables,
        FormPhotos,
        FormBadges,
        FormText,
        FormBackground,
        FormBorder,
        FormClasses,
    },
    props: {
        template: TemplateModel,
        creation: CreationModel,
    },
    data() {
        return {
            photoFilesByPlaceholder: {},
            imageFilesByPlaceholder: {},
            maskFilesByPlaceholder: {},
            isGraphicsLoading: false,
            req: {
                loadGraphics: templateService.graphics(0, 0),
                loadGalleryGraphics: galleryInProjectService.getConnectionGraphics(
                    0
                ),
            },
        }
    },
    computed: {
        ...mapGetters('currentProject', ['projectBadges', 'allProjectBadges']),
        currentTemplatePossibleValues() {
            return this.template?.possibleValues || {}
        },
        currentTemplateFrozenFields() {
            return this.template?.frozenFields || {}
        },
        hasCta() {
            return this.template.hasCta
        },
        hasBadges() {
            return this.template.hasBadges
        },
        templateUsedFonts() {
            return this.template?.usedFontsMetadata || {}
        },
        templateFontNames() {
            return this.template?.usedFontsMetadata?.names || []
        },
        badgesLang() {
            return this.controlsSettings?.badges?.badges?.lang
        },
        templateId() {
            return this.template.id
        },
        isTemplateGeneric() {
            return this.template.type === 'generic'
        },
        isForSingleCreation() {
            return Boolean(this.creation)
        },
        connectionId() {
            return this.template?.connectionId
        },
        // Those things represent the VALUE(s) of the Controls
        controlsSettings() {
            return this.isForSingleCreation
                ? this.creation.settings
                : this.template.settings
        },
        // Those things represent the META(s) of the Controls (additional settings for inputs e.g.)
        controlsMetadata() {
            return this.template.templateHolderControls || {}
        },
        // Decide if CTA Type can be changed:
        showCtaType() {
            return Boolean(this.getCta())
        },
        hasGlobalVars() {
            return this.template.hasCSSVariables
        },
        cssGlobalVariables() {
            return this.template.cssVariables
        },
        cssLocalVariables() {
            // Must be separated with cssGlobalVariables due to watcher (see below!)
            // @Fix: use merge() instead of spread {...} operator to preserve actionTriggers !
            return merge(this.cssGlobalVariables, this.creation.cssVariables)
        },
        parsedHTMLDescription() {
            return markupParser.parse(this.template?.description || '')
        },
    },
    methods: {
        ...mapActions(['showAlertError']),
        ...mapActions('currentProject', ['convertBadgesToDifferentLang']),
        ...mapActions('connectionTemplate', [
            'updateHolder',
            'updateHolderByCreationId',
        ]),
        getTemplateControl(type, id) {
            // Read values from: templating/ Template[X] classes: like "TemplateText"
            if (!id) {
                return this.controlsMetadata?.[type]
            }
            if (Array.isArray(this.controlsMetadata?.[type]?.[id])) {
                return this.controlsMetadata[type][id][0] // return first.
            }
            return this.controlsMetadata?.[type]?.[id]
        },
        extractSettingsFor(type, id) {
            return this.getSetting(type, id)
        },
        extractValuesFor(type, id) {
            return templatePossibleValuesService.extractValueByLanguage({
                type,
                id,
                baseValues: this.currentTemplatePossibleValues,
            })
        },
        extractFrozenFieldsFor(type, id) {
            return id
                ? this.currentTemplateFrozenFields?.[type]?.[id]
                : this.currentTemplateFrozenFields?.[type]
        },
        getSetting(type, id) {
            return this.controlsSettings[type]?.[id]
        },
        getIds(name) {
            return Object.keys(this.controlsSettings?.[name] || {})
        },
        getBadges() {
            return this.getSetting('badges', 'badges')
        },
        txt(id) {
            return this.getSetting('text', id)
        },
        css(id) {
            return this.getSetting('classes', id)
        },
        bg(id) {
            return this.getSetting('background', id)
        },
        // need to be selBorder ("border" name collision)
        selBorder(id) {
            return this.getSetting('border', id)
        },
        img(id) {
            return this.getSetting('image', id)
        },
        pho(id) {
            return this.getSetting('photo', id)
        },
        getCta() {
            return this.getSetting('cta', 'cta')
        },
        async fireBadgesLangChange({ badges, lang }) {
            const mappedBadges = await this.convertBadgesToDifferentLang({
                badges,
                lang,
            })
            this.fireChange('cta', 'cta', 'lang', lang)
            // order of this fireChanges matters:
            this.fireChange('badges', 'badges', 'lang', lang)
            this.fireChange('badges', 'badges', 'list', mappedBadges)
        },
        async resetBadges() {
            await this.fireBadgesLangChange({ badges: null, lang: null })
        },
        fireChange(type, id, key, value) {
            const action = { id, type, key, value }
            this.$emit('controlUpdated', action)
            // TODO: refactor -> make this code outside of this scope without if'o-logy
            if (this.isForSingleCreation) {
                this.updateHolderByCreationId({
                    ...action,
                    creationId: this.creation.id,
                })
            } else {
                this.updateHolder({ ...action, templateId: this.templateId })
            }
        },
        handleControlChange({ type, id, key, value }) {
            this.fireChange(type, id, key, value)
        },
        handleControlReset({ type, id, key }) {
            this.resetValueOf(id, key, type)
        },
        resetValueOf(id, key, type) {
            this.fireChange(type, id, key, null)
            if (type === 'photo') {
                // @Business: when photo resets, bring back `50/50%` position
                this.fireChange(type, id, 'posX', '50%')
                this.fireChange(type, id, 'posY', '50%')
            }
        },
        handleCssVariableChange({ key, value, actionTriggers }) {
            {
                // Just emit action with css Variables without informing the controls:
                this.$emit('controlUpdated', {
                    id: key,
                    type: 'cssVariables',
                    key: 'value',
                    value,
                })
            }
            for (const { type, id, key } of actionTriggers) {
                this.fireChange(type, id, key, value)
            }
        },
        handleCssVariableReset({ key, actionTriggers }) {
            this.handleCssVariableChange({ key, value: null, actionTriggers })
        },
        getListIfExists({ placeholder, type }) {
            const listName = this.graphicsGetSource(type)
            const theList = this[listName]
            if (!theList) {
                // @Business: if there are no lists - it might be a mirror(/type/)
                return
            }
            if (!theList[placeholder]) {
                // Make new 100% observable nested list:
                Vue.set(theList, placeholder, [])
            }
            return theList[placeholder]
        },
        pushGraphic(graphic) {
            const { id, name, placeholder, type, url, focal_point } = graphic
            const list = this.getListIfExists({ placeholder, type })
            list?.push({
                uid: id,
                status: 'done',
                name: name,
                url: sanitizeUrl(
                    url,
                    templateService.getTemplateGraphicUrl(this.templateId)
                ),
                focalPoint: focal_point,
            })
        },
        pushGalleryItem({ placeholder, type, fileInfo }) {
            this.getListIfExists({ placeholder, type })?.push(fileInfo)
        },
        graphicsGetSource(type) {
            if (type === 'image') return 'imageFilesByPlaceholder'
            if (type === 'photo') return 'photoFilesByPlaceholder'
            if (type === 'mask') return 'maskFilesByPlaceholder'
        },
        graphicEmpty(type, id) {
            this.fireChange(type, id, 'url', '')
        },
        graphicChange(file, type, id) {
            this.fireChange(type, id, 'url', normalizeUrl(file.url))
            if (type === 'photo') {
                // @Business: when url changed [data-photo] should be center/center by default or use Focal Point
                const { x = '50%', y = '50%' } = file.focalPoint || {}
                this.fireChange(type, id, 'posX', x)
                this.fireChange(type, id, 'posY', y)
            }
        },
        focalChange({ file, focalPoint }, type, id) {
            // @Important: mutate file with new focalPoint (magic)
            file.focalPoint = focalPoint
            // also change graphics:
            this.graphicChange(file, type, id)
        },
        horizontalFlip(value, type, id) {
            this.fireChange(type, id, 'hFlip', value)
        },
        async graphicDelete({ file, fileList }, type, placeholder) {
            const graphicId = file.uid
            try {
                let removed = false
                if (
                    file.type === galleryInProjectService.fileType.GALLERY_ITEM
                ) {
                    removed = await galleryInProjectService.removeGalleryItem(
                        file.inProjectId
                    )
                } else {
                    removed = await templateService.removeGraphic(
                        this.templateId,
                        this.connectionId,
                        graphicId
                    )
                }
                if (removed?.status) {
                    const source = this.graphicsGetSource(type)
                    this[source][placeholder] = fileList
                }
            } catch (e) {
                this.showAlertError(e.message)
                console.error('Cannot remove', type, e)
            }
        },
        async graphicUpload(file, type, id) {
            const { connectionId } = this
            try {
                const photo = await templateService.addGraphic(
                    this.templateId,
                    { file, type, id, connectionId }
                )
                this.pushGraphic(photo)
            } catch (e) {
                this.showAlertError(e.message)
                console.error('Cannot add', type, e)
            }
        },
        async handleAddIdsFromGallery(ids, type, placeholder) {
            const { connectionId, templateId } = this
            try {
                const newItems = await galleryInProjectService.addGalleryItems(
                    ids,
                    {
                        fieldType: type,
                        fieldPlaceholder: placeholder,
                        connectionId,
                        templateId,
                    }
                )
                ;(newItems || [])
                    .map(galleryInProjectService.mapGalleryGraphicToUidFileInfo)
                    .forEach(this.pushGalleryItem)
            } catch (e) {
                this.showAlertError(e.message)
            }
        },
    },
    watch: {
        // To handle changes from OUTSIDE word:
        cssGlobalVariables: {
            handler(newOnes, oldOnes) {
                Object.entries(newOnes)
                    .map(([key, { value }]) => ({ key, value })) // map to key value
                    .filter(({ key, value }) => value !== oldOnes?.[key]?.value) // only those who changed
                    .forEach(({ key, value }) => {
                        // update
                        this.template.setCssVariable(key, value)
                    })
            },
            deep: true,
        },
    },
    async mounted() {
        const { templateId, connectionId } = this
        this.req.loadGraphics = templateService.graphics(
            templateId,
            connectionId
        )
        this.req.loadGalleryGraphics = galleryInProjectService.getConnectionGraphics(
            connectionId
        )
        try {
            this.isGraphicsLoading = true
            const [photos, galleryGraphics] = await Promise.all([
                this.req.loadGraphics.call(),
                this.req.loadGalleryGraphics.call(),
            ])
            ;(photos || []).forEach(this.pushGraphic)
            ;(galleryGraphics || [])
                .map(galleryInProjectService.mapGalleryGraphicToUidFileInfo)
                .forEach(this.pushGalleryItem)
        } catch (e) {
            console.error('Cannot load photos!', e)
        } finally {
            this.isGraphicsLoading = false
        }
    },
    beforeDestroy() {
        this.req.loadGraphics.cancel()
    },
}
</script>

<style scoped lang="less">
.edition__template {
    .ant-upload.ant-upload-select-picture-card {
        background: #0a0a0a;
    }
}

.edition__form {
    margin-top: 10px;

    .form__group {
        margin-bottom: 20px;
        padding-bottom: 15px;
        border-bottom: 1px solid #272727;

        &:last-of-type {
            border-bottom: none;
        }
    }

    .ant-form-item {
        margin-bottom: 5px;
    }
}

.form__delete {
    height: 30px;
    font-size: 12px;
    margin: 0 0 5px;
}

.edition__description {
    position: relative;
    opacity: 0.8;
}

.edition__select {
    position: relative;
    margin-bottom: 0;
}

.edition__font {
    display: flex;
    flex-wrap: wrap;

    .edition__font-color {
        margin-left: 0;
    }
}

.edition__photo {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.edition__photo-item {
    margin-right: 5px;
}

.edition__label {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.edition__label-item {
    margin-right: 5px;
    flex: 1;
    min-width: 150px;
}
</style>
