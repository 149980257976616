<template>
    <section>
        <div style="padding: 10px">
            <a-input-search
                enter-button
                placeholder="Search"
                v-model="searchText"
                @input="handleSearch($event)"
                :allowClear="true"
            />
        </div>
        <div class="scroll" style="height: calc(100vh - 500px);">
            <gallery-items-table
                :is-loading="isLoading"
                :page-size="pageSize"
                :current-page="currentPage"
                :total-items="totalItems"
                :gallery-items="collection"
                :query-text="searchText"
                :has-actions="false"
                :is-selectable="true"
                v-model="selectedItems"
                @pageChanged="handlePaginationChange($event)"
            />
        </div>
    </section>
</template>

<script>
import { debounce } from '@/view-helpers/debouncer'
import GalleryItemsTable from '@/components/gallery/components/GalleryItemsTable.vue'

export default {
    name: 'SelectGalleryElements',
    components: { GalleryItemsTable },
    props: {
        value: {
            // selected items.
            type: Array,
            default: () => [],
        },
        loadElementsFn: {
            type: Function,
            default: () => () => {},
        },
        collectionMapper: {
            type: Function,
            default: () => e => e,
        },
    },
    data() {
        return {
            searchText: '',
            previousSearchText: '',
            collection: [],
            selectedItems: [],
            isLoading: true,
            currentPage: 0,
            totalItems: 0,
            pageSize: 50,
            req: {
                loadElementsFn: this.loadElementsFn,
            },
        }
    },
    mounted() {
        this.loadElements()
    },
    methods: {
        handleSearch: debounce(async function(timer) {
            this.timeout = timer
            if (this.previousSearchText !== this.searchText) {
                // search distinct until changed
                await this.loadElements()
            }
            this.previousSearchText = this.searchText
        }),
        async loadElements() {
            this.isLoading = true
            this.req.loadElementsFn = this.loadElementsFn(
                this.searchText,
                { page: this.currentPage, limit: this.pageSize },
                { orderBy: 'created_at', orderDir: 'desc' }
            )
            try {
                const { results, total } = await this.req.loadElementsFn.call()
                this.collection = results.map(this.collectionMapper)
                this.totalItems = total
            } catch (e) {
                console.log(e)
            } finally {
                this.isLoading = false
            }
        },
        async handlePaginationChange(current) {
            this.currentPage = current
            await this.loadElements()
        },
    },
    watch: {
        selectedItems(sel) {
            this.$emit('input', sel)
        },
        value(val) {
            this.selectedItems = val
        },
    },
}
</script>

<style scoped lang="less"></style>
