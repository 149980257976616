<template>
    <section class="safe_area_page">
        <div style="display: flex; justify-content: end; padding: 10px">
            <a-button
                :loading="isLoading"
                type="primary"
                @click="handleAddNewArea()"
            >
                <a-icon v-if="!isLoading" type="plus" />
                New safe area
            </a-button>
        </div>
        <div class="scroll safe_area_page__scroll-panel">
            <a-table
                class="safe_area_page__table"
                size="small"
                row-key="id"
                :loading="isLoading"
                :columns="columns"
                :data-source="areas"
                :pagination="{ pageSize, position: 'both' }"
            >
                <template #actions="record">
                    <div key="record.id">
                        <a
                            style="margin-right: 16px"
                            @click="handleEdit(record)"
                        >
                            Edit
                        </a>
                        <a-popconfirm
                            title="Are you sure remove this Safe Area?"
                            ok-text="Yes"
                            cancel-text="No"
                            @confirm="handleRemove(record.id)"
                        >
                            <a> Remove </a>
                        </a-popconfirm>
                    </div>
                </template>
            </a-table>
        </div>
        <new-or-update-safe-area-modal
            v-if="newOrUpdateAreaModal"
            :safe-area="safeAreaToUpdate"
            @closeSafeAreaModal="newOrUpdateAreaModal = false"
            @updateSafeArea="handleSafeAreaUpdate($event)"
            @newSafeAra="handleSafeAreaCreate($event)"
        />
    </section>
</template>

<script>
import NewOrUpdateSafeAreaModal from '@/components/settings/safe-areas/NewOrUpdateSafeAreaModal.vue'
import { settingsSafeAreasService } from '@/components/settings/safe-areas/safe-areas.service'
import { mapActions } from 'vuex'

const SAFE_AREAS_PER_PAGE_LIMIT = 16

export default {
    name: 'SafeAreasSettingsPage',
    components: { NewOrUpdateSafeAreaModal },
    data() {
        return {
            newOrUpdateAreaModal: false,
            areas: [],
            safeAreaToUpdate: null,
            isLoading: true,
        }
    },
    computed: {
        columns() {
            return [
                {
                    title: 'Creation size',
                    dataIndex: 'size',
                    width: 160,
                },
                {
                    title: 'Safe area',
                    dataIndex: 'safeArea',
                },
                {
                    title: 'Actions',
                    scopedSlots: { customRender: 'actions' },
                },
            ]
        },
        pageSize() {
            return SAFE_AREAS_PER_PAGE_LIMIT
        },
    },
    methods: {
        ...mapActions(['showAlertSuccess', 'showAlertError']),
        handleAddNewArea() {
            this.safeAreaToUpdate = null
            this.newOrUpdateAreaModal = true
        },
        handleEdit(record) {
            this.safeAreaToUpdate = { ...record }
            this.newOrUpdateAreaModal = true
        },
        async loadAreas() {
            try {
                this.isLoading = true
                this.areas = await settingsSafeAreasService.getAll()
            } catch {
                //
            } finally {
                this.isLoading = false
            }
        },
        async handleSafeAreaUpdate(safeArea) {
            try {
                await settingsSafeAreasService.update(safeArea.id, safeArea)
                this.newOrUpdateAreaModal = false
                this.showAlertSuccess(
                    `Safe area: ${safeArea.width}x${safeArea.height} updated!`
                )
                await this.loadAreas()
            } catch (e) {
                this.showAlertError(e.message)
            }
        },
        async handleSafeAreaCreate(safeArea) {
            try {
                await settingsSafeAreasService.create(safeArea)
                this.newOrUpdateAreaModal = false
                this.showAlertSuccess(
                    `Safe area: ${safeArea.width}x${safeArea.height} created!`
                )
                await this.loadAreas()
            } catch (e) {
                this.showAlertError(e.message)
            }
        },
        async handleRemove(areaId) {
            try {
                await settingsSafeAreasService.remove(areaId)
                this.newOrUpdateAreaModal = false
                await this.loadAreas()
            } catch (e) {
                this.showAlertError(e.message)
            }
        },
    },
    async mounted() {
        await this.loadAreas()
    },
}
</script>
<style lang="less">
.safe_area_page {
    &__table .ant-table {
        border: 0;
    }
}
</style>
<style scoped lang="less">
.safe_area_page {
    &__scroll-panel {
        overflow-y: auto;
        max-height: calc(100vh - 260px);
        overflow-x: hidden;
    }
}
</style>
