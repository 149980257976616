<template>
    <a-modal class="add-product"
        v-model="visible"
        title="Add offer"
        @ok="handleAddProducts"
        @cancel="$emit('closeAddProductModal')"
        :width="600"
    >
        <div class="add-product__search">
            <div class="flex">
                <a-input class="add-product__add-input" placeholder="Allegro offer ID number" v-model="offer_id"></a-input>
                <a-button class="add-product__add-btn" key="back" type="primary" @click="addProduct($event)">
                  Add
                </a-button>
            </div>

            <a-input placeholder="Search" @input="handleSearch($event)">
                <a-icon slot="prefix" type="search" />
            </a-input>
        </div>

         <a-config-provider>
            <div class="config-provider" v-if="products.length">
                <a-table
                    :columns="columns"
                    :data-source="products"
                    :row-selection="rowSelection"
                    :pagination="pagination"
                    row-key="id"
                    @change="handleTableChange"
                >
                    <img class="add-product__image" slot="image" slot-scope="text" :src="text" />
                    <a slot="offerId" slot-scope="text, record" :href="record.offer_url" target="_blank" rel="noopener noreferrer">{{ text }}</a>
                    <template slot="actions" slot-scope="record">
                      <a-button v-if="record.offer_id" @click="updateProduct(record.id)">
                        <a-icon type="reload"></a-icon>
                      </a-button>
                    </template>
                </a-table>
            </div>
            <template v-else>
             <s-empty :size="'xl'"/>
            </template>
        </a-config-provider>

        <template slot="footer">
           <div style="display: flex">
             <a-button key="back" type="default" @click="$emit('closeAddProductModal')">
               Close
             </a-button>
             <locale-picker style="margin-left: auto" v-model="lang" display-type="flag-only" :show-label="false"/>
             <a-select v-model="currency">
               <a-select-option key="PLN">PLN</a-select-option>
               <a-select-option key="EUR">EUR</a-select-option>
               <a-select-option key="CZK">CZK</a-select-option>
             </a-select>
             <a-button class="primary-action" :disabled="!selectedProductIds.length" key="submit" type="primary" @click="handleAddProducts">
               Add selected
             </a-button>
           </div>
        </template>

    </a-modal>
</template>

<script>
import LocalePicker from '@/components/shared/locale-picker/LocalePicker'
import { LANG_LOCALES } from '@/components/shared/supported-languages';
import Empty from "../../layout/Empty";
import { mapActions } from "vuex"
import { productService } from "@/services/product.service"
import { debounce } from '@/view-helpers/debouncer'

const NO_OF_PRODUCTS_PER_PAGE = 8;

export default {
    name: 'AddProductModal',
    components: {
      LocalePicker,
      SEmpty: Empty
    },
    data(){
        return {
          query: "",
          timeout: 0,
          offer_id: "",
          visible: true,
          products: [],
          selectedProducts: [],
          selectedProductIds: [],
          lang: LANG_LOCALES.pl,
          currency: 'PLN',
          columns: [
              {
                  title: 'Product name',
                  key: 'image',
                  dataIndex: 'image_url',
                  scopedSlots: { customRender: 'image' },
                  width: 36,
                  colSpan: 2
              },
              {
                  title: '',
                  key: 'name',
                  dataIndex: 'name',
                  ellipsis: true,
                  colSpan: 0
              },
              {
                  title: 'Offer no.',
                  key: 'offerId',
                  dataIndex: 'offer_id',
                  scopedSlots: { customRender: 'offerId' },
                  width: 120
              },
              {
                title: '',
                key: '',
                width: 64,
                scopedSlots: { customRender: 'actions' }
              }
          ],
          rowSelection: {
              onSelect: (record, selected, selectedRows) => {
                  this.selectedProductIds = selectedRows.map(r => r.id);
              },
              onSelectAll: (selected, selectedRows) => {
                  this.selectedProductIds = selectedRows.map(r => r.id);
              },
          },
          pagination: {
            total: 1,
            current: 1,
            pageSize: NO_OF_PRODUCTS_PER_PAGE
          }
        }
    },
    mounted(){
      this.loadProducts();
    },
    methods: {
        ...mapActions(["showAlertError", "showAlertSuccess", "setWaiting"]),
        handleAddProducts() {
          const {selectedProductIds: productIds, currency, lang} = this;
          this.$emit('addProducts', {productIds, currency, lang});
        },
        handleTableChange(pagination) {
          const {current} = pagination;
          this.pagination.current = current;
          this.loadProducts();
        },
        async addProduct() {
            if (!this.offer_id) {
                this.showAlertError('Provide Allegro offer ID number')
            }

            try {
                await productService.add(this.offer_id)
            } catch ( error ) {
                this.showAlertError('Product addition error: ' + error.message)
                return;
            }

            this.showAlertSuccess('Product was added correctly');
            this.offer_id = '';
            await this.loadProducts();
        },
        handleSearch: debounce(function (timer, {target}) {
          this.timeout = timer;
          this.query = target.value;
          this.loadProducts();
        }),
        async loadProducts() {
          try {
            this.setWaiting(true)
            const {current} = this.pagination;
            const pageIndex = current - 1;
            const {results, total} = await productService.all( {offer_id: this.query}, [ 'created_at', 'desc' ], pageIndex, NO_OF_PRODUCTS_PER_PAGE )
            this.products = results;
            this.pagination = { ...this.pagination, total }
          } catch ( error ) {
            this.showAlertError('Products loading error: ' + error.message )
          } finally {
            this.setWaiting(false)
          }
        },
        async updateProduct(productId) {
          try {
            this.setWaiting(true)
            await productService.update(productId)
          } catch ( error ) {
            this.showAlertError('Products update error: ' + error.message )
          } finally {
            this.setWaiting(false)
          }
        }
    },
    beforeDestroy () {
      if(this.timeout) {
        clearTimeout(this.timeout);
      }
    }
}
</script>

<style lang="less">
.flex {
    display: flex;
}
.primary-action {
  margin-left: 5px;
}
.add-product {
    &__add-input {
        margin-top: 5px;
    }
    &__add-btn {
        margin-top: 5px;
        margin-left: 5px;
    }
    &__search {
        padding: 16px;
        border-bottom: solid 1px @gray-4;
    }
    .flex {
        margin-bottom: 10px;
    }
    .ant-modal-body {
        padding: 0;
    }
    th:nth-child(2) {
        padding-left: 0;
        text-align: left !important;
    }
    td:nth-child(2) {
        padding: 8px 0 !important;
    }
    td:nth-child(4) {
        text-decoration: underline;
    }
    .ant-table-tbody > tr > td {
        padding: 8px 8px;
        font-weight: 400;
    }

    &__image {
        width: 32px;
        height: 32px;
        border-radius: 2px;
        object-fit: cover;
    }
}

</style>
