import axios from 'axios';
import store from '../store'
import merge from 'deepmerge'

const { CancelToken } = axios;

// register app router (need to be like that, because import dependencies inside router!)
let router = {};
export function registerRouter(appRouter) {
	router = appRouter;
}

export const axInstance = axios.create({
	baseURL: process.env.VUE_APP_API_URL
})

axInstance.interceptors.request.use(function (config) {
	const { token } = store.state.user;
	if(token) {
		config.headers.Authorization = 'Bearer '+ token;
	}
	return config;
});

axInstance.interceptors.response.use(function (response) {
	if (response.config.responseType === 'arraybuffer') {
		return response;
	}
	return response.data;
}, function (errorData) {
	const {response} = errorData;
	if(response) {
		const { status, data, statusText } = response;
		const { error } = normalizeErrorData(data);
		const isString = typeof error === 'string';
		const err = new Error(isString ? error : statusText);
		err.status = status;
		if(status === 422) {
			// Special ValidationError occurred, so:
			const asArray = Array.isArray(error) ? error : [];
			err.errors = mapServerErrorsArrayToErrorsObject(asArray);
		}
		// Handle 401 + Expired Token
		forceLogOutWhenTokenExpired(status, error);
		return Promise.reject(err);
	}
	// If is does not have an response it is a NetworkError then:
	// (either server side unreachable or no network connection)
	return Promise.reject(errorData);
});

/*
* @SideEffect
* encapsulation of logic needed to force LogOut on Expired Token
* */
const ERROR_401_EXPIRED_TOKEN = 'Your token key expired';
const LOGIN_ROUTE = '/login';
function forceLogOutWhenTokenExpired(status, errorMessage) {
	if(status === 401 && errorMessage === ERROR_401_EXPIRED_TOKEN) {
		const {fullPath, path} = router.history.current;
		store.dispatch('user/logOut').then(() => {
			// #Fix: do not change route again when already on /login
			if(path !== LOGIN_ROUTE) {
				router.push({ path: LOGIN_ROUTE, query: { backTo: fullPath, reason: ERROR_401_EXPIRED_TOKEN } });
			}
		})
	}
}

/*
* This function merges ONLY 2nd argument! and DROPS the rest
* It is designed for [SETTINGS] - and 2nd argument SHOULD be an object !!
*
* */
const overwriteMerge = (destinationArray, sourceArray) => sourceArray
export function debounceMerge2ndArg( fnCall, debounceTime = 150) {
	const mergedTimers = {};
	const mergedArgs = {};
	return (memo, objectArg, ...restArgs) => new Promise((resolve, reject) => {
		if(!(objectArg instanceof Object) && !Array.isArray(objectArg)) {
			reject(new Error('debounceMerge2ndArg - 2nd arg should be an simple object !'))
		}
		if(mergedTimers[memo] && mergedArgs[memo]) {
			clearTimeout(mergedTimers[memo]);
		}
		if(!mergedArgs[memo]) {
				mergedArgs[memo] = { ...objectArg };
		}
		mergedArgs[memo] = merge(mergedArgs[memo], objectArg, { arrayMerge: overwriteMerge });
		mergedTimers[memo] = setTimeout(() => {
			fnCall(memo, mergedArgs[memo], ...restArgs).then(resolve).catch(reject);
			delete mergedArgs[memo];
		}, debounceTime);
	})
}

/*
* This function handle case when axios expects 'arraybuffer' data,
* It also wants the json Error - to be `arraybuffer` - so we need to convert it back to JSON
* ([!] despite the fact that server sends proper header Content-Type: application/json)
*
* exported for test purpose only !
* */
export function normalizeErrorData(data) {
	let computedData = data;
	if (data instanceof ArrayBuffer) {
		try {
			const converted = String.fromCharCode(...new Uint8Array(data));
			computedData = JSON.parse(decodeURIComponent(escape(converted)));
			// eslint-disable-next-line no-empty
		} catch (e) {}
	}
	return computedData;
}

/*
* This function should map [{a: 1}, {a, 2}] to { a: [Error(1), Error(2)] }
* */
function mapServerErrorsArrayToErrorsObject(errorArr = []) {
	return errorArr.reduce((main, single) => {
		const [key] = Object.keys(single || {});
		if(!main[key]) {
			main[key] = [];
		}
		main[key].push(Error(single[key]));
		return main;
	}, {});
}

export function cancelableRequest(requestFn) {
	const source = CancelToken.source();
	return {
		call: () => requestFn({cancelToken: source.token}),
		cancel: (message = 'Operation canceled') => source.cancel(message)
	}
}

export const debounceRequest = (reqFn, debounceTime = 100) => {
	let timer;
	return async (...args) => {
		if(timer) {
			clearTimeout(timer);
		}
		return new Promise((resolve) => {
			timer = setTimeout(() => {
				resolve(reqFn(...args))
			}, debounceTime);
		})
	}
}
