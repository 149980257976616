
const makeTemporaryId = () => Math.random() * 2311232;
const sortEntriesBasedOnOrder = ([,entryA],[,entryB]) => (entryA?.order || 0) - (entryB.order || 0)
const entryToArrayAdapter = (entry) => Array.isArray(entry) ? entry : (entry?.values || [])

function extractEntriesFromObjectWithOrdering(objectToModify) {
	return Object.entries(objectToModify)
		.sort(sortEntriesBasedOnOrder)
		.map(([name, entry]) => [name, entryToArrayAdapter(entry) ]);
}

/*
* Make entry factory with Closure nextOrder
* */
function makeColorGroupEntry(nextOrder = 1) {
	return ({name = '', entry = []}) => {
		const values = entryToArrayAdapter(entry)
		const order = entry?.order || nextOrder++;
		return {name, values, order, currentColor: '', tempId: makeTemporaryId() }
	}
}

export const possibleColorGroups = {
	sortEntriesBasedOnOrder,
	entryToArrayAdapter,
	makeColorGroupEntry,
	extractEntriesFromObjectWithOrdering,
}
