<template>
  <section>
    <p>Template validation</p>
    <p class="description-preview">Description preview</p>
    <section class="md-container scroll" style="height: 100px; overflow-y: auto" v-html="parsedHTMLDescription"/>
    <p class="report">Report <span>{{formattedReportDate}}</span></p>
    <a-collapse :default-active-key="[1,2,3]" :bordered="false" class="collapse-panel scroll">
      <a-collapse-panel key="1" v-if="isLoading || noAvailableReport || reportPending" :header="headerDynamicPanel">
        <div v-if="noAvailableReport">
          <a-button @click="handleValidationRequestClick"><a-icon type="reload" />Validate template</a-button>
        </div>
        <div v-if="reportPending">
          <inline-preloader text="Awaiting report..." />
        </div>
      </a-collapse-panel>
      <a-collapse-panel key="1" v-if="infoMessages.length">
        <template #header>
          <div class="collapse-header">
            Info <a-badge :count="infoMessages.length" :number-style="{ backgroundColor: '#1890ff', color: 'black' }"  />
          </div>
        </template>
        <a-alert v-for="info of infoMessages" :message="info.message" :key="info.id" :banner="true" type="info" />
      </a-collapse-panel>
      <a-collapse-panel key="2" v-if="warningMessages.length">
        <template #header>
          <div class="collapse-header">
            Warnings <a-badge :count="warningMessages.length" :number-style="{ backgroundColor: '#faad14', color: 'black' }"  />
          </div>
        </template>
        <a-alert v-for="info of warningMessages" :message="info.message" :key="info.id" :banner="true" />
      </a-collapse-panel>
      <a-collapse-panel key="3" v-if="errorMessages.length">
        <template #header>
          <div class="collapse-header">
            Critical <a-badge :count="errorMessages.length" :number-style="{ backgroundColor: '#f5222d', color: 'black' }"  />
          </div>
        </template>
        <a-alert v-for="info of errorMessages" :message="info.message" :key="info.id" :banner="true" type="error" />
      </a-collapse-panel>
    </a-collapse>
    <div class="warn-about-upload" v-if="showDraftReportNotice">
      <a-icon type="warning" /> NOTICE: Report above is about CURRENT template in system, NOT about freshly uploaded draft:
      <code>{{templateDraftName}}</code>. You will see report about it after confirming "Update".
    </div>
  </section>
</template>

<script>

import InlinePreloader from '@/components/project/components/InlinePreloader';
import { markupParser } from '@/components/shared/markup/markup.parser';
import { templateValidationService } from '@/services/template-validation.service';
import { dateFormatter } from '@/view-helpers/date-formatter';
const TYPES = {
  INFO: 'info',
  WARN: 'warning',
  ERROR: 'error'
}
const WAIT_FOR_REPORT = 3000;

export default {
  name: 'TemplateValidationPanel',
  components: { InlinePreloader },
  props: {
    templateId: {
      type: [String, Number],
      default: 0,
      required: true
    },
    description: {
      type: String,
      default: ''
    },
    zipUploadDraftName: {
      type: String,
      default: ''
    },
    draftLogReport: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      isLoading: true,
      noAvailableReport: false,
      reportPending: false,
      messages: [],
      dynamicPanel: '',
      timeoutId: 0,
      reportDate: ''
    }
  },
  beforeDestroy() {
    if(this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  },
  async mounted() {
    this.isLoading = !this.draftLogReport;
    if(this.draftLogReport) {
      this.messages = mapAllErrors(this.draftLogReport?.log || [])
    } else {
      await this.refreshReport()
    }
  },
  computed: {
    headerDynamicPanel() {
      return this.isLoading ? 'Loading report...' : 'No report available'
    },
    showDraftReportNotice() {
      return this.zipUploadDraftName && !this.draftLogReport
    },
    templateDraftName() {
      const [name] = this.zipUploadDraftName.split('--')
      return name
    },
    formattedReportDate() {
      return this.reportDate ? dateFormatter(this.reportDate) : ''
    },
    infoMessages() {
      return this.messages.filter(m => m.severity === TYPES.INFO)
    },
    warningMessages() {
      return this.messages.filter(m => m.severity === TYPES.WARN)
    },
    errorMessages() {
      return this.messages.filter(m => m.severity === TYPES.ERROR)
    },
    parsedHTMLDescription(){
      return markupParser.parse(this?.description || '')
    }
  },
  methods: {
    refreshReportPooling() {
      this.timeoutId = setTimeout(async () => {
        await this.refreshReport();
      }, WAIT_FOR_REPORT)
    },
    async handleValidationRequestClick() {
      await templateValidationService.request(this.templateId)
      this.reportPending = true;
      this.noAvailableReport = false;
      this.refreshReportPooling()
    },
    async refreshReport() {
      this.noAvailableReport = false;
      this.reportPending = true;
      try {
        const { status, results, generated_at } = await templateValidationService.get(this.templateId)
        this.reportDate = generated_at;
        switch( status ) {
          case templateValidationService.STATUS.PENDING:
            this.reportPending = true;
            this.refreshReportPooling()
            break;
          case templateValidationService.STATUS.FAILED:
            this.noAvailableReport = true;
            this.messages = [];
            break;
          default:
            this.messages = mapAllErrors(results?.log || [])
        }
      } catch (e) {
        if(e.status === 404) {
          this.noAvailableReport = true
        } else {
          console.error(e);
        }
      } finally {
        this.isLoading = false
        this.reportPending = false;
      }
    }
  }
}

function mapAllErrors(errors = []) {
  // Add virtual Ids, to error messages list.\:
  return errors.map(msg => ({id: Math.random() * 9000, ...msg}))
}
</script>

<style scoped lang="less">

.collapse-panel {
  max-height: 465px;
  overflow-y: auto
}

.description-preview {
  font-size: 12px;
  margin-top: 6em;
  border-bottom: 1px solid @gray-5;
  margin-bottom: .2em;
}

.report {
  font-size: 12px;
  margin-bottom: .5em;
  padding-top: .6em;
  border-top: 1px solid @gray-5;
  display: flex;
  justify-content: space-between;
}

.collapse-header {
  display: flex;
  justify-content: space-between;
}

.warn-about-upload {
  margin-top: 1em;
  background-color: @gray-3;
  padding: .4em;
  color: @primary-7;
}
</style>

