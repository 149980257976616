import { isUrlAbsolute } from "@/templating/templating-helpers";

const { VUE_APP_VERSION } = process.env;

const PARSED_VERSION = (VUE_APP_VERSION || '1.0.0').split('.').join('');
const LINK_PREFIX_REG = /^\//;

export const cacheResolver = {
    assureUrlWithVersionQuery(urlString) {
        if(typeof urlString !== 'string') {
            return urlString;
        }
        const isPrefixed = LINK_PREFIX_REG.test(urlString);
        const isAbsolute = isUrlAbsolute(urlString);
        try {
            const url = new URL(urlString, location.origin);
            url.searchParams.set('v', PARSED_VERSION);
            const makeURL = isAbsolute ? url.href : (url.pathname + url.search);
            return isPrefixed ? makeURL : makeURL.replace(LINK_PREFIX_REG, '');
        } catch (e) {
            console.error(`Unable to make cached link from: ${urlString}.`)
            return urlString;
        }
    }
}
