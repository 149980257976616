let templateHolders = {}
let templateHoldersByCreation = {}
let templateHoldersByProduct = {}

import autoprefixer from 'autoprefixer'
import postcss from 'postcss'

// @fix: need to use postcss, due to `-webkit-mask` prefix problem (removed from .cssText)
const runPostCss = postcss([autoprefixer])
// If Not an array: do nothing
const removeArrayFalsyEntriesHelper = arr =>
    !Array.isArray(arr) ? arr : arr.filter(Boolean)

export const templateHoldersService = {
    cleanTemplateHolders() {
        templateHolders = {}
        templateHoldersByCreation = {}
        templateHoldersByProduct = {}
    },
    getTemplateHoldersById(templateId) {
        return removeArrayFalsyEntriesHelper(templateHolders[templateId])
    },
    getTemplateHoldersByCreationId(creationId) {
        return removeArrayFalsyEntriesHelper(
            templateHoldersByCreation[creationId]
        )
    },
    addTemplateHolder({ id, templateHolder, creationId, productId }) {
        if (id) {
            if (!templateHolders[id]) {
                templateHolders[id] = []
            }
            templateHolders[id].push(templateHolder)
        }
        if (creationId) {
            if (!templateHoldersByCreation[creationId]) {
                templateHoldersByCreation[creationId] = []
            }
            templateHoldersByCreation[creationId].push(templateHolder)
        }
        if (productId) {
            if (!templateHoldersByProduct[productId]) {
                templateHoldersByProduct[productId] = []
            }
            templateHoldersByProduct[productId].push(templateHolder)
        }
    },
    removeTemplateHolder({ id, creationId, productId, templateHolder }) {
        let idx = templateHoldersByCreation[creationId]?.findIndex(
            t => t === templateHolder
        )
        if (idx > -1) {
            delete templateHoldersByCreation[creationId][idx]
        }
        idx = templateHoldersByProduct[productId]?.findIndex(
            t => t === templateHolder
        )
        if (idx > -1) {
            delete templateHoldersByProduct[productId][idx]
        }
        idx = templateHolders[id]?.findIndex(t => t === templateHolder)
        if (idx > -1) {
            delete templateHolders[id][idx]
        }
    },
    setConnectionSettings(templateId, settingsByConnections) {
        if (!templateHolders[templateId]) {
            console.warn(
                `(setNewConnectionSettings): There is no template holder (:${templateId}) to handle settings`,
                settingsByConnections
            )
            return
        }
        templateHolders[templateId].forEach(
            applySettingsForProperConnection(settingsByConnections)
        )
    },
    setConnectionSettingsByProduct(productId, settingsByConnections) {
        if (!templateHoldersByProduct[productId]) {
            console.warn(
                `
					(setNewConnectionSettingsByProduct): There is no template holder with productId(:${productId}) to handle settings`,
                settingsByConnections
            )
            return
        }
        templateHoldersByProduct[productId].forEach(
            applySettingsForProperConnection(settingsByConnections)
        )
    },
    setCreationsSettings(settingsByCreations) {
        // updateSettings by creationIDs:
        // extract templateHolders -> update each and repaint by "setValues".
        for (const creationId of Object.keys(settingsByCreations)) {
            const templateHolders = templateHoldersByCreation[creationId]
            if (templateHolders) {
                templateHolders.forEach(template => {
                    const settings = settingsByCreations[creationId]
                    template.getCreationModel().updateSettings(settings)
                    template.setValues(template.getCreationModel().settings)
                })
            }
        }
    },
    updateTemplateValues({
        id,
        type,
        key,
        value,
        templateId,
        creationId,
        productId,
    }) {
        if (!templateHolders[templateId]) {
            console.warn(
                `(updateTemplate): There is no template holder (:${templateId})  to handle change`,
                { id, type, key, value, templateId }
            )
            return
        }
        if (creationId) {
            templateHoldersByCreation[creationId].forEach(t => {
                t.updateControl({ type, id, key, value })
            })
        } else if (productId) {
            templateHoldersByProduct[productId].forEach(t => {
                t.updateControl({ type, id, key, value })
            })
        } else {
            templateHolders[templateId].forEach(t => {
                //Do not propagate change if creation settings are overwritten:
                if (
                    !isValuePresentOnCreationBaseSettings(t, { id, type, key })
                ) {
                    t.updateControl({ type, id, key, value })
                }
            })
        }
    },
    updateTemplateHolder({ type, id, key, value, templateId }) {
        if (!templateHolders[templateId]) {
            console.warn(
                `
					(updateHolder): There is no template holder (:${templateId}) to handle change`,
                { id, type, key, value, templateId }
            )
            return
        }
        templateHolders[templateId].forEach(t => {
            //Do not propagate change if creation settings are overwritten:
            if (!isValuePresentOnCreationBaseSettings(t, { id, type, key })) {
                let sendValue = value
                if (value === null) {
                    t.creationModel.removeConnectionSetting({ type, id, key })
                    sendValue = t.creationModel.settings?.[type]?.[id]?.[key]
                }
                t.updateControl({ type, id, key, value: sendValue })
            }
        })
    },
    updateTemplateHolderByCreation({ type, id, key, value, creationId }) {
        if (!templateHoldersByCreation[creationId]) {
            console.warn(
                `
					(updateHolderByCreationId): There is no template holder with creationId(:${creationId}) to handle change`,
                { id, type, key, value, creationId }
            )
            return
        }
        templateHoldersByCreation[creationId].forEach(t => {
            let sendValue = value
            if (value === null) {
                t.creationModel.removeCreationSetting({ type, id, key })
                sendValue = t.creationModel.settings?.[type]?.[id]?.[key]
            }
            t.updateControl({ type, id, key, value: sendValue })
        })
    },
    updateTemplateHolderByProduct({ type, id, key, value, productId }) {
        if (!templateHoldersByProduct[productId]) {
            console.warn(
                `
					(updateHolderByProductId): There is no template holder with productId(:${productId}) to handle change`,
                { id, type, key, value, productId }
            )
            return
        }
        templateHoldersByProduct[productId].forEach(t => {
            // Do not propagate change if creation settings with productData are overwritten:
            const isNotPresentInProductData = !isValuePresentOnCreationProductDataSettings(
                t,
                { id, type, key, value }
            )
            // @Fix: with product we can also change BADGES (or e.g. CTA lang), so we need to check also this:
            const isNotPresentInBaseSettings = !isValuePresentOnCreationBaseSettings(
                t,
                { id, type, key }
            )
            if (isNotPresentInProductData && isNotPresentInBaseSettings) {
                t.updateControl({ type, id, key, value })
            }
        })
    },
    sendCurrentCreationSettingsToHolders(creationId, settings) {
        if (templateHoldersByCreation[creationId]) {
            templateHoldersByCreation[creationId].forEach(t => {
                t.setValues(settings)
            })
        }
    },
    // Methods needed for "RENDER" via back-end:
    async getGlobalStylesByTemplateId(templateCssId = '') {
        // We need to extract rules that way due to possible changes via JavaScript
        const { cssRules } = document.getElementById(templateCssId)?.sheet || {}
        const allRules = Array.from(cssRules || [], rule => rule.cssText)
        const styles = allRules.join('')
        return runPostCss.process(styles).then(r => r.css)
    },
    getTemplateHolderHTML(templateHolder) {
        // @Jabi: consider proper compression of HTML
        return templateHolder.getTemplate()
            .outerHTML /*.replace(/[\n\r\t]/g, '');*/
    },
}

function isValuePresentOnCreationBaseSettings(template, { id, type, key }) {
    const creationModel = template.getCreationModel()
    return Boolean(creationModel?.baseSettings?.[type]?.[id]?.[key])
}

function isValuePresentOnCreationProductDataSettings(
    template,
    { id, type, key, value }
) {
    const creationModel = template.getCreationModel()
    if (key === 'productData' && value !== null && typeof value === 'object') {
        const [objKey] = Object.keys(value) || []
        return Boolean(
            creationModel?.baseSettings?.[type]?.[id]?.[key]?.[objKey]
        )
    }
    return false
}

function applySettingsForProperConnection(settingsByConnections) {
    return templateHolder => {
        const id = templateHolder.getCreationModel().connectionId
        const settings = settingsByConnections[id]
        // Apply setting but ONLY to proper connection !
        if (settings) {
            // This is CRUCIAL to update Template.js Ahead of Time !
            templateHolder.getCreationModel().applySettings(settings)
            templateHolder.getCreationModel().setDirty()
        }
    }
}
