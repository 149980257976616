<template>
  <div class="change-password-form">
    <a-form-model ref="formRef" :model="changeForm" :rules="rules" >
      <template v-if="resetViaOldPassword">
        <p class="change-password-form__label">Current password</p>
        <a-form-model-item prop="oldPassword" :style="modelItemStyle">
          <a-input-password
              class="remind__input"
              :style="inputStyle"
              placeholder="Current password"
              v-model="changeForm.oldPassword"
              @change="handleFormChange"
              @blur="handleFormChange"
          />
        </a-form-model-item>
      </template>
      <div style="display: flex; justify-content: space-between; align-content: end">
        <p class="change-password-form__label">New password</p>
        <a-tooltip :title="passwordRequirements">
          <a-icon type="question-circle" />
        </a-tooltip>
      </div>
      <a-form-model-item prop="password" :style="modelItemStyle">
        <a-input-password
            class="remind__input"
            :style="inputStyle"
            placeholder="Password"
            v-model="changeForm.password"
            @change="handleFormChange"
            @blur="handleFormChange"
        />
      </a-form-model-item>
      <p class="change-password-form__label">Repeat new password</p>
      <a-form-model-item prop="repeatPassword" :style="modelItemStyle">
        <a-input-password
            class="remind__input"
            :style="inputStyle"
            placeholder="Repeat password"
            v-model="changeForm.repeatPassword"
            @change="handleFormChange"
            @blur="handleFormChange"
        />
      </a-form-model-item>
    </a-form-model>
    <div class="change-password-form__submit" v-if="showActionButton">
      <a-button type="primary" @click="handleSubmit()" :loading="loading">{{ctaBtnText}}</a-button>
    </div>
  </div>
</template>

<script>

const PASSWORD_VALIDATE = [
  { predicate: v => v.length < 8, failMessage: 'At least 8 chars long.' },
  { predicate: v => !/\d+/.test(v), failMessage: 'At least one number' },
  { predicate: v => !/[A-ZŻĆÓŚŹŁŃ]/.test(v), failMessage: 'At least one uppercase letter' },
]

const passwordMustMeetRequirements = (rule, value, callback) => {
  for (const validateAgainst of PASSWORD_VALIDATE) {
    if(validateAgainst.predicate(value)) {
      return callback(validateAgainst.failMessage)
    }
  }
  callback()
}

const mustBeSameAsField = (data, dataRefName, fieldName = '', errorMessage = '') => (rule, value, callback) => {
  const otherFieldValue = data[dataRefName][fieldName];
  if(!value || !otherFieldValue) {
    return callback();
  }
  const hasTheSameValue = otherFieldValue === value;
  const error = hasTheSameValue ? undefined : errorMessage
  return callback(error)
}

export default {
  name: 'ChangePasswordForm',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    resetViaOldPassword: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    ctaBtnText: {
      type: String,
      default: 'Change password'
    }
  },
  data() {
    return {
      changeForm: {
        password: '',
        oldPassword: '',
        repeatPassword: ''
      },
      rules: {
        oldPassword: [
          { required: this.resetViaOldPassword, message: 'This field is required' }
        ],
        password: [
            { required: true, message: 'This field is required' },
            { validator: passwordMustMeetRequirements, trigger: 'blur' },
        ],
        repeatPassword: [
          { required: true, message: 'This field is required' },
          {
            validator: mustBeSameAsField(this, 'changeForm', 'password', 'Value must be same as new password'),
            trigger: 'blur'
          }
        ],
      }
    }
  },
  computed: {
    passwordRequirements() {
      return  'Min. 8 chars, at least one number, at least one uppercase character';
    },
    showActionButton() {
      return !this.resetViaOldPassword;
    },
    modelItemStyle() {
      return {marginBottom: 0};
    },
    inputStyle() {
      return this.resetViaOldPassword ?
          {
            width: '100%'
          }
          :
          {};
    }
  },
  methods: {
    handleFormChange() {
      this.$nextTick(() => {
        const isValid = this.$refs.formRef.fields.map(f => f.validateState).every(m => m === 'success');
        this.$emit('change', {
          isValid,
          ...this.changeForm
        })
      })
    },
    handleSubmit() {
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          const { password } = this.changeForm;
          this.$emit('sendForm', {password })
        }
      });
    }
  },
}
</script>

<style scoped lang="less">
   .change-password-form {
     &__label {
       margin-bottom: 4px;
       font-size: 12px;
       font-weight: 400;
       line-height: 20px;
       color: @gray-9;
     }

     &__submit {
       display: flex;
       button {
         flex-grow: 1;
       }
     }
   }
</style>
