import { fileDownloadService } from '@/services/file-download.service';
import { format } from 'date-fns';
import { axInstance, cancelableRequest } from './ax-instance'

const BASE_AUTH_URI = '/auth';
const BASE_USERS_URI = '/users';

function logIn(email, password) {
	return cancelableRequest(({cancelToken}) => axInstance.post(BASE_AUTH_URI + '/login', {email, password}, {cancelToken}))
}

function logOut() {
	return axInstance.post(BASE_AUTH_URI + '/logout')
}

function signUp(email) {
	return axInstance.post(BASE_AUTH_URI + '/register', {email})
}

function resetPassword(email) {
	return axInstance.post(BASE_AUTH_URI + '/reset-password', {email})
}

function changePassword({token, email, password, oldPassword}) {
	return axInstance.put(BASE_AUTH_URI + '/change-password', {token, email, password, oldPassword})
}

function confirmInvitation({token, password}) {
	return axInstance.patch(BASE_AUTH_URI + '/confirm-invitation',{token, password})
}

function confirmRegistration({token, password}) {
	return axInstance.patch(BASE_AUTH_URI + '/confirm-registration',{token, password})
}

function loadInfo(id) {
	return axInstance.get(`${BASE_USERS_URI}/${id}`)
}

function saveInfo(id, userInfo) {
	return axInstance.patch(`${BASE_USERS_URI}/${id}`, userInfo)
}

function getAllEmails() {
	return axInstance.get(`${BASE_USERS_URI}/emails`)
}

function getAll(page = 0, limit = 30, search) {
	return axInstance.get(`${BASE_USERS_URI}`, {params: {page , limit, search }})
}

function getPermissionList() {
	return axInstance.get(`${BASE_USERS_URI}/permissions`)
}

function createNew({ email, name, lastName, roles }) {
	return axInstance.post(`${BASE_USERS_URI}`, { email, name, lastName, roles })
}

function remove(id) {
	return axInstance.delete(`${BASE_USERS_URI}/${id}`)
}

function update({ email, name, lastName, roles }) {
	return axInstance.put(`${BASE_USERS_URI}`, { email, name, lastName, roles })
}

function downloadAsCSV() {
	return axInstance.get(`${BASE_USERS_URI}`, {params:  {downloadAs: 'csv'}})
		.then((content) => {
			const date = format(new Date(), 'yyyy-MM-dd-HHmmss')
			fileDownloadService.saveFile(content, `user-list__${date}`, 'csv', 'text/csv;charset=utf-8');
		})
}

export const userService = {
	logIn, logOut, signUp, loadInfo, saveInfo,
	resetPassword, changePassword,
	getAllEmails,
	getAll, getPermissionList,
	confirmInvitation,
	confirmRegistration,
	createNew,
	remove,
	update,
	downloadAsCSV
}
