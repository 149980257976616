import {differenceInDays, set} from "date-fns";
import {galleryService} from "@/services/gallery.service";
import path from "path";

const MAX_DAYS_AS_SOON = 7;
const ZERO_TIME = {hours: 0, minutes: 0, seconds: 0}

const NEVER_EXPIRED = 'NEVER';
const EXPIRED = 'EXPIRED';
const NOT_EXPIRED = 'NOT_EXPIRED';
const SOON_EXPIRES = 'SOON_EXPIRES';

export const ITEM_EXPIRE = {
    EXPIRED,
    NOT_EXPIRED,
    NEVER_EXPIRED,
    SOON_EXPIRES,
}

export function hasGalleryItemExpired(expiredAt) {
    if(expiredAt === null) {
        return NEVER_EXPIRED;
    }
    const expirationDate = new Date(expiredAt);
    const diffDays = differenceInDays(
        set(expirationDate, ZERO_TIME),
        set(Date.now(), ZERO_TIME)
    );
    if(diffDays >= 0 && diffDays <= MAX_DAYS_AS_SOON) {
        return SOON_EXPIRES;
    }
    return diffDays > 0 ? NOT_EXPIRED : EXPIRED;
}

export function mapFileInfoToExpirationInfo(file) {
    const {expiresAt, isArchived} = file;
    let severity = 0;
    let icon = '';
    let style = {};
    const status = expiresAt === undefined ? '' : hasGalleryItemExpired(expiresAt)
    if(status === ITEM_EXPIRE.EXPIRED) {
        icon = 'close-circle';
        style = {color: '#D32029'};
        severity = 2;
    }
    if(status === ITEM_EXPIRE.SOON_EXPIRES) {
        icon = 'exclamation-circle';
        style =  {color: '#D89614'};
        severity = 3;
    }
    // @Business: it is possible to have archived but not expired item
    if(isArchived) {
        icon = 'container';
        style =  {color: '#951319'};
        severity = 1;
    }
    return { icon, status, style, severity };
}

export function galleryItemMapper({id, item, tags, campaign}) {
   if(!item) {
       return {id}
   }
   return {
       id,
       name: item.name,
       url: item.url,
       tags: tags ? tags.map(({id, name}) => ({name, id})) : [],
       expires: {
           at: item.expires_at,
           type: item.expiration_type
       },
       campaign
   }
}

export function mapGalleryItemToEditModel(galleryItem) {
    const {id, name, tags, expires, url} = galleryItem;
    return {
        id,
        hasFiles: true,
        name: path.basename(name, path.extname(name)),
        tags: tags?.map(({name}) => name),
        expiresAt: expires.at,
        expirationType: expires.type,
        image: {
            id,
            name, // should be without .ext
            url: galleryService.getGalleryItemUrl(url)
        }
    }
}
