<template>
    <a-modal
        v-model="visible"
        title="Account"
        @cancel="closeModal()"
    >
        <a-tabs @change="handleTabTypeChange">
          <a-tab-pane key="1" tab="General" >
            <p class="form__label">Name</p>
            <a-input class="form__input" placeholder="Name" v-model="user.name" />

            <p class="form__label">Last name</p>
            <a-input class="form__input" placeholder="Last name" v-model="user.lastName" />

            <p class="form__label">E-mail</p>
            <a-input class="form__input" placeholder="E-mail" v-model="user.email" read-only  />
          </a-tab-pane>
          <a-tab-pane key="2" tab="Password">
            <change-password-form
                :reset-via-old-password="true"
                @change="handleFormChange"
                v-if="!isChangedWithSuccess"
            />
            <a-alert
                v-if="changePasswordMessage"
                :message="changePasswordMessage"
                :type="changePasswordMessageType"
                show-icon
                banner
            />
          </a-tab-pane>
        </a-tabs>

        <template slot="footer">
            <a-button key="back" type="default" @click="closeModal()">
              Close
            </a-button>
            <a-button v-if="isGeneral" key="submit" type="primary" @click="handleSaveUserInfo()" :class="{'disabled': !dirty}">
              Save
            </a-button>
            <a-button v-if="isPasswordChange" key="submit" type="primary" @click="handlePasswordChange()" :disabled="isChangedWithSuccess">
              Confirm password change
            </a-button>
        </template>

    </a-modal>
</template>

<script>
import ChangePasswordForm from '@/components/login/ChangePasswordForm'
import { userService } from '@/services/user.service'
import { mapActions, mapGetters } from 'vuex'

const TYPE = {
  GENERAL: '1',
  PASS_CHANGE: '2'
}

export default {
  name: 'UserModal',
  components: { ChangePasswordForm },
  data(){
      return {
        visible: true,
        tabType: TYPE.GENERAL,
        changePasswordData: {},
        changePasswordMessage: '',
        changePasswordEmail: '',
        changePasswordMessageType: 'error',
        user: {}
      }
  },
  computed: {
    ...mapGetters("user", ["userInfo"]),
    dirty() {
      const {name, lastName} = this.user;
      return name !== this.userInfo.name || lastName !== this.userInfo.lastName;
    },
    isGeneral() {
      return this.tabType === TYPE.GENERAL
    },
    isPasswordChange() {
      return this.tabType === TYPE.PASS_CHANGE
    },
    isChangedWithSuccess() {
      return this.changePasswordMessageType === 'info';
    }
  },
  methods: {
    ...mapActions("user", ["loadUserInfo", "saveUserInfo"]),
    closeModal() {
      this.$emit('closeUserModal');
    },
    async handleSaveUserInfo() {
      if(!this.dirty) {
        return; // No changes detected...
      }
      const {name, lastName} = this.user;
      try {
        await this.saveUserInfo( { name, lastName } )
        this.closeModal();
      } catch {
        //
      }
    },
    handleTabTypeChange(key) {
      this.tabType = key;
    },
    async handlePasswordChange() {
      const { isValid, oldPassword, password } = this.changePasswordData;
      if(!isValid) {
        if(!oldPassword || !password) {
          this.changePasswordMessage = 'Fill in: Current and New password';
        }
        return;
      }
      try {
        await userService.changePassword({ oldPassword, email: this.changePasswordEmail, password })
        this.changePasswordMessageType = 'info';
        this.changePasswordMessage = 'Password successfully changed';
      } catch (e) {
        this.changePasswordMessage = e.message;
      }
    },
    handleFormChange(value) {
      this.changePasswordMessage = '';
      this.changePasswordData = value;
    }
  },
  async mounted () {
    try {
      await this.loadUserInfo()
      this.user = { ...this.userInfo }
      this.changePasswordEmail = this.user.email;
    } catch {
      //
    }
  }
}
</script>

<style lang="less">

</style>
