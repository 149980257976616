import { TControl } from './TControl'
import { sanitizeUrl } from './templating-helpers'

export class TemplateImage extends TControl {

	static $selector = '[data-img]';

	constructor (ref, baseUrl) {
		super(ref, 'image')
		this.id = this.ref.getAttribute(TemplateImage.$selector.slice(1, -1));
		this.baseUrl = baseUrl;
		this.url = ref.getAttribute('src');
	}

	get hFlip() {
		const computedStyle = getComputedStyle(this.ref);
		const { transform } = computedStyle;
		if(!transform || transform === 'none') {
			return undefined
		}
		const domMatrix = new DOMMatrix(transform);
		return domMatrix.a < 0;
	}
	//
	set hFlip(value) {
		setTimeout(() => {
			const computedStyle = getComputedStyle(this.ref);
			const { transform } = computedStyle;
			const domMatrix = new DOMMatrix(transform);
			const updateMatrix = () => this.ref.style.transform = domMatrix.toString();
			const scale = domMatrix.a;
			if(scale > 0 && value) {
				domMatrix.a = -scale;
				updateMatrix();
			} else if (transform && transform !== 'none') {
				domMatrix.a = scale < 0 ? -scale : scale;
				updateMatrix();
			}
		})
	}

	get url() {
		return this.ref.getAttribute('src')
	}

	set url(value) {
		this.ref.setAttribute('src', sanitizeUrl(value, this.baseUrl))
	}
}
