import AcceptInvitationPage from '@/components/login/AcceptInvitationPage'
import ConfirmRegistrationPage from '@/components/login/ConfirmRegistrationPage'
import SignupPage from '@/components/login/SignupPage.vue'
import UsersPage from '@/components/users/UsersPage'
import VueRouter from 'vue-router'
import { registerRouter } from '@/services/ax-instance'
import store from './store'
import ProjectsPage from '@/components/projects/ProjectsPage.vue'
import ProjectPage from '@/components/project/ProjectPage.vue'
import TemplatesPage from '@/components/templates/TemplatesPage'
import ReportsPage from '@/components/reports/ReportsPage'
import LoginPage from '@/components/login/LoginPage.vue'
import RemindPasswordPage from '@/components/login/RemindPasswordPage'
import ChangePasswordPage from '@/components/login/ChangePasswordPage'
import LinksPage from '@/components/links/LinksPage.vue'
import BannerPage from '@/components/banner/BannerPage.vue'
import GalleryPage from '@/components/gallery/GalleryPage.vue'
import CampaignsPage from '@/components/gallery/CampaignsPage.vue'
import ArchivedGalleryPage from '@/components/gallery/ArchivedGalleryPage.vue'
import GeneralUseGalleryPage from '@/components/gallery/GeneralUseGalleryPage.vue'
import CampaignGalleryItemsPage from '@/components/gallery/CampaignGalleryItemsPage.vue'
import SettingsPage from '@/components/settings/SettingsPage.vue'
import SafeAreasSettingsPage from '@/components/settings/safe-areas/SafeAreasSettingsPage.vue'
import CtaSizesSettingsPage from '@/components/settings/cta-sizes/CtaSizesSettingsPage.vue'

const routes = [
    { path: '/', redirect: '/projects' },
    { path: '/login', component: LoginPage, meta: { noAuthNeed: true } },
    {
        path: '/accept-invitation',
        component: AcceptInvitationPage,
        meta: { noAuthNeed: true },
    },
    {
        path: '/signup-confirm',
        component: ConfirmRegistrationPage,
        meta: { noAuthNeed: true },
    },
    { path: '/signup', component: SignupPage, meta: { noAuthNeed: true } },
    {
        path: '/remind-password',
        component: RemindPasswordPage,
        meta: { noAuthNeed: true },
    },
    {
        path: '/change-password',
        component: ChangePasswordPage,
        meta: { noAuthNeed: true },
    },
    { path: '/projects', component: ProjectsPage },
    {
        name: 'project',
        path: '/projects/:projectId',
        component: ProjectPage,
        children: [
            {
                name: 'edit-connections',
                path: 'connections',
                component: LinksPage,
            },
            { name: 'project-connection', path: 'connections/:connectionId' },
            {
                name: 'project-creation',
                path: 'connections/:connectionId/creation/:creationId',
                component: BannerPage,
            },
        ],
    },
    { path: '/templates', component: TemplatesPage },
    {
        path: '/reports',
        component: ReportsPage,
        meta: {
            requirePermissions: [
                store.getters['user/allPermissions'].REPORTS_READ,
            ],
        },
    },
    {
        path: '/users',
        component: UsersPage,
        meta: {
            requirePermissions: [
                store.getters['user/allPermissions'].USER_READ,
            ],
        },
    },
    {
        path: '/gallery',
        component: GalleryPage,
        children: [
            {
                name: 'gallery',
                path: '',
                component: GeneralUseGalleryPage,
                meta: {
                    requirePermissions: [
                        store.getters['user/allPermissions'].GALLERY_READ,
                    ],
                },
            },
            {
                name: 'gallery-campaigns',
                path: 'campaigns',
                component: CampaignsPage,
                meta: {
                    requirePermissions: [
                        store.getters['user/allPermissions'].GALLERY_READ,
                    ],
                },
            },
            {
                name: 'gallery-archived',
                path: 'archived',
                component: ArchivedGalleryPage,
                meta: {
                    requirePermissions: [
                        store.getters['user/allPermissions']
                            .GALLERY_ITEM_ARCHIVE,
                    ],
                },
            },
        ],
    },
    {
        path: '/gallery/campaigns/:campaignId',
        component: CampaignGalleryItemsPage,
        meta: {
            requirePermissions: [
                store.getters['user/allPermissions'].GALLERY_READ,
            ],
        },
    },
    {
        path: '/settings',
        component: SettingsPage,
        children: [
            {
                path: '',
                component: SafeAreasSettingsPage,
                meta: {
                    requirePermissions: [
                        store.getters['user/allPermissions'].SETTINGS_READ,
                    ],
                },
            },
            {
                name: 'safe-areas',
                path: 'safe-areas',
                component: SafeAreasSettingsPage,
                meta: {
                    requirePermissions: [
                        store.getters['user/allPermissions'].SETTINGS_READ,
                    ],
                },
            },
            {
                name: 'cta-sizes',
                path: 'cta-sizes',
                component: CtaSizesSettingsPage,
                meta: {
                    requirePermissions: [
                        store.getters['user/allPermissions'].SETTINGS_READ,
                    ],
                },
            },
        ],
    },
]

export const router = new VueRouter({
    // mode: 'history',
    routes,
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => !record.meta.noAuthNeed)) {
        const { requirePermissions } = to.meta
        if (store.getters['user/isAuth']) {
            if (requirePermissions && Array.isArray(requirePermissions)) {
                const hasRequiredRoles = requirePermissions.every(permission =>
                    store.getters['user/hasPermission'](permission)
                )
                if (!hasRequiredRoles) {
                    return next({
                        path: '/login',
                        query: {
                            backTo: to.path,
                            reason: `Insufficient access rights to ${to.path}`,
                        },
                    })
                }
            }
            // @Business: From now - we need permissions for user access - it must be provided by back-end or local-storage.
            if (!store.getters['user/userPermissions']) {
                return next({
                    path: '/login',
                    query: {
                        backTo: to.path,
                        reason: `You need to log in again - lack of permissions`,
                    },
                })
            }
            return next()
        }
        next('/login')
    } else {
        next()
    }
})

// Give router to axios instance:
registerRouter(router)
