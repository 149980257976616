<template>
  <a-modal
      v-model="visible"
      :maskClosable="false"
      @cancel="$emit('closeModal')"
  >
    <template #title>
      <div class="modal-top">
        {{modalTitle}}
      </div>
    </template>
    <gallery-items-form
        v-model="formModel"
        :edit-mode="isInEditMode"
        :can-add-tags="false"
        :add-inherit-from-campaign-option="inheritFromCampaignObject"
        @formRefReady="handleFormRef($event)"
        @submitForm="handleAddClick()"
    />
    <a-alert v-if="serverError" type="error" :message="serverError" :show-icon="true" :banner="true" />
    <template #footer>
      <a-button key="back" type="default" @click="$emit('closeModal')">
        Cancel
      </a-button>
      <a-button key="submit" type="primary" :loading="isFormSent" @click="handleAddClick()">
        {{ isInEditMode ? 'Update' : 'Add'}}
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import GalleryItemsForm from "@/components/gallery/components/form/GalleryItemsForm.vue";
import {sendGalleryItemsService} from "@/components/gallery/service/send-gallery-items.service";
import {mapActions} from "vuex";
import {galleryService} from "@/services/gallery.service";
import {hasGalleryItemExpired, ITEM_EXPIRE} from "@/components/gallery/model/gallery-item";

export default {
  name: "AddMoreGalleryItemsToCampaignModal",
  components: {GalleryItemsForm},
  props: {
    campaignId: {
      type: Number,
      required: true
    },
    expiresAt: { // refactor to ExpiresAtCampaign
      // type: [String, null],
      validator: prop => typeof prop === 'string' || prop === null,
      required: true
    },
    editModel: {
      type: Object,
      default: null
    }
  },
  data(){
    return {
      visible: true,
      isFormSent: false,
      formModel: {
        hasFiles: false,
        name: '',
        tags: [],
        expiresAt: this.expiresAt,
        expirationType: 'SET',
        files: []
      },
      serverError: ''
    }
  },
  computed: {
    modalTitle() {
      return this.isInEditMode ? 'Edit Campaign item' : 'Add more items to Campaign'
    },
    isInEditMode() {
      return Boolean(this.editModel)
    },
    inheritFromCampaignObject() {
      // should check IF gallery has not expired !!!
      const result = hasGalleryItemExpired(this.expiresAt)
      if(result === ITEM_EXPIRE.EXPIRED) {
        return null;
      }
      return {date: this.expiresAt}
    }
  },
  methods: {
    ...mapActions(['showAlertError', 'showAlertSuccess']),
    handleFormRef(formRef) {
      this.formRef = formRef
    },
    handleAddClick() {
      this.formRef.validate( async (valid) => {
        if (!valid) {
          return;
        }
        this.isFormSent = true;
        if(this.isInEditMode) {
          await this.updateExistingItem()
        } else {
          await this.postNewItem();
        }
        this.isFormSent = false;
      })
    },
    async postNewItem() {
      const { sentFilesNames, notSendFilesNames, notSendFiles }  = await sendGalleryItemsService.uploadFiles({
        ...this.formModel, tags: [], campaignId: this.campaignId
      })
      sentFilesNames && this.showAlertSuccess(`Photo: ${sentFilesNames} uploaded.`);
      notSendFilesNames && this.showAlertError(`Upload error: ${notSendFilesNames}`);
      this.formModel.files = notSendFiles;
      this.$emit('fileUploadSuccess', this.formModel.files);
      if(!notSendFiles.length) {
        this.$emit('closeModal')
      }
    },
    async updateExistingItem() {
      const {files, expiresAt, expirationType} = this.formModel;
      const [file] = files;
      this.serverError = '';
      try {
        await galleryService.patchItem(this.formModel.id, {name: file.computedName, expiresAt, expirationType});
        this.$emit('galleryItemUpdated', this.formModel);
        this.$emit('closeModal')
      } catch (e) {
        this.serverError = e.message
      }
    },
  },
  watch: {
    editModel: {
      deep: true,
      immediate: true,
      handler(newEdition) {
        if(newEdition) {
          this.formModel = newEdition;
          if(newEdition.image) {
            this.formModel.files = [{...newEdition.image}];
          }
        }
      },
    }
  }
}
</script>



<style scoped lang="less">

</style>
