<template>
  <div>
    <div v-if="isLoading" style="display: flex; align-items: center;justify-content: center; margin-top: 5em">
      <inline-preloader :text="loadingText"></inline-preloader>
    </div>
    <div v-else style="margin: 10px 0" class="scroll">
      <a-table
          class="gallery_table"
          :columns="columns"
          :data-source="galleryItems"
          :pagination="paginationObj"
          :row-key="record => record.id"
          :row-selection="rowSelection"
          :locale="{
            emptyText: queryText ? `No items found for serach criteria ' ${queryText} '` : 'No data'
          }"
          size="small"
          @change="handlePaginationChange"
      >
        <template #image="{name, url}">
          <div class="thumbnail">
            <div class="thumbnail_img" :style="{ backgroundImage: resolveGalleryImageUrl(url) } "></div>
            <div class="thumbnail_text">{{name}}</div>
          </div>
        </template>
        <template #tags="tags">
          <a-tag color="#108ee9" v-for="{name} of tags" :key="name">
            {{name}}
          </a-tag>
        </template>
        <template #expires="expires">
          <expiration-marker :expired-at="expires.at" />
        </template>
        <template #action="galleryItem">
          <div class="actions">
            <a @click.prevent="handleItemEdit(galleryItem)" v-if="canEdit && hasPermission(allPermissions.GALLERY_CREATE)"> Edit </a>
            <a @click.prevent="handleItemArchive(galleryItem)" v-if="hasPermission(allPermissions.GALLERY_ITEM_ARCHIVE)"> {{archiveActionName}} </a>
            <a-popconfirm
                v-if="hasPermission(allPermissions.GALLERY_ITEM_REMOVE)"
                title="Are you sure remove this graphic?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="handleItemRemove(galleryItem)"
            >
              <a> Remove </a>
            </a-popconfirm>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import InlinePreloader from "@/components/project/components/InlinePreloader.vue";
import {galleryService} from "@/services/gallery.service";
import ExpirationMarker from "@/components/gallery/components/ExpirationMarker.vue";
import {mapGetters} from "vuex";

export default {
  name: "GalleryItemsTable",
  components: {
    ExpirationMarker,
    InlinePreloader
  },
  props: {
    isLoading: {
      type: Boolean,
      default: true
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    archiveActionName: {
      type: String,
      default: 'Archive'
    },
    loadingText: {
      type: String,
      default: 'Loading pictures...'
    },
    pageSize: {
      type: Number,
      default: 100
    },
    currentPage: {
      type: Number,
      default: 1
    },
    totalItems: {
      type: Number,
      default: 0
    },
    galleryItems: {
      type: Array,
      default: () => []
    },
    queryText: {
      type: String,
      default: ''
    },
    showHashtagsColumn: {
      type: Boolean,
      default: true
    },
    showCampaignColumn: {
      type: Boolean,
      default: false
    },
    hasActions: {
      type: Boolean,
      default: true
    },
    isSelectable: {
      type: Boolean,
      default: false
    },
    // v-model will handle selected rows
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      rowSelection: this.isSelectable ? {
        selectedRowKeys: [],
        onChange: (selectedRowKeys) => {
          this.rowSelection.selectedRowKeys = selectedRowKeys;
          this.rowsSelected();
        },
        onSelectAll: (selected, selectedRows) => {
          this.rowSelection.selectedRowKeys = selected ? selectedRows.map(r => r.id) : [];
          this.rowsSelected();
        },
      } : undefined
    }
  },
  computed: {
    ...mapGetters('user', ['hasPermission', 'allPermissions']),
    columns() {
      const cols = [
        {
          title: 'Image',
          scopedSlots: { customRender: 'image' }
        }
      ]
      if(this.showHashtagsColumn) {
        cols.push(
            {
              title: 'Hashtags',
              dataIndex: 'tags',
              scopedSlots: { customRender: 'tags' }
            }
        )
      }
      if(this.showCampaignColumn) {
        cols.push(
            {
              title: 'Campaign',
              dataIndex: 'campaign',
              customRender: (c) => c ? c.name : '-'
            }
        )
      }
      cols.push(
          {
            title: 'Expires',
            dataIndex: 'expires',
            scopedSlots: { customRender: 'expires' }
          }
      )
      if(this.hasActions) {
        cols.push(
            {
              title: 'Action',
              scopedSlots: { customRender: 'action' }
            }
        )
      }
      return cols;
    },
    paginationObj() {
      const { pageSize, totalItems, currentPage } = this;
      if(totalItems < pageSize) {
        return false;
      }
      return { pageSize, total: totalItems, current: currentPage + 1, position: 'both' }
    }
  },
  methods: {
    handlePaginationChange({ current }) {
      this.$emit('pageChanged', current - 1)
    },
    handleItemArchive(itemId) {
      this.$emit('itemArchive', itemId)
    },
    handleItemRemove(itemId) {
      this.$emit('itemRemove', itemId)
    },
    handleItemEdit(galleryItem) {
      this.$emit('itemEdit', galleryItem)
    },
    rowsSelected() {
      this.$emit('input', this.rowSelection.selectedRowKeys)
    },
    resolveGalleryImageUrl(relativeUrl) {
      return `url("${galleryService.getGalleryItemUrl(relativeUrl)}")`;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if(Array.isArray(newValue) && this.isSelectable) {
          this.rowSelection.selectedRowKeys = newValue
        }
      }
    }
  }
}
</script>

<style lang="less">
.gallery_table {
  .ant-table {
    border: 0;

    .ant-empty-description {
      color: @gray-7;
    }
  }
}
</style>
<style scoped lang="less">
  .gallery_table {
    border: 0;
  }
  .thumbnail {
    display: flex;
    align-items: center;
    &_img {
      width: 120px;
      height: 80px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50%;
    }
    &_text {
      margin-left: 1em;
    }
  }
  .actions {
    display: flex;
    gap: .8em;
    flex-wrap: wrap;
    max-width: 160px;
  }
</style>
