<template>
  <div>
    <template v-if="hasArrayGroupValues">
      <possible-values-preview-table-cell-group-listed
        :shape-type="shapeType"
        :values="groupValues"
        :template-id="templateId"
      />
    </template>
    <template v-else>

      <a-row :gutter="[16, 16]">
        <a-col :sm="24" :md="12" :lg="8" :xl="6" v-for="[groupName, groupValue] of extractedGroups" :key="groupName">
          <div>
            {{groupName}}
            <possible-values-preview-table-cell-group-listed
                :shape-type="shapeType"
                :values="groupValue"
                :template-id="templateId"
            />
          </div>
        </a-col>
      </a-row>
    </template>
  </div>
</template>

<script>
import { possibleColorGroups } from '@/components/templates/possible-values/possible-color-groups';
import PossibleValuesPreviewTableCellGroupListed from './PossibleValuesPreviewTableCellGroupListed.vue';

export default {
  name: 'PossibleValuesPreviewTableCell',
  components: { PossibleValuesPreviewTableCellGroupListed },
  props: {
    groupValues: {
      type: [Array, Object],
      default: () => ([])
    },
    shapeType: {
      type: String,
      required: true
    },
    templateId: [String, Number],
  },
  computed: {
    hasArrayGroupValues() {
      return Array.isArray(this.groupValues)
    },
    extractedGroups() {
      if(!(this.groupValues instanceof Object)) {
        console.warn('groupValues provided in some wrong (not Object) format...')
        return []
      }
      return Object.entries(this.groupValues).sort(possibleColorGroups.sortEntriesBasedOnOrder)
    }
  },
  methods: {}
};
</script>
