<template>
  <a-modal
      v-model="visible"
      title="Duplicate project for other User"
      @ok="handleDuplicateClick()"
      @cancel="$emit('close')"
  >
    <div class="duplicate-project">
      <h3 class="duplicate-project__title">{{projectName}}</h3>
      <a-radio-group v-model="duplicationBy">
        <a-radio value="users">For users:</a-radio>
        <a-select
            class="duplicate-project__control"
            mode="multiple"
            placeholder="Chosen users"
            v-model="userEmails"
            :disabled="duplicationByGroup"
        >
          <a-select-option v-for="{email} in emailsFeed" :key="email" :value="email" >
            {{ email }}
          </a-select-option>
        </a-select>
        <a-radio value="group">For group:</a-radio>
        <a-select
            class="duplicate-project__control"
            placeholder="Chosen roles"
            v-model="userRole"
            :disabled="duplicationByUsers"
        >
          <a-select-option v-for="{name, value} in rolesFeed" :key="value" :value="value" >
            {{ name }}
          </a-select-option>
        </a-select>
      </a-radio-group>

    </div>
    <template slot="footer">
      <a-button key="back" type="default" @click="$emit('close')">
        Cancel
      </a-button>
      <a-button key="submit" type="primary" @click="handleDuplicateClick()">
        Duplicate
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import { userService } from '@/services/user.service';

export default {
  name: 'DuplicateProjectModal',
  props: {
    projectName: String
  },
  data() {
    return {
      visible: true,
      duplicationBy: 'users',
      emailsFeed: [],
      userEmails: [],
      userRole: ''
    }
  },
  computed: {
    rolesFeed() {
      return [
        {name: 'User', value: 'user'},
        {name: 'Editor', value: 'editor'},
        {name: 'Template Editor', value: 'templateEditor'},
        {name: 'Admin', value: 'admin'},
      ]
    },
    duplicationByUsers() {
      return this.duplicationBy === 'users';
    },
    duplicationByGroup() {
      return this.duplicationBy === 'group';
    },
  },
  methods: {
    handleDuplicateClick() {
      const {userEmails, userRole, duplicationBy} = this;
      const payload = {userEmails: [], userRoles: []};
      if(duplicationBy === 'users') {
        payload.userEmails = userEmails;
        if(!userEmails.length) {
          return;
        }
      } else {
        payload.userRoles = [userRole];
        if(!userRole) {
          return;
        }
      }
      this.$emit('duplicate', payload)
      this.$emit('close')
    }
  },
  async mounted() {
    try {
      this.emailsFeed = await userService.getAllEmails();
    } catch( e ) {
      console.warn(e);
    }
  }
};
</script>

<style scoped lang="less">
   .duplicate-project {
     &__title {
       margin-bottom: 1em;
     }
     &__control {
       width: 100%;
       min-width: 250px;
       margin: .5em 0 1.5em;
     }
   }
</style>
