<template>
    <a-modal
        v-model="visible"
        :maskClosable="false"
        @cancel="$emit('closeModal')"
    >
        <template #title>
          <div class="modal-top">
            {{modalTitle}}
          </div>
        </template>
        <gallery-items-form
          v-model="formModel"
          :edit-mode="isInEditMode"
          @formRefReady="handleFormRef($event)"
        />
        <a-alert v-if="serverError" type="error" :message="serverError" :show-icon="true" :banner="true" />
        <a-alert :banner="true" type="info" v-if="isUnarchiveMode" style="margin-top: 10px">
          <template #description>
            <p>When you try to Unarchive <code>expired item</code></p>
            - you need to set new <em>Expires at</em> date.
          </template>
        </a-alert>
        <template #footer>
          <a-button key="back" type="default" @click="$emit('closeModal')">
            Cancel
          </a-button>
          <a-button key="submit" type="primary" :loading="isFormSent" @click="handleAddOrEditClick()">
            {{buttonTitle}}
          </a-button>
        </template>
    </a-modal>
</template>

<script>
import {mapActions} from "vuex";
import GalleryItemsForm from "@/components/gallery/components/form/GalleryItemsForm.vue";
import {sendGalleryItemsService} from "@/components/gallery/service/send-gallery-items.service";

const EDIT_STRATEGY_UPDATE = 'UPDATE'
const EDIT_STRATEGY_UNARCHIVE = 'UPDATE_AND_UNARCHIVE'


export default {
  name: 'NewOrUpdateGalleryItemModal',
  components: {GalleryItemsForm},
  props: {
    editModel: {
      type: Object,
      default: null
    },
    editStrategy: {
      type: String,
      default: EDIT_STRATEGY_UPDATE
    }
  },
  data(){
      return {
        visible: true,
        formModel: {
          hasFiles: false,
          name: '',
          tags: [],
          expiresAt: null,
          expirationType: 'SET',
          files: [],
        },
        isFormSent: false,
        formRef: null,
        serverError: ''
      }
  },
  computed: {
    isInEditMode() {
      return Boolean(this.editModel)
    },
    modalTitle() {
      const prefix = this.isInEditMode ?  this.isUnarchiveMode ? 'Unarchive' : 'Edit' : 'Upload new'
      const pluralize =  this.formModel.files.length < 2 ? 'image' : 'images';
      return `${prefix} ${pluralize}`
    },
    buttonTitle() {
        return this.isInEditMode ? this.isUnarchiveMode ? 'Unarchive' : 'Update' : 'Add'
    },
    isUnarchiveMode() {
       return this.isInEditMode && this.editStrategy === EDIT_STRATEGY_UNARCHIVE
    }
  },
  methods: {
    ...mapActions(['showAlertError', 'showAlertSuccess']),
    handleFormRef(formRef) {
      this.formRef = formRef
    },
    handleAddOrEditClick() {
      this.formRef.validate().then(async () => {
        this.isFormSent = true;
        const {tags, expiresAt, expirationType, files} = this.formModel;
        this.serverError = '';
        if(this.isInEditMode) {
          const [file] = files;
          await this.updateGalleryItem({tags, expiresAt, expirationType, name: file.computedName});
        } else {
          await this.addNewGalleryItem({tags, expiresAt, expirationType, files});
        }
        this.isFormSent = false;
      });
    },
    async updateGalleryItem({tags, expiresAt, expirationType, name}) {
      try {
        await sendGalleryItemsService.updateItem(this.editModel.id, { name, tags, expiresAt, expirationType})
        this.$emit('galleryItemUpdated', this.formModel);
        this.$emit('closeModal')
      } catch (err) {
        this.serverError = err.message
        await this.showAlertError(err.message)
      }
    },
    async addNewGalleryItem({tags, expiresAt, expirationType, files}) {
      const { sentFilesNames, notSendFilesNames, notSendFiles, lastError }  = await sendGalleryItemsService.uploadFiles({
        tags,  expiresAt, expirationType, files
      })
      sentFilesNames && this.showAlertSuccess(`Photo: ${sentFilesNames} uploaded.`);
      notSendFilesNames && this.showAlertError(`Upload error: ${notSendFilesNames}`);
      this.formModel.files = notSendFiles;
      this.$emit('fileUploadSuccess', this.formModel.files);
      if(!notSendFiles.length) {
        this.$emit('closeModal')
      } else if(lastError) {
        this.serverError = lastError.message
      }

    }
  },
  watch: {
    editModel: {
      deep: true,
      immediate: true,
      handler(newEdition) {
        if(newEdition) {
          this.formModel = {...newEdition};
          if(newEdition.image) {
            this.formModel.files = [{...newEdition.image}];
          }
        }
      },
    }
  }

}

</script>

<style scoped lang="less">

.form__is-test {
  font-size: x-small;
}

.form__checkbox {
  font-size: x-small;
}

.external-campaign-check {
  margin: 0;
  padding: 0;
}

.modal-top {
  display: flex;
  justify-content: space-between;
  margin-right: 1.5em;
  align-items: center
}

</style>
