var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[(_vm.mirrorFields.hasCssVarsMirrors)?_c('h4',{staticStyle:{"text-align":"center"}},[_vm._v(" -- Global variables -- ")]):_vm._e(),_c('mirror-css-variable-fields',{attrs:{"mirror-fields":_vm.mirrorFields},on:{"change":function($event){return _vm.changeField($event)},"reset":function($event){return _vm.resetField($event)}}}),(_vm.mirrorFields.hasTextMirrors)?_c('h4',{staticStyle:{"text-align":"center"}},[_vm._v(" -- texts -- ")]):_vm._e(),_vm._l((_vm.mirrorFields.getTextMirrors()),function({ fieldName, templateIds }){return _c('div',{key:fieldName},[_vm._v(" Text: "),_c('em',[_vm._v(_vm._s(fieldName))]),_vm._v(" "),_c('sup',[_vm._v("("+_vm._s(templateIds.length)+")")]),_c('div',{staticClass:"text_field"},[_c('renewable-input',{attrs:{"placeholder":_vm.mirrorFields.getCombinedValue(fieldName),"show-mark-toggle":true},on:{"change":function($event){return _vm.changeField({
                        value: $event.target.value,
                        fieldName: fieldName,
                        templateIds,
                        type: 'text',
                        key: 'value',
                    })},"reset":function($event){return _vm.resetField({ fieldName: fieldName, templateIds })}},model:{value:(_vm.modelValues[fieldName]),callback:function ($$v) {_vm.$set(_vm.modelValues, fieldName, $$v)},expression:"modelValues[fieldName]"}})],1)])}),(_vm.mirrorFields.hasImageMirrors && _vm.mirrorFields.hasTextMirrors)?_c('hr',{staticStyle:{"background-color":"#663c07","height":"1px","margin":"1em 20px"}}):_vm._e(),(_vm.mirrorFields.hasImageMirrors)?_c('h4',{staticStyle:{"text-align":"center"}},[_vm._v(" -- Images / icons -- ")]):_vm._e(),_vm._l((_vm.mirrorFields.getImageMirrors()),function({ fieldName, templateIds }){return _c('div',{key:'IMG_' + fieldName},[_vm._v(" Image: "),_c('em',[_vm._v(_vm._s(fieldName))]),_c('sup',[_vm._v("("+_vm._s(templateIds.length)+")")]),_c('form-photos',{attrs:{"template-url":"","loading":_vm.mirrorImagesLoading,"file-list":_vm.imagesByField[fieldName] || [],"can-reset":true,"can-h-flip":true,"can-add-form-gallery":false,"photo-settings":_vm.getGraphicSettings({
                    fieldName,
                    templateIds,
                    type: 'image',
                }),"loading-text":"Image mirrors loading..."},on:{"uploadPhoto":function($event){return _vm.graphicUpload($event, {
                    fieldName,
                    templateIds,
                    mirrorType: 'imageMirror',
                })},"removePhoto":function($event){return _vm.graphicDelete($event, {
                    fieldName,
                    templateIds,
                    mirrorType: 'imageMirror',
                })},"switchPhoto":function($event){return _vm.graphicChange($event, {
                    fieldName,
                    templateIds,
                    type: 'image',
                })},"horizontalFlipPhoto":function($event){return _vm.horizontalFlip($event, {
                    fieldName,
                    templateIds,
                    type: 'image',
                })},"resetPhoto":function($event){return _vm.setGraphicField({
                    fieldName,
                    templateIds,
                    type: 'image',
                    key: 'url',
                    value: null,
                })},"emptyPhoto":function($event){return _vm.setGraphicField({
                    fieldName,
                    templateIds,
                    type: 'image',
                    key: 'url',
                    value: '',
                })}}})],1)}),(
            _vm.mirrorFields.hasPhotoMirrors &&
                (_vm.mirrorFields.hasImageMirrors ||
                    _vm.mirrorFields.hasTextMirrors)
        )?_c('hr',{staticStyle:{"background-color":"#663c07","height":"1px","margin":"1em 20px"}}):_vm._e(),(_vm.mirrorFields.hasPhotoMirrors)?_c('h4',{staticStyle:{"text-align":"center"}},[_vm._v(" -- Background images -- ")]):_vm._e(),_vm._l((_vm.mirrorFields.getPhotoMirrors()),function({ fieldName, templateIds }){return _c('div',{key:'PHOTO_' + fieldName},[_vm._v(" Photo: "),_c('em',[_vm._v(_vm._s(fieldName))]),_c('sup',[_vm._v("("+_vm._s(templateIds.length)+")")]),_c('form-photos',{attrs:{"template-url":"","loading":_vm.mirrorPhotoLoading,"file-list":_vm.photosByField[fieldName] || [],"can-reset":true,"can-h-flip":true,"can-add-form-gallery":false,"photo-settings":_vm.getGraphicSettings({
                    fieldName,
                    templateIds,
                    type: 'photo',
                }),"loading-text":"Photo mirrors loading..."},on:{"uploadPhoto":function($event){return _vm.graphicUpload($event, {
                    fieldName,
                    templateIds,
                    mirrorType: 'photoMirror',
                })},"removePhoto":function($event){return _vm.graphicDelete($event, {
                    fieldName,
                    templateIds,
                    mirrorType: 'photoMirror',
                })},"switchPhoto":function($event){return _vm.graphicChange($event, {
                    fieldName,
                    templateIds,
                    type: 'photo',
                })},"horizontalFlipPhoto":function($event){return _vm.horizontalFlip($event, {
                    fieldName,
                    templateIds,
                    type: 'photo',
                })},"resetPhoto":function($event){return _vm.setGraphicField({
                    fieldName,
                    templateIds,
                    type: 'photo',
                    key: 'url',
                    value: null,
                })},"emptyPhoto":function($event){return _vm.setGraphicField({
                    fieldName,
                    templateIds,
                    type: 'photo',
                    key: 'url',
                    value: '',
                })}}})],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }