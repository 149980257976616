<template>
    <a-modal
        :visible="value"
        :maskClosable="false"
        @cancel="$emit('input', false)"
        width="60vw"
    >
        <template #title>
            Add form {{ selectFromGeneralUse ? galleryTitle : 'Galleries' }}
        </template>
        <template v-if="selectFromGeneralUse">
            <select-gallery-elements
                :load-elements-fn="loadGeneralUseFn"
                :collection-mapper="galleryItemsMapper"
                v-model="selectedItems"
            />
        </template>
        <a-tabs v-else>
            <a-tab-pane
                key="1"
                tab="General Use"
                :disabled="!!numberOfSelectedCampaigns"
            >
                <select-gallery-elements
                    :load-elements-fn="loadGeneralUseFn"
                    :collection-mapper="galleryItemsMapper"
                    v-model="selectedItems"
                />
            </a-tab-pane>
            <a-tab-pane
                key="2"
                tab="Campaign"
                :disabled="!!numberOfSelectedItems"
            >
                <select-gallery-elements
                    :load-elements-fn="loadCampaignsFn"
                    :collection-mapper="campaignMapper"
                    v-model="selectedCampaigns"
                />
            </a-tab-pane>
        </a-tabs>
        <template #footer>
            <a-button key="back" type="default" @click="$emit('input', false)">
                Cancel
            </a-button>
            <a-button
                key="clear"
                type="default"
                icon="stop"
                v-if="
                    numberOfSelectedItems > 1 || numberOfSelectedCampaigns > 1
                "
                @click="handleClearSelection()"
            >
                Clear selection
            </a-button>
            <a-button
                key="submit"
                type="primary"
                v-if="numberOfSelectedItems"
                @click="$emit('addItemsFromGallery', selectedItems)"
            >
                Add selected ({{ numberOfSelectedItems }})
            </a-button>
            <a-button
                key="submit"
                type="primary"
                v-if="numberOfSelectedCampaigns"
                @click="$emit('addCampaigns', selectedCampaigns)"
            >
                Add selected campaigns ({{ numberOfSelectedCampaigns }})
            </a-button>
        </template>
    </a-modal>
</template>

<script>
import { galleryService } from '@/services/gallery.service'
import { galleryItemMapper } from '@/components/gallery/model/gallery-item'
import SelectGalleryElements from '@/components/project/components/form/photos/SelectGalleryElements.vue'

export default {
    name: 'FormFotosUploaderAddFromGalleryModal',
    components: { SelectGalleryElements },
    props: {
        value: {
            // v-model will handle visibility of the Modal! ($emit('input', false))
            type: Boolean,
        },
        selectionType: {
            type: String,
            default: 'GALLERY', // 'GALLERY' / 'GALLERY_AND_CAMPAIGN'
        },
        galleryTitle: {
            type: String,
            default: 'General Use',
        },
        campaignId: {
            type: [String, Number],
            default: 0,
        },
    },
    data() {
        return {
            selectedItems: [],
            selectedCampaigns: [],
        }
    },
    computed: {
        selectFromGeneralUse() {
            return this.selectionType === 'GALLERY'
        },
        galleryItemsMapper() {
            return galleryItemMapper
        },
        campaignMapper() {
            return c => ({
                ...c,
                url: c.cover_url,
                expires: { at: c.expires_at },
            })
        },
        loadGeneralUseFn() {
            if (this.campaignId) {
                return (query, params, order) =>
                    galleryService.getAll(
                        query,
                        { ...params, campaignId: this.campaignId },
                        order
                    )
            }
            return galleryService.getAll
        },
        loadCampaignsFn() {
            return galleryService.getAllCampaigns
        },
        numberOfSelectedItems() {
            return this.selectedItems.length
        },
        numberOfSelectedCampaigns() {
            return this.selectedCampaigns.length
        },
    },
    methods: {
        handleClearSelection() {
            this.selectedItems = []
            this.selectedCampaigns = []
        },
    },
}
</script>

<style scoped lang="less"></style>
