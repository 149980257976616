import { axInstance } from '@/services/ax-instance'
import { safeAreasCssLoader } from '@/components/settings/safe-areas/safe-areas-css-loader'

const BASE_SETTINGS_SAFE_AREAS_URI = '/settings/safe-areas'

export const settingsSafeAreasService = {
    getAll() {
        return axInstance.get(BASE_SETTINGS_SAFE_AREAS_URI).then(result => {
            // GLOBAL: reload safe-areas css, each time it loads (changes):
            safeAreasCssLoader.reloadAreas(result || [])
            return result
        })
    },
    create(safeAreaData) {
        return axInstance.post(BASE_SETTINGS_SAFE_AREAS_URI, safeAreaData)
    },
    update(areaId, safeAreaData) {
        return axInstance.patch(
            `${BASE_SETTINGS_SAFE_AREAS_URI}/${areaId}`,
            safeAreaData
        )
    },
    remove(areaId) {
        return axInstance.delete(`${BASE_SETTINGS_SAFE_AREAS_URI}/${areaId}`)
    },
}
