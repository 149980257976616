import { templateService } from '@/services/template.service'
const defaultGraphic = require('@/assets/graphics/banner.png');

export function resolveTemplateGraphicUrl(templateId, fileName, cacheControl = '') {
	const resolveUrl = fileName ? templateService.resolveTemplateThumbnailUrl(templateId, fileName, cacheControl) : defaultGraphic;
	return `url(${resolveUrl})`;
}

export function resolveTemplateGraphicSrc(templateId, fileName) {
	return fileName ? templateService.resolveTemplateThumbnailUrl(templateId, fileName) : defaultGraphic;
}
