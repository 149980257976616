<template>
    <div>
        <a-popover
            v-if="files.length"
            style="display: flex; justify-content: center; align-items: center; margin-right: 5px;"
        >
            <div style="font-size: smaller">{{ files.length }}x</div>
            <icon-expiration-for-file :file="mostExpiredFile" />
            <template #content>
                <div
                    class="popover_entry"
                    v-for="(file, idx) of files"
                    :key="file.url + '_' + idx"
                >
                    <icon-expiration-for-file
                        style="margin-right: 5px"
                        :file="file"
                    />
                    {{ file.computedName }} - [{{ formatDate(file.expiresAt) }}]
                </div>
            </template>
        </a-popover>
    </div>
</template>

<script>
import IconExpirationForFile from '@/components/gallery/components/IconExpirationForFile.vue'
import { useGalleryCache } from '@/store/modules/gallery-cache.store'
import { mapFileInfoToExpirationInfo } from '@/components/gallery/model/gallery-item'
import { mapState } from 'pinia'
import { dateFormatter } from '@/view-helpers/date-formatter'
import { CreationModel } from '@/components/project/model/CreationModel'
import { useTemplateBaseFilesCache } from '@/store/modules/template-base-files.store'

export default {
    name: 'CreationExpireImageIndicator',
    components: { IconExpirationForFile },
    props: {
        creationSettings: {
            type: Object,
            default: () => ({}),
        },
        creation: {
            type: CreationModel,
            required: true,
        },
    },
    computed: {
        ...mapState(useGalleryCache, ['getAllArchivedByUrlsFromSettings']),
        ...mapState(useTemplateBaseFilesCache, [
            'getAllArchivedBaseFilesByUrlsFromSettings',
        ]),
        files() {
            return [
                ...this.getAllArchivedByUrlsFromSettings(this.creationSettings),
                ...this.getAllArchivedBaseFilesByUrlsFromSettings(
                    this.creationSettings,
                    this.creation.template.id
                ),
            ]
        },
        mostExpiredFile() {
            let idx = -1
            let currentLevel = 0
            this.files
                .map(mapFileInfoToExpirationInfo)
                .forEach(({ severity }, index) => {
                    switch (severity) {
                        case 3:
                            if (idx === -1) {
                                idx = index
                                currentLevel = severity
                            }
                            break
                        case 2:
                            if (idx === -1 || currentLevel > 2) {
                                idx = index
                                currentLevel = severity
                            }
                            break
                        case 1:
                            idx = index
                            currentLevel = severity
                            break
                        default:
                    }
                })
            return this.files[idx] || { isArchived: false }
        },
    },
    methods: {
        formatDate(expiresAt) {
            if (expiresAt === null) {
                return 'never'
            }
            return dateFormatter(expiresAt, dateFormatter.SHORT_DATE_FORMAT)
        },
    },
}
</script>

<style scoped lang="less">
.popover_entry {
    padding: 2px;
    display: flex;
}
</style>
