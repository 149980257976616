<template>
	<a-modal
		class="add-project"
		v-model="visible"
		title="Create project"
		@cancel="$emit('closeAddProductModal')"
		:width="600"
	>
		<div class="add-project__search">
			<a-input placeholder="Project name" v-model="projectData.name" autoFocus @pressEnter="addNewProject()" />
      <p class="add-project__info">(The name must have at least 3 characters)</p>
		</div>

		<template slot="footer">
			<a-button
				key="submit"
				type="primary"
        :disabled="!isProjectNameAtLeast3chars"
				@click="addNewProject"
			>
        Create
			</a-button>
		</template>
	</a-modal>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'AddProjectModal',
	data() {
		return {
      projectData: {
        name: '',
        is_public: false
      },
			visible: true,
		}
	},
  computed: {
    isProjectNameAtLeast3chars() {
      return this.projectData.name.length >= 3;
    }
  },
	methods: {
		...mapActions(['showAlertError', 'showAlertSuccess', 'setWaiting']),
		...mapActions('projects', ['addProject']),
    async addNewProject() {
      if(!this.isProjectNameAtLeast3chars) {
        return;
      }
      const project = await this.addProject(this.projectData);
      this.$emit('projectAdded', project)
    }
	},
}
</script>

<style scoped lang="less">
  .add-project {
    &__info {
      color: rgba(255,255,255,.4);
      margin-top: 10px;
      margin-left: 12px;
      font-size: .75rem;
    }
  }
</style>
