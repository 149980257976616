<template>
  <div class="add-badge">
    <a-form :form="addBadgeForm" @submit.prevent="handleSubmit">

      <p >Name</p>
      <a-form-item>
        <a-input placeholder="Label name"
                 @input="badgeComputation"
                 v-decorator="['name', { rules: [{ required: true, message: 'Required field', }] }]"
        />
      </a-form-item>
      <p>Label value</p>
      <a-form-item>
        <a-input placeholder="Text"
                 @input="badgeComputation"
                 v-decorator="['label', { rules: [{ required: true, message: 'Required field' }] }]"
        />
      </a-form-item>

      <div class="add-badge__columns">
          <div class="add-badge__row">
            <p>Text color</p>
            <a-form-item>
              <a-input type="color"
                       @input="badgeComputation"
                       v-decorator="['color']"
              />
            </a-form-item>
          </div>
          <div class="add-badge__row">
            <p>Background color</p>
            <a-form-item>
              <a-input type="color"
                       @input="badgeComputation"
                       v-decorator="['background']"
              />
            </a-form-item>
          </div>
      </div>

      <div class="add-badge__preview-box label" :style="{backgroundColor: bgPreviewColor}">
        <div v-html="makeStandardBadge(badgeValue)" ></div>
      </div>

      <a-alert
          v-if="errorMessage"
          :message="errorMessage"
          type="error"
          show-icon
          banner
      />

      <div class="flex-spread">
        <a-button html-type="button" @click="handleBackgroundToggle()" > Switch background </a-button>
        <a-button type="primary" html-type="submit" > Add </a-button>
      </div>
    </a-form>

  </div>
</template>

<script>

import { makeStandardBadge } from '@/templating/badge-rederer'

const DEFAULT_PREVIEW_BG_COLOR = '#171717';
const INVERTED_PREVIEW_BG_COLOR = '#fff';
const INITIAL_BADGE_VALUES = {
  color: '#ffffff',
  background: '#2e89ca'
}

export default {
    name: 'CustomBadgeModalAdditionForm',
    components: {},
    props: {
      errorMessage: String
    },
    data(){
      return {
        addBadgeForm: this.$form.createForm(this, { name: 'addBadge' }),
        bgPreviewColor: DEFAULT_PREVIEW_BG_COLOR,
        badgeValue: {...INITIAL_BADGE_VALUES},
      }
    },
    mounted () {
      this.addBadgeForm.setFieldsValue(this.badgeValue);
    },
    methods: {
      makeStandardBadge: makeStandardBadge,
      handleBackgroundToggle() {
         this.bgPreviewColor = this.bgPreviewColor === DEFAULT_PREVIEW_BG_COLOR ?
             INVERTED_PREVIEW_BG_COLOR
             :
             DEFAULT_PREVIEW_BG_COLOR;
      },
      badgeComputation() {
        this.badgeValue = this.addBadgeForm.getFieldsValue();
      },
      handleSubmit() {
        this.addBadgeForm.validateFields(async (err, values) => {
          if (!err) {
            this.$emit('add-new-badge', {values, resetForm: () => {
                this.addBadgeForm.resetFields();
                this.badgeValue = {...INITIAL_BADGE_VALUES};
                this.addBadgeForm.setFieldsValue(this.badgeValue);
              }})
          }
        });
      }
    }
}
</script>

<style lang="less">
  .add-badge {
    padding: 3pt;
    margin-top: 10px;

    .flex-spread {
      text-align: right;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
    }

    &__preview-box {
      min-height: 20px;
      border-radius: 5px;
      margin: 5px 0 10px 0;
      padding: 20px;
      display: flex;
      justify-content: center;
    }

    &__columns {
      display: flex;
      justify-content: flex-start;
    }

    &__row {
      min-width: 150px;

      input[type="color"] {
        width: 70%;
      }
    }
  }
</style>
