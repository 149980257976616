<template>
    <a-modal
        v-model="visible"
        :maskClosable="false"
        @cancel="$emit('closeCtaSizeModal')"
        :width="450"
    >
        <template #title>
            <div class="modal-top">
                {{ isInEditMode ? 'Edit' : 'Add new' }} CTA Size
            </div>
        </template>
        <div style="width: 400px; ">
            <a-form-model ref="formRef" :model="formData" :rules="rules">
                CTA size:
                <a-form-model-item prop="size">
                    <a-input
                        :disabled="isInEditMode"
                        :auto-focus="true"
                        v-model="formData.size"
                        @keyup.enter="handleAddOrEditClick()"
                        style="margin-bottom: 12px"
                    />
                </a-form-model-item>
                <a-form-model-item prop="isMobile">
                    <a-checkbox v-model="formData.isMobile"
                        >is Mobile (arrow)</a-checkbox
                    >
                </a-form-model-item>
            </a-form-model>
        </div>
        <template #footer>
            <div style="display: flex">
                <a-popconfirm
                    v-if="isInEditMode"
                    :title="`Are you sure remove: ${formData.size}?`"
                    ok-text="Yes"
                    cancel-text="No"
                    style="margin-right: auto"
                    @confirm="$emit('removeCtaSize', formData)"
                >
                    <a-button type="danger" ghost>
                        <a-icon type="delete" />
                        Remove
                    </a-button>
                </a-popconfirm>
                <a-button
                    key="back"
                    type="default"
                    @click="$emit('closeCtaSizeModal')"
                >
                    Cancel
                </a-button>
                <a-button
                    key="submit"
                    type="primary"
                    @click="handleAddOrEditClick()"
                >
                    {{ isInEditMode ? 'Update' : 'Add' }}
                </a-button>
            </div>
        </template>
    </a-modal>
</template>

<script>
import { makeSizeFormValidators } from '@/components/shared/form/size-form-validators'

const CTA_SIZE_TYPES = {
    MOBILE: 'MOBILE',
    DESKTOP: 'DESKTOP',
}

function defaultToMobile(type = CTA_SIZE_TYPES.MOBILE) {
    return type === CTA_SIZE_TYPES.MOBILE
}

export default {
    name: 'NewOrUpdateCtaSizeModal',
    props: {
        ctaSize: {
            type: Object,
        },
    },
    data() {
        return {
            visible: true,
            formData: {
                size: '',
                isMobile: defaultToMobile(),
            },
            rules: {
                size: makeSizeFormValidators(),
                isMobile: [],
            },
        }
    },
    computed: {
        isInEditMode() {
            return this.ctaSize && Boolean(Object.keys(this.ctaSize).length)
        },
        width() {
            const [w = 0] = this.formData.size.split('x')
            return Number(w)
        },
        height() {
            const [, h = 0] = this.formData.size.split('x')
            return Number(h)
        },
    },
    methods: {
        async handleAddOrEditClick() {
            try {
                await this.$refs.formRef.validate()
                this.$emit(this.isInEditMode ? 'updateCtaSize' : 'newCtaSize', {
                    id: this.isInEditMode ? this.formData.id : undefined,
                    width: this.width,
                    height: this.height,
                    type: this.formData.isMobile
                        ? CTA_SIZE_TYPES.MOBILE
                        : CTA_SIZE_TYPES.DESKTOP,
                })
            } catch {
                //
            }
        },
    },
    watch: {
        ctaSize: {
            immediate: true,
            handler(data) {
                this.formData = {
                    id: data?.id || undefined,
                    size: data?.size || '',
                    isMobile: defaultToMobile(data?.type),
                }
            },
        },
    },
}
</script>

<style scoped lang="less"></style>
