import { TControl } from '@/templating/TControl'

export class TemplateBorder extends TControl {

  static $selector = '[data-border]';

  constructor (ref, container) {
    super(ref, 'border');
    this.id = this.ref.getAttribute(TemplateBorder.$selector.slice(1, -1));
    this.container = container;
    this.color = getComputedStyle(ref).borderColor;
  }

  get color() {
    return this.ref.style.borderColor;
  }
  set color(value) {
    return this.ref.style.borderColor = value;
  }
}
