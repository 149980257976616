import { fileDownloadService } from '@/services/file-download.service';
import { dateFormatter } from '@/view-helpers/date-formatter';
import { ElementQueries } from 'css-element-queries'
import { axInstance, cancelableRequest } from './ax-instance'
import { TemplateModel } from '@/components/project/model/TemplateModel'
import { Template } from '@/templating/Template'

const BASE_TEMPLATE_URI = '/templates';

function all(searchQuery, order = [], inactive = false) {
	let searchParam = {}
	if(Object.keys(searchQuery).length) {
		searchParam = searchQuery
	}
	let orderParam = {}
	if(order.length > 0) {
		const [orderBy, orderDir] = order;
		orderParam = {
			orderBy,
			orderDir
		}
	}
	let inactiveTemplates = {};
	if(inactive) {
		inactiveTemplates = { includeinactive: 1 }
	}
	return axInstance.get(BASE_TEMPLATE_URI, { params: { ...searchParam, ...orderParam, ...inactiveTemplates }})
}

function allWithInactive(searchQuery, order = []) {
	return all(searchQuery, order, true)
}

function getCategories(searchQuery = {}) {
	let searchParam = {}
	if(Object.keys(searchQuery).length) {
		searchParam = searchQuery
	}
	return axInstance.get(BASE_TEMPLATE_URI + '/categories', { params: { ...searchParam }})
}

function getKeywords() {
	return axInstance.get(BASE_TEMPLATE_URI + '/keywords')
}

function create(template) {
	return axInstance.post(BASE_TEMPLATE_URI, template)
}

function update( templateId, fields) {
	return axInstance.put(`${BASE_TEMPLATE_URI}/${templateId}`, fields)
}

function publish(templateId) {
	return axInstance.put(`${BASE_TEMPLATE_URI}/${templateId}/publish`)
}

function clone(templateId) {
	return axInstance.post(`${BASE_TEMPLATE_URI}/${templateId}/clone`)
}


function archive(templateId, markArchived) {
	return axInstance.put(`${BASE_TEMPLATE_URI}/${templateId}/archive`, { markArchived })
}

function rename( templateId, name) {
	return axInstance.patch(`${BASE_TEMPLATE_URI}/${templateId}`, { name })
}

function remove(templateId) {
	return axInstance.delete(`${BASE_TEMPLATE_URI}/${templateId}`);
}

async function downloadAsZip(templateId, {name = '', status = '', type = '', updatedAt = ''}) {
	const { data } = await axInstance.get(`${BASE_TEMPLATE_URI}/${templateId}/download`, { responseType: "arraybuffer" })
	const computedType = type?.toLowerCase() || ''
	const computedStatus = status?.toLowerCase() || ''
	const computedModifyDate = dateFormatter(updatedAt)
	return fileDownloadService.saveFile(data, [name, computedType, computedStatus, computedModifyDate].join('_'), 'zip', 'application/zip');
}

const postDraftFile = (path) => (fileData) => {
	const fileForm = new FormData();
	fileForm.append( 'file', fileData.file );
	return axInstance.post(path, fileForm, {
		headers: {
			'content-type': 'multipart/form-data'
		},
		onUploadProgress({loaded, total}) {
			const percent = Math.round((loaded * 100) / total)
			fileData.onProgress({percent});
		}
	})
}

function sendDraftFile(fileData) {
	return postDraftFile(BASE_TEMPLATE_URI + '/draft/file')(fileData);
}

function updateDraftFile(templateId, fileData) {
	return postDraftFile(`${BASE_TEMPLATE_URI}/${templateId}/draft/file`)(fileData);
}

function checkDraftFile() {
	return axInstance.get(BASE_TEMPLATE_URI + '/draft/file')
}

function checkTemplateDraftFile(templateId) {
	return axInstance.get(`${BASE_TEMPLATE_URI}/${templateId}/draft/file`)
}

function removeDraftFile(fileName) {
	return axInstance.delete(BASE_TEMPLATE_URI + '/draft/file/' + fileName);
}

function removeTemplateDraftFile(templateId, fileName) {
	return axInstance.delete(`${BASE_TEMPLATE_URI}/${templateId}/draft/file/${fileName}`)
}

const getTemplateHtmlBaseFile = (templateId, cancelToken) => axInstance.get(`${BASE_TEMPLATE_URI}/${templateId}/base-file`, {cancelToken})
const getTemplatePreviewUrl = (templateId) => `${process.env.VUE_APP_API_URL}${BASE_TEMPLATE_URI}/${templateId}/files/preview`;
const getTemplateFileUrl = (templateId) => `${process.env.VUE_APP_API_URL}${BASE_TEMPLATE_URI}/${templateId}/files`;
const getTemplateGraphicUrl = (templateId) => `${process.env.VUE_APP_API_URL}${BASE_TEMPLATE_URI}/${templateId}/graphics`;

function provideTemplateModel(templateId, mountPoint, settings = {}, connectionSettings = {}, possibleValues = {}, frozenFields = {}) {
	const getTemplate = cancelableRequest(({cancelToken}) => getTemplateHtmlBaseFile(templateId, cancelToken));
	return {
		async call () {
			const templateModel = new TemplateModel(templateId);
			templateModel.possibleValues = possibleValues || {};
			templateModel.frozenFields = frozenFields?.values || {};
			// Order matters here - you need set template to apply settings from connection
			// before making any cssVariable computation requests
			templateModel.cleanTemplateSettings = settings;
			templateModel.applySettings(connectionSettings);
			templateModel.absoluteUrl = getTemplateFileUrl(templateId);
			const template = new Template(templateModel.absoluteUrl, templateId);
			// After combining settings from connection:
			template.requestCssVariablesComputation(templateModel.settings.cssVariables);
			await template.load('', () => getTemplate.call())
			const newDiv = document.createElement('div');
			mountPoint.appendChild(newDiv);
			await template.mount(newDiv);
			await templateModel.extractTemplateHolderMeta(template);
			newDiv.remove();
			// #Fix: Assure Element queries fired as a JS task (animation frame or microtask is not enough):
			setTimeout(ElementQueries.init);
			return templateModel;
		},
		cancel() {
			getTemplate.cancel()
		}
	};
}

function resolveTemplateThumbnailUrl(templateId, fileName, cacheControl = '') {
	const baseURI = getTemplatePreviewUrl(templateId);
	return [baseURI, fileName].join('/') + (cacheControl ? `?check=${cacheControl}` : '');
}

// function resolveTemplateGraphicUrl(templateId, fileName) {
// 	const baseURI = getTemplateGraphicUrl(templateId);
// 	return [baseURI, fileName].join('/');
// }

function graphics(templateId, connectionId, type) {
	return cancelableRequest(({cancelToken}) => axInstance.get(BASE_TEMPLATE_URI + '/' + templateId + '/graphics', {
		params: { connectionId, type },
		cancelToken
	}))
}

async function removeGraphic(templateId, connectionId, graphicId) {
	return await axInstance.delete(BASE_TEMPLATE_URI + '/' + templateId + '/graphics/' + graphicId, {
		data: {
			connectionId
		}
	})
}

async function addGraphic(templateId, {file, type, id, connectionId} ) {
	const formData = new FormData();
	formData.append('type', type);
	formData.append('placeholder', id);
	formData.append('connection_id', connectionId);
	formData.append('graphic', file.originFileObj);
	return await axInstance.post(BASE_TEMPLATE_URI + '/' + templateId + '/graphics', formData, {
    headers: {
			'content-type': 'multipart/form-data'
    }
  })
}

export const templateService = {
	all,
	allWithInactive,
	rename,
	create,
	update,
	remove,
	clone,
	downloadAsZip,
	publish,
	archive,
	graphics,
	addGraphic,
	removeGraphic,
	sendDraftFile,
	updateDraftFile,
	checkDraftFile,
	checkTemplateDraftFile,
	removeDraftFile,
	removeTemplateDraftFile,
	getCategories,
	getKeywords,
	provideTemplateModel,
	getTemplateGraphicUrl,
	getTemplateFileUrl,
	resolveTemplateThumbnailUrl
}
