import { possibleColorGroups } from '@/components/templates/possible-values/possible-color-groups';
import VanillaPicker from 'vanilla-picker';
import Moveable from "moveable";

const BG_TRANSP = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='2' height='2'%3E%3Cpath d='M1,0H0V1H2V2H1' fill='lightgrey'/%3E%3C/svg%3E")`;
const MIN_PICKER_WRAPPER_WIDTH = 260;

function PredefinedColorGroupTitleComponent({text}) {
	const div = document.createElement('div');
	div.className = 'picker_predefined_title'
	div.textContent = text;
	return div;
}

function PredefinedColorComponent({color, clickHandler}) {
	const li = document.createElement('li');
	li.className = 'picker_predefined_color';
	li.setAttribute('title', color);
	li.style.backgroundColor = color;
	li.addEventListener('click', clickHandler)
	return li;
}

function PickerPredefinedList({children = []}) {
	const ul = document.createElement('ul')
	ul.className = 'picker_predefined_list'
	ul.append(...children)
	return ul
}

// NOTE: This class is a part of ColorPicker.vue logic !!!
// It is crucial for ColorPicker component work right.
export class ExtendedVanillaPicker extends VanillaPicker {

	constructor({parent, alpha}) {
		super({
			parent,
			alpha,
			cancelButton: true,
			template: makeTemplate()
		});
		this.$ref = parent;
		this.hasAlpha = alpha;
		this.displayAlpha = alpha;
	}

	show(inputBox) {
		super.show();
		if(!inputBox) {
			return;
		}
		// #Fix: position of the popup depend on the inputBox width:
		const { width } = inputBox.getBoundingClientRect();
		const leftOffset = width - MIN_PICKER_WRAPPER_WIDTH;
		if(leftOffset < 0) {
			const $pickerWrapper = this.$ref.querySelector('.picker_wrapper');
			$pickerWrapper.style.left = `${leftOffset}px`;
		}
	}

	getHexRgbaColor() {
		return this.hasAlpha ? this.color.rgbaString : this.color.hex.slice(0, -2);
	}

	setPickerColorPreview(colorValue) {
		const $colorPreview = this.$ref.querySelector('[data-color-preview]');
		if($colorPreview) {
			$colorPreview.style.backgroundColor = colorValue;
		}
	}

	toggleEditorElementsVisibility(visible = true) {
		['.picker_sl', '.picker_hue', '.picker_editor', '.picker_alpha_ex'].forEach(selector => {
			// Always hide alpha.
			const isNotAlpha = selector !== '.picker_alpha_ex';
			this.$ref.querySelector(selector).style.display = (visible && isNotAlpha) ? 'block' : 'none';
		})
		this.$ref.querySelector('.picker_title').style.display = visible? 'flex' : 'none';
		this.displayAlpha = visible;
		// Fix alpha bar Visibility:
		if(visible) {
			this.setExtendedAlpha()
		}
	}

	setPredefinedColors(values) {
		const areValuesArray = Array.isArray(values)
		const computedValues = areValuesArray ? values : possibleColorGroups.extractEntriesFromObjectWithOrdering(values || {})
		const hasPossibleValues = computedValues.length > 0;
		const $pickerPredefined = this.$ref.querySelector('.picker_predefined');
		if($pickerPredefined) {
			$pickerPredefined.style.display = hasPossibleValues ? 'block' : 'none';
		}
		if(hasPossibleValues && $pickerPredefined) {
			const $predefinedList = this.$ref.querySelector('.picker_predefined_lists');
			$predefinedList.innerHTML = '';
			const elements = []
			if(areValuesArray) {
				elements.push(
					PickerPredefinedList({
						children: computedValues.map(pV =>
							PredefinedColorComponent({color: pV, clickHandler: () => this.setColor(pV)}))
					})
				)
			} else {
				elements.push(
					...[
						...computedValues.flatMap(([groupName, values]) => [
							PredefinedColorGroupTitleComponent({text: groupName}),
							PickerPredefinedList({
								children: values.map(color =>
									PredefinedColorComponent({ color, clickHandler: () => this.setColor(color) })
								)
							})
						])
					]
				)
			}
			$predefinedList.append(...elements)
		}
		// Turn off editor controls and make it "ReadOnly" if needed

	}

	setCopyToClipboardHandler() {
		const $copyIcon = this.$ref.querySelector('.anticon-copy')
		const $pickerInput = this.$ref.querySelector('.picker_input')
		if(!$copyIcon.dataset.clickHandler) {
			$copyIcon.addEventListener('click', () => {
				navigator.clipboard.writeText($pickerInput.value);
			})
			$copyIcon.dataset.clickHandler = 'set';
		}
	}

	setExtendedAlpha() {
		if(!this.hasAlpha || !this.displayAlpha) {
			return;
		}
		const $alph = this.$ref.querySelector('.picker_alpha_ex')
		// Assure alpha shown:
		$alph.style.display = 'block';
		const $selector = this.$ref.querySelector('.picker_alpha_ex .picker_selector');
		if(!$alph.dataset.movable){
			const toHex = p => {
				const hexValue = Math.round(p * 255).toString(16);
				if(hexValue.length > 2) {
					return 'ff';
				}
				return hexValue.padStart(2, '0')
			}
			const moveable = new Moveable($selector, {
				target: $selector,
				// If the container is null, the position is fixed. (default: parentElement(document.body))
				container: $alph,
				edge: false,
				origin: false,
				draggable: true,
			});
			moveable.on('drag', ({ target, left, }) => {
				const padding = 20;
				const [{width}] = $alph.getClientRects();
				const full = width + padding;
				if(left - padding > 0 && left < full) {
					target.style.left = `${left}px`;
					const numeric = ((left - padding) / width).toFixed(2)
					this.setColor(this.color.hex.slice(0, -2) + toHex(numeric))
				}
			})
			$alph.dataset.movable = true;
		}
		const [,,,alpha] = this.color.rgba;
		const padding = 20;
		const [{width}] = $alph.getClientRects();
		this.setAlphaBar();
		$selector.style.left = `${(width * alpha)+ padding}px`
	}

	setAlphaBar() {
		if(!this.hasAlpha) {
			return;
		}
		const $alph = this.$ref.querySelector('.picker_alpha_ex')
		const colorStr = this.color.rgbaString;
		const min = colorStr.replace(/,\d*\.?\d*\)/, ',0)');
		const max = colorStr.replace(/,\d*\.?\d*\)/, ',1)');
		$alph.style.backgroundImage = `linear-gradient(90deg, ${min}, ${max}),${BG_TRANSP}`;
	}
}

function makeTemplate() {
	return `
			<div class="picker_wrapper" tabindex="-1">
          <h3 class="picker_title">
            Color Picker
            <div class="picker_cancel">
                <button class="picker_cancel_btn">X</button>
            </div>
          </h3>
          <div class="picker_arrow"></div>
          <div class="picker_sl">
              <div class="picker_selector"></div>
          </div>
          <div class="picker_panel">
            <div class="picker_hue picker_slider">
              <div class="picker_selector"></div>
            </div>
            <div class="picker_editor">
                <span class="ant-input-affix-wrapper">
                      <span class="ant-input-prefix">
                          <span data-color-preview class="color_picker__color picker__color-transparent"></span>
                      </span>
                      <input type="text" class="ant-input picker_input">
                      <span class="ant-input-suffix">
                          <i aria-label="icon: copy" class="anticon anticon-copy"><svg viewBox="64 64 896 896" data-icon="copy" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32zM704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32zM350 856.2L263.9 770H350v86.2zM664 888H414V746c0-22.1-17.9-40-40-40H232V264h432v624z"></path></svg></i>
                      </span>
                </span>
            </div>
            <div class="picker_alpha picker_slider" style="display: none">
                <div class="picker_selector"></div>
            </div>
            <div class="picker_alpha_ex">
                <div class="picker_selector"></div>
            </div>
            <div class="picker_predefined">
              <p>Pick a predefined color:</p>
              <div class="picker_predefined_lists"></div>
            </div>
          </div>
          <div class="picker_sample" style="display: none"></div>
          <div class="picker_done" style="display: none">
              <button>Ok</button>
          </div>
      </div>
`
}
