<template>
  <transition name="fadeIn" mode="in-out" appear>
    <s-mask>
      <div class="remind">
          <a-form-model class="remind__box" ref="formRef" :model="remindForm" :rules="rules" >
              <img class="remind__logo" src="../../assets/logo-white.svg" alt="Company Logo">

              <div style="margin: 10px">
                <environment-marker />
              </div>
              <p class="remind__title">Remind Password</p>
              <template v-if="!isAccepted">
                <p class="remind__label">Email</p>
                <a-form-model-item prop="email">
                  <a-input class="remind__input" placeholder="Your email" v-model="remindForm.email" />
                </a-form-model-item>
                <a-button
                    type="primary"
                    @click="handleSubmit()"
                    :loading="isLoading"
                >
                  Send reset link
                </a-button>
              </template>

              <a-alert
                  style="margin-top: 20px; max-width: 300px"
                  v-if="message"
                  :message="message"
                  :type="messageType"
                  show-icon
                  banner
              />
          </a-form-model>
          <div class="remind__bottom">
            <router-link class="remind__link" :to="loginLink">
              <a-icon type="login"/> Login page
            </router-link>
          </div>
      </div>
    </s-mask>
  </transition>

</template>

<script>
import Mask from '@/components/layout/Mask';
import EnvironmentMarker from '@/components/main/components/EnvironmentMarker'
import { userService } from '@/services/user.service'

const mustBeAnEmailRule = (rule, value, callback) => {
  if(!value) {
    return callback();
  }
  const isAnEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);
  const error = isAnEmail ? undefined : 'Invalid e-mail address'
  return callback(error)
}

const STATUS = {LOADING: 1, ACCEPTED: 2, ERROR: 3}

export default {
  name: 'RemindPasswordPage',
  components: {
    EnvironmentMarker,
    SMask: Mask
  },
  data() {
    return {
      message: '',
      messageType: 'info',
      status: 0,
      remindForm: {
        email: ''
      },
      rules: {
         email: [
             { required: true, message: 'This field is required' },
             { validator: mustBeAnEmailRule, trigger: 'blur' }
         ]
      }
    }
  },
  computed: {
    loginLink() {
       const { email } = this.remindForm;
       return email ? `/login?email=${email}` : '/login';
    },
    isLoading() {
      return this.status === STATUS.LOADING;
    },
    isAccepted() {
      return this.status === STATUS.ACCEPTED;
    }
  },
  mounted () {
    const {email} = this.$route.query;
    if(email) {
      this.remindForm.email = email;
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          const { email } = this.remindForm;
          this.status = STATUS.LOADING;
          this.message = '';
          try {
            await userService.resetPassword( email )
            this.status = STATUS.ACCEPTED;
            this.message = 'Request sent. If you got an account in our system, check your mail box. Follow instructions from the e-mail';
            this.messageType = 'info';
          } catch (err) {
            this.status = STATUS.ERROR;
            this.message = `Can't send request (${err.status})`;
            this.messageType = 'error'
          }
        }
      });
    }
  }
}
</script>

<style lang="less">
    .remind {

        &__box {
            display: flex;
            flex-direction: column;
            background-color: @gray-2;
            border: solid 1px @gray-4;
            border-radius: 6px;
            padding: 56px 24px;
        }

        &__bottom {
            margin-top: 8px;
            text-align: center;
        }

        &__title {
            font-size: 16px;
            font-weight: 600;
            margin-top: 32px;
            margin-bottom: 16px;
        }

        &__logo {
            display: flex;
            width: 240px;
            margin: auto;
        }

        &__input {
            width: 290px;
            margin-bottom: 16px;
        }

        &__link {
            text-align: center;
            margin-top: 16px;
            text-decoration: underline;

            &:hover {
                text-decoration: underline;
            }
        }

        &__label {
            margin-bottom: 4px;
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            color: @gray-9;
        }
    }
</style>
