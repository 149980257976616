import { defineStore } from 'pinia'
import jp from 'jsonpath'

function extractImageUrlsFromSettings(settings) {
    const images = jp.query(settings, '$.image..url')
    const photos = jp.query(settings, '$.photo..url')
    const masks = jp.query(settings, '$.mask..url')
    // Do not repeat same url's:
    return Array.from(new Set([...images, ...photos, ...masks])).map(url =>
        url.replace(/^\//, '')
    )
}

function mapToFileItem(file) {
    const [path, fileName] = (file.url || '').split('/')
    return {
        url: file.url,
        expiresAt: file.expires_at,
        computedName: fileName || path,
        isArchived: file.status === 'INACTIVE' || Boolean(file.archived_at),
    }
}

export const useTemplateBaseFilesCache = defineStore(
    'template-base-files-cache',
    {
        state: () => ({
            expiredItemsByTemplateIds: {},
        }),
        getters: {
            getAllArchivedBaseFilesByUrlsFromSettings: state => (
                settings = {},
                templateId = 0
            ) => {
                const allUrls = extractImageUrlsFromSettings(settings)
                return (state.expiredItemsByTemplateIds[templateId] || [])
                    .map(mapToFileItem)
                    .filter(({ url }) => allUrls.includes(url))
                    .filter(({ isArchived }) => isArchived)
            },
            hasAnyArchivedBaseFiles: state => (
                settings = {},
                templateId = 0
            ) => {
                return (
                    state.getAllArchivedBaseFilesByUrlsFromSettings(
                        settings,
                        templateId
                    ).length > 0
                )
            },
        },
        actions: {
            async loadFilesForTemplateIds(moreTemplatesFiles) {
                Object.assign(
                    this.expiredItemsByTemplateIds,
                    moreTemplatesFiles
                )
            },
        },
    }
)
