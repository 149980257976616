<template>
  <a-button-group class="edition__font-style" v-if="hasEnabledOptions">
    <a-button
        v-if="enabledOptions.includes('weight')"
        :type="Number(weight) >= 400 ? 'primary' : ''"
        @click="toggleValue('weight', weight)"
    >
      <a-icon type="bold" />
    </a-button>
    <a-button
        v-if="enabledOptions.includes('italic')"
        :type="fontStyle ? 'primary' : ''"
        @click="toggleValue('fontStyle', fontStyle)"
    >
      <a-icon type="italic" />
    </a-button>
    <a-button
        v-if="enabledOptions.includes('underline')"
        :type="textDecorationLine ? 'primary' : ''"
        @click="toggleValue('textDecorationLine', textDecorationLine)"
    >
      <a-icon type="underline" />
    </a-button>
  </a-button-group>
</template>

<script>

const POSSIBLE_VALUES = {
  weight: 400,
  textDecorationLine: 'underline',
  fontStyle: 'italic'
}

export default {
  props: {
    weight: [Number, String],
    fontStyle: String,
    textDecorationLine: String,
    enabledOptions: {
      type: Array,
      default: () => ['weight', 'italic', 'underline']
    }
  },
  name: 'TextStyleChanger',
  computed: {
    hasEnabledOptions() {
      return this.enabledOptions.length > 0
    }
  },
  methods: {
    toggleValue(type, currentValue) {
      const value = currentValue ? '' : POSSIBLE_VALUES[type];
      this.$emit('change', {type, value})
    }
  }
}
</script>
