<template>
    <a-layout-header class="header">
        <div class="header__left">
            <!-- @Business: right-click should allow to open projects card in other window  -->
            <a href="/#projects">
                <img
                    alt="Showbox logo"
                    class="header__logo"
                    @click="$emit('goToProjects')"
                    src="/logo-white.svg"
                />
            </a>
            <menu-list :links="mainMenuLinks" />
            <environment-marker />

            <div class="header__project" v-if="view === 'project'">
                <a-input
                    v-model="currentSelectedProject.name"
                    class="header__search"
                    enter-button
                    placeholder="New Project"
                />
                <a-dropdown>
                    <a-button
                        class="ant-dropdown-link header__project-btn"
                        type="default"
                        @click="e => e.preventDefault()"
                    >
                        <a-icon type="more" />
                    </a-button>
                    <template #overlay>
                        <a-menu>
                            <a-menu-item
                                @click="
                                    $emit('saveProject', currentSelectedProject)
                                "
                            >
                                <span><a-icon type="save" /> Save name</span>
                            </a-menu-item>
                            <a-menu-item
                                @click="
                                    showProjectInfoModal(currentSelectedProject)
                                "
                            >
                                <span><a-icon type="code" /> Information</span>
                            </a-menu-item>
                            <a-menu-divider />
                            <a-menu-item
                                @click="
                                    $emit(
                                        'deleteProject',
                                        currentSelectedProject
                                    )
                                "
                            >
                                <span class="danger"
                                    ><a-icon type="delete" /> Delete</span
                                >
                            </a-menu-item>
                        </a-menu>
                    </template>
                </a-dropdown>
            </div>
        </div>

        <a-input-search
            v-if="view === 'projects'"
            @input="handleSearch(view, $event)"
            class="header__search"
            enter-button
            placeholder="Search"
            v-model="searchText"
            :allowClear="true"
        />

        <a-input-search
            v-if="view === 'templates'"
            @input="handleSearch(view, $event)"
            class="header__search"
            enter-button
            placeholder="Search"
            :allowClear="true"
        />

        <a-dropdown>
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-avatar :size="32" icon="user" />
            </a>
            <a-menu slot="overlay">
                <a-menu-item @click="$emit('showUserModal')">
                    <span><a-icon type="user" /> Account</span>
                </a-menu-item>
                <a-menu-item
                    @click="$emit('showReports')"
                    v-if="hasPermission(allPermissions.REPORTS_READ)"
                >
                    <span><a-icon type="area-chart" /> Reports </span>
                </a-menu-item>
                <a-menu-item
                    @click="$emit('showUsers')"
                    v-if="hasPermission(allPermissions.USER_READ)"
                >
                    <span><a-icon type="user" /> Manage users </span>
                </a-menu-item>
                <a-menu-item @click="$emit('showTemplates')">
                    <span
                        ><a-icon type="file-add" />
                        {{
                            hasPermission(allPermissions.TEMPLATE_EDIT)
                                ? 'Manage templates'
                                : 'List of templates'
                        }}</span
                    >
                </a-menu-item>
                <a-menu-item
                    @click="$emit('showSettings')"
                    v-if="hasPermission(allPermissions.SETTINGS_READ)"
                >
                    <span><a-icon type="setting" /> Settings </span>
                </a-menu-item>
                <a-menu-divider />
                <a-menu-item @click="$emit('logout')">
                    <span><a-icon type="logout" /> Log out</span>
                </a-menu-item>
            </a-menu>
        </a-dropdown>
    </a-layout-header>
</template>

<script>
import EnvironmentMarker from '@/components/main/components/EnvironmentMarker'
import MenuList from '@/components/shared/general/MenuList.vue'
import { debounce } from '@/view-helpers/debouncer'
import { mapActions, mapGetters } from 'vuex'

export default {
    components: {
        MenuList,
        EnvironmentMarker,
    },
    data() {
        return {
            view: 'projects',
            name: '',
            searchText: '',
            timeout: 0,
        }
    },
    computed: {
        ...mapGetters('uiState', ['currentSelectedProject']),
        ...mapGetters('user', ['hasPermission', 'allPermissions']),
        mainMenuLinks() {
            const menuLinks = [{ name: 'Projects', to: '/projects' }]
            if (this.hasPermission(this.allPermissions.GALLERY_READ)) {
                menuLinks.push({ name: 'Gallery', to: '/gallery' })
            }
            menuLinks.push({ name: 'Templates', to: '/templates' })
            return menuLinks
        },
    },
    methods: {
        ...mapActions('uiState', ['showProjectInfoModal', 'setCurrentProject']),
        ...mapActions('projects', ['initProjects']),
        ...mapActions('templates', ['initTemplates']),
        handleSearch: debounce(function(timer, contextView, { target }) {
            this.timeout = timer
            const searchText = target.value
            switch (contextView) {
                case 'projects':
                    this.initProjects({ searchText }).catch(() => {})
                    break
                case 'templates':
                    this.initTemplates(searchText).catch(() => {})
                    break
                default:
                    throw new Error(
                        'Search not provided for view: ' + contextView
                    )
            }
        }),
        temporaryRouterResolver(to) {
            if (to.path === '/projects') {
                this.view = 'projects'
                this.searchText = ''
            }
            if (to.path.startsWith('/projects/') && to.params.projectId) {
                this.view = 'project'
            }
            if (to.path === '/templates') {
                this.view = 'templates'
                this.searchText = ''
            }
            if (to.path === '/reports') {
                this.view = 'reports'
            }
            if (to.path === '/users') {
                this.view = 'users'
            }
            if (to.path.startsWith('/gallery')) {
                this.view = 'gallery'
            }
            if (to.path.startsWith('/settings')) {
                this.view = 'settings'
            }
        },
    },
    watch: {
        $route(to, from) {
            this.temporaryRouterResolver(to, from)
        },
    },
    mounted() {
        this.temporaryRouterResolver(this.$route, {})
    },
    beforeDestroy() {
        if (this.timeout) {
            clearTimeout(this.timeout)
        }
    },
}
</script>

<style lang="less">
.header {
    position: fixed;
    z-index: 3;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    background-color: @gray-2;
    border-bottom: solid 1px @gray-4;

    &__left {
        display: flex;
        align-items: center;
    }

    &__logo {
        cursor: pointer;
        height: 32px;
    }

    &__search {
        width: 320px;
        background-color: @gray-1;
    }

    &__project {
        display: flex;
        align-items: center;
        margin-left: 32px;

        &-btn {
            margin-left: 8px;
            padding-left: 8px;
            padding-right: 8px;
        }

        &-save {
            margin-left: 32px;
            font-weight: 400;
            color: @gray-8;
        }
    }
}
</style>
