/*
 * Helper function to determine unique badges.
 * Taking all product badges and filter the PROJECT badges to avoid duplication.
 *
 * IF icon is present - then uniqueness is based on the icon_url.
 * IF NOT: it is based on label TEXT
 * */
export function onlyUniqueProductBadgesFilter(productBadges) {
    return badge => {
        const { icon_url, label } = badge;
        const hasIcon = Boolean(icon_url);
        if(hasIcon) {
            const sameUrl = Boolean(productBadges.find(b => b.icon_url === icon_url));
            return !sameUrl;
        }
        const mapToUpperLabel = b => b.label?.toString().toUpperCase();
        const caseInsensitiveLabel = label.toString().toUpperCase();
        const sameLabel = Boolean(
            productBadges
                .map(mapToUpperLabel)
                .find(label => label === caseInsensitiveLabel)
        );
        return !sameLabel;
    }
}
