import { TControl } from './TControl'

export class TemplateClasses extends TControl {

  static CTA = {
    MOBILE: 'settings--mobile',
    NO_CTA: 'settings--no-cta',
    // Available to EndUser setup:
    MOBILE_FORCE: 'settings--mobile-force',
    NO_CTA_FORCE: 'settings--no-cta-force',
    CTA_FORCE: 'settings--cta-force',
  }
  static CTA_TYPE = {
     INVERTED: 'settings--inverted-cta'
  }
  static PRICE_TYPES = {
    LARGE: 'settings--large',
    MINI: 'settings--mini',
    MICRO: 'settings--micro',
  }
	static $selector = '[data-classes]';
  ctaClasses = [TemplateClasses.CTA.MOBILE, TemplateClasses.CTA.NO_CTA, TemplateClasses.CTA.MOBILE_FORCE, TemplateClasses.CTA.NO_CTA_FORCE, TemplateClasses.CTA.CTA_FORCE];
  ctaTypeClasses = [TemplateClasses.CTA_TYPE.INVERTED];
  labelTypeClasses = ['settings--inverted'];
  priceTypesList = [TemplateClasses.PRICE_TYPES.LARGE, TemplateClasses.PRICE_TYPES.MINI, TemplateClasses.PRICE_TYPES.MICRO];
  priceModifiers = ['settings--price-from', 'settings--price-to'];

	constructor (ref) {
		super(ref, 'classes')
		this.id = 'classes'
	}

	updateClassList(value) {
    if (value) {
      this.ref.classList.add(value);
    }
  }

  removeClasses(listToRemove = []) {
    listToRemove.forEach(className => this.ref.classList.remove(className));
  }

  getActiveClassFromList(listToPickFrom = []) {
    let activeClass = null;
    this.ref.classList.forEach(className => {
      if (listToPickFrom.includes(className)) {
        activeClass = className;
      }
    })
    return activeClass;
  }

  get cta() {
    return this.getActiveClassFromList(this.ctaClasses);
  }

  set cta(value) {
    // Potentially buggy place (cta : {}) SHOULD be only used by back-end !!
    // TODO: check usage and compare with: project-connection.service:88 ServerSide [!]
    this.removeClasses(this.ctaClasses);
    this.updateClassList(value);
  }

  get ctaType() {
    return this.getActiveClassFromList(this.ctaTypeClasses);
  }

  set ctaType(value) {
    this.removeClasses(this.ctaTypeClasses);
    this.updateClassList(value);
  }

  get labelType() {
    return this.getActiveClassFromList(this.labelTypeClasses);
  }

  set labelType(value) {
    this.removeClasses(this.labelTypeClasses);
    this.updateClassList(value);
  }

  get priceType() {
    return this.getActiveClassFromList(this.priceTypesList);
  }

  set priceType(value) {
    this.removeClasses(this.priceTypesList);
    this.updateClassList(value);
  }

  get priceModifier() {
    return this.getActiveClassFromList(this.priceModifiers);
  }

  set priceModifier(value) {
    this.removeClasses(this.priceModifiers);
    this.updateClassList(value);
  }

}
