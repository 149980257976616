<template>
    <a-form-model class="form" ref="formRef" :model="formModel" :rules="rules">
        <a-form-model-item prop="hasFiles" v-if="canUploadPhotos">
            <p class="form__label" v-if="!editMode">
                Upload <em v-if="formModel.hasFiles">({{ numberOfFiles }})</em>
            </p>
            <gallery-uploader
                v-model="formModel.files"
                :name-prefix="formModel.name"
                :read-only="editMode"
            />
        </a-form-model-item>
        <a-form-model-item prop="name" v-if="canUploadPhotos">
            <p class="form__label">Name</p>
            <a-input
                class="form__input"
                placeholder="Optional name prefix to all uploaded images..."
                v-model="formModel.name"
                @keyup.enter="$emit('submitForm')"
            />
        </a-form-model-item>
        <a-form-model-item prop="tags" v-if="canAddTags">
            <p class="form__label">Hashtags</p>
            <a-select
                class="form__input"
                placeholder="Hashtags..."
                mode="tags"
                :token-separators="[',', ' ']"
                v-model="formModel.tags"
            >
                <a-select-option v-for="{ name } in possibleTags" :key="name">
                    {{ name }}
                </a-select-option>
            </a-select>
        </a-form-model-item>
        <a-form-model-item prop="expiresAt" v-if="canSetExpireDate">
            <p class="form__label">{{ expiresAtLabel }}</p>
            <a-select v-model="formModel.expirationType">
                <a-select-option
                    :key="value"
                    v-for="{ name, value } of EXPIRE_OPTIONS"
                    >{{ name }}</a-select-option
                >
            </a-select>
            <a-date-picker
                v-if="expirationTypeSET"
                v-model="formModel.expiresAt"
                style="width: 100%"
                :show-today="false"
                :disabled-date="onlyFutureDate"
                format="DD-MM-yyyy"
            />
        </a-form-model-item>
    </a-form-model>
</template>

<script>
import { endOfDay, isAfter } from 'date-fns'
import moment from 'moment'
import GalleryUploader from '@/components/gallery/components/form/GalleryUploader.vue'
import { galleryService } from '@/services/gallery.service'
import { dateFormatter } from '@/view-helpers/date-formatter'

export default {
    name: 'GalleryItemsForm',
    components: { GalleryUploader },
    props: {
        value: {
            type: Object,
        },
        editMode: {
            type: Boolean,
            default: false,
        },
        skipMetadataOption: {
            type: Boolean,
            default: false,
        },
        addInheritFromCampaignOption: {
            type: Object,
            default: () => null,
        },
        canAddTags: {
            type: Boolean,
            default: true,
        },
        canSetExpireDate: {
            type: Boolean,
            default: true,
        },
        canUploadPhotos: {
            type: Boolean,
            default: true,
        },
        shouldHaveAtLeastOneFile: {
            type: Boolean,
            default: true,
        },
        expiresAtLabel: {
            type: String,
            default: 'Expires at',
        },
    },
    data() {
        return {
            formModel: {
                hasFiles: false,
                name: '',
                tags: [],
                expiresAt: null,
                expirationType: 'SET',
                files: [],
            },
            possibleTags: [],
            TODAY: endOfDay(new Date()),
        }
    },
    async mounted() {
        this.$emit('formRefReady', this.$refs.formRef)
        try {
            this.possibleTags = await galleryService.getAllPossibleTags()
        } catch (e) {
            console.error(e)
        }
    },
    computed: {
        EXPIRE_OPTIONS() {
            const options = [
                { name: 'Set exact date in future', value: 'SET' },
                { name: 'Never expires', value: 'NEVER' },
            ]
            if (!this.skipMetadataOption) {
                options.push({
                    name: 'Read expire date from metadata',
                    value: 'METADATA',
                })
            }
            if (this.addInheritFromCampaignOption) {
                const { date } = this.addInheritFromCampaignOption
                const readableDate =
                    date === null
                        ? 'Never expires'
                        : `expires at: ${dateFormatter(
                              date,
                              dateFormatter.SHORT_DATE_FORMAT
                          )}`
                options.push({
                    name: `Inherit from campaign (${readableDate})`,
                    value: 'INHERIT',
                })
            }
            return options
        },
        expirationTypeSET() {
            return this.formModel.expirationType === 'SET'
        },
        rules() {
            return {
                hasFiles: [
                    {
                        validator: (rule, value, callback) => {
                            if (this.shouldHaveAtLeastOneFile) {
                                return callback(
                                    value
                                        ? undefined
                                        : 'You must upload at least one file...'
                                )
                            }
                            callback()
                        },
                        trigger: ['change', 'blur'],
                    },
                ],
                expiresAt: [
                    {
                        validator: (rule, value, callback) => {
                            if (!this.expirationTypeSET) {
                                return callback()
                            }
                            if (!value) {
                                callback('You must select a date')
                            } else if (isAfter(this.TODAY, value.toDate())) {
                                callback('Date should be form the future')
                            }
                            callback()
                        },
                    },
                ],
            }
        },
        numberOfFiles() {
            return this.formModel.files.length
        },
    },
    methods: {
        onlyFutureDate(currentDate) {
            return isAfter(this.TODAY, currentDate.toDate())
        },
    },
    watch: {
        value: {
            deep: true,
            immediate: true,
            handler(newValues, oldValues) {
                if (
                    newValues &&
                    JSON.stringify(newValues) !== JSON.stringify(oldValues)
                ) {
                    if (newValues.expiresAt) {
                        // CRUCIAL (need to normalize to moment()) [ant BUG validation if not]
                        newValues.expiresAt = moment(newValues.expiresAt)
                    }
                    this.formModel = { ...newValues }
                }
            },
        },
        'formModel.files': {
            deep: true,
            handler() {
                this.formModel.hasFiles = Boolean(this.formModel.files.length)
            },
        },
        formModel: {
            deep: true,
            immediate: true,
            handler() {
                this.$emit('input', this.formModel)
            },
        },
    },
}
</script>

<style scoped lang="less"></style>
