<template>
    <div class="edition">
        <h4 class="edition__title box__title" @click="expandAll()">
            Edition
            <a-icon
                type="right-square"
                :title="expandAllTitle"
                :style="{
                    transform: allExpanded ? 'rotate(90deg)' : '',
                    transition: 'transform 0.3s',
                    cursor: 'pointer',
                }"
            ></a-icon>
        </h4>
        <div
            class="scroll"
            style="height: calc(100vh - 128px - 36px - 36px); overflow-x: hidden;"
        >
            <div v-if="loading" class="box__empty">
                <s-preloader text="Forms initialization…" />
            </div>
            <div v-else-if="!hasAnyModels && !loading" class="box__empty">
                <p>Select a connection to start</p>
            </div>
            <template v-else>
                <template v-if="hasAnyModels">
                    <a-collapse expand-icon-position="right" v-model="expanded">
                        <a-collapse-panel
                            v-if="hasMirrorFields"
                            key="MirrorFields"
                        >
                            <template #header>
                                <div
                                    class="edition__collapse_header for--mirror"
                                >
                                    Mirror fields <a-icon type="branches" />
                                </div>
                            </template>
                            <div class="scroll" style="overflow-x: hidden;">
                                <mirror-template-fields-update-form
                                    :mirror-fields="mirrorFields"
                                    @mirrorUpdated="
                                        handleControlUpdate(
                                            $event.action,
                                            $event.templateModel,
                                            { templateId: $event.templateId }
                                        )
                                    "
                                ></mirror-template-fields-update-form>
                            </div>
                        </a-collapse-panel>
                        <a-collapse-panel
                            v-for="tempModel in templateModels"
                            :key="`T${tempModel.id}`"
                        >
                            <template #header>
                                <div class="edition__collapse_header">
                                    {{ tempModel.name }}
                                    <a-icon type="layout" />
                                </div>
                            </template>
                            <div
                                class="scroll"
                                style="overflow-x: hidden; min-height: 200px"
                            >
                                <template-form
                                    class="edition__box"
                                    :template="tempModel"
                                    @controlUpdated="
                                        handleControlUpdate($event, tempModel, {
                                            templateId: tempModel.id,
                                        })
                                    "
                                />
                            </div>
                        </a-collapse-panel>
                        <a-collapse-panel
                            v-for="tempModel in templateModelsForProducts"
                            :key="`P${tempModel.productId}`"
                        >
                            <template #header>
                                <div class="edition__collapse_header">
                                    {{
                                        extractProductData(tempModel.settings)
                                            .name
                                    }}
                                    <a-icon type="shopping-cart" />
                                </div>
                            </template>
                            <product-form
                                class="edition__box"
                                :productId="tempModel.productId"
                                :template="tempModel"
                                :controlsSettings="tempModel.settings"
                                :product-data="
                                    extractProductData(tempModel.settings)
                                "
                                :has-more-than-one-product-templates="
                                    hasMoreThanOneProductTemplates
                                "
                                @controlUpdated="
                                    handleControlUpdate($event, tempModel, {
                                        productId: tempModel.productId,
                                    })
                                "
                            />
                        </a-collapse-panel>
                    </a-collapse>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import Preloader from '@/components/project/components/InlinePreloader'
import MirrorTemplateFieldsUpdateForm from '@/components/project/components/mirror-fields/MirrorTemplateFieldsUpdateForm.vue'
import { MirrorFields } from '@/components/project/model/MirrorFields'
import { CONNECTION_ALL_TO_ALL } from '@/routes-specials'
import axios from 'axios'
import TemplateForm from './form/TemplateForm'
import ProductForm from './ProductForm'
import { connectionService } from '@/services/project-connection.service'
import { mapActions } from 'vuex'

export default {
    name: 'Edition',
    components: {
        MirrorTemplateFieldsUpdateForm,
        TemplateForm,
        ProductForm,
        SPreloader: Preloader,
    },
    props: {
        connectionId: [Number, String], // might be a number or "all-to-all"
        selected: Boolean,
        loading: Boolean,
        currentLink: Object,
        projectId: Number,
        visibility: Object,
        templateModels: {
            type: Array,
            default: () => [],
        },
        templateModelsForProducts: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            expanded: [],
            alwaysExpandAll: true, // TODO: change somehow based on expand / collapse individual panel...
            mirrorFields: new MirrorFields(this.templateModels),
        }
    },
    computed: {
        hasMirrorFields() {
            return this.mirrorFields.hasMirrorFields
        },
        hasAnyModels() {
            return Boolean(this.templateModels?.length > 0)
        },
        allExpanded() {
            return this.templateExpandKeys.every(ex =>
                this.expanded.includes(ex)
            )
        },
        templateExpandKeys() {
            return this.templateModels
                .map(m => `T${m.id}`)
                .concat(this.templateModelsForProducts.map(m => `P${m.id}`))
                .concat(['MirrorFields'])
        },
        isAllToAllConnection() {
            return this.$route.params.connectionId === CONNECTION_ALL_TO_ALL
        },
        expandAllTitle() {
            return `${this.allExpanded ? 'C' : 'Unc'}ollapse all`
        },
        // This computed property is needed by ProductForm to decide which controls should be rendered:
        hasMoreThanOneProductTemplates() {
            return (
                this.isAllToAllConnection &&
                this.templateModels.length > 1 &&
                this.templateModels.filter(t => t.type === 'product').length > 1
            )
        },
    },
    methods: {
        ...mapActions(['showAlertError']),
        ...mapActions('connectionTemplate', [
            'setNewConnectionSettings',
            'setNewConnectionSettingsByProduct',
            'updateHolder',
        ]),
        async handleControlUpdate(
            action,
            template,
            { productId, templateId } = {}
        ) {
            const { connectionId } = template
            const { projectId } = this
            try {
                let settingsByConnections
                if (this.isAllToAllConnection) {
                    settingsByConnections = await connectionService.updateAllToAllSettings(
                        { projectId, productId, templateId },
                        action
                    )
                    if (productId) {
                        this.setNewConnectionSettingsByProduct({
                            productId,
                            settingsByConnections,
                        })
                    } else {
                        this.setNewConnectionSettings({
                            templateId,
                            settingsByConnections,
                        })
                    }
                } else {
                    const updatedSettings = await connectionService.updateConnectionSettings(
                        { projectId, connectionId },
                        action
                    )
                    settingsByConnections = { [connectionId]: updatedSettings }
                    this.setNewConnectionSettings({
                        templateId: template.id,
                        settingsByConnections,
                    })
                }
                // #Fix: base templateModel, should also apply settings (to refresh the ):
                template.applySettings(settingsByConnections[connectionId])
                // #Fix: DECIDE: update templateHolder AGAIN! (this is due to merge all-to-all settings) !
                this.updateHoldersOnValueReset(
                    action,
                    template.id,
                    settingsByConnections[connectionId]
                )
            } catch (e) {
                if (!axios.isCancel(e)) {
                    this.showAlertError(`Update settings failure: ${e.message}`)
                }
            }
        },
        updateHoldersOnValueReset(action, templateId, newSettings) {
            const { type, id, key, value } = action
            // This state update is needed ONLY when value was reset (value === null) on NON a-to-a connections
            if (!this.isAllToAllConnection && value === null) {
                const newValue = newSettings?.[type]?.[id]?.[key]
                if (newValue) {
                    this.updateHolder({
                        ...action,
                        value: newValue,
                        templateId,
                    })
                }
            }
        },
        extractProductData(settings) {
            return settings?.product?.product?.productData || {}
        },
        expandAll() {
            if (this.allExpanded) {
                this.expanded = []
            } else {
                this.expanded = [...this.templateExpandKeys]
            }
        },
    },
    watch: {
        templateModels(newTemplateModels) {
            if (this.alwaysExpandAll) {
                this.expanded = [...this.templateExpandKeys]
            }
            this.mirrorFields = new MirrorFields(newTemplateModels)
        },
        templateModelsForProducts() {
            if (this.alwaysExpandAll) {
                this.expanded = [...this.templateExpandKeys]
            }
        },
    },
}
</script>

<style lang="less">
.edition {
    overflow: hidden;
    opacity: 1;
    height: calc(100vh - 64px - 16px - 24px);

    &--visible {
        opacity: 1;
        margin-right: 16px;
        width: calc(100% - 8px);
    }

    &--extended {
        opacity: 1;
        width: 100%;
    }

    &__collapse_header {
        font-size: 16px;
        color: @primary-7;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.for--mirror {
            color: @gray-7;
        }
    }

    &__row {
        display: flex;
        justify-content: space-between;
    }

    &__row-item {
        flex: 1;

        &:first-child {
            margin-right: 10px;
        }
    }

    &__header {
        font-weight: 600;
        margin-bottom: 16px;
        margin-top: 12px;
    }

    &__input {
        margin-bottom: 16px;
    }

    &__font {
        display: flex;
        justify-content: space-between;

        &-size,
        &-weight {
            flex-grow: 1;
            margin-right: 16px;
        }
        &-style {
            margin-left: 10px;
            margin-top: 24px;
        }
    }

    &__image {
    }

    &__select {
        width: 100%;
        margin-bottom: 16px;
    }

    & &__product:last-child {
        .ant-divider {
            display: none;
        }
    }

    .ant-tabs-bar {
        margin-bottom: 32px;
        border-bottom-color: @gray-2;
    }

    &__box {
        background-color: @gray-2;
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__title {
        background-color: @gray-2;
        border: 1px solid @gray-4;
        border-radius: 6px 6px 0 0;
    }
}
</style>
