<template>
  <div>
    <div class="project__content">
      <div class="column-wrapper"
      :class="{
        'edition--extended': !panelsVisibility['graphics'] && panelsVisibility['edition'],
        'edition--visible': panelsVisibility['graphics'] && panelsVisibility['edition'],
      }">
        <a-button type="button" class="action" :class="{'action--disabled': !panelsVisibility['edition'] }" @click="() => changePanelVisibility('edition')">
          <span v-if="panelsVisibility['edition']"><a-icon type="eye"/></span>
          <span v-if="!panelsVisibility['edition']"><a-icon type="eye-invisible"/></span>
        </a-button>
        <div
          class="column-content"
          :class="{
          'edition--visible': panelsVisibility['graphics'] && panelsVisibility['edition'],
        }">
          <s-edition
              v-if="connectionId"
              :loading="areTemplatesLoading"
              :connectionId="connectionId"
              :projectId="projectId"
              :selected="!!connectionId"
              :currentLink="{}"
              :templateModels="templateModels"
              :templateModelsForProducts="templateModelsForProducts"
              :visibility="panelsVisibility"
          />
        </div>
      </div>

      <div class="column-wrapper column-wrapper--right"
        :class="{
            'graphics--extended': panelsVisibility['graphics'] && !panelsVisibility['edition'],
            'graphics--visible': panelsVisibility['graphics'] && panelsVisibility['edition']
        }"
      >
        <a-button type="button" class="action" :class="{'action--disabled': !panelsVisibility['graphics'] }" @click="() => changePanelVisibility('graphics')">
          <span v-if="panelsVisibility['graphics']"><a-icon type="eye"/> </span>
          <span v-if="!panelsVisibility['graphics']"><a-icon type="eye-invisible"/> </span>
        </a-button>
        <div
            class="column-content"
          :class="{
            'graphics--visible': panelsVisibility['graphics'] && panelsVisibility['edition']
          }"
        >
          <s-graphics
              v-if="connectionId"
              :loading="areCreationsLoading"
              :selected="!!connectionId"
              :connectionId="connectionId"
              :creations="creationModels"
              :visibility="panelsVisibility"
              @exportSelectedGraphics="exportSelectedGraphics"
              @exportAllCreations="exportAllCreations"
              @exportAllLinks="exportAllLinks"
          />
        </div>
      </div>
      <s-download-modal
          :visible="showDownloadModal"
          :projectName="currentSelectedProject.name"
          :modal-title="downloadModalProps.title"
          :action-btn-text="downloadModalProps.actionText"
          @closeDownloadModal="showDownloadModal = false"
          @startDownloading="startDownload"
      />
      <section v-show="false">
        <div ref="mountPoint"></div>
      </section>
    </div>

  </div>
</template>

<script>
import { modelProvider } from '@/components/project/model.provider';
import { CONNECTION_ALL_TO_ALL } from '@/routes-specials'
import { creationsDownloadService } from '@/services/creations-download.service'
import { templateHoldersService } from '@/services/template-holders.service'
import { useSelectedCreations } from '@/store/selected-creations';
import ElementQueries from 'css-element-queries/src/ElementQueries'
import Edition from './Edition'
import Graphics from './Graphics'
import DownloadModal from './DownloadModal'
import { mapActions, mapGetters } from 'vuex'
import { mapActions as mapPActions } from 'pinia'
import { projectService } from '@/services/project.service'

export default {
  name: 'Creations',
  components: {
    SEdition: Edition,
    SGraphics: Graphics,
    SDownloadModal: DownloadModal
  },
  data() {
    return {
      extendedGraphicsPanel: false,
      showDownloadModal: false,
      downloadModalProps: {
        title: undefined,
        actionText: undefined
      },
      connectionId: '0',
      templateModels: [],
      templateModelsForProducts: [],
      creationModels: [],
      areTemplatesLoading: false,
      areCreationsLoading: false,
      reqList: [],
      exportToCim: false,
    }
  },
  computed: {
    ...mapGetters('currentProject',['templates', 'connections', 'hasAllToAll', 'allSelectedCreations']),
    ...mapGetters('connectionTemplate',['currentConnectionId', 'shouldReloadAllToAllConnection', 'shouldReloadCurrentConnection']),
    ...mapGetters('uiState', ['currentSelectedProject', 'panelsVisibility']),

    projectId() {
      const { projectId } = this.$route.params;
      return Number(projectId) || 0;
    }
  },
  methods: {
    ...mapActions(["showAlertSuccess", "showAlertError", "setWaiting"]),
    ...mapActions("currentProject", ["updateProjectFirstCreationId"]),
    ...mapActions("connectionTemplate", ["setNewConnection", "selectedCreation", "refreshCurrentConnection"]),
    ...mapActions('uiState', ["togglePanel"]),
    ...mapPActions(useSelectedCreations, ['deselectAll']),
    clearDataState() {
      for(const model of this.templateModels) {
        // clean mounted <style> css from each template  in DOM !
        model.detachTemplateStyles();
      }
      this.templateModels = [];
      this.templateModelsForProducts = [];
      this.creationModels = [];
    },
    async loadCreationsFor(connectionId) {
      this.connectionId = connectionId;
      this.clearDataState();
      this.areCreationsLoading = true;
      this.areTemplatesLoading = true;
      this.reqList = [];
      this.setWaiting(true)
      try {
        if (connectionId === CONNECTION_ALL_TO_ALL) {
          const allConnections = await projectService.getAllToAllConnections( this.projectId )
          for(const connection of allConnections){
            await this.setupViewModelsForConnection(connection);
          }
          this.firstCreationInProjectCount(allConnections);
        } else {
          const connection = await projectService.getConnection(this.projectId, connectionId);
          await this.setupViewModelsForConnection(connection);
        }
        // Reload creations element query:
        this.$nextTick(() => ElementQueries.init())
      } catch ( e ) {
        this.showAlertError(e.message)
      } finally {
        this.areTemplatesLoading = false;
        this.areCreationsLoading = false;
        this.setWaiting(false)
      }
    },
    async setupViewModelsForConnection(connection) {
      const {id, template, product_id, settings} = connection;
      if(!template) {
        return;
      }
      // Assure TemplateModels Unique:
      let [model] = this.templateModels.filter(tm => tm.id === template.id)
      if(!model) {
        const request = modelProvider.makeTemplateModel(template, {
          $mountPoint: this.$refs.mountPoint,
          productId: product_id,
          connectionId: id,
          connectionSettings: settings || {}
        })
        this.reqList.push(request);
        model = await request.call();
        this.templateModels.push(model);
        this.$refs.mountPoint.innerHTML = '';
      }

      let otherModel;
      const uniqueProductTemplate = this.templateModelsForProducts.find(tmfp => tmfp.productId === product_id);
      if(!uniqueProductTemplate && product_id) {
         // Make a copy of already existing TemplateModel:
         otherModel = modelProvider.extendTemplateModelForProduct(template.id, {
           templateModel: model,
           productId: product_id,
           connectionId: id,
           connectionSettings: settings
         })
         this.templateModelsForProducts.push(otherModel)
      }

      this.creationModels.push(...modelProvider.loadCreationModelsForConnection(connection, otherModel || model))
      // #Router fix:
      const currentCreationModel = this.creationModels.find(cM => Number(this.$route.params.creationId) === cM.id)
      if(currentCreationModel) {
        this.selectedCreation(currentCreationModel);
      }
    },
    exportSelectedGraphics({creationModels, exportToCim}){
      this.exportCreations(creationModels, exportToCim);
    },
    exportAllCreations({creationModels}){
      this.exportCreations(creationModels);
    },
    async startDownload({ alt, fileType, quality }) {
      const {creations, exportToCim, projectId} = this;
      const projectName = this.currentSelectedProject.name;
      this.setWaiting(true);
      try {
        const msg = await creationsDownloadService.start({
          alt, fileType, quality,
          creations, exportToCim, projectId, projectName,
          onSingleExported: this.showAlertSuccess
        })
        if(msg) {
          this.showAlertSuccess(msg);
        }
        this.deselectAll();
      } catch (error) {
        this.showAlertError(error.message);
      } finally {
        this.setWaiting(false);
        this.showDownloadModal = false;
        this.exportToCim = false;
      }
    },
    exportCreations(creations, exportToCim = false) {
      this.downloadModalProps = {
        title: exportToCim ? 'CIM Export Settings' : undefined,
        actionText: exportToCim ? 'Export' : undefined
      };
      this.showDownloadModal = true;
      this.creations = creations;
      this.exportToCim = exportToCim;
    },
    exportAllLinks(){
      //TODO:
      this.showAlertSuccess('All bindings exported')
    },
    changePanelVisibility(panel) {
      this.togglePanel({panel, visibility: !this.panelsVisibility[panel] });
      // this.extendedGraphicsPanel = !this.extendedGraphicsPanel;
    },
    firstCreationInProjectCount(allToAllConnections) {
      // @Business: we need info about first creation to track Thumbnail updates!
      const [connection] = allToAllConnections;
      if(connection) {
        const [creation] = connection.creations || [];
        if(creation) {
          this.updateProjectFirstCreationId( creation.id )
        }
      }
    }
  },
  beforeDestroy () {
    this.reqList.forEach(r => r.cancel())
    // clean memory:
    this.templateModels = null;
    templateHoldersService.cleanTemplateHolders();
  },
  watch: {
    // Determine when reload current connection creations and settings
    currentConnectionId(next, prev) {
      const hasChanged = next !== prev;
      const isNotZero = next !== '0';
      if(next && hasChanged && isNotZero) {
        this.loadCreationsFor(next);
      }
    },
    // Clean state when allToAll status changed:
    hasAllToAll(newAtoA, oldAtoA) {
      if(oldAtoA === true && newAtoA === false) {
        this.clearDataState();
      }
    },
    $route: {
      immediate: true,
      handler ( to ) {
        // react to route changes...
        // This execution is VERY Fragile - edit with CAUTION [!!!]
        // connectionId must be a String - (we use it as special 'all-to-all' connection)
        const { connectionId } = to.params;
        const strConnectionId = String(connectionId || '');
        const isNotZero = strConnectionId !== '0';
        const hasChanged = strConnectionId !== this.connectionId;
        if (strConnectionId && isNotZero && hasChanged) {
          this.setNewConnection( strConnectionId );
        } else if(this.shouldReloadAllToAllConnection || this.shouldReloadCurrentConnection) { // we know we should reload all-to-all now!
          this.refreshCurrentConnection();
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.column-wrapper {
  width: 65px;
  overflow: hidden;
  opacity: 1;
  position: relative;
  padding-left: 65px;

  background: #171717;
  border-radius: 10px;
  border: 1px solid #2f3030;

  &.edition--extended {
    opacity: 1;
    width: 100%;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
  }

  &.graphics--extended {
    opacity: 1;
    width: 100%;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
  }

  &.edition--visible {
    opacity: 1;
    margin-right: 16px;
    width: calc(100% - 8px);
    padding-left: 0;
    background: none;
    border: none;
    border-radius: 0;
  }

  &.graphics--visible {
    opacity: 1;
    margin-right: 16px;
    width: calc(100% - 8px);
    padding-left: 0;
    background: none;
    border: none;
    border-radius: 0;
  }

  &--right {
    margin-left: 10px;
    padding-left: 65px;

    &.graphics--visible {
      margin-right: 0;
    }
  }
}

.column-content {

  &.edition--extended {
    opacity: 1;
    width: 100%;
  }

  &.graphics--extended {
    opacity: 1;
    width: 100%;
  }
}
.action {
  position: absolute;
  left: 10px;
  top: 13px;

  &:focus {
    color: #fff;
  }
  &--disabled {
    opacity: .5;
    color: #fff;
  }
}
</style>
