<template>
    <a-modal
        v-model="visible"
        :maskClosable="false"
        :title="windowTitle"
        @cancel="$emit('closeNewUserModal')"
        class="modal"
    >
        <a-form-model class="form" ref="formRef" :model="userForm" :rules="rules">
          <div class="form__fields" v-if="!userEditionMode">
            <a-form-model-item prop="email">
              <p class="form__label">E-mail username</p>
              <a-input class="form__input" placeholder="Username" v-model="userForm.email"  />
            </a-form-model-item>
            <a-form-model-item prop="domain">
              <p class="form__label">E-mail domain</p>
              <a-select class="form__input form__input--disabled" placeholder="Domain" :title="`Domain: ${domain}`" v-model="domain" disabled>
                <a-select-option :value="domain"> {{ domain }} </a-select-option>
              </a-select>
            </a-form-model-item>
         </div>

          <p class="form__label">Name</p>
          <a-input class="form__input" placeholder="Name" v-model="userForm.name" />

          <p class="form__label">Last name</p>
          <a-input class="form__input" placeholder="Last name" v-model="userForm.lastName" />

          <p class="form__label">Role</p>
          <a-form-model-item prop="roles">
            <a-select class="form__input" placeholder="Role" v-model="userForm.roles" :mode="userEditionMode ? 'multiple' : ''">
              <a-select-option v-for="role in possibleRoles" :key="role.value" :value="role.value">
                {{ role.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
        <div class="modal__permissions">
          <a-tag v-for="p in permissionsForCurrentRole" :key="p" color="#994a00" >{{p}}</a-tag>
        </div>
        <a-alert
          type="info"
          :message="'NOTICE: When you hit that \'Register User\' button. System will send an invitation e-mail to: ' + emailWithDomain"
        />
        <a-alert
            class="modal__error-box"
            type="error"
            v-if="errorMessage"
            :show-icon="true"
            :banner="true"
        >
          <div slot="message" v-html="errorMessage" />
        </a-alert>
        <template slot="footer">
          <a-button key="back" type="default" @click="$emit('closeNewUserModal')">
            Cancel
          </a-button>
          <a-button key="submit" type="primary"
                    @click="handleRegisterOrEditUserClick()"
                    :loading="isLoading"
          >
            {{registerOrEditAction}}
          </a-button>
        </template>
    </a-modal>
</template>

<script>

import { userService } from '@/services/user.service';
import { USER_ROLES, MAPPED_USER_ROLES } from '@/store/modules/user';
import { mapActions } from 'vuex';

const capitalize = (word) => [...word].map((letter, idx) => idx === 0 ? letter.toUpperCase() : letter).join('');
const snakeToPascalSpaced = (sentence) => sentence.split('-').map((word, idx) => idx === 0 ? capitalize(word) : word).join(' ');

const emailUsernameValidator = (rule, value, callback) => {
  if(value.includes('@')) {
    return callback('Do not type "@" here.')
  }
  if(/\s/.test(value)) {
    return callback('Do not use whitespaces')
  }
  callback();
}

const onlySingleRole = (rule, value, callback) => {
  if(Array.isArray(value) && value.length > 1) {
    return callback('For now, single role choice is enough (please check Permissions)')
  }
  if(value.includes(USER_ROLES.CIM_EXPORTER)) {
    return callback('You cannot use CIM Exporter role, it is here for compatibility reasons only')
  }
  callback();
}

export default {
  name: 'NewOrUpdateUserModal',
  components: {
  },
  props: {
    permissionList: Object,
    userEditionMode: {
      type: Boolean,
      default: false,
    },
    editedUserData: {
      type: Object,
      required: false
    }
  },
  data(){
      return {
        userForm: {
          email: '',
          name: '',
          lastName: '',
          roles: [ USER_ROLES.USER ]
        },
        visible: true,
        domain: '@allegro.pl',
        isLoading: false,
        errorMessage: ''
      }
  },
  computed: {
    windowTitle() {
      return this.userEditionMode ? `Edit User: ${this.userForm.email}` : 'Add New User';
    },
    registerOrEditAction() {
      return this.userEditionMode ? 'Edit User' : 'Register User';
    },
    rules() {
      return {
        email: [
            { required: true, message: 'This field is required' },
            { validator: emailUsernameValidator, trigger: 'blur' }
        ],
        roles: [
          { required: true, message: 'This field is required' },
          { validator: onlySingleRole, trigger: 'blur' }
        ]
      }
    },
    possibleRoles() {
      const roles =  [
        {name: MAPPED_USER_ROLES[USER_ROLES.USER], value:  USER_ROLES.USER },
        {name: MAPPED_USER_ROLES[USER_ROLES.EDITOR], value:  USER_ROLES.EDITOR },
        {name: MAPPED_USER_ROLES[USER_ROLES.TEMPLATE_EDITOR], value:  USER_ROLES.TEMPLATE_EDITOR },
        {name: MAPPED_USER_ROLES[USER_ROLES.ADMIN], value:  USER_ROLES.ADMIN },
      ]
      if(this.userEditionMode) {
        roles.push({name: MAPPED_USER_ROLES[USER_ROLES.CIM_EXPORTER] + ` (obsolete role)`, value:  USER_ROLES.CIM_EXPORTER })
      }
      return roles;
    },
    permissionsForCurrentRole() {
      return (this.permissionList[this.userForm.roles] || []).map(snakeToPascalSpaced) || [];
    },
    emailWithDomain() {
      return this.userForm.email + this.domain;
    }
  },
  async mounted () {
      if(this.editedUserData) {
         const {email, name, last_name: lastName, roles} = this.editedUserData;
         const roleNames = roles.map(r => r.name);
         this.userForm = {
           email,
           name,
           lastName,
           roles: roleNames
         }
      }
  },
  methods: {
    ...mapActions(['showAlertError', 'showAlertSuccess']),
    async handleRegisterOrEditUserClick() {
      this.errorMessage = '';
      this.$refs.formRef.validate( async (valid) => {
        if (!valid) {
          return;
        }
        this.isLoading = true;
        try {
          if(this.userEditionMode) {
            await this.updateExistingUser()
          } else {
            await this.createNewUser();
          }
        } catch (e) {
          console.dir(e);
          if(e.status === 422) {
            return this.errorMessage = Object.values(e?.errors || {})
                .map(v =>`${v} <br/>`).join('')
          }
          this.errorMessage = e.message;
        } finally {
          this.isLoading = false;
        }
      });

    },
    async createNewUser(){
      const { name, lastName, roles } = this.userForm;
      await userService.createNew({ email: this.emailWithDomain, name, lastName, roles })
      this.showAlertSuccess('User successfully created');
      this.$emit('userCreationSuccess');
    },
    async updateExistingUser() {
      const { email, name, lastName, roles } = this.userForm;
      await userService.update({ email, name, lastName, roles })
      this.showAlertSuccess(`User ${email} successfully updated`);
      this.$emit('userEditionSuccess');
    }
  }
}
</script>

<style scoped lang="less">
  .modal {
    &__permissions {
      padding: 1em;
      display: flex;
      gap: .5em;
      width: 100%;
      flex-wrap: wrap;
      justify-content: flex-start;
      & > .ant-tag {
        min-width: calc(100% / 3 - (3 * .5em));
      }
    }
    &__error-box {
      margin-top: 1em;
    }
  }
  .form {
    &__fields {
      display: flex;
      justify-content: stretch;
      gap: .3em;
      & > div {
        width: 100%;
      }
    }
    &__input--disabled {
      opacity: 0.7;
    }
  }
</style>
