export const fileDownloadService = {
	saveFile(fileContent, filename, ext, mimeType) {
		const blob = new Blob([fileContent], { type: mimeType })
		const a = document.createElement('a')
		a.href = URL.createObjectURL(blob)
		a.download = `${filename}.${ext}`
		document.body.appendChild(a)
		a.click()
		document.body.removeChild(a)
		URL.revokeObjectURL(a.href)
	}
}
