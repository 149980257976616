<template>
  <section>
    <p style="min-width: 100px; margin-bottom: .5em">{{title}}</p>
    <a-checkbox
        style="height: auto; margin-left: 8px"
        v-for="chLabel of checkboxLabels"
        :key="chLabel"
        v-model="frozenValues[chLabel]"
        @change="handleValueChange"
    >
      {{chLabel}}
    </a-checkbox>
  </section>
</template>

<script>

export default {
  name: 'FrozenFieldsDivisor',
  props: {
    baseFrozenValues: {
      type: Object,
      default: () => ({})
    },
    frozenShape: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      frozenValues: {}
    }
  },
  computed: {
    title() {
       return `Frozen field${this.checkboxLabels.length > 1 ? 's' : ''}:`
    },
    checkboxLabels() {
      return Object.keys(this.frozenShape || {})
    }
  },
  methods: {
     handleValueChange() {
       this.$emit('update', this.frozenValues )
     }
  },
  watch: {
    baseFrozenValues: {
      immediate: true,
      handler(newVal) {
        this.frozenValues = newVal
      }
    }
  }
};
</script>
