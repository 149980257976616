import { TControl } from './TControl'

export class TemplateCssVars extends TControl {

	constructor (styleRef, templateSelector, variableBinder) {
		super(styleRef, 'cssVars')
		this.id = 'cssVars'
		this.variableBinder = variableBinder;
		const { sheet } = styleRef || {};
		if(variableBinder) {
			this.computeVariables(sheet, templateSelector)
		}
	}

	computeVariables(sheet, templateSelector) {
		const { variableMappings, variableRule } = this.variableBinder.makeBindings(sheet, templateSelector);
		if(!variableRule || variableMappings.length === 0) {
			return;
		}
		this.varRule = variableRule;
		variableMappings.forEach(({name, value, triggers}) => {
			Object.defineProperty(this, name, {
				get() {
					return {
						value: this.varRule.style.getPropertyValue(name).trim(),
						triggers
					}
				},
				set(value) {
					this.varRule.style.setProperty(name, value)
				}
			});
			this[name] = value || this[name].value;
		})
	}

}
