const isAMainRuleWithVariables = templateSelector => rule => (rule.selectorText || '').trim() === `.${templateSelector}`

export class VariableBinder {

	static triggerSeparator = '::'

	constructor(templateControls, computedVariables) {
		this.controls = templateControls
		this.computedVariables = computedVariables;
	}

	// @Business: same interface .makeBindings(a, b) shared with back-end !
	makeBindings( sheet, templateSelector) {
		const variableMappings = [];
		if(!(sheet instanceof CSSStyleSheet)) {
			return {variableMappings};
		}
		const {cssRules} = sheet;
		const allRules = Array.from(cssRules);
		const varRule = allRules.find(isAMainRuleWithVariables(templateSelector))
		if(!varRule) { // No main variable "holder" present...
			return {variableMappings}
		}
		return this.computedVariables ? {
			variableMappings: Object.entries(this.computedVariables).map(([name, {value, triggers}]) => ({name, value, triggers})),
			variableRule: varRule
		}
		:
		{ variableMappings }
	}
}
