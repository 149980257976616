<template>
    <div class="products ">
        <div class="title">
            <h3>Products</h3>
            <a-button type="dashed">
                <a-dropdown>
                    <a class="ant-dropdown-link" @click.prevent="">
                      Add <a-icon type="down" />
                    </a>
                    <a-menu slot="overlay">
                        <a-menu-item @click="addProductModal=true">
                            <span>Add from base</span>
                        </a-menu-item>
                        <a-menu-item @click="emptyProductModal=true">
                            <span>Add blank</span>
                        </a-menu-item>
                        <a-menu-item @click="addCustomBadgeModal=true">
                          <span>Add new badge</span>
                        </a-menu-item>
                    </a-menu>
                </a-dropdown>
            </a-button>
        </div>
        <div class="links__search">
            <a-input-search enter-button placeholder="Search" v-model="query"/>
        </div>
        <s-empty v-if="query && !filteredProducts.length"/>
        <ul v-else class="links__items scroll" style="height: calc(100vh - 348px)">
            <product-list-item
                v-for="product in filteredProducts"
                :product="product"
                class="links__item"
                :class="{'is-selected': product.id === selectionId}"
                :key="product.id"
                :canSelect="canSelectProducts"
                @renameProduct="$emit('renameProduct', {product, event: $event})"
                @selectProduct="$emit('selectProduct', product)"
                @deleteProduct="$emit('deleteProduct', product.id)"
                @updateProduct="$emit('updateProduct', product.id)"
            />
        </ul>

        <add-product-modal
            v-if="addProductModal"
            @addProducts = "addProducts"
            @closeAddProductModal = "addProductModal = false"
        />

        <custom-badge-modal
            v-if="addCustomBadgeModal"
            @closeAddCustomBadgeModal="addCustomBadgeModal=false"
        />

        <a-modal
            v-model="emptyProductModal"
            @ok="addEmptyProducts"
            @cancel="emptyProductModal=false"
        >
            <p class="infoModal__desc">Select number of empty products you want to add</p>
            <a-input class="infoModal__input" v-model="emptyProductsNumber" placeholder="Number of empty products" />

            <template slot="footer">
                <a-button key="back" type="default" @click="emptyProductModal=false">
                  Cancel
                </a-button>
                <a-button key="submit" type="primary" @click="addEmptyProducts">
                  Add
                </a-button>
            </template>
        </a-modal>
    </div>
</template>

<script>
import Empty from "../../layout/Empty";
import ProductListItem from "./ProductListItem";
import AddProductModal from "./AddProductModal";
import CustomBadgeModal from '@/components/links/components/CustomBadgeModal'

export default {
    name: 'ProductList',
    props: {
        selectionId: Number,
        canSelectProducts: Boolean,
        products: Array
    },
    components: {
        ProductListItem,
        AddProductModal,
        CustomBadgeModal,
        SEmpty: Empty
    },
    data(){
        return {
            query: "",
            emptyProductsNumber: 3,
            emptyProductModal: false,
            addCustomBadgeModal: false,
            addProductModal: false
        }
    },
    computed: {
        filteredProducts(){
            return this.query
                ?
                this.products.filter(p=>p.name.toLowerCase().indexOf(this.query.toLowerCase()) !== -1 )
                :
                this.products;
        }
    },
    methods: {
        addEmptyProducts(){
            this.emptyProductModal = false;
            this.$emit('addEmptyProducts', this.emptyProductsNumber)
        },
        addProducts(products){
            this.addProductModal = false;
            this.$emit('addProducts', products);
        }
    }

}
</script>

<style lang="less">
    .products {
        width: 25%;
        border-right: solid 1px @gray-4;

        &__image {
            width: 32px;
            height: 32px;
            border-radius: 2px;
            margin-right: 8px;
        }

        &__info {
            display: flex;
            align-items: center;
            overflow: hidden;
        }

    }
</style>
