import { TControl } from '@/templating/TControl'
import { TemplateClasses } from '@/templating/TemplateClasses';
import { sanitizeUrl } from '@/templating/templating-helpers'

const CHECK_PRICE_ALIGN_SAFE_TIME_MS = 100; // [!] It must be less than: Template.MOUNTING_SAFE_TIME_MS

const dataProductAttr = (name) => `[data-product-${name}]`

export class TemplateProduct extends TControl {

	static DATA_FIELDS = {
			NAME: 'name',
			PRICE_MAIN: 'price-main',
			PRICE_REST: 'price-rest',
			ID: 'id', // not used ?
			PRICE: 'price', // not used ?
			DISCOUNT: 'discount',  // not used ?
	}
	static IMG_FIELDS = {
		IMG: 'img',
		PARTNER_IMG: 'partner-img'
	}
	static $selector = '[data-product]';
	static priceToSelector = '.price__to'
	static priceFromSelector = '.price__from'

	constructor (ref, baseUrl= '', templateClasses) {
		super(ref, 'product')
		const { NAME, PRICE_MAIN, PRICE_REST } = TemplateProduct.DATA_FIELDS;
		const { IMG, PARTNER_IMG } = TemplateProduct.IMG_FIELDS;
		this.id = 'product'
		this.placeholderKeys = [ NAME, PRICE_MAIN, PRICE_REST];
		this.placeholders = {};
		this.hasData = {}
		this.baseUrl = baseUrl;
		this.imgPlaceholder = this.$(IMG);
		this.partnerImgPlaceholder = this.$(PARTNER_IMG);
		this.url = this.imgPlaceholder ? this.imgPlaceholder.getAttribute('src') : '';
		this.partnerUrl = this.partnerImgPlaceholder ? this.partnerImgPlaceholder.getAttribute('src') : '';
		this.templateClasses = templateClasses;
		this.$priceTo = this.ref.querySelector(TemplateProduct.priceToSelector)
		this.$priceFrom = this.ref.querySelector(TemplateProduct.priceFromSelector)
		this.setup();

		setTimeout(() => {
			// @Business: Price should be in one line - IF it is not set by priceType
			this.autoPriceAlign = !templateClasses.priceType;
			this.shrinkPriceToAvoidWrap();
		}, CHECK_PRICE_ALIGN_SAFE_TIME_MS)
	}

	get url() {
		if(this.imgPlaceholder) {
			return this.imgPlaceholder.getAttribute('src') || '';
		}
		return '';
	}

	set url(value) {
		if(this.imgPlaceholder) {
			this.imgPlaceholder.setAttribute('src', sanitizeUrl(value, this.baseUrl));
		}
	}

	get partnerUrl() {
		if(this.partnerImgPlaceholder) {
			return this.partnerImgPlaceholder.getAttribute('src') || '';
		}
		return '';
	}

	set partnerUrl(value) {
		if(this.partnerImgPlaceholder) {
			this.partnerImgPlaceholder.setAttribute('src', sanitizeUrl(value, this.baseUrl));
		}
	}

	set productData({id, name, 'price-main': price_main, 'price-rest': price_rest, discount, price}) {
		const { NAME, PRICE_MAIN, PRICE_REST } = TemplateProduct.DATA_FIELDS;
		const dataMapping = {
			id,
			name,
			price,
			discount,
			[PRICE_MAIN]: price_main,
			[PRICE_REST]: price_rest
		}
		// We need price size before update...
		const priceMainBefore = this.placeholders[PRICE_MAIN]?.textContent || '';
		const priceRestBefore = this.placeholders[PRICE_REST]?.textContent || '';
		const priceLengthBefore = priceMainBefore.length + priceRestBefore.length;
		Object.entries(dataMapping).forEach(([key, value]) => {
			if(this.placeholders[key]) {
				if (key === NAME) {
					this.placeholders[key].innerHTML = value;
				} else {
					this.placeholders[key].textContent = value;
				}
			}
		})
		// @Business: Price should be in one line when value is changing - decide how to act.
		const priceLengthAfter = price_main?.length + price_rest?.length;
		if(priceLengthBefore > priceLengthAfter) {
			this.enlargePriceButAvoidWrap();
		} else {
			this.shrinkPriceToAvoidWrap();
		}
	}

	get productData() {
		const data = {};
		Object.entries(this.placeholders).forEach(([key, value]) => {
			data[key] = value.textContent
		})
		return data
	}

	$(productAttrName) {
		const ref = this.ref.querySelector(dataProductAttr(productAttrName));
		this.hasData[productAttrName] = Boolean(ref);
		return ref;
	}

	setup() {
		this.placeholderKeys.forEach(key => {
			const placeholder = this.$(key);
			if(placeholder) {
				this.placeholders[key] = placeholder;
			} else {
				console.warn(`TEMPLATE PRODUCT: product placeholder ${key} - not found!`)
			}
		})
		// Check Price from / to:
		this.checkPriceFromTo()
	}

	checkPriceFromTo() {
		const { priceFromSelector, priceToSelector } = TemplateProduct;
		this.hasData[priceToSelector] = Boolean(this.$priceTo);
		this.hasData[priceFromSelector] = Boolean(this.$priceFrom);
	}

	isDisplayedInOneLine() {
		const priceMain = this.$(TemplateProduct.DATA_FIELDS.PRICE_MAIN);
		const priceRest = this.$(TemplateProduct.DATA_FIELDS.PRICE_REST);
		const priceTo = this.$priceTo;
		if(!priceMain || !priceRest) {
			console.warn('Cannot determine isDisplayedInOneLine, priceMain or priceRest refs missing')
			return;
		}
		const isPriceToVisible = priceTo ? !(getComputedStyle(priceTo)?.display === 'none') : false;
		const compareStartBox = isPriceToVisible ? priceTo : priceMain;
		const compareEndBox = isPriceToVisible ? priceMain : priceRest;
		const sub = compareEndBox?.getBoundingClientRect()?.y - compareStartBox?.getBoundingClientRect()?.y;
		return sub < 10;
	}

	shrinkPriceToAvoidWrap() {
		const { templateClasses } = this;
		if(!templateClasses || !this.autoPriceAlign) {
			return;
		}
		if(!this.isDisplayedInOneLine()) {
			const {MICRO, MINI, LARGE} = TemplateClasses.PRICE_TYPES
			if(templateClasses.priceType !== MICRO) {
				if(templateClasses.priceType === LARGE || !templateClasses.priceType) {
					templateClasses.priceType = MINI
				}
				if(templateClasses.priceType === MINI) {
					templateClasses.priceType = MICRO
				}
				this.shrinkPriceToAvoidWrap();
			}
		}
	}

	enlargePriceButAvoidWrap() {
		const { templateClasses } = this;
		if(!templateClasses || !this.autoPriceAlign) {
			return;
		}
		if(this.isDisplayedInOneLine()) {
			const {MICRO, MINI, LARGE} = TemplateClasses.PRICE_TYPES
			if(templateClasses.priceType !== LARGE && templateClasses.priceType) {
				if(templateClasses.priceType === MINI) {
					templateClasses.priceType = LARGE
				}
				if(templateClasses.priceType === MICRO) {
					templateClasses.priceType = MINI
				}
				this.enlargePriceButAvoidWrap();
			}
		}
	}
}
