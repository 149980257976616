<template>
    <div class="links">
        <div class="title">
            <p>Edit bindings</p>
            <a-icon
                type="close"
                :style="{ fontSize: '20px', cursor: 'pointer', padding: '8px' }"
                @click="handleClose()"
            />
        </div>
        <div class="links__main scroll">
            <template-list
                :selectionId="selection.template.id"
                :templates="templates"
                :canSelectGeneric="!isProductSelected"
                @selectTemplate="selectTemplate"
                @deleteTemplate="confirmAndDeleteTemplate"
                @addTemplates="addTemplates"
            />
            <product-list
                :selectionId="selection.product.id"
                :products="products"
                :canSelectProducts="!isTemplateGeneric"
                @selectProduct="selectProduct"
                @deleteProduct="confirmAndDeleteProduct"
                @updateProduct="updateProduct"
                @renameProduct="handleRenameProduct"
                @addProducts="addProducts"
                @addEmptyProducts="addEmpty"
            />
            <connection-list
                :connections="connections"
                :showAllToAll="hasAllToAll"
                @editLink="editLink"
                @renameLink="renameLink"
                @resetSettings="resetSettings"
                @moveSettings="moveSettingsToAllToAll"
                @deleteLink="deleteLink"
                @exportLinks="exportLinks"
            />
            <div class="links__info links__info--2" v-if="infoText">
                <p>{{ infoText }}</p>
            </div>
            <div class="links__info" v-if="linkReady || isTemplateGeneric">
                <p>
                    <span class="links__info--name">
                        {{ selectedTemplate.name }}
                    </span>
                    <template v-if="!isTemplateGeneric">
                        z
                        <span class="links__info--name">
                            {{ selectedProduct.name }}
                        </span>
                    </template>
                </p>
                <template v-if="isProductSelected">
                    <locale-picker
                        style="margin-left: auto"
                        v-model="productMeta.lang"
                        display-type="flag-only"
                        :show-label="false"
                    />
                    <a-select
                        v-model="productMeta.currency"
                        style="margin-right: 10px"
                    >
                        <a-select-option key="PLN">PLN</a-select-option>
                        <a-select-option key="EUR">EUR</a-select-option>
                        <a-select-option key="CZK">CZK</a-select-option>
                    </a-select>
                </template>
                <template v-if="isTemplateGeneric">
                    <locale-picker
                        style="margin-left: auto"
                        v-model="genericLang"
                        display-type="flag-only"
                        value-range="lang-only"
                        :show-label="false"
                    />
                </template>
                <a-button @click="handleNewConnection()" type="primary">
                    <a-icon type="build" />
                    Bind
                    <template v-if="isTemplateGeneric"> generic</template>
                </a-button>
            </div>
        </div>
        <div class="links__bottom">
            <a-button type="primary" @click="saveAndClose">Close</a-button>
        </div>
    </div>
</template>

<script>
import LocalePicker from '@/components/shared/locale-picker/LocalePicker'
import { LANG_LOCALES } from '@/components/shared/supported-languages'
import { CONNECTION_ALL_TO_ALL } from '@/routes-specials'
import { connectionService } from '@/services/project-connection.service'
import { mapActions, mapGetters } from 'vuex'
import { Modal } from 'ant-design-vue'
import TemplateList from './components/TemplateList'
import ProductList from './components/ProductList'
import ConnectionList from './components/ConnectionList'

function confirmRemovalModal(title, content, onOk) {
    return confirmModal(title, content, onOk, { okText: 'Delete' })
}

function confirmModal(
    title,
    content,
    onOk,
    { okText = 'Confirm', cancelText = 'Cancel' }
) {
    return Modal.confirm({
        title,
        content,
        okText,
        onOk,
        cancelText,
        icon: 'exclamation-circle',
    })
}

export default {
    name: 'LinksPage',
    components: {
        TemplateList,
        ProductList,
        ConnectionList,
        LocalePicker,
    },
    data() {
        return {
            routerReplacePath: '',
            overlaidConnectionId: '',
            connectionIdAllToAllReset: '',
            selection: {
                template: {},
                product: {},
            },
            productMeta: {
                currency: 'PLN',
                lang: LANG_LOCALES.pl,
            },
            genericLang: 'pl',
        }
    },
    beforeRouteEnter(to, from, next) {
        if (from.name === 'project-connection') {
            const { connectionId } = from.params
            if (connectionId) {
                return next((vm) => {
                    vm.routerReplacePath = from.path
                    vm.overlaidConnectionId = connectionId
                })
            }
        }
        next()
    },
    watch: {
        // Clean routing forwards when allToAll status changed:
        hasAllToAll(newAtoA, oldAtoA) {
            // @Jabi: it has to be so "explicit" for safety reasons:
            const getAtoANow = oldAtoA === false && newAtoA === true
            const hasAtoAThenNot = oldAtoA === true && newAtoA === false
            if (getAtoANow) {
                this.connectionIdAllToAllReset = CONNECTION_ALL_TO_ALL
            } else if (hasAtoAThenNot) {
                this.routerReplacePath = ''
            }
        },
    },
    computed: {
        ...mapGetters('currentProject', [
            'projectId',
            'connections',
            'products',
            'templates',
            'hasAllToAll',
        ]),
        ...mapGetters('connectionTemplate', ['currentConnectionId']),
        currentProject() {
            return {
                templates: this.templates,
                products: this.products,
                connections: this.connections,
            }
        },
        infoText() {
            const { isProductSelected, isTemplateSelected } = this
            if (!this.templates.length && !this.products.length) {
                return 'Add product and templates to create a connection'
            } else if (!this.templates.length) {
                return 'Add template to create bindings'
            } else if (!this.products.length) {
                return 'Add products to create bindings'
            } else if (!isTemplateSelected && !isProductSelected) {
                return 'Select template / product to create binging'
            } else if (!isTemplateSelected) {
                return 'Select template to create binding with the selected product'
            } else if (!isProductSelected) {
                return 'Select product to create binding with the selected template'
            } else {
                return ''
            }
        },
        linkReady() {
            if (this.isTemplateGeneric) {
                //...
            }
            return this.selectedTemplate.id && this.selectedProduct.id
        },
        selectedTemplate() {
            return this.selection.template || {}
        },
        selectedProduct() {
            return this.selection.product || {}
        },
        isProductSelected() {
            return Boolean(this.selectedProduct.id)
        },
        isTemplateSelected() {
            return Boolean(this.selectedTemplate.id)
        },
        isTemplateGeneric() {
            return this.selectedTemplate.type === 'generic'
        },
    },
    methods: {
        ...mapActions(['showAlertError', 'showAlertSuccess']),
        ...mapActions('currentProject', [
            'addTemplates',
            'deleteTemplate',
            'addProducts',
            'addEmptyProducts',
            'renameProduct',
            'deleteProduct',
            'makeConnection',
            'renameConnection',
            'deleteConnection',
            'updateThumbnailCreationId',
            'updateProductForAllConnections',
        ]),
        ...mapActions('connectionTemplate', [
            'setNewConnectionSettings',
            'refreshCurrentConnection',
            'emptyConnections',
            'refreshWhenAllToAll',
        ]),
        selectTemplate(template) {
            this.selection.template =
                this.selection.template === template ? {} : template
        },
        selectProduct(product) {
            this.selection.product =
                this.selection.product === product ? {} : product
        },
        async handleRenameProduct({ product, event }) {
            await this.renameProduct({ id: product.id, name: event.name })
            event.onComplete()
        },
        deselectTemplate(id) {
            if (this.selection.template?.id === id) {
                this.selection.template = {}
            }
        },
        deselectProduct(id) {
            if (this.selection.product?.id === id) {
                this.selection.product = {}
            }
        },
        async handleNewConnection() {
            const templateId = this.selectedTemplate.id
            const productId = this.selectedProduct.id
            const type = this.isTemplateGeneric ? 'generic' : ''
            const metaData = !this.isTemplateGeneric
                ? this.productMeta
                : { lang: this.genericLang }
            await this.makeConnection({ templateId, productId, type, metaData })
            this.deselectProduct(productId)
            this.deselectTemplate(templateId)
        },
        confirmAndDeleteTemplate(id) {
            confirmRemovalModal(
                'Remove template?',
                'You will lose all bindings for which the template was used',
                () => {
                    this.deselectTemplate(id)
                    this.deleteTemplate(id)
                }
            )
        },
        confirmAndDeleteProduct(id) {
            confirmRemovalModal(
                'Remove product?',
                'You will lose all bindings for which the product was used',
                () => {
                    this.deselectProduct(id)
                    this.deleteProduct(id)
                }
            )
        },
        updateProduct(id) {
            confirmModal(
                'Update product from API for all',
                'This action will override all connections where this product is used inside this project. Are you sure?',
                () => {
                    this.updateProductForAllConnections(id)
                },
                { okText: 'Update' }
            )
        },
        deleteLink(id) {
            confirmRemovalModal(
                'Remove binding?',
                'You will lose all the creations on this binding',
                () => {
                    this.deleteConnection(id)
                    // what if user deleted overlay connection id ?? (currently opened behind!)
                    if (this.overlaidConnectionId === String(id)) {
                        this.routerReplacePath = ''
                        // Now - we need to switch back to /all-to-all since current connection does not exists
                        this.connectionIdAllToAllReset = CONNECTION_ALL_TO_ALL
                    }
                }
            )
        },
        addEmpty(number) {
            this.addEmptyProducts(number)
        },
        async renameLink({ connectionId, name, onComplete }) {
            if (await this.renameConnection({ connectionId, name })) {
                onComplete()
            }
        },
        editLink(id) {
            this.handleClose(String(id))
        },
        resetSettings({ connectionId }) {
            const isAllToAllConnection = connectionId === CONNECTION_ALL_TO_ALL
            const title = isAllToAllConnection
                ? 'Reset ALL settings?'
                : 'Reset settings?'
            const content = isAllToAllConnection
                ? '⚠ WARNING: you will lose all changes in this project (in all bindings) ⚠'
                : 'You will lose all changes in this binding.'
            Modal.confirm({
                title,
                content,
                cancelText: 'Cancel',
                okText: 'Reset',
                icon: 'exclamation-circle',
                onOk: async () => {
                    const { projectId } = this
                    try {
                        await connectionService.resetConnectionSettings({
                            projectId,
                            connectionId,
                        })
                        // @Business: all-to-all resets current connection settings as well [!]
                        if (
                            this.currentConnectionId === String(connectionId) ||
                            isAllToAllConnection
                        ) {
                            await this.refreshCurrentConnection()
                            // @Business: when all resets -> project preview might need to be changed:
                            await this.updateThumbnailCreationId(null)
                        }
                        this.showAlertSuccess('Settings have been reset')
                    } catch (e) {
                        this.showAlertError('Failed to reset: ' + e.message)
                    }
                },
            })
        },
        moveSettingsToAllToAll(connectionId) {
            Modal.confirm({
                title: 'Are you sure ?',
                content:
                    'Transferring settings from this connection to all-to-all will override current all-to-all settings.',
                cancelText: 'Cancel',
                okText: 'Transfer settings',
                icon: 'exclamation-circle',
                onOk: async () => {
                    const { projectId } = this
                    try {
                        await connectionService.moveConnectionSettingsToAll2All(
                            { projectId, connectionId }
                        )
                        await this.refreshWhenAllToAll()
                        this.showAlertSuccess('Settings have been moved')
                    } catch (e) {
                        this.showAlertError('Failed to move: ' + e.message)
                    }
                },
            })
        },
        exportLinks(linksToExport) {
            if (linksToExport) {
                //TODO:
                this.showAlertSuccess('Selected links were exported')
            } else {
                //TODO:
                this.showAlertSuccess('Export finished')
            }
        },
        saveAndClose() {
            // this.$emit('saveProject');
            this.handleClose()
        },
        async handleClose(gotoConnectionId = '') {
            const connectionId =
                gotoConnectionId || this.connectionIdAllToAllReset
            // connectionId can be gotoConnectionId or empty, or can be 'all-to-all' when connectionIdAllToAllReset is set;
            // always clear the connectionIdAllToAllReset
            this.connectionIdAllToAllReset = ''
            if (connectionId) {
                // WHEN: Connection is changed
                const { projectId } = this.$route.params
                return this.$router.push({
                    name: 'project-connection',
                    params: { projectId, connectionId },
                })
            }
            if (this.routerReplacePath) {
                // WHEN: Closing overlay, but connection was opened (check: beforeRouteEnter)
                await this.$router.replace(this.routerReplacePath)
                this.routerReplacePath = ''
                return
            }
            // WHEN: Just Closing overlay
            await this.$router.push({ name: 'project' })
        },
    },
}
</script>

<style lang="less">
.links {
    position: absolute;
    top: 64px;
    bottom: 0;
    left: 0;
    width: 100%;
    // height: 100%;
    background-color: @gray-1;
    z-index: 20;

    &__main {
        display: flex;
        height: calc(100% - 64px - 48px);
    }

    &__search {
        background-color: @gray-2;
        padding: 12px 16px;
    }

    &__items {
        padding: 16px;
    }

    &__item {
        display: flex;
        transition: 0.2s all ease-in-out;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        padding: 0 16px;
        background-color: @gray-2;
        border: 1px solid @gray-4;
        border-radius: 6px;
        font-weight: 400;
        margin-bottom: 4px;
        cursor: pointer;

        .anticon {
            padding: 8px;
            color: @gray-10;
            font-size: 16px;
            transition: color 0.1s ease-in-out;
        }

        &:hover {
            border: 1px solid @gray-6;

            .anticon {
                color: @primary-6;
            }
        }

        &.is-selected {
            background-color: @primary-1;
            border-color: @primary-7;
        }
    }

    &__bottom {
        padding: 0 24px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 48px;
        background-color: @gray-2;
        border-top: solid 1px @gray-4;
    }

    &__info {
        width: calc(50% - 32px);
        position: absolute;
        left: 16px;
        bottom: 64px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 16px;
        background-color: @gray-2;
        border-radius: 6px;
        box-shadow: 0 9px 28px 8px #000000;
        color: @gray-9;
        font-weight: 400;

        &--name {
            font-weight: 600;
        }

        &--2 {
            height: 40px;
            justify-content: center;
        }
    }
}
</style>
