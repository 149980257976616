<template>
  <div> <a-spin style="margin-right: 10px" size="default" /> {{ text }}</div>
</template>

<script>
export default {
  name: 'InlinePreloader',
  props: {
    text: {
      type: String,
      default: ''
    }
  }
}
</script>
