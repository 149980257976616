import { message } from 'ant-design-vue';
import Vue from 'vue';
import Vuex from 'vuex';
import projects from './modules/projects';
import currentProject from './modules/current-project';
import templates from './modules/templates';
import user from './modules/user';
import connectionTemplate from './modules/connection-template';
import uiState from './modules/ui-state';

Vue.use(Vuex);

const DEFAULT_SNACKBAR_TIMEOUT = 3;
message.config({ duration: DEFAULT_SNACKBAR_TIMEOUT });

export default new Vuex.Store({
    modules: {
        connectionTemplate,
        projects,
        currentProject,
        templates,
        user,
        uiState
    },
    state: {
        waiting: false,
    },
    mutations: {
        SET_WAITING(state, value) {
            state.waiting = value
        }
    },
    actions: {
        setWaiting({commit}, value) {
            commit('SET_WAITING', value );
        },
        showAlert(config, text) {
            message.info(text);
        },
        showAlertError(config, text) {
            message.error(text);
        },
        showAlertSuccess(config, text) {
            message.success(text);
        } ,
        showAlertWarning(config, text) {
            message.warning(text);
        }
    },
});
