<template>
    <a-modal
        v-model="visible"
        :maskClosable="false"
        @cancel="$emit('closeNewTempModal')"
        :width="modalWidth"
    >
        <template #title>
            <div class="modal-top">
                {{ modalTitle }}
                <a-button
                    v-if="isInEditMode || draftTemplateValidationReport"
                    type="button"
                    size="small"
                    class="action"
                    title="Show details"
                    @click="validationPreview = !validationPreview"
                >
                    <a-icon v-if="validationPreview" type="eye" />
                    <a-icon v-else type="eye-invisible" />
                </a-button>
            </div>
        </template>
        <a-row :gutter="16">
            <a-col
                :xs="{ span: 24 }"
                :lg="{ span: validationPreview ? 12 : 24 }"
            >
                <a-form :form="templateForm" @submit.prevent="">
                    <p class="form__label">Template name</p>
                    <a-form-item>
                        <a-input
                            class="form__input"
                            placeholder="Template name"
                            v-decorator="[
                                'name',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Required field',
                                        },
                                    ],
                                },
                            ]"
                        />
                    </a-form-item>

                    <p class="form__label">Description</p>
                    <a-form-item>
                        <a-textarea
                            class="form__input scroll"
                            placeholder="Description"
                            :rows="3"
                            v-decorator="['description']"
                            @input="description = $event.target.value"
                        />
                    </a-form-item>

                    <p class="form__label">Category</p>
                    <a-form-item>
                        <a-select
                            class="newTemp__select"
                            placeholder="Select"
                            v-decorator="[
                                'category_id',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Required field',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-select-option
                                v-for="category in categories"
                                :key="category.id"
                                :value="category.id"
                            >
                                {{ category.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <p class="form__label">Choose supported sizes</p>
                    <a-tabs
                        @change="handleSizeLangTabChange"
                        v-model="chosenSizeLang"
                    >
                        <a-tab-pane
                            v-for="{ title, value, count } of languageTabs"
                            :key="value"
                        >
                            <template #tab>
                                <a-popover>
                                    <div style="display: flex">
                                        <div v-if="value === LANG_FALLBACK">
                                            {{ title }}&nbsp;
                                        </div>
                                        <locale-picker
                                            v-else
                                            display-type="flag-only"
                                            value-range="lang-only"
                                            :read-only="true"
                                            :show-label="false"
                                            :value="value"
                                        />
                                        <span>{{ count }}</span>
                                    </div>
                                    <template #content>
                                        <div v-if="count">
                                            {{
                                                extractSizeFromDefUserSting(
                                                    sizesByLanguage[value] || []
                                                ).join(', ')
                                            }}
                                        </div>
                                        <div v-else>empty</div>
                                    </template>
                                </a-popover>
                            </template>
                        </a-tab-pane>
                    </a-tabs>
                    <a-form-item>
                        <a-select
                            mode="tags"
                            class="newTemp__select"
                            placeholder="Choose sizes"
                            :token-separators="[',', ' ']"
                            ref="sizeRef"
                            @change="handleSizesChange"
                            @deselect="handleSizesDeselect"
                            v-decorator="[
                                'sizes',
                                {
                                    rules: [
                                        { validator: handleSizeValidation },
                                    ],
                                },
                            ]"
                        >
                            <a-select-option
                                v-for="{ key, value } in allSizes"
                                :key="key"
                            >
                                {{ value }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                    <p class="form__label">Set a preview template</p>
                    <a-form-item>
                        <a-select
                            class="newTemp__select"
                            placeholder="Choose the size of the preview"
                            v-decorator="['templatePreviewSize']"
                        >
                            <a-select-option
                                v-for="size in possibleTemplatePreviewSizes"
                                :key="size"
                            >
                                {{ size }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item style="margin-bottom: 0">
                        <p class="form__label">Keywords</p>
                        <a-select
                            class="form__input"
                            placeholder="Keywords"
                            mode="tags"
                            :token-separators="[',', ' ']"
                            v-decorator="['keywords']"
                        >
                            <a-select-option
                                v-for="{ name } in keywords"
                                :key="name"
                            >
                                {{ name }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item class="external-campaign-check">
                        <a-checkbox
                            class="form__checkbox"
                            @change="handleCampaignChange"
                            :checked="isExternalCampaign"
                        >
                            External Campaign
                        </a-checkbox>
                    </a-form-item>
                    <p class="form__label">
                        Template {{ currentTemplateType }}
                    </p>
                    <p class="form__label" v-if="isInEditMode">
                        Current: {{ currentTemplateFileName }}
                    </p>
                    <a-form-item>
                        <zip-or-style-upload
                            @onLoading="handleTemplateFileLoading"
                            :template-id="currentTemplateId"
                            :allow-files="allowedFileTypes"
                            v-decorator="[
                                'templateFileName',
                                {
                                    rules: [
                                        {
                                            required: !currentTemplateId,
                                            message: 'Required field',
                                        },
                                    ],
                                },
                            ]"
                        />
                    </a-form-item>
                    <a-form-item class="form__is-test" v-if="!isInEditMode">
                        <a-checkbox
                            class="form__is-test"
                            v-decorator="[
                                'is_test',
                                { valuePropName: 'checked' },
                            ]"
                        >
                            Test template
                        </a-checkbox>
                    </a-form-item>
                    <a-alert
                        v-if="errorMessage"
                        type="error"
                        :show-icon="true"
                        :banner="true"
                    >
                        <div slot="message" v-html="errorMessage" />
                    </a-alert>
                </a-form>
            </a-col>
            <a-col
                :xs="{ span: 24 }"
                :lg="{ span: 12 }"
                v-if="validationPreview"
            >
                <TemplateValidationPanel
                    :description="description"
                    :template-id="templateData.id"
                    :zip-upload-draft-name="templateFileName"
                    :draft-log-report="draftTemplateValidationReport"
                />
            </a-col>
        </a-row>
        <template #footer>
            <a-button
                key="back"
                type="default"
                @click="$emit('closeNewTempModal')"
            >
                Cancel
            </a-button>
            <a-button
                key="submit"
                type="primary"
                :loading="isFileLoading || isTemplateFormSend"
                @click="handleAddOrEditClick()"
            >
                {{ isInEditMode ? 'Update' : 'Add' }}
            </a-button>
        </template>
    </a-modal>
</template>

<script>
import TemplateValidationPanel from '@/components/templates/validation/TemplateValidationPanel'
import { templateValidationService } from '@/services/template-validation.service'
import ZipOrStyleUpload from './ZipOrStyleUpload'
import { templateService } from '@/services/template.service'
import {
    LANG_FALLBACK,
    LANG_LOCALES,
    LANGUAGES,
} from '@/components/shared/supported-languages'
import LocalePicker from '@/components/shared/locale-picker/LocalePicker.vue'
import Vue from 'vue'
import { sizeAscSorter } from '@/view-helpers/size-asc-sorter'

// @Business: Template can have same sizes multiple times
// that means to us:
// from now on - we CAN repeat same "tags" inside a-select (which has to be worked around :( )
// Those helper functions are needed
const sizeDef = (value) => ({ key: `def${value}`, value })
const userKey = (sizeName, sizeId) => `usr${sizeName}_${sizeId}`
const makeRandomUserKey = (value) => userKey(value, Math.random() * 90999)
const parseSizesValue = (values) =>
    values.map((v) => {
        const value = v.replace(/^(def|usr)/g, '')
        const [name] = value.split('_')
        return name
    })
const prepareSizesCollection = (collectionFromView) =>
    Object.fromEntries(
        Object.entries(collectionFromView).map(([name, sizes]) => [
            name,
            parseSizesValue(sizes),
        ])
    )
const sizesListToCollectionDecorator = (sizes, vueDataSet) => {
    for (const { lang, name, id } of sizes) {
        const langKey = lang || LANG_FALLBACK
        if (!vueDataSet[langKey]) {
            Vue.set(vueDataSet, langKey, [])
        }
        vueDataSet[langKey].push(userKey(name, id))
    }
}

const LARGE_WINDOW_SIZE = 960
const TEMPLATE_CAMPAIGN = {
    INTERNAL: 'internal',
    EXTERNAL: 'external',
}

export default {
    name: 'NewOrUpdateTemplateModal',
    components: {
        LocalePicker,
        TemplateValidationPanel,
        ZipOrStyleUpload,
    },
    props: {
        templateData: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            templateForm: this.$form.createForm(this, {
                onValuesChange: (_, { sizes }) => {
                    if (Array.isArray(sizes)) {
                        // @Business: templatePreviewSize should change - depend on available unique values in: sizesByLanguage (languageCollection)
                        const allSizesAsList = Object.values(
                            prepareSizesCollection(this.sizesByLanguage) || {}
                        ).flat()
                        this.possibleTemplatePreviewSizes = [
                            ...new Set(allSizesAsList),
                        ].sort(sizeAscSorter)
                        const currentPreview = this.templateForm.getFieldValue(
                            'templatePreviewSize'
                        )
                        const lastPreview =
                            this.possibleTemplatePreviewSizes[
                                this.possibleTemplatePreviewSizes.length - 1
                            ]
                        if (allSizesAsList.length === 0) {
                            this.templateForm.setFieldsValue({
                                templatePreviewSize: undefined,
                            })
                        } else if (
                            !this.possibleTemplatePreviewSizes.includes(
                                currentPreview
                            )
                        ) {
                            this.templateForm.setFieldsValue({
                                templatePreviewSize: lastPreview,
                            })
                        }
                    }
                },
            }),
            visible: true,
            isFileLoading: false,
            isTemplateFormSend: false,
            campaign: undefined,
            templateFileName: '',
            errorMessage: '',
            categories: [],
            keywords: [],
            userSizes: [],
            possibleTemplatePreviewSizes: [],
            description: '',
            validationPreview: false,
            draftTemplateValidationReport: null,
            sizesByLanguage: {
                [LANG_FALLBACK]: [],
                ...Object.fromEntries(
                    Object.entries(LANGUAGES).map(([lang]) => [lang, []])
                ),
            },
            chosenSizeLang: LANG_FALLBACK,
        }
    },
    computed: {
        commonSizes() {
            return [
                sizeDef('160x160'),
                sizeDef('224x364'),
                sizeDef('270x270'),
                sizeDef('300x250'),
                sizeDef('300x300'),
                sizeDef('320x100'),
                sizeDef('300x600'),
                sizeDef('330x400'),
                sizeDef('400x300'),
                sizeDef('450x338'),
                sizeDef('500x380'),
                sizeDef('500x500'),
                sizeDef('522x765'),
                sizeDef('528x288'),
                sizeDef('528x528'),
                sizeDef('540x170'),
                sizeDef('600x200'),
                sizeDef('600x300'),
                sizeDef('600x600'),
                sizeDef('616x582'),
                sizeDef('672x288'),
                sizeDef('720x340'),
                sizeDef('750x200'),
                sizeDef('750x300'),
                sizeDef('768x298'),
                sizeDef('768x640'),
                sizeDef('792x643'),
                sizeDef('800x800'),
                sizeDef('932x125'),
                sizeDef('932x250'),
                sizeDef('960x252'),
                sizeDef('990x170'),
                sizeDef('1056x576'),
                sizeDef('1080x288'),
                sizeDef('1080x1080'),
                sizeDef('1080x1920'),
                sizeDef('1196x356'),
                sizeDef('1200x300'),
                sizeDef('1200x400'),
                sizeDef('1200x627'),
                sizeDef('1247x400'),
                sizeDef('1344x576'),
                sizeDef('1584x864'),
                sizeDef('1600x72'),
                sizeDef('1600x100'),
                sizeDef('1600x200'),
                sizeDef('1600x300'),
                sizeDef('1600x360'),
                sizeDef('1600x400'),
                sizeDef('1600x572'),
                sizeDef('1920x640'),
                sizeDef('2016x864'),
            ]
        },
        allSizes() {
            return [...this.commonSizes, ...this.userSizes]
        },
        languageTabs() {
            return [
                {
                    title: 'All',
                    value: LANG_FALLBACK,
                    count: this.sizesByLanguage[LANG_FALLBACK]?.length || 0,
                },
                ...Object.entries(LANG_LOCALES).map(([language]) => ({
                    title: '',
                    value: language,
                    count: this.sizesByLanguage[language]?.length || 0,
                })),
            ]
        },
        LANG_FALLBACK() {
            return LANG_FALLBACK
        },
        modalTitle() {
            return `${this.isInEditMode ? 'Edit' : 'Add'} template`
        },
        modalWidth() {
            const { clientWidth } = document.body
            let computedWidth = LARGE_WINDOW_SIZE
            if (clientWidth) {
                computedWidth =
                    clientWidth > LARGE_WINDOW_SIZE
                        ? LARGE_WINDOW_SIZE
                        : clientWidth - 40
            }
            return this.validationPreview ? computedWidth : undefined
        },
        isInEditMode() {
            const isAnObject = typeof this.templateData === 'object'
            const hasIdentifier = this.templateData?.id
            return Boolean(isAnObject && hasIdentifier)
        },
        currentTemplateFileName() {
            if (this.isInEditMode) {
                const url = this.templateData?.file_url || '/'
                const [lastSegment] = url.split(/[\\/]/).reverse()
                const [justName] = lastSegment.split('--')
                return justName
            }
            return ''
        },
        currentTemplateId() {
            return this.templateData?.id
        },
        currentTemplateType() {
            const { type } = this.templateData || {}
            if (type) {
                return type === 'generic' ? 'Generic' : 'Product'
            }
            return ''
        },
        allowedFileTypes() {
            if (this.isInEditMode) {
                if (this.templateData?.is_test) {
                    // In test mode, you can upload both file types
                    return '.zip,.css'
                }
                // When updated, but not test template - only css can be changed
                return '.css'
            }
            // New templates should be zipped
            return '.zip'
        },
        isExternalCampaign() {
            return this.campaign === 'external'
        },
    },
    async mounted() {
        this.isFileLoading = !this.isInEditMode
        this.$nextTick(() => {
            // @Business: paste-bug, need to be patched by DOM event in the ant $ref / $el:
            // nextTick used for $ref presence!!
            this.$refs.sizeRef.$el.addEventListener('paste', (ev) => {
                ev.preventDefault()
                const typed = (ev.clipboardData.getData('text') || '')
                    .split(/[ ,]/)
                    .map((e) => e.trim())
                    .filter(Boolean)
                const existing = this.templateForm.getFieldValue('sizes')
                if (typed.length > 0) {
                    this.handleSizesChange([...existing, ...typed])
                }
            })
            if (this.templateData) {
                this.loadTemplateDataForEdition(this.templateData)
            }
        })
        this.categories = await templateService.getCategories().catch(() => {})
        this.keywords = await templateService.getKeywords().catch(() => {})
    },
    methods: {
        handleSizeValidation(rule, value, callback) {
            // trick to handle validation with possibleTemplatePreviewSizes,
            // as we got all parsed values over there.
            const { possibleTemplatePreviewSizes = [] } = this
            const errors = []
            if (!possibleTemplatePreviewSizes.length) {
                errors.push(new Error('Required field'))
            } else {
                const sizeFormat = /^\d+x\d+$/
                const hasProperSizesFormat = possibleTemplatePreviewSizes.every(
                    (s) => sizeFormat.test(s)
                )
                if (!hasProperSizesFormat) {
                    errors.push(
                        new Error(
                            'Each size, for each lang must have NxN format where N is a number'
                        )
                    )
                }
            }
            callback(errors)
        },
        handleSizesChange(currentValue) {
            const isTyped = (v) => !v.startsWith('usr') && !v.startsWith('def')
            const typedValues = currentValue.filter(isTyped)
            const autoValues = currentValue.filter((v) => !isTyped(v))
            if (typedValues.length > 0) {
                const valuesToAdd = []
                for (const value of typedValues) {
                    const trimmedValue = value.trim()
                    const uniqueKey = makeRandomUserKey(trimmedValue)
                    this.userSizes.push({ key: uniqueKey, value: trimmedValue })
                    valuesToAdd.push(uniqueKey)
                }
                const sizes = [...autoValues, ...valuesToAdd]

                this.$nextTick(() => {
                    this.sizesByLanguage[this.chosenSizeLang] = sizes
                    this.templateForm.setFieldsValue({ sizes })
                })
            }
            this.sizesByLanguage[this.chosenSizeLang] = currentValue
        },
        handleSizesDeselect(value) {
            if (value.startsWith('usr')) {
                this.userSizes = this.userSizes.filter(
                    ({ key }) => key !== value
                )
            }
        },
        handleTemplateFileLoading({ isLoading, fileName }) {
            this.isFileLoading = isLoading
            this.templateFileName = fileName
            this.templateForm.setFieldsValue({
                templateFileName: this.templateFileName,
            })
        },
        loadTemplateDataForEdition(templateData) {
            const {
                name,
                description,
                category_id,
                sizes,
                keywords,
                thumbnail,
                campaign,
            } = templateData
            const [templatePreviewSize] = thumbnail?.match(/\d+x\d+/g) || []
            this.userSizes = (sizes || []).map(({ id, name }) => ({
                key: userKey(name, id),
                value: name,
            }))
            // Unwrap sizes as list to sizes as so called languageCollection:
            sizesListToCollectionDecorator(sizes || [], this.sizesByLanguage)
            this.templateForm.setFieldsValue({
                name,
                description,
                category_id,
                sizes: this.sizesByLanguage[LANG_FALLBACK],
                keywords: (keywords || []).map(({ name }) => name),
                templatePreviewSize,
            })
            this.description = description
            this.campaign = campaign
        },
        async loadTemplateValidationReport(draftTemplateName) {
            if (!draftTemplateName) {
                this.draftTemplateValidationReport = null
                this.validationPreview = false
            } else if (!this.isInEditMode) {
                try {
                    this.draftTemplateValidationReport =
                        await templateValidationService.getForDraft()
                    this.validationPreview = true
                } catch (e) {
                    console.error('Loading draft validation template fail:', e)
                }
            }
        },
        async handleAddOrEditClick() {
            this.errorMessage = ''
            this.templateForm.validateFields(async (err, values) => {
                if (!err) {
                    const templateValues = {
                        ...values,
                        campaign: this.campaign,
                    }
                    // [!!] From now on we need to parse Size values for right ones still by language:
                    templateValues.sizesCollection = prepareSizesCollection(
                        this.sizesByLanguage
                    )
                    // [!!] sizes field is still important for previews:
                    templateValues.sizes = this.possibleTemplatePreviewSizes
                    try {
                        this.isTemplateFormSend = true
                        if (this.isInEditMode) {
                            const responseTemplate =
                                await templateService.update(
                                    this.templateData.id,
                                    templateValues
                                )
                            this.$emit('onTemplateUpdated', responseTemplate)
                        } else {
                            const res =
                                await templateService.create(templateValues)
                            this.$emit('onNewTemplateCreated', res.name)
                        }
                    } catch (e) {
                        this.errorMessage = e.message
                    } finally {
                        this.isTemplateFormSend = false
                    }
                }
            })
        },
        handleCampaignChange() {
            const { INTERNAL, EXTERNAL } = TEMPLATE_CAMPAIGN
            this.campaign =
                this.campaign === INTERNAL || !this.campaign
                    ? EXTERNAL
                    : INTERNAL
        },
        handleSizeLangTabChange(lang) {
            const sizes = this.sizesByLanguage[lang] || []
            this.templateForm.setFieldsValue({ sizes })
        },
        extractSizeFromDefUserSting(size) {
            return parseSizesValue(size)
        },
    },
    watch: {
        templateFileName(newValue) {
            this.loadTemplateValidationReport(newValue)
        },
    },
}
</script>

<style scoped lang="less">
.form__is-test {
    font-size: x-small;
}

.form__checkbox {
    font-size: x-small;
}

.external-campaign-check {
    margin: 0;
    padding: 0;
}

.modal-top {
    display: flex;
    justify-content: space-between;
    margin-right: 1.5em;
    align-items: center;
}
</style>
