<template>
    <div class="color_picker">
        <div class="picker">
            <div
                :class="{ 'picker__color-transparent': transparency }"
                :style="{ backgroundColor: color, position: 'absolute' }"
                ref="picker"
            />
        </div>
        <a-input
            :class="inputClass"
            v-model="color"
            @input="handleInput"
            @focus="handlePickerShow"
            ref="colorInput"
            :placeholder="placeholderText"
            :read-only="!allowToPickAnyColor"
        >
            <template #prefix>
                <span
                    class="color_picker__color"
                    :class="{ 'picker__color-transparent': transparency }"
                    :style="{ backgroundColor: color }"
                    @click="handlePickerShow"
                />
            </template>
            <template #suffix>
                <div style="display: flex">
                    <a-icon
                        v-if="wrongColorMessage"
                        :title="wrongColorMessage"
                        class="color_picker__warning"
                        type="warning"
                    />
                    <a-button
                        v-if="isResettable"
                        class="color_picker__reset"
                        title="Restore default value"
                        @click="$emit('reset')"
                    >
                        <a-icon style="color: white" type="undo" />
                    </a-button>
                </div>
            </template>
        </a-input>
    </div>
</template>

<script>
import { ExtendedVanillaPicker } from '@/components/shared/form/ExtendedVanillaPicker'
import { debounce } from '@/view-helpers/debouncer'

export default {
    name: 'ColorPicker',
    props: {
        transparency: {
            default: false,
            type: Boolean,
        },
        isResettable: {
            default: true,
            type: Boolean,
        },
        readOnly: {
            default: false,
            type: Boolean,
        },
        allowToPickAnyColor: {
            default: true,
            type: Boolean,
        },
        placeholderText: {
            default: '',
            type: String,
        },
        inputClass: {
            default: '',
            types: String,
        },
        value: String,
        defaultValue: String,
        possibleValues: {
            type: [Array, Object],
            default: () => [],
        },
    },
    mounted() {
        this.picker = new ExtendedVanillaPicker({
            parent: this.$refs.picker,
            alpha: this.transparency,
        })
        this.picker.onChange = () => {
            this.color = this.picker.getHexRgbaColor()
            this.picker.setPickerColorPreview(this.color)
            this.picker.setExtendedAlpha()
            this.handleColorChange()
        }
        this.picker.onClose = () => {
            this.$emit('blur', this.color)
        }
        this.picker.onOpen = () => {
            // Run code belo JUST ONCE (attach listeners only once if needed).
            this.picker.setPickerColorPreview(this.color)
            this.picker.setPredefinedColors(this.possibleValues)
            this.picker.setExtendedAlpha()
            this.picker.setCopyToClipboardHandler()
            this.picker.toggleEditorElementsVisibility(this.allowToPickAnyColor)
            this.alignWithParent()
        }
        this.setPickerColor(this.color)
    },
    data() {
        return {
            color: this.value || this.defaultValue,
            wrongColorMessage: '',
        }
    },
    methods: {
        handleInput: debounce(function() {
            this.handleColorChange()
        }),
        handleColorChange() {
            this.$emit('change', this.color)
            this.$emit('input', this.color)
        },
        handlePickerShow() {
            if (this.readOnly) {
                // Don't react when this control is read-only!
                return
            }
            // Timeout to prevent (close() of picker effect - when focused on input)
            setTimeout(() => {
                this.picker.show(this.$refs.colorInput.$el)
                // Need to fire onOpen by hand :(
                this.picker.onOpen()
            }, 20)
        },
        setPickerColor(value) {
            if (this.picker) {
                try {
                    this.picker.setColor(value, true)
                    this.wrongColorMessage = ''
                } catch (e) {
                    // Don't care if user is wrong - but Error won't affect DOM tree / Vue.
                    this.wrongColorMessage = `Wrong color value: "${value}". That will fall back to #fff`
                }
                this.setPickerColorPreview()
            }
        },
        setPickerColorPreview() {
            const $colorPreview = this.$refs.picker.querySelector(
                '[data-color-preview]'
            )
            if ($colorPreview) {
                $colorPreview.style.backgroundColor = this.color
            }
        },
        alignWithParent() {
            const { picker, colorInput } = this.$refs
            picker.parentElement.style.left = `${
                colorInput.$el.getBoundingClientRect().left
            }px`
        },
    },
    watch: {
        value: {
            handler(newValue) {
                this.color = newValue || this.defaultValue
                this.setPickerColor(this.color)
            },
            immediate: true,
        },
    },
}
</script>

<style lang="less">
.color_picker {
    min-width: 37px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: visible;

    &__color {
        width: 12px;
        height: 12px;
        border-radius: 2px;
        margin-right: 6px;
    }

    &__reset {
        height: 25px;
        padding: 2px;
        border: 0;
        background-color: transparent !important;
    }

    &__warning {
        height: 25px;
        padding: 2px;
        margin-top: 3px;
        margin-right: 3px;
        border: 0;
        color: #d89614;
    }
}

.picker .picker_wrapper {
    margin: 2em 0 0 !important;
    border-color: @gray-5;
    padding: 0;
    border-radius: 10px;
    min-width: 260px;
    z-index: 10;
    position: absolute;
    overflow: hidden;

    .picker_title {
        padding: 8px;
        font-size: 14px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .picker_cancel {
            .picker_cancel_btn {
                padding: 0;
                box-shadow: none;
                min-width: auto;
                background: transparent;
            }
        }
    }

    .picker_sl {
        margin: 0;
        width: 100%;

        .picker_selector {
            padding: 6px;
        }
    }

    .picker_panel {
        width: 100%;
        padding: 0 12px 12px;

        .picker_editor {
            width: 100%;
            padding: 0;
            margin: 0;

            .anticon-copy {
                cursor: pointer;
            }
        }

        .picker_input {
            margin: 1em 0;
            padding: 8px 8px 8px 32px;
            font-size: 16px;
            width: 100%;
            text-align: left;
        }

        .picker_predefined {
            margin-top: 12px;
            color: @gray-10;
            font-size: 14px;

            .picker_predefined_title {
                font-size: 12px;
                margin: 6px 0 1px;
                color: #7a7a7a;
                padding: 0;
            }

            .picker_predefined_list {
                display: flex;
                flex-wrap: wrap;
                gap: 4px;
            }

            .picker_predefined_color {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }
    }

    .picker_hue {
        margin-top: 12px;

        &.picker_slider {
            padding: 2px;
        }

        .picker_selector {
            padding: 5px;
            border-radius: 50%;
        }
    }

    .picker_alpha_ex {
        padding: 2px;
        border: 1px solid @gray-5;

        .picker_selector {
            padding: 5px;
            border-radius: 50%;
            border: 2px solid @gray-10;
            background-color: @gray-1;
        }

        // hide movable box...
        .picker_selector div {
            display: none;
        }
    }
}

.picker_wrapper.no_alpha .picker_alpha_ex {
    display: none;
}

.no-radius .ant-input {
    border-radius: 0;
}

.picker {
    //@fix: a TRICK to make picker visible outside the overflow:
    //https://github.com/w3c/csswg-drafts/issues/4092
    // position: fixed; /* ! this causes a BUG ! - removed with quickfix */
    z-index: 999;
}
</style>
