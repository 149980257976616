import { axInstance } from './ax-instance'

const BASE_PROJECT_URI = '/projects'

function all(searchQuery, order = [], page = 0, limit = 20, type) {
    // return Promise.resolve({results: projects})
    let searchParam = {}
    if (searchQuery) {
        searchParam = {
            name: searchQuery,
        }
    }
    let orderParam = {}
    if (order.length > 0) {
        const [orderBy, orderDir] = order
        orderParam = {
            orderBy,
            orderDir,
        }
    }
    return axInstance.get(BASE_PROJECT_URI, {
        params: { page, limit, ...searchParam, ...orderParam, type },
    })
}

function create(name) {
    return axInstance.post(BASE_PROJECT_URI, { name })
}

function duplicate(id) {
    return axInstance.post(`${BASE_PROJECT_URI}/${id}/clone`, { id })
}

function duplicateForOthers(id, { userRoles = [], userEmails = [] }) {
    return axInstance.post(`${BASE_PROJECT_URI}/${id}/duplicate-for-others`, {
        userEmails,
        userGroups: userRoles,
    })
}

function rename(id, name) {
    return axInstance.patch(BASE_PROJECT_URI + '/' + id, { name })
}

function setDescription(id, description) {
    return axInstance.patch(BASE_PROJECT_URI + '/' + id, { description })
}

function remove(id) {
    return axInstance.delete(BASE_PROJECT_URI + '/' + id)
}

function getOne(id) {
    return axInstance.get(BASE_PROJECT_URI + '/' + id)
}

function addNewTemplates(id, { templateIds = [], genericLang }) {
    return axInstance.post(`${BASE_PROJECT_URI}/${id}/templates`, {
        templateIds,
        genericLang,
    })
}

function getTemplates(id) {
    return axInstance.get(`${BASE_PROJECT_URI}/${id}/templates`)
}

function renameTemplate(id, templateId, name) {
    return axInstance.patch(
        `${BASE_PROJECT_URI}/${id}/templates/${templateId}`,
        { name }
    )
}

function removeTemplate(id, templateId) {
    return axInstance.delete(
        `${BASE_PROJECT_URI}/${id}/templates/${templateId}`
    )
}

function addNewProducts(id, { productIds = [], currency, lang }) {
    return axInstance.post(`${BASE_PROJECT_URI}/${id}/products`, {
        productIds,
        currency,
        lang,
    })
}

function addEmptyProducts(id, productCount) {
    return axInstance.post(`${BASE_PROJECT_URI}/${id}/emptyProducts`, {
        productCount,
    })
}

function getProducts(id) {
    return axInstance.get(`${BASE_PROJECT_URI}/${id}/products`)
}

function renameProduct(id, productId, name) {
    return axInstance.patch(`${BASE_PROJECT_URI}/${id}/products/${productId}`, {
        name,
    })
}

function updateProductForAllConnections(id, productId) {
    return axInstance.put(`${BASE_PROJECT_URI}/${id}/products/${productId}`)
}

function removeProduct(id, productId) {
    return axInstance.delete(`${BASE_PROJECT_URI}/${id}/products/${productId}`)
}

function addNewConnection(id, { templateId, productId, type, metaData }) {
    return axInstance.post(`${BASE_PROJECT_URI}/${id}/connections`, {
        templateId,
        productId,
        type,
        metaData,
    })
}

function getAllToAllConnections(projectId) {
    return axInstance.get(
        `${BASE_PROJECT_URI}/${projectId}/connections/all-to-all`
    )
}

function getConnection(projectId, connectionId) {
    return axInstance.get(
        `${BASE_PROJECT_URI}/${projectId}/connections/${connectionId}`
    )
}

function removeConnection(projectId, connectionId) {
    return axInstance.delete(
        `${BASE_PROJECT_URI}/${projectId}/connections/${connectionId}`
    )
}

function getDefaultBadges() {
    return axInstance.get(`${BASE_PROJECT_URI}/default-badges`)
}

function getCustomBadges(projectId) {
    return axInstance.get(`${BASE_PROJECT_URI}/${projectId}/custom-badges`)
}

function addNewBadge(projectId, badge) {
    return axInstance.post(
        `${BASE_PROJECT_URI}/${projectId}/custom-badges`,
        badge
    )
}

function addCustomSize(projectId, { dimensions, ctaType, lang }) {
    return axInstance.post(`${BASE_PROJECT_URI}/${projectId}/custom-sizes`, {
        dimensions,
        ctaType,
        lang,
    })
}

function removeCustomSize(projectId, sizeId = 0) {
    return axInstance.delete(
        `${BASE_PROJECT_URI}/${projectId}/custom-sizes/${sizeId}`
    )
}

function markOpened(projectId) {
    return axInstance.patch(`${BASE_PROJECT_URI}/${projectId}/open`)
}

function updateConfiguration(projectId, { filterSizes = [] }) {
    return axInstance.patch(`${BASE_PROJECT_URI}/${projectId}/config`, {
        filterSizes,
    })
}

export const projectService = {
    all,
    create,
    duplicate,
    duplicateForOthers,
    rename,
    setDescription,
    remove,
    getOne,
    getConnection,
    getAllToAllConnections,
    getTemplates,
    getProducts,
    addNewTemplates,
    renameTemplate,
    removeTemplate,
    addEmptyProducts,
    addNewProducts,
    renameProduct,
    removeProduct,
    updateProductForAllConnections,
    addNewConnection,
    removeConnection,
    getDefaultBadges,
    getCustomBadges,
    addNewBadge,
    addCustomSize,
    removeCustomSize,
    markOpened,
    updateConfiguration,
}
