<template>
  <transition name="fadeIn" mode="in-out" appear>
    <s-mask>
      <div class="invitation">
        <section class="invitation__box">
          <img class="invitation__logo" src="../../assets/logo-white.svg" alt="Company Logo">

          <div style="margin: 10px">
            <environment-marker />
          </div>
          <p class="remind__title">Signup confirmation</p>
          <change-password-form
              @sendForm="handleSubmit($event)"
              :loading="isLoading"
              cta-btn-text="Setup password"
          />
          <a-alert
              style="margin-top: 20px; max-width: 320px"
              v-if="message"
              :type="messageType"
              show-icon
              banner
          >
             <div slot="message" v-html="message" />
          </a-alert>
        </section>
        <div class="remind__bottom">
          <router-link class="remind__link" to="/login">
            <a-icon type="login"/> Login page
          </router-link>
        </div>
      </div>
    </s-mask>
  </transition>

</template>

<script>
import Mask from '@/components/layout/Mask';
import ChangePasswordForm from '@/components/login/ChangePasswordForm'
import EnvironmentMarker from '@/components/main/components/EnvironmentMarker'
import { userService } from '@/services/user.service'
import { mapActions } from 'vuex';


// TODO: refactor to ONE PAGE ! with AcceptInvitationPage - similar logic !
const STATUS = {LOADING: 1, ACCEPTED: 2, ERROR: 3}

export default {
  name: 'ConfirmRegistrationPage',
  components: {
    ChangePasswordForm,
    EnvironmentMarker,
    SMask: Mask
  },
  data() {
    return {
      message: '',
      messageType: 'info',
      status: 0
    }
  },
  computed: {
    token() {
      return this.$route.query.token;
    },
    isLoading() {
      return this.status === STATUS.LOADING;
    },
    isAccepted() {
      return this.status === STATUS.ACCEPTED;
    },
    hasError() {
      return this.status === STATUS.ERROR;
    }
  },
  mounted () {
    if(!this.token) {
      this.status = STATUS.ERROR
      this.message = 'Token not provided, cannot continue...';
      this.messageType = 'error';
    }
  },
  methods: {
    ...mapActions('user', ['signUp']),
    async handleSubmit({password}) {
        const { token } = this;
        if(!token) {
          return;
        }
        this.status = STATUS.LOADING
        try {
          const authInfo = await userService.confirmRegistration({ token, password })
          this.signUp({...authInfo})
          await this.$router.push('/projects');
        } catch (err) {
          this.status = STATUS.ERROR
          this.message = err.message;
          this.messageType = 'error';
        }
    }
  }
}
</script>

<style lang="less">
    .invitation {

        &__box {
            display: flex;
            flex-direction: column;
            background-color: @gray-2;
            border: solid 1px @gray-4;
            border-radius: 6px;
            padding: 56px 24px;
        }

        &__bottom {
            margin-top: 8px;
            text-align: center;
        }

        &__title {
            font-size: 16px;
            font-weight: 600;
            margin-top: 32px;
            margin-bottom: 16px;
        }

        &__logo {
            display: flex;
            width: 240px;
            margin: auto;
        }

        &__input {
            width: 290px;
            margin-bottom: 16px;
        }

        &__link {
            text-align: center;
            margin-top: 16px;
            text-decoration: underline;

            &:hover {
                text-decoration: underline;
            }
        }

        &__label {
            margin-bottom: 4px;
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            color: @gray-9;
        }
    }
</style>
