<template>
    <div
        class="graphics box"
        :class="{
            'disable-safe-area': !safeArea,
        }"
    >
        <div>
            <div class="box__title">
                <div class="box__actions">
                    <h4>Graphics</h4>
                </div>
                <div class="box_resize_buttons">
                    <scale-stepper v-model="scale" :min="0.1" :max="1" />
                </div>
                <a-button v-if="hasAnyCreations" type="default">
                    <a-dropdown>
                        <a class="ant-dropdown-link" @click.prevent="">
                            Export <a-icon type="down" />
                        </a>
                        <a-menu slot="overlay">
                            <a-menu-item
                                @click="
                                    $emit('exportSelectedGraphics', {
                                        creationModels: allSelectedCreations,
                                    })
                                "
                                :disabled="!isDownloadForSelectedEnabled"
                                :title="unableToExportTitle"
                                style="position: relative"
                            >
                                <p>
                                    Selected:
                                    {{
                                        allSelectedCreationsCount > 0
                                            ? allSelectedCreationsCount
                                            : ''
                                    }}
                                    (local download)
                                </p>
                            </a-menu-item>
                            <a-menu-item
                                @click="
                                    $emit('exportSelectedGraphics', {
                                        creationModels: allSelectedCreations,
                                        exportToCim: true,
                                    })
                                "
                                :disabled="!isDownloadForSelectedEnabled"
                                :title="unableToExportTitle"
                                v-if="hasPermission(allPermissions.CIM_EXPORT)"
                                style="position: relative"
                            >
                                <p>
                                    Selected:
                                    {{
                                        allSelectedCreationsCount > 0
                                            ? allSelectedCreationsCount
                                            : ''
                                    }}
                                    (send to CIM)
                                </p>
                            </a-menu-item>
                            <a-menu-item
                                @click="
                                    $emit('exportAllCreations', {
                                        creationModels: banners,
                                    })
                                "
                            >
                                <p>All banners (local download)</p>
                            </a-menu-item>
                            <!-- LOCKED FOR 1.0
						<a-menu-divider />
						<a-menu-item @click="$emit('exportAllLinks')">
							<p>All bindings</p>
						</a-menu-item> -->
                        </a-menu>
                    </a-dropdown>
                </a-button>
            </div>
            <div v-if="loading" class="box__empty">
                <s-preloader text="Loading banners..." />
            </div>
            <div v-else-if="!hasAnyCreations" class="box__empty">
                <p>Select a binding to begin</p>
            </div>
            <template v-else>
                <div class="graphics__search">
                    <a-select
                        mode="tags"
                        placeholder="Search by size"
                        v-model="selectedSizes"
                        @change="searchSize"
                        :allowClear="true"
                    >
                        <a-select-option v-for="size in allSizes" :key="size">
                            {{ size }}
                        </a-select-option>
                    </a-select>

                    <a-button class="safe-area" @click="safeArea = !safeArea"
                        >Safe area</a-button
                    >

                    <a-button
                        class="new-sizes"
                        @click="showProjectSizesModal = true"
                    >
                        <a-icon type="pic-center" /> Sizes
                    </a-button>
                </div>
                <div class="graphics__checkbox-wrapper">
                    <a-checkbox
                        v-model="selectAll"
                        :indeterminate="indeterminateSelection"
                        @change="toggleAll()"
                        class="banner__checkbox"
                    >
                        Select all {{ selectionStats }}
                    </a-checkbox>
                </div>
                <ul class="box__main scroll" ref="allCreations">
                    <li
                        v-for="creation in filteredSelectableBanners"
                        :key="creation.id"
                        class="banner"
                    >
                        <div
                            class="banner__header"
                            :title="
                                creation.isDisabled
                                    ? 'Can\'t select caused by some expired /  deleted galley item(s) used on this creation'
                                    : ''
                            "
                            :style="{
                                borderBottom: creation.size.project_id
                                    ? '2px solid #E5580B'
                                    : '',
                            }"
                        >
                            <a-checkbox
                                :checked="
                                    isCreationIdSelected(
                                        connectionId,
                                        creation.id
                                    )
                                "
                                @change="toggleSelect(creation)"
                                class="banner__checkbox"
                                :disabled="creation.isDisabled"
                            >
                                {{ creation.name }} |
                                <strong>{{ creation.size.name }}</strong>
                                <a-icon
                                    v-if="creation.size.project_id"
                                    type="project"
                                    :style="{
                                        color: '#E5580B',
                                        marginLeft: '5px',
                                    }"
                                    theme="filled"
                                    title="Project custom size"
                                />
                            </a-checkbox>
                            <creation-expire-image-indicator
                                :creation-settings="creation.settings"
                                :creation="creation"
                            />
                            <a-popconfirm
                                title="Set this banner as project thumbnail?"
                                ok-text="Yes"
                                cancel-text="Cancel"
                                @confirm="makeBannerProjectPreview(creation)"
                            >
                                <a-button
                                    class="banner__btn"
                                    style="margin-right: 3px"
                                    title="Set as project thumbnail"
                                    :type="
                                        calculatePreviewButtonType(creation.id)
                                    "
                                >
                                    <a-icon type="picture" />
                                </a-button>
                            </a-popconfirm>
                            <a-button
                                class="banner__btn"
                                title="Open in Single Creation Editor"
                                @click="editBanner(creation)"
                            >
                                <a-icon type="edit" />
                            </a-button>
                        </div>
                        <div
                            class="banner__image scroll"
                            :data-creation-id="creation.id"
                        >
                            <template-holder
                                :creationModel="creation"
                                :transform-when-ready="creationTransform"
                                @transformApplied="
                                    recalculateHeight(creation.id, $event)
                                "
                            />
                        </div>
                    </li>
                </ul>
            </template>
        </div>
        <template>
            <ProjectSizesModal
                :visible="showProjectSizesModal"
                @closeProjectSizesModal="showProjectSizesModal = false"
            />
        </template>
    </div>
</template>

<script>
import Preloader from '@/components/project/components/InlinePreloader'
import ProjectSizesModal from '@/components/project/components/ProjectSizesModal'
import TemplateHolder from '@/components/project/components/TemplateHolder'
import ScaleStepper from '@/components/shared/form/ScaleStepper.vue'
import { projectPreviewService } from '@/services/project-preview.service'
import { projectService } from '@/services/project.service'
import { templateHoldersService } from '@/services/template-holders.service'
import { useSelectedCreations } from '@/store/selected-creations'
import { mapActions, mapGetters } from 'vuex'
import { mapActions as mapPActions, mapState } from 'pinia'
import CreationExpireImageIndicator from '@/components/project/components/graphics/CreationExpireImageIndicator.vue'
import { useGalleryCache } from '@/store/modules/gallery-cache.store'
import { useTemplateBaseFilesCache } from '@/store/modules/template-base-files.store'
import {
    sizeAscSorter,
    sortByConnectionAndSize,
} from '@/view-helpers/size-asc-sorter'

export default {
    name: 'Graphics',
    components: {
        CreationExpireImageIndicator,
        ScaleStepper,
        TemplateHolder,
        SPreloader: Preloader,
        ProjectSizesModal,
    },
    props: {
        loading: Boolean,
        selected: Boolean,
        creations: Array,
        visibility: Object,
        connectionId: [Number, String],
    },
    data() {
        return {
            scale: 1,
            safeArea: false,
            saveAsJpg: false,
            selectAll: false,
            selectedSizes: this.projectSizesFilter || [],
            showProjectSizesModal: false,
            creationHolderScrollBoxRefs: {},
        }
    },
    watch: {
        creations() {
            // Recalculate the local state each time new Creations are loaded:
            this.selectedSizes = this.projectSizesFilter
            this.updateSelectAll()
        },
    },
    computed: {
        ...mapGetters('user', ['hasPermission', 'allPermissions']),
        ...mapGetters('currentProject', [
            'projectId',
            'thumbnailCreationId',
            'projectSizesFilter',
        ]),
        ...mapState(useSelectedCreations, [
            'selectedCreationIds',
            'isCreationIdSelected',
            'allSelectedCreations',
            'allSelectedCreationsCount',
        ]),
        ...mapState(useGalleryCache, ['hasAnyArchivedItem']),
        ...mapState(useTemplateBaseFilesCache, ['hasAnyArchivedBaseFiles']),
        creationTransform() {
            return {
                transform: `scale(${this.scale})`,
                'transform-origin': 'top left',
            }
        },
        banners() {
            return this.creations
        },
        hasAnyCreations() {
            return this.creations?.length && !this.loading
        },
        isDownloadForSelectedEnabled() {
            return (
                this.allSelectedCreationsCount > 0 &&
                this.allSelectedCreationsEnabled
            )
        },
        allSelectedCreationsEnabled() {
            return this.selectedCreations.every((c) => !c.isDisabled)
        },
        unableToExportTitle() {
            return this.allSelectedCreationsEnabled
                ? ''
                : 'Some of selected items are not permitted to export'
        },
        allSizes() {
            return Array.from(
                new Set(this.banners.map((b) => b.size.name))
            ).sort(sizeAscSorter)
        },
        filteredBanners() {
            return this.selectedSizes.length
                ? this.banners.filter(
                      (b) => this.selectedSizes.indexOf(b.size.name) !== -1
                  )
                : this.banners
        },
        filteredSelectableBanners() {
            return this.filteredBanners
                .map((creation) => {
                    creation.isDisabled =
                        this.hasAnyArchivedItem(creation.settings) ||
                        this.hasAnyArchivedBaseFiles(
                            creation.settings,
                            creation.template.id
                        )
                    return creation
                })
                .sort(sortByConnectionAndSize)
        },
        selectedCreations() {
            return this.filteredSelectableBanners.filter((c) =>
                this.selectedCreationIds(this.connectionId).includes(c.id)
            )
        },
        indeterminateSelection() {
            if (this.selectedCreations.length === 0) {
                return false
            }
            return (
                this.selectedCreations.length !==
                this.filteredSelectableBanners.length
            )
        },
        selectionStats() {
            const selected = this.selectedCreations.length
            const all = this.filteredSelectableBanners.length
            if (selected > 0) {
                return `(${selected} / ${all})`
            }
            return ''
        },
    },
    methods: {
        ...mapActions('connectionTemplate', ['selectedCreation']),
        ...mapActions('currentProject', ['updateFilteredSizes']),
        ...mapPActions(useSelectedCreations, [
            'toggleModelSelection',
            'selectManyModels',
            'deselectManyModels',
        ]),
        ...mapPActions(useGalleryCache, ['assureFileUrlsPresentFromSettings']),
        calculatePreviewButtonType(creationId) {
            return creationId === this.thumbnailCreationId ? 'primary' : ''
        },
        toggleAll() {
            if (this.selectAll) {
                this.selectManyModels({
                    connectionId: this.connectionId,
                    creationModels: this.filteredSelectableBanners,
                })
            } else {
                this.deselectAll()
            }
        },
        async deselectAll() {
            await this.deselectManyModels({ connectionId: this.connectionId })
            this.selectAll = false
        },
        updateSelectAll() {
            const hasAnySelections = this.selectedCreations.length > 0
            this.selectAll = hasAnySelections && !this.indeterminateSelection
        },
        toggleSelect(creation) {
            this.toggleModelSelection({
                connectionId: this.connectionId,
                creationModel: creation,
            })
            this.updateSelectAll()
        },
        async searchSize(value) {
            await this.deselectAll()
            this.selectedSizes = value || []
            try {
                await projectService.updateConfiguration(this.projectId, {
                    filterSizes: value,
                })
                await this.updateFilteredSizes(value)
                // eslint-disable-next-line no-empty
            } catch {}
        },
        editBanner(creation) {
            const { projectId, connectionId } = this.$route.params
            const creationId = creation.id
            this.selectedCreation(creation)
            this.$router.push({
                name: 'project-creation',
                params: { projectId, connectionId, creationId },
            })
        },
        makeBannerProjectPreview(creation) {
            const [templateHolder] =
                templateHoldersService.getTemplateHoldersByCreationId(
                    creation.id
                )
            projectPreviewService.generateNewProjectThumbnail({
                projectId: this.projectId,
                creationModel: creation,
                templateHolder,
            })
        },
        recalculateHeight(creationId, { height }) {
            // @Warn: this must be used as outer ref + querySelector + creationId,
            // due to improper match (stale) when indexes of refs used !!
            const creationHolderScrollBox =
                this.$refs.allCreations.querySelector(
                    `[data-creation-id="${creationId}"]`
                )
            if (creationHolderScrollBox && height) {
                const { paddingTop, paddingBottom } = getComputedStyle(
                    creationHolderScrollBox
                )
                const top = parseFloat(paddingTop)
                const bottom = parseFloat(paddingBottom)
                creationHolderScrollBox.style.height = `${
                    height + top + bottom
                }px`
            }
        },
        checkIfItemIsDisabled(creationModel) {
            const { settings, template } = creationModel
            // this.assureFileUrlsPresentFromSettings(settings)
            const isDisabled =
                this.hasAnyArchivedItem(settings) ||
                this.hasAnyArchivedBaseFiles(settings, template.id)
            if (isDisabled) {
                // @Fix: this must be here - to make side effect if already selected one, is now disabled (changed from a-to-a)
                // logic inside toggleSelect should know to NOT select disabled, but it will deselect this creation if it's selected
                this.toggleSelect(creationModel)
            }
            return isDisabled
        },
    },
}
</script>

<style lang="less">
.graphics {
    overflow: hidden;
    opacity: 1;
    height: calc(100vh - 64px - 76px);

    .expiration_icon {
        margin-right: 6px;
    }

    .new-sizes {
        margin-top: 3px;
    }

    .ant-switch {
        background: #0a0a0a;
        border: 1px solid #434343;
        margin-right: 10px;
    }

    .ant-switch-checked {
        background-color: #e5580b;
    }

    &--visible {
        opacity: 1;
        width: calc(100% - 8px);
    }

    &--extended {
        opacity: 1;
        width: 100%;
    }

    &.box {
        .box__actions {
            display: flex;
        }
    }

    &__checkbox-wrapper {
        padding: 0 34px;
    }

    &__search {
        padding: 12px 34px;
        display: flex;
        justify-content: space-between;
        border-bottom: solid 1px @gray-4;

        .ant-checkbox-wrapper {
            flex-grow: initial;
            width: auto;
            user-select: none;
        }

        .ant-select {
            max-width: 600px;
            width: 80%;
            margin-top: 3px;
        }
    }

    .box {
        &__main {
            height: calc(100vh - 64px - 64px - 168px);
            padding-top: 0;
        }
    }
}
</style>
