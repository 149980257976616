import { axInstance } from './ax-instance'

const makeBaseValidationURI = templateId => `/templates/${templateId}/validation`;

export const templateValidationService = {
	STATUS: {
		PENDING: 'PENDING',
		DONE: 'DONE',
		FAILED: 'FAILED'
	},
	get(templateId) {
		return axInstance.get(makeBaseValidationURI(templateId))
	},
	getForDraft(templateId = 0) {
		return axInstance.get(makeBaseValidationURI(templateId), { params: { draft: true } })
	},
	request(templateId) {
		return axInstance.post(makeBaseValidationURI(templateId), { generate: true })
	}
}
