/* eslint-disable no-unused-vars */

import { CONNECTION_ALL_TO_ALL } from '@/routes-specials'
import { projectPreviewService } from '@/services/project-preview.service'
import { templateHoldersService } from '@/services/template-holders.service'

export function makeBaseState() {
	return {
		connectionId: '0', // :string [!] Important unification here, because it can be "all-to-all"
		selectedCreation: null,
		refreshAllToAll: false, // Marker: mark that all-to-all need to be refreshed!,
		refreshCurrent: false // Marker: mark that current connection need to be refreshed!,
	}
}

export default {
	state: makeBaseState(),
	namespaced: true,
	getters: {
		selectedCreation: state => state.selectedCreation,
		currentConnectionId: state => state.connectionId,
		isCurrentConnectionAllToAll: state => state.connectionId === CONNECTION_ALL_TO_ALL,
		shouldReloadAllToAllConnection: ( state, getters) => getters.isCurrentConnectionAllToAll && state.refreshAllToAll,
		shouldReloadCurrentConnection: (state) => state.refreshCurrent
	},
	mutations: {
		SET_CONNECTION_ID(state, connectionId) {
			state.connectionId = String(connectionId);
			// THIS FLAG need to be changed despite connectionId!!
			state.refreshAllToAll = false;
			state.refreshCurrent = false;
			state.selectedCreation = null;
			templateHoldersService.cleanTemplateHolders();
		},
		SET_CURRENT_CREATION_MODEL( state, creation) {
			state.selectedCreation = creation;
		},
		UPDATE_CURRENT_CREATION_MODEL_SETTINGS( state, settings) {
			state.selectedCreation.updateSettings(settings);
		},
		SET_REFRESH_ALL_TO_ALL(state, boolValue) {
			state.refreshAllToAll = boolValue;
		},
		// This commit will be used internally only by: currentProject/[actions]
		SET_REFRESH_CURRENT(state, boolValue) {
			state.refreshCurrent = boolValue;
		}
	},
	actions: {
		setNewConnection( {commit}, connectionId = '0') {
			commit("SET_CONNECTION_ID", connectionId)
			templateHoldersService.cleanTemplateHolders();
		},
		emptyConnections({dispatch}) {
			dispatch('setNewConnection', '0')
		},
		async refreshCurrentConnection({dispatch, getters}) {
			const id = getters.currentConnectionId;
			await dispatch("emptyConnections")
			await dispatch("setNewConnection", id)
		},
		setMirrorTemplate({commit}, {id, templateHolder, creationId, productId}) {
			templateHoldersService.addTemplateHolder({id, templateHolder, creationId, productId})
		},
		removeMirrorTemplate({commit}, {id, templateHolder, productId, creationId}) {
			templateHoldersService.removeTemplateHolder({ id, creationId, productId, templateHolder});
		},
		updateTemplate({commit}, {id, type, key, value, templateId, creationId, productId}) {
			templateHoldersService.updateTemplateValues({id, type, key, value, templateId, creationId, productId})
		},
		updateHolder({commit, state}, {id, type, key, value, templateId}) {
			templateHoldersService.updateTemplateHolder({type, id, key, value, templateId})
		},
		updateHolderByCreationId({commit, state}, {id, type, key, value, creationId}) {
			templateHoldersService.updateTemplateHolderByCreation({type, id, key, value, creationId})
		},
		updateHolderByProductId({commit, state}, {id, type, key, value, productId}) {
			templateHoldersService.updateTemplateHolderByProduct({type, id, key, value, productId})
		},
		whenCreationDestroyed({rootGetters}, {creationModel, templateHolder}) {
			const projectId = rootGetters['currentProject/projectId'];
			const thumbnailCreationId = rootGetters['currentProject/thumbnailCreationId'];
			const firstCreationInProjectId = rootGetters['currentProject/firstCreationInProjectId'];
			projectPreviewService.calculateIfProjectThumbnailGenerationNeeded({
				projectId,
				creationModel,
				templateHolder,
				firstCreationInProjectId,
				thumbnailCreationId
			})
		},
		selectedCreation({commit}, creationModel) {
			commit("SET_CURRENT_CREATION_MODEL", creationModel)
		},
		setNewCreationsSettings( {commit, state, getters}, settingsByCreationId) {
			const currentCreationSettings = settingsByCreationId[state.selectedCreation.id];
			if(currentCreationSettings) {
				commit("UPDATE_CURRENT_CREATION_MODEL_SETTINGS", currentCreationSettings)
			}
			// Update other creations if we got all to all settings.
			// @business_rule:
			// all-to-all template of particular size but with different products - should mirror template settings.
			// TODO: check (after productData changing per creation - this should be applied despite a-to-a connection)
			// if(getters.isCurrentConnectionAllToAll) {
				templateHoldersService.setCreationsSettings(settingsByCreationId)
			//}
		},
		cleanCurrentCreationSettings({dispatch}, settingsByCreationId) {
			// Clean logic is the same as settingNew:
			dispatch('setNewCreationsSettings', settingsByCreationId);
		},
		setNewConnectionSettings({commit, state}, {templateId, settingsByConnections}) {
			templateHoldersService.setConnectionSettings(templateId, settingsByConnections);
		},
		setNewConnectionSettingsByProduct({commit, state}, {productId, settingsByConnections}) {
			templateHoldersService.setConnectionSettingsByProduct(productId, settingsByConnections);
		},
		refreshWhenAllToAll({commit}) {
			commit('SET_REFRESH_ALL_TO_ALL', true)
		}
	},
};
