<template>
  <div class="scroll" style="height: calc(100vh - (128px + 16px));">
    <gallery-items-table
        :is-loading="isLoading"
        :current-page="currentPage"
        :total-items="totalItems"
        :gallery-items="galleryCollection"
        :query-text="searchText"
        :can-edit="false"
        :show-campaign-column="true"
        archive-action-name="Unarchive"
        @pageChanged="handlePaginationChange($event)"
        @itemArchive="handleItemUnArchive($event)"
        @itemRemove="handleItemRemove($event)"
    />
  </div>
</template>

<script>
import GalleryItemsTable from "@/components/gallery/components/GalleryItemsTable.vue";
import {galleryService} from "@/services/gallery.service";
import {galleryItemMapper, hasGalleryItemExpired, ITEM_EXPIRE} from "@/components/gallery/model/gallery-item";
import {mapActions} from "vuex";

export default {
  name: 'ArchivedGalleryPage',
  components: {GalleryItemsTable},
  props: {
    newUploadedFile: {
      type: [Object, Array],
      default: () => []
    },
    searchText: {
      type: String,
      default: ''
    },
    sortBy: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      galleryCollection: [],
      isLoading: true,
      currentPage: 0,
      totalItems: 0,
      req: {
        getAllArchived: galleryService.getAllArchived(),
      }
    }
  },
  async mounted() {
    await this.loadArchivedGallery();
  },
  methods: {
    ...mapActions(['showAlertSuccess', 'showAlertError']),
    async handleItemUnArchive(galleryItem) {
      const onConfirm = async () => {
        try {
          await galleryService.unArchiveGalleryCampaignItem(galleryItem.id)
          await this.loadArchivedGallery();
          await this.showAlertSuccess(`Item ${galleryItem.name} unarchived.`)
        } catch (e) {
          console.error(e);
        }
      }
      if(hasGalleryItemExpired(galleryItem.expires.at) === ITEM_EXPIRE.EXPIRED) {
        // You must change date if item is expired.
        this.$emit('itemUnarchive', {galleryItem, onConfirm})
      } else {
        await onConfirm()
      }
    },
    async handleItemRemove(galleryItem) {
      try {
        await galleryService.removeItem(galleryItem.id)
        this.showAlertSuccess(galleryItem.name + ' removed.')
        await this.loadArchivedGallery();
      } catch (e) {
        this.showAlertError(galleryItem.name + ' cannot be removed.')
      }
    },
    handlePaginationChange(current) {
      this.currentPage = current;
      this.loadArchivedGallery()
    },
    async loadArchivedGallery() {
      this.isLoading = true;
      this.req.getAllArchived = galleryService.getAllArchived(
          this.searchText,
          {page: this.currentPage, limit: this.pageSize},
          {orderBy: this.sortBy.current, orderDir: this.sortBy.direction }
      );
      try {
        const { results, total } = await this.req.getAllArchived.call();
        this.galleryCollection = results.map(galleryItemMapper)
        this.totalItems = total;
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false;
      }
    }
  },
  beforeDestroy() {
    this.req.getAllArchived.cancel();
  },
  watch: {
    searchText() {
      this.currentPage = 0;
      this.loadArchivedGallery()
    },
    sortBy() {
      this.loadArchivedGallery()
    },
    newUploadedFile() {
      this.loadArchivedGallery()
    }
  }
}
</script>

<style scoped lang="less">

</style>
