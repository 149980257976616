<template>
    <div class="empty-card">
        <span class="empty-card__plus">
            <a-icon type="plus"/>
        </span>
      Add project
    </div>
</template>

<script>
export default {

}
</script>
