<template>
    <div class="banner" style="visibility: hidden">
      <template-holder
          v-for="{creationModel, renderCallback} of renderQueue"
          :key="creationModel.id"
          :creationModel="creationModel"
          :listenToChanges="false"
          :skip-mirror-register="true"
          :mount-callback="renderCallback"
      />
    </div>
</template>

<script>
import TemplateHolder from '@/components/project/components/TemplateHolder';
import { useSelectedCreations } from '@/store/selected-creations';
import { mapState } from 'pinia'

export default {
  name: 'InvisibleRenderQueue',
  components: { TemplateHolder },
  computed: {
    ...mapState(useSelectedCreations, ['renderQueue'])
  }
};
</script>

<style scoped>

</style>
