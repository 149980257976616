<template>
  <div>
      <div v-for="group in editableValues" :key="group.tempId" style="margin-bottom: 1em">
        <div style="display: flex">
          <a-input v-model="group.name" placeholder="group name or leve empty if no groups" @blur="valuesUpdate" />
          <color-picker
              style="width: 40%"
              placeholder-text="pick..."
              input-class="no-radius"
              :is-resettable="false"
              :transparency="hasTransparency"
              v-model="group.currentColor"
          />
          <a-button class="bordered-link grouped" title="Add chosen color" :disabled="!Boolean(group.currentColor.trim())" size="small" type="link" @click="handleAddColorToGroup(group.tempId, group.currentColor)">
            <a-icon type="plus"></a-icon>
          </a-button>

          <a-popconfirm
              title="Sure to remove this color group?"
              ok-text="Remove"
              cancel-text="Cancel"
              @confirm="handleRemoveGroup(group.tempId)"
          >
            <a-button v-if="!hasOneGroup" class="bordered-link" title="Remove group" size="small" type="link">
              <a-icon type="delete"></a-icon>
            </a-button>
          </a-popconfirm>
        </div>
        <div style="display: flex">
          <a-select style="width: 100%" mode="tags" v-model="group.values" @change="handleNewValuesChanged($event, group)">
            <a-select-option
                v-for="(color, idx) of computeUnique(groupValues[group.name], group.values)"
                :key="color+idx"
                :value="color"
                style="display: flex"
            >
              <span class="preview-color" :style="{backgroundColor: color}" /> {{color}}
            </a-select-option>
          </a-select>
          <a-button class="bordered-link grouped"
                    size="small"
                    type="link"
                    :disabled="group.values.length === 0"
                    @click="handleCopyToClipboard(group.name, group.values)"
          >
            <a-icon type="copy"></a-icon>
          </a-button>
          <a-button class="bordered-link" size="small" type="link" @click="handleMakeNewGroupClick">
            <a-icon type="folder-add"></a-icon>
          </a-button>
        </div>
      </div>
  </div>
</template>

<script>

import { hasNoColorValue, REGEXP_COLORS_MATCH_ALL } from '@/components/shared/colors.helper';
import ColorPicker from '@/components/shared/form/ColorPicker';
import { possibleColorGroups } from '@/components/templates/possible-values/possible-color-groups';

const MIN_NO_OF_GROUP_NAME = 3

export default {
  name: 'PossibleValuesAdderGroupColorValues',
  components: { ColorPicker },
  props: {
    groupValues: {
      type: [Array, Object],
      default: () => ({})
    },
    hasTransparency: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      groupNames: [],
      editableValues: []
    }
  },
  computed: {
    hasOneUnnamedGroup() {
      return this.hasOneGroup && !this.editableValues[0].name?.trim()
    },
    hasOneGroup() {
      return this.editableValues.length === 1
    }
  },
  methods: {
    valuesUpdate() {
      if(this.hasOneUnnamedGroup) {
        this.$emit('valuesUpdate', this.editableValues[0].values)
      } else {
        const asObject = {};
        for(const {name, values, order} of this.editableValues.filter(({values}) => Array.isArray(values))) {
          if(!asObject[name]) {
            asObject[name] = { values: [] }
          }
          asObject[name] = { order, values: [...asObject[name].values, ...values]}
        }
        this.$emit('valuesUpdate', asObject)
      }
    },
    handleAddColorToGroup(tempId, color) {
      if(!(color||'').trim()) {
        return
      }
      const group = this.editableValues.find(g => g.tempId === tempId);
      if(group) {
        group.values.push(color)
        group.currentColor = ''
        this.valuesUpdate()
      }
    },
    handleRemoveGroup(tempId) {
      this.editableValues = this.editableValues.filter(g => g.tempId !== tempId);
      this.valuesUpdate()
    },
    handleMakeNewGroupClick() {
      const [ highestOrder = 0 ] = this.editableValues.map(({order}) => order).sort((a, b) => b - a)
      const group = possibleColorGroups.makeColorGroupEntry(highestOrder + 1)({})
      this.editableValues.push(group)
      return group
    },
    handleCopyToClipboard(name, values) {
      const computedName = name ? `${name}: ` : ''
      navigator.clipboard.writeText(computedName + values.toString());
    },
    handleNewValuesChanged(currentValues, group) {
      const lastElement = currentValues[currentValues.length-1];
      if(!lastElement) {
        return this.valuesUpdate()
      }
      const colors = lastElement.split(REGEXP_COLORS_MATCH_ALL)
          .filter(Boolean)
          .map(s => s.trim().length <= MIN_NO_OF_GROUP_NAME ?
              s.replace(/[,.;: ]/g, '').trim()
              :
              hasNoColorValue(s) ?
                  s.replace(/[:,]/g, '').trim()
                  :
                  s
          )
          .filter(Boolean)
      if(colors.length <= 1) {
        return this.valuesUpdate()
      }
      let currentKey = ''
      const colorObj = colors.reduce((obj, possibleColor) => {
        if(hasNoColorValue(possibleColor)) {
          currentKey = possibleColor.trim()
        } else {
          obj[currentKey] = [...(obj[currentKey]||[]), possibleColor]
        }
        return obj
      }, {})

      group.values.pop()
      for(const [name, values] of Object.entries(colorObj)) {
        if(name === '')  {
          group.values = this.computeUnique(group.values, values)
        } else if(!group.name.trim()) {
          group.name = name
          group.values = this.computeUnique(group.values, values)
        } else {
          const oldGroup = this.editableValues.find(g => g.name === name);
          if(oldGroup) {
            oldGroup.values = this.computeUnique(oldGroup.values, values)
          } else {
            const newGroup = this.handleMakeNewGroupClick()
            newGroup.name = name
            newGroup.values.push(...values)
          }
        }
      }
      this.valuesUpdate()

    },
    computeUnique(colorGroup = [], colorGroup2 = []) {
      return Array.from(new Set([
        ...possibleColorGroups.entryToArrayAdapter(colorGroup),
        ...possibleColorGroups.entryToArrayAdapter(colorGroup2)
      ]))
    }
  },
  watch: {
    groupValues: {
      immediate: true,
      handler(newVal) {
        const entryFactory = possibleColorGroups.makeColorGroupEntry();
        if(Array.isArray(newVal)) {
          this.editableValues = [entryFactory({name: '', entry: newVal})]
        } else {
          this.editableValues = Object.entries(newVal || {})
              .sort(possibleColorGroups.sortEntriesBasedOnOrder)
              .map(([name, entry]) => entryFactory({name, entry}))
        }
      }
    }
  }
};

</script>

<style scoped lang="less">
.preview-color {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  vertical-align: middle;
}
.bordered-link  {
  background-color: transparent !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 5px 18px;
  height: 32px;
  &.grouped {
    border-radius: 0;
  }
}
</style>
