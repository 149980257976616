<template>
    <div
        class="sidebar"
        :class="{ 'is-collapsed': collapsed }"
        @click="clickOutside"
    >
        <div class="sidebar__main">
            <div class="sidebar__header" @click="toggleCollapsed">
                <p v-if="!collapsed">Bindings</p>
                <div
                    v-if="!collapsed && allSelectedCreationsCount > 0"
                    style="margin-left: auto; margin-right: 1em"
                >
                    <a-badge
                        :count="allSelectedCreationsCount"
                        :overflow-count="Infinity"
                        :number-style="{
                            backgroundColor: '#e5580b',
                            padding: '3px 6px',
                            color: '#0a0a0a',
                            fontSize: '12px',
                        }"
                    />
                </div>
                <a-icon
                    @click.stop="toggleCollapsed"
                    style="font-size: 20px"
                    :type="collapsed ? 'menu-unfold' : 'menu-fold'"
                />
            </div>
            <div class="sidebar__content scroll">
                <a-menu
                    mode="inline"
                    theme="dark"
                    class="sidebar__menu"
                    :inline-collapsed="collapsed"
                    v-if="connections.length || hasAllToAll"
                    :selectedKeys="[selectedConnectionId]"
                >
                    <a-menu-item
                        v-if="hasAllToAll"
                        @click="handleSelectConnection(CONNECTION_ALL_TO_ALL)"
                        :key="CONNECTION_ALL_TO_ALL"
                        class="sidebar__item"
                        :class="{ 'is-warning': getAllWarnings() }"
                    >
                        <a-badge
                            :style="selectedCountBadgeStyle"
                            :count="
                                countConnectionCreations(CONNECTION_ALL_TO_ALL)
                            "
                            :overflow-count="Infinity"
                            :number-style="{
                                backgroundColor: '#e5580b',
                                padding: '3px 6px',
                                color: '#0a0a0a',
                                fontSize: '12px',
                            }"
                        />
                        <a-badge class="sidebar__badge">
                            <a-icon
                                #count
                                type="copy"
                                style="margin-top: 8px; margin-left: 8px"
                            />
                        </a-badge>
                        <span class="sidebar__name">
                            All templates x All products
                        </span>
                    </a-menu-item>

                    <a-menu-item
                        v-for="(connection, index) in connections"
                        :key="connection.id"
                        @click="handleSelectConnection(connection.id)"
                        class="sidebar__item"
                        :class="{ 'is-warning': getWarning() }"
                    >
                        <a-badge
                            :style="selectedCountBadgeStyle"
                            :count="countConnectionCreations(connection.id)"
                            :overflow-count="Infinity"
                            :number-style="{
                                backgroundColor: '#e5580b',
                                padding: '3px 6px',
                                color: '#0a0a0a',
                                fontSize: '12px',
                            }"
                        />
                        <div class="sidebar__badge">
                            <a-badge
                                :count="index + 1"
                                :overflow-count="Infinity"
                            />
                        </div>
                        <div
                            v-if="!collapsed"
                            class="sidebar__name"
                            :title="connection.name"
                        >
                            <div
                                class="sidebar__name__infobox"
                                v-if="hasProduct(connection)"
                            >
                                <locale-picker
                                    v-if="getProductMeta(connection).lang"
                                    display-type="flag-only"
                                    v-model="getProductMeta(connection).lang"
                                    :show-label="false"
                                    :read-only="true"
                                />
                                <div
                                    v-if="getProductMeta(connection).currency"
                                    v-text="getProductMeta(connection).currency"
                                />
                            </div>
                            <div v-else class="sidebar__name__infobox">
                                <locale-picker
                                    v-if="getConnectionMeta(connection).lang"
                                    display-type="flag-only"
                                    value-range="lang-only"
                                    v-model="getConnectionMeta(connection).lang"
                                    :show-label="false"
                                    :read-only="true"
                                />
                            </div>
                            {{ trimNameOverflow(connection.name) }}
                        </div>
                        <div v-if="collapsed" class="sidebar__name">
                            <strong>{{ connection.name }}</strong>
                            <div>ID: {{ connection.id }}</div>
                            <template v-if="hasProduct(connection)">
                                <locale-picker
                                    v-model="getProductMeta(connection).lang"
                                    :read-only="true"
                                />
                                <div v-if="getProductMeta(connection).currency">
                                    Currency:
                                    {{ getProductMeta(connection).currency }}
                                </div>
                            </template>
                            <locale-picker
                                v-if="getConnectionMeta(connection).lang"
                                v-model="getConnectionMeta(connection).lang"
                                :read-only="true"
                                value-range="lang-only"
                            />
                        </div>
                    </a-menu-item>
                </a-menu>
                <div class="sidebar__empty" @click="openLinks()" v-else>
                    <p v-if="!collapsed">Add bindings to start</p>
                </div>
                <div class="sidebar__bottom">
                    <a-button
                        class="sidebar__bottom--btn"
                        type="primary"
                        @click="openLinks()"
                    >
                        <a-icon type="plus"></a-icon>
                        <span v-if="!collapsed">Add / Edit bindings</span>
                    </a-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LocalePicker from '@/components/shared/locale-picker/LocalePicker'
import { CONNECTION_ALL_TO_ALL } from '@/routes-specials'
import { useSelectedCreations } from '@/store/selected-creations'
import { mapGetters } from 'vuex'
import { mapState } from 'pinia'

export default {
    name: 'Sidebar',
    components: {
        LocalePicker,
    },
    data() {
        return {
            isForcedToOpenTray: false,
            allCreationsSelected: false,
        }
    },
    computed: {
        ...mapGetters('currentProject', [
            'connections',
            'hasAllToAll',
            'numberOfSelectedCreations',
        ]),
        ...mapState(useSelectedCreations, [
            'countConnectionCreations',
            'allSelectedCreationsCount',
            'allSelectedCreations',
        ]),
        selectedConnectionId() {
            const { connectionId } = this.$route.params
            // handle special (non id) type:
            const isAllToAll = connectionId === CONNECTION_ALL_TO_ALL
            return isAllToAll
                ? CONNECTION_ALL_TO_ALL
                : Number(connectionId) || 0
        },
        collapsed() {
            return this.isForcedToOpenTray
                ? false
                : Boolean(this.selectedConnectionId)
        },
        selectedCountBadgeStyle() {
            return 'top: -10px; right: -5px; position: absolute; z-index: 2'
        },
        CONNECTION_ALL_TO_ALL() {
            return CONNECTION_ALL_TO_ALL
        },
    },
    methods: {
        openLinks() {
            this.isForcedToOpenTray = false
            this.$router.push({ name: 'edit-connections' })
        },
        toggleCollapsed() {
            this.isForcedToOpenTray = !this.isForcedToOpenTray
        },
        clickOutside(e) {
            if (!e.target.closest('.sidebar__main')) {
                this.toggleCollapsed()
            }
        },
        async handleSelectConnection(connectionId) {
            const { projectId } = this.$route.params
            await this.$router.push({
                name: 'project-connection',
                params: { projectId, connectionId },
            })
            this.isForcedToOpenTray = false
        },
        getTempWarnings(temp) {
            return (
                temp &&
                (!temp.fontColor ||
                    !temp.backgroundColor ||
                    !temp.oneLine ||
                    !temp.moreLines)
            )
        },
        getProdWarnings(prod) {
            return prod && (!prod.name || !prod.seller || !prod.price)
        },
        hasProduct(connection) {
            return Boolean(connection?.metadata?.product)
        },
        getProductMeta(connection) {
            return connection?.metadata?.product || connection?.metadata || {}
        },
        getConnectionMeta(connection) {
            return connection?.metadata || {}
        },
        hasLang(connection) {
            return Boolean(
                connection?.metadata?.product?.lang ||
                    connection?.metadata?.lang
            )
        },
        trimNameOverflow(name) {
            const trimmed = name.slice(0, 42)
            const isLarge = trimmed.length < name.length
            return isLarge ? `${trimmed}...` : name
        },
        // eslint-disable-next-line no-unused-vars
        getWarning() {
            return false
        },
        getAllWarnings() {
            return false
        },
    },
}
</script>

<style lang="less">
.sidebar {
    position: fixed;
    top: 64px;
    bottom: 0;
    left: 0;
    width: 480px;
    z-index: 20;
    background-color: @gray-1;
    border-right: solid 1px @gray-4;
    transition: width 0.3s ease-in-out;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 480px;
        width: calc(100vw - 480px);
        background-color: #000;
        transition: opacity 0.2s ease-in-out;
        transition-delay: 0.2s;
        opacity: 0.8;
    }

    &.is-collapsed {
        width: 64px;

        &::after {
            width: 0;
            left: 64px;
            opacity: 0;
        }
    }

    &__main {
        height: 100%;
    }

    &__empty {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        color: @gray-7;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        // text-decoration: underline;
        cursor: pointer;
    }

    &__content {
        background-color: @gray-1;
        height: calc(100% - 64px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow-x: hidden;
    }

    &__bottom {
        display: flex;
        align-items: center;
        background-color: @gray-2;
        height: 64px;
        padding: 0 16px;
        box-shadow: 0 1px 0 0 @gray-4 inset;

        &--btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 16px;
        font-size: 16px;
        font-weight: 600;
        height: 64px;
        background-color: @gray-2;
        cursor: pointer;
        box-shadow: 0px -1px 0px 0px @gray-4 inset;
        .is-collapsed & {
            width: 63px;
            justify-content: center;
        }
    }

    &__menu {
        margin-top: 4px;
    }

    &__item {
        .anticon {
            position: static;
            transform: none;
        }
        //padding: 0;
        overflow: visible !important;
    }

    &__selected_badge {
    }

    &__badge {
        margin-right: 10px;
    }

    &__name {
        font-weight: 400;

        .sidebar & {
            margin-left: 12px;
        }

        &__infobox {
            display: flex;
            font-size: 10px;
            margin-right: 10px;
        }
    }

    .ant-menu {
        padding: 8px 16px;
    }

    .ant-menu-dark {
        background-color: @gray-1;
        // border-right: solid 1px @gray-4;
    }

    .ant-menu-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 48px;
        line-height: 22px;
        background-color: @gray-2;
        border: solid 1px @gray-4 !important;
        border-radius: 6px;
        color: @gray-10;

        /*
            &:first-child {
                background-color: @blue-1 !important;
                border: 1px solid @blue-5 !important;
                & .ant-badge-count {
                    background: @blue-5;
                    box-shadow: none !important;
                }
            }
            */

        &-selected.is-warning {
            background-color: @gray-2 !important;
            border: 1px solid #d89614 !important;

            & .ant-badge-count {
                background: #d89614;
                box-shadow: none !important;
            }
        }

        &-selected {
            background-color: @primary-1 !important;
            border: 1px solid @primary-7 !important;

            & .ant-badge-count {
                background: @primary-6;
                box-shadow: none !important;
            }
        }
    }

    .ant-menu-inline-collapsed {
        width: 63px;
        padding: 8px !important;

        .ant-menu-item {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 48px;
            line-height: 22px;
            padding: 0 8px !important;
        }

        .sidebar__name {
            display: none;
        }
    }
}
</style>
