<template>
  <div v-if="notFrozenAndWithoutPossibleValues">
    <p class="form__label">Background color: {{ identifier }}</p>
    <a-form-item>
      <color-picker
          v-model="background"
          :transparency="true"
          :possibleValues="possibleColorValues"
          :allow-to-pick-any-color="!isFrozen"
          @change="handleInputChange($event)"
          @reset="handleInputReset()"
      />
    </a-form-item>
  </div>
</template>

<script>
import { possibleValuesMixin } from '@/components/project/components/form/possible-values.mixin';
import ColorPicker  from '@/components/shared/form/ColorPicker';
import { TemplateBackground } from '@/templating/TemplateBackground';

const KEY_BACKGROUND = 'background';
const KEY_COLOR = 'color'; // This is @Legacy mismatch (between TControl and default values in template)

export default {
  name: 'FormBackground',
  props: {
    identifier: {
      type: String,
      required: true
    },
    templateControl: {
      type: TemplateBackground,
      required: true
    },
    settings: {
      default: () => ({})
    },
    serverDefinedFrozenFields: {
      default: () => ({})
    }
    // serverDefinedPossibleValues from Mixin !
  },
  components: {
    ColorPicker
  },
  mixins: [ possibleValuesMixin ],
  data() {
    return {
      background: this.settings?.[KEY_BACKGROUND]
    }
  },
  computed: {
    isFrozen() {
      return Boolean(this.serverDefinedFrozenFields?.color)
    },
    possibleColorValues() {
      return this.possibleValues?.[KEY_BACKGROUND] || this.inTemplatePossibleValues?.[KEY_COLOR] || [];
    },
    // @Business: Control should NOT be displayed when it is FROZEN nad without possibleValues
    notFrozenAndWithoutPossibleValues() {
      return !(this.isFrozen && this.possibleColorValues.length === 0)
    }
  },
  methods: {
    handleInputChange(value) {
      this.$emit('change', {type: this.templateControl.type, id: this.identifier, key: KEY_BACKGROUND, value})
    },
    handleInputReset() {
      this.$emit('reset', {type: this.templateControl.type, id: this.identifier, key: KEY_BACKGROUND })
    }
  },
  watch: {
    settings(newValue) {
      if(KEY_BACKGROUND in newValue && this.background !== newValue.background) {
        this.background = newValue.background;
      }
    }
  }
};
</script>

<style scoped lang="less">

</style>
