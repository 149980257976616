<template>
    <li
        class="temp-card"
        :class="{ 'is-selected': isSelected }"
        @click="handleSelect"
    >
        <div class="temp-card__top">
            <div class="temp-card__panel">
                <a-checkbox
                    @click.native.prevent
                    @change="handleSelect"
                    :checked="isSelected"
                />
                <a-icon type="star" v-if="isFavourite" theme="filled" />
            </div>
            <div class="temp-card__img" :style="imgStyle" />
        </div>
        <div class="temp-card__bottom">
            <p class="temp-card__title">{{ template.name }}</p>
            <p class="temp-card__desc">
                Template description: {{ template.description }}
            </p>
        </div>
    </li>
</template>

<script>
import { resolveTemplateGraphicUrl } from '@/view-helpers/template-preview-resolver'

export default {
    name: 'TempCard',
    props: {
        template: Object,
        isFavourite: {
            type: Boolean,
            default: false,
        },
        isSelected: Boolean,
    },
    computed: {
        imgStyle() {
            const { id, thumbnail, updated_at } = this.template
            return {
                backgroundImage: resolveTemplateGraphicUrl(
                    id,
                    thumbnail,
                    updated_at
                ),
            }
        },
    },
    methods: {
        handleSelect() {
            this.$emit('select')
        },
    },
}
</script>

<style lang="less">
.temp-card {
    &:nth-child(2n + 1) {
        margin-right: 16px;
    }

    margin-bottom: 16px;
    width: calc(50% - 8px);
    background-color: @gray-2;
    border: solid 1px @gray-4;
    border-radius: 6px;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:hover {
        border-color: @gray-6;
    }

    &.is-selected {
        border-color: @primary-6;
    }

    &__img {
        margin-top: 16px;
        width: 100%;
        height: 75%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__top {
        padding: 16px;
        height: 180px;
    }

    &__panel {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__bottom {
        border-radius: 0 0 6px 6px;
        padding: 16px;
        background-color: @gray-1;
        flex: 1;
    }

    &__title {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: @gray-9;
    }

    &__desc {
        margin-top: 4px;
        font-weight: 400;
        color: @gray-7;
    }
}
</style>
