import { pseudoJSONToObjectParse } from '@/templating/pseudo-json-to-object';

const REST_LANG = Symbol(); // key to represent all languages not present in possibleValues

export class PossibleValuesProvider {

	constructor(ref) {
		this.ref = ref;
		this.values = {
			[REST_LANG]: {}
		};
		this.setup();
	}

	setup() {
		const { possibleValues } = this.ref.dataset;
		if(!possibleValues) {
			return;
		}
		const valueObj = pseudoJSONToObjectParse(possibleValues);
		const setups = Array.isArray(valueObj) ? valueObj : [valueObj];
		for( const setup of setups ) {
			const { lang, ...config } = setup || {};
			const languages = (lang || '').toLowerCase().split(/[;, ]/);
			for(const lan of languages) {
				this.values[lan || REST_LANG] = config;
			}
		}
	}

	computeValues(lang) {
		return this.values[lang] || this.values[REST_LANG];
	}

	langValues(lang) {
		return this.values[lang] || {};
	}

	fallbackValues() {
		return this.values[REST_LANG] || {};
	}
}
