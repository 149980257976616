<template>
    <a-modal
        v-model="visible"
        :title="title"
        @ok="saveNewName()"
        @cancel="$emit('closeRenameModal')"
    >
        <p class="form__label">{{fieldLabel}}</p>
        <a-input
            class="form__input"
            :placeholder="fieldLabel"
            v-model="newName"
            @pressEnter="saveNewName()"
            autoFocus
        />
        <slot />
        <template slot="footer">
            <a-button key="back" type="default" @click="$emit('closeRenameModal')">
              Cancel
            </a-button>
            <a-button key="submit" type="primary" @click="saveNewName()">
              Save
            </a-button>
        </template>
    </a-modal>
</template>

<script>

export default {
    props: {
        currentName: String,
        title: String,
        fieldLabel: String,
        renameRule: {
          type: Function,
          default: () => true
        }
    },
    data(){
        return {
            visible: true,
            newName: this.currentName
        }
    },
    methods: {
       saveNewName() {
         if(this.renameRule(this.newName)) {
           this.$emit('whenNewName', this.newName);
         }
       }
    }
}
</script>
