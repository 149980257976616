<template>
    <transition name="fadeIn" mode="in-out" appear>
      <s-mask>
        <div class="login">
            <a-form :form="signUpForm" class="login__box" @submit.prevent="handleSubmit">
                <img class="login__logo" src="../../assets/logo-white.svg" alt="logo">

                <div style="margin: 10px">
                  <environment-marker />
                </div>

                <div v-if="isRegistrationFormSent">
                  Your registration form has been sent.
                  <p>
                    Please check you e-mail box to verify account.
                  </p>
                </div>

                <template v-else>

                  <p class="login__title">Create account </p>

                  <p class="login__label">Email</p>
                  <a-form-item>
                    <a-input class="login__input" placeholder="Email"
                             v-decorator="['email', { rules: [{ required: true, message: 'Enter valid e-mail address' }] }]"/>
                  </a-form-item>

                  <a-alert
                      v-if="errorMessage"
                      :message="errorMessage"
                      type="error"
                      :show-icon="true"
                      :banner="true"
                  />

                  <a-button type="primary" html-type="submit" :loading="isLoading">Create account</a-button>
                </template>
            </a-form>

            <div class="login__bottom">
                <p class="login__info">Do you have an account?</p>
                <router-link class="login__link" to="/login">Login</router-link>
            </div>

        </div>
    </s-mask>
    </transition>
</template>

<script>
import Mask from '@/components/layout/Mask';
import EnvironmentMarker from '@/components/main/components/EnvironmentMarker';
import { userService } from '@/services/user.service'
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SignupPage',
  components: {
    SMask: Mask,
    EnvironmentMarker
  },
  data(){
    return {
      signUpForm: this.$form.createForm(this),
      errorMessage: '',
      isLoading: false
    }
  },
  computed: {
    ...mapGetters('user', ['isRegistrationFormSent'])
  },
  methods: {
  ...mapActions('user', ['markRegistrationSent']),
    handleSubmit() {
      this.signUpForm.validateFields(async (err, values) => {
        if (!err) {
          const {email} = values;
          this.errorMessage = '';
          this.isLoading = true;
          try {
            await userService.signUp(email.toLowerCase().trim());
            await this.markRegistrationSent();
            await this.$router.push('/login');
          } catch (err) {
            if(err.status === 422) {
              const fields = {
                'email': {value: email, errors: err.errors.email},
              }
              this.signUpForm.setFields(fields);
            } else {
              this.errorMessage = err.message;
            }
          } finally {
            setTimeout(() => {
              this.isLoading = false
            }, 1500)
          }
        }
      });
    }
  },
}
</script>
