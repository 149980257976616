<template>
  <div style="margin-bottom: 1em">
    <a-row :gutter="[12, 12]">
      <a-col v-for="({fieldName, templateIds, variableActionTriggers}) in cssVarsMirrors" :key="fieldName" :span="12" :set="type = getVariableControlType(fieldName)">
        <code>{{fieldName}}</code> <sup>({{templateIds.length}})</sup>
        <div style="flex-grow: 1">
          <renewable-input
              v-if="type === 'text'"
              v-model="values[fieldName]"
              :placeholder="mirrorFields.getCombinedValue(fieldName, { mirrorType: 'cssVariables'})"
              @change="emitChange({value: $event.target.value, fieldName, templateIds, variableActionTriggers})"
              @reset="emitReset({fieldName, templateIds, variableActionTriggers})"
          ></renewable-input>
          <color-picker
              v-else-if="type === 'color'"
              v-model="values[fieldName]"
              :transparency="true"
              :allow-to-pick-any-color="true"
              @input="emitChange({value: $event, fieldName, templateIds, variableActionTriggers})"
              @reset="emitReset({fieldName, templateIds, variableActionTriggers})"
          />
          <renewable-select
              v-if="type === 'font'"
              v-model="values[fieldName]"
              @change="emitChange({value: $event, fieldName, templateIds, variableActionTriggers})"
              @reset="emitReset({fieldName, templateIds, variableActionTriggers})"
          >
            <a-select-option
                v-for="fontName of getMutualFonts(templateIds)"
                :key="fontName"
            >
              {{ fontName }}
            </a-select-option>
          </renewable-select>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>

import { MirrorFields } from '@/components/project/model/MirrorFields';
import ColorPicker from '@/components/shared/form/ColorPicker.vue';
import RenewableInput from '@/components/shared/form/RenewableInput.vue';
import RenewableSelect from '@/components/shared/form/RenewableSelect.vue';

export default {
  name: 'MirrorCssVariableFields',
  components: { RenewableSelect, ColorPicker, RenewableInput },
  props: {
    mirrorFields: {
      type: MirrorFields
    }
  },
  computed: {
    cssVarsMirrors() {
      return this.mirrorFields.getCssVarsMirrors();
    }
  },
  data() {
    return {
      values: {}
    }
  },
  methods: {
    getVariableControlType(name) {
      const [type] = name.split('-').reverse();
      const DEFAULT_TYPE = 'text';
      const definedTypes = [DEFAULT_TYPE, 'color', 'font']
      return definedTypes.includes(type) ? type : DEFAULT_TYPE;
    },
    emitChange({value, fieldName, templateIds, variableActionTriggers}) {
       this.$emit('change', {value, fieldName, templateIds, variableActionTriggers, type: 'cssVariables', key: 'value'})
    },
    emitReset({fieldName, templateIds, variableActionTriggers}) {
      this.$emit('reset', {fieldName, templateIds, variableActionTriggers, type: 'cssVariables'})
    },
    getMutualFonts(templateIds = []) {
      let fonts = [];
      if(templateIds.length <= 1) {
        return [];
      }
      const getTemplate = (id) => this.mirrorFields.getTemplateModelById(id);
      const extractFontNames = (template) => template?.usedFontsMetadata?.names || [];
      const template = getTemplate(templateIds[0]);
      fonts.push(...extractFontNames(template));
      // Filter out font that not exist in all templates at once:
      for(let n = 1; n < templateIds.length; n++) {
        const template = getTemplate(templateIds[n]);
        const fontNames = extractFontNames(template);
        fonts = fonts.filter(name => fontNames.includes(name))
      }
      return fonts;
    }
  }
}
</script>

<style scoped lang="less">

</style>
