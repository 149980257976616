<template>
  <div>
    <a-layout-header class="sub_header">
      <router-link to=".">
        <a-button title="Go back to Campaigns">
          <a-icon type="arrow-left" />
        </a-button>
      </router-link>
      <template v-if="!hasNotFound">
        {{ campaign.name }}
        <expiration-marker style="margin-left: auto" :expired-at="campaign.expiresAt" />
        <div class="tags">
          <a-tag color="#108ee9" v-for="{ name } of campaign.tags" :key="name">
            {{ name }}
          </a-tag>
        </div>
        <a-dropdown :trigger="['click']" v-if="canModifyCampaign">
          <a-button small>
            <a-icon type="more" />
          </a-button>
          <template #overlay>
            <a-menu>
              <a-menu-item
                v-if="canEditCampaign"
                @click="editCampaignModal = true"
              >
                <span><a-icon type="edit" /> Edit</span>
              </a-menu-item>
              <a-menu-item v-if="canRemoveCampaign">
                <a-popconfirm
                  :title="`Are you sure to remove ${campaign.name} campaign?`"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="handleCampaignRemove(campaignId)"
                >
                  <span class="danger"><a-icon type="delete" /> Remove</span>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
        <a-button
          v-if="canEditCampaign"
          type="primary"
          @click="handleOpenUploadModeModel()"
        >
          <a-icon type="upload" />
          Upload {{ galleryCollection.length > 0 ? "more" : "" }}
        </a-button>
      </template>
    </a-layout-header>
    <div v-if="hasNotFound" class="campaign_not_found">
      <div class="is_404">404</div>
      Campaign with id: <code>/{{ campaignId }}</code
      >. Was not found.
    </div>
    <gallery-items-table
      class="scroll"
      style="height: calc(100vh - (128px + 16px));"
      v-else
      :is-loading="isLoading"
      :current-page="currentPage"
      :total-items="totalItems"
      :gallery-items="galleryCollection"
      :show-hashtags-column="false"
      @pageChanged="handlePaginationChange($event)"
      @itemArchive="handleItemArchive($event)"
      @itemEdit="handleItemEdit($event)"
      @itemRemove="handleItemRemove($event)"
    />
    <add-more-gallery-items-to-campaign-modal
      v-if="addMoreGalleryItemsToCampaignModal"
      :expires-at="campaign.expiresAt"
      :campaign-id="campaignId"
      :edit-model="galleryItemToEdit"
      @closeModal="addMoreGalleryItemsToCampaignModal = false"
      @fileUploadSuccess="loadCampaignGallery()"
      @galleryItemUpdated="loadCampaignGallery()"
    />
    <new-or-update-campaign-modal
      v-if="editCampaignModal"
      :edit-model="campaign"
      @closeModal="editCampaignModal = false"
      @editCampaign="handleCampaignEdit($event)"
    />
  </div>
</template>

<script>
import { galleryService } from "@/services/gallery.service";
import GalleryItemsTable from "@/components/gallery/components/GalleryItemsTable.vue";
import {
  galleryItemMapper,
  mapGalleryItemToEditModel
} from "@/components/gallery/model/gallery-item";
import { mapActions, mapGetters } from "vuex";
import AddMoreGalleryItemsToCampaignModal from "@/components/gallery/components/AddMoreGalleryItemsToCampaignModal.vue";
import NewOrUpdateCampaignModal from "@/components/gallery/components/NewOrUpdateCampaignModal.vue";
import ExpirationMarker from "@/components/gallery/components/ExpirationMarker.vue";

export default {
  name: "CampaignGalleryItemsPage",
  components: {
    ExpirationMarker,
    NewOrUpdateCampaignModal,
    AddMoreGalleryItemsToCampaignModal,
    GalleryItemsTable
  },
  props: {
    searchText: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      addMoreGalleryItemsToCampaignModal: false,
      editCampaignModal: false,
      campaign: {
        name: "...",
        tags: []
      },
      galleryCollection: [],
      isLoading: true,
      hasNotFound: true,
      currentPage: 0,
      totalItems: 0,
      req: {
        getAllForCampaign: galleryService.getAllForCampaign(0)
      },
      galleryItemToEdit: null
    };
  },
  beforeDestroy() {
    this.req.getAllForCampaign.cancel();
  },
  computed: {
    ...mapGetters("user", ["hasPermission", "allPermissions"]),
    campaignId() {
      return Number(this.$route.params.campaignId);
    },
    pageSize() {
      return 100;
    },
    canModifyCampaign() {
      return this.canEditCampaign || this.canRemoveCampaign;
    },
    canEditCampaign() {
      return this.hasPermission(
        this.allPermissions.GALLERY_CAMPAIGN_CREATE_EDIT
      );
    },
    canRemoveCampaign() {
      return this.hasPermission(this.allPermissions.GALLERY_ITEM_REMOVE);
    }
  },
  methods: {
    ...mapActions(["showAlertError", "showAlertSuccess"]),
    handleOpenUploadModeModel() {
      this.galleryItemToEdit = null;
      this.addMoreGalleryItemsToCampaignModal = true;
    },
    async handlePaginationChange(current) {
      this.currentPage = current;
      await this.loadCampaignGallery();
    },
    async handleItemEdit(galleryItem) {
      this.galleryItemToEdit = mapGalleryItemToEditModel(galleryItem);
      this.addMoreGalleryItemsToCampaignModal = true;
    },
    async handleItemArchive(galleryItem) {
      try {
        await galleryService.archiveGalleryCampaignItem(galleryItem.id);
        await this.loadCampaignGallery();
        await this.showAlertSuccess(`Item ${galleryItem.name} archived.`);
      } catch (e) {
        console.error(e);
      }
    },
    async handleCampaignRemove(campaignId) {
      try {
        await galleryService.removeCampaign(campaignId);
        await this.showAlertSuccess(`${this.campaign.name} removed.`);
        await this.$router.push(".");
      } catch (e) {
        this.showAlertError(e.message);
      }
    },
    async handleCampaignEdit({ id, name, tags, expires_at: expiresAt }) {
      this.editCampaignModal = false;
      this.campaign = {
        id,
        expiresAt,
        name,
        tags
      };
      await this.loadCampaignGallery();
    },
    async handleItemRemove(galleryItem) {
      try {
        await galleryService.removeItem(galleryItem.id);
        this.showAlertSuccess(galleryItem.name + " removed.");
        await this.loadCampaignGallery();
      } catch (e) {
        this.showAlertError(galleryItem.name + " cannot be removed.");
      }
    },
    async handleUpload({ file }) {
      try {
        await galleryService.uploadItem(file.originFileObj, {
          campaignId: this.campaignId
        });
        this.showAlertSuccess(`Photo: ${file.originFileObj.name} uploaded.`);
        await this.loadCampaignGallery();
      } catch (error) {
        await this.showAlertError(
          `Cannot upload file: ${file.originFileObj.name} | ${error.message}`
        );
      }
    },
    async loadCampaignGallery() {
      this.isLoading = true;
      this.hasNotFound = false;
      this.req.getAllForCampaign = galleryService.getAllForCampaign(
        this.campaignId,
        this.currentPage,
        this.pageSize
      );
      try {
        const {
          results,
          total,
          name,
          tags,
          expiresAt
        } = await this.req.getAllForCampaign.call();
        this.campaign = {
          id: this.campaignId,
          expiresAt,
          name,
          tags
        };
        this.galleryCollection = results.map(galleryItemMapper);
        this.totalItems = total;
      } catch (err) {
        if (err.status === 404) {
          this.hasNotFound = true;
        } else {
          console.error(err);
        }
      } finally {
        this.isLoading = false;
      }
    }
  },
  watch: {
    campaignId: {
      handler(newId) {
        if (newId) {
          this.loadCampaignGallery();
        }
      },
      immediate: true
    }
  }
};
</script>

<style scoped lang="less">
.sub_header {
  background-color: @gray-2;
  border-bottom: solid 1px @gray-4;
  padding: 0 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5em;
  font-weight: 600;
  font-size: 20px;
}

.tags {
  display: flex;
  align-items: center;
}

.campaign_not_found {
  padding: 3em;
  text-align: center;
  font-size: 18px;
  .is_404 {
    color: crimson;
    margin: 1em;
    font-size: 36px;
  }
}
</style>
