import { axInstance } from '@/services/ax-instance';

const BASE_FAV_TEMPLATE_URI = '/favourite-templates';


export const favouriteTemplatesService = {
	getAll(searchQuery) {
		let searchParam = {}
		if(Object.keys(searchQuery).length) {
			searchParam = searchQuery
		}
		return axInstance.get(BASE_FAV_TEMPLATE_URI, { params: { ...searchParam }})
	},
	markFavourite({ templateId, isFavourite }) {
		if( isFavourite ) {
			return axInstance.post(BASE_FAV_TEMPLATE_URI, { templateId });
		}
		return axInstance.delete(`${BASE_FAV_TEMPLATE_URI}/${templateId}`);
	}
};

