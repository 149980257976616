import { templatePossibleValuesService } from '@/services/template-possible-values.service';

export class PossibleValuesExtractor {

	constructor(possibleValues, language = 'pl') {
		this.possibleValues = possibleValues || {}
		this.language = language
	}

	get currentLangPossibleValues() {
		return templatePossibleValuesService.getPossibleValuesForShape({
			lang: this.language,
			values: this.possibleValues
		}) || {}
	}

	getPossibleValuesForKey(key, field, innerField) {
		const firstLevel = this.currentLangPossibleValues[key] || {};
		const secondLevel = firstLevel[field] || {};
		return secondLevel[innerField]
	}
}
