<template>
    <section :data-id="dataId" :style="inlineStyle" style="overflow: hidden">
        <div
            v-if="isLoading"
            class="display-flex-center"
            :style="{ height: height + 'px' }"
        >
            <inline-preloader text="Attaching banner..."></inline-preloader>
        </div>
        <div ref="mountPoint"></div>
    </section>
</template>

<script>
import InlinePreloader from '@/components/project/components/InlinePreloader'
import {
    modifyTemplateCloneIndex,
    modifyTemplateOrientationClass,
    modifyTemplateSize,
} from '@/components/project/model/creation-on-dom-modifiers'
import { Template } from '@/templating/Template'
import { mapActions } from 'vuex'
import { mapActions as mapPActions } from 'pinia'
import { CreationModel } from '@/components/project/model/CreationModel'
import inViewport from 'vue-in-viewport-mixin'
import { useGalleryCache } from '@/store/modules/gallery-cache.store'

export default {
    name: 'TemplateHolder',
    components: { InlinePreloader },
    props: {
        creationModel: CreationModel,
        listenToChanges: {
            type: Boolean,
            default: false,
        },
        mountCallback: {
            type: Function,
            default: () => () => {},
        },
        skipMirrorRegister: {
            type: Boolean,
            default: false,
        },
        transformWhenReady: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            isLoading: true,
        }
    },
    computed: {
        dataId() {
            return `${this.width}x${this.height}`
        },
        inlineStyle() {
            return { width: `${this.width}px`, height: `${this.height}px` }
        },
        width() {
            return Number(this.creationModel.width) || 300
        },
        height() {
            return Number(this.creationModel.height) || 250
        },
        format() {
            return this.creationModel.format || 'mobile'
        },
        templateModel() {
            return this.creationModel.template
        },
        frozenFields() {
            return this.templateModel.frozenFields
        },
    },
    methods: {
        ...mapActions('connectionTemplate', [
            'setMirrorTemplate',
            'removeMirrorTemplate',
            'whenCreationDestroyed',
        ]),
        ...mapPActions(useGalleryCache, ['assureFileUrlsPresentFromSettings']),
        mountTheTemplate() {
            this.templateHolder.assureMountedOnDOM()
        },
        setupChangesReactivity(templateHolder, htmlTemplate) {
            if (this.listenToChanges) {
                setTimeout(() => {
                    // @hacky: Timeout needed due to movable refresh
                    // @Fix: increased to 800ms
                    templateHolder.setupReactivity(this.frozenFields)
                }, 800)
                htmlTemplate.addEventListener('data-changed', ev => {
                    this.$emit('dataChanged', ev.detail)
                })
            }
        },
        applyStyle() {
            if (!this.templateHolder.isMounted() || !this.transformWhenReady) {
                return
            }
            // Fire ONLY when isMounted (on DOM);
            // this.$nextTick might be NOT ENOUGH for delay! | used 20 ms timeout instead
            setTimeout(() => {
                const { mountPoint } = this.$refs
                for (const [key, value] of Object.entries(
                    this.transformWhenReady
                )) {
                    mountPoint.style[key] = value
                }
                const { height } = mountPoint.getBoundingClientRect()
                this.$emit('transformApplied', { height })
            }, 20)
        },
    },
    mounted() {
        const { templateModel, creationModel } = this
        const { id } = templateModel
        const creationId = creationModel.id
        const { productId } = creationModel
        this.templateHolder = new Template(templateModel.absoluteUrl, id)
        if (!this.skipMirrorRegister) {
            this.setMirrorTemplate({
                id,
                templateHolder: this.templateHolder,
                creationId,
                productId,
            })
        }
        this.templateHolder.setCreationModel(creationModel)
        this.templateHolder.assureMountedOnDOM = () => {
            if (!this.templateHolder.isNotMounted()) {
                // If it is mounted or mounting - just return current state
                return this.templateHolder.mountState
            }
            const { templateModel, creationModel } = this
            const { templateHolder } = this
            templateHolder.makeTemplateFrom(templateModel.baseHTML)
            return templateHolder
                .mount(this.$refs.mountPoint)
                .then(() => {
                    const { width, height } = this
                    const htmlTemplate = templateHolder.getTemplate()
                    modifyTemplateSize(htmlTemplate, { width, height })
                    modifyTemplateOrientationClass(htmlTemplate, {
                        width,
                        height,
                    })
                    modifyTemplateCloneIndex(
                        htmlTemplate,
                        creationModel.cloneIndex
                    )
                    templateHolder.setLocalCssVariables(
                        creationModel.cssVariables
                    )
                    templateHolder.setValues(creationModel.settings)
                    this.setupChangesReactivity(templateHolder, htmlTemplate)
                    // We need to "wait safe time" to assure all DOM "alignments" are set (eg.  templateProduct.checkPriceAlign() )
                    return new Promise(resolve =>
                        setTimeout(
                            () => resolve(this.templateHolder.mountState),
                            Template.MOUNTING_SAFE_TIME_MS
                        )
                    )
                })
                .finally(() => {
                    this.isLoading = false
                    this.applyStyle()
                })
        }
        this.mountCallback(this.templateHolder)
        // Assure gallery items present (even deleted or archived):
        this.assureFileUrlsPresentFromSettings(creationModel.settings)
    },
    mixins: [inViewport],
    watch: {
        'inViewport.now'(visible) {
            if (visible) {
                this.mountTheTemplate()
                this.removeInViewportHandlers()
            }
        },
        transformWhenReady() {
            this.applyStyle()
        },
    },
    beforeDestroy() {
        const { templateHolder, templateModel, creationModel } = this
        const { id } = templateModel
        const creationId = creationModel.id
        const { productId } = creationModel
        this.whenCreationDestroyed({ creationModel, templateHolder })
        // Free up memory + run "lifecycle hooks":
        this.removeMirrorTemplate({ id, templateHolder, creationId, productId })
        this.templateHolder.destroy()
        this.templateHolder = null
        // Assure ref removal of DOM:
        this.$refs.mountPoint.remove()
    },
}
</script>

<style scoped>
.display-flex-center {
    display: flex;
    align-items: center;
}
</style>
