<template>
  <div>
    <div v-for="[key, value] of Object.entries(values || {})" :key="key">
      <template v-if="dataShape[key] === SHAPE.cssVar">
        <possible-values-preview-table-cell
            :template-id="templateId"
            :values="mapCssValues(value, getCssFieldKey())"
            :data-shape="getCssFieldShape()"
        />
      </template>
      <template v-else>
        <possible-values-preview-table-cell-group
          :groupValues="value"
          :shape-type="dataShape[key]"
          :template-id="templateId"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { SHAPE } from '@/components/templates/possible-values/possible-values-shape';
import PossibleValuesPreviewTableCellGroup
  from './PossibleValuesPreviewTableCellGroup.vue';

export default {
  name: 'PossibleValuesPreviewTableCell',
  components: { PossibleValuesPreviewTableCellGroup },
  props: {
    values: {
      type: Object,
      default: () => ({})
    },
    dataShape: {
      type: Object,
      default: () => ({})
    },
    templateId: [String, Number],
    fieldName: String
  },
  computed: {
    SHAPE() {
      return SHAPE;
    }
  },
  methods: {
    getCssFieldKey() {
      const [, key] = this.fieldName.replace('--', '').split('-');
      return key;
    },
    getCssFieldShape() {
      const varType = this.getCssFieldKey();
      return { [varType]: Object.values(SHAPE).includes(varType) ? varType : SHAPE.text };
    },
    mapCssValues(values, key) {
      return { [key]: values };
    }
  }
};
</script>
