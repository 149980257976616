import { axInstance } from '@/services/ax-instance';

const BASE_FAV_PROJECT_URI = '/favourite-projects';


export const favouriteProjectService = {
	getAll(searchQuery, order = [], page = 0, limit = 20) {
		let searchParam = {};
		if( searchQuery ) {
			searchParam = {
				name: searchQuery
			};
		}
		let orderParam = {};
		if( order.length > 0 ) {
			const [orderBy, orderDir] = order;
			orderParam = {
				orderBy,
				orderDir
			};
		}
		return axInstance.get(BASE_FAV_PROJECT_URI, { params: { page, limit, ...searchParam, ...orderParam } });
	},
	markFavourite({ projectId, isFavourite }) {
		if( isFavourite ) {
			return axInstance.post(BASE_FAV_PROJECT_URI, { projectId });
		}
		return axInstance.delete(`${BASE_FAV_PROJECT_URI}/${projectId}`);
	}
};

