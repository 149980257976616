<template>
    <div>
        <template v-if="shapeType === SHAPE.text">
            <a-input
                v-for="(value, index) of editableValues"
                :key="value + index"
                :default-value="value"
                @input="handleValueUpdate(index, $event.target.value)"
                class="default-value-input-box"
            >
                <template #addonAfter>
                    <a-button
                        size="small"
                        type="link"
                        @click="handleRemoveValue(index, value)"
                    >
                        <a-icon type="delete"></a-icon>
                    </a-button>
                </template>
            </a-input>
            <a-input
                v-model="newValue"
                @keydown.enter="handleAddValue"
                class="default-value-input-box"
            >
                <template #addonAfter>
                    <a-button size="small" type="link" @click="handleAddValue">
                        <a-icon type="plus"></a-icon>
                    </a-button>
                </template>
            </a-input>
        </template>
        <template v-if="[SHAPE.color, SHAPE.alphaColor].includes(shapeType)">
            <div
                style="display: flex; justify-content: end; margin-bottom: .5em"
                v-if="
                    editableValues.length > 0 ||
                        Object.keys(editableValues).length > 0
                "
            >
                <a-popconfirm
                    title="Sure to remove all entries?"
                    ok-text="Remove all"
                    cancel-text="Cancel"
                    @confirm="handleUpdateColorValues([])"
                >
                    <a-button title="Remove all">
                        <a-icon type="delete" /> All
                    </a-button>
                </a-popconfirm>
            </div>
            <possible-values-adder-group-color-values
                :group-values="editableValues"
                :has-transparency="shapeType === SHAPE.alphaColor"
                @valuesUpdate="handleUpdateColorValues"
            />
        </template>
        <template v-if="shapeType === SHAPE.font">
            <a-select
                style="width: 100%"
                mode="multiple"
                v-model="editableValues"
                @change="valuesUpdated()"
                placeholder="Fonts narrowing"
            >
                <a-select-option
                    v-for="name of narrowedFontNames"
                    :key="name"
                    :value="name"
                >
                    {{ fontDisplay(name) }}
                </a-select-option>
            </a-select>
        </template>
        <template v-if="shapeType === SHAPE.image">
            <form-photos-uploader
                :file-list="editableValues"
                :max-photos="Infinity"
                :live-campaigns-as-folders="true"
                :can-select="false"
                gallery-selection-type="GALLERY_AND_CAMPAIGN"
                @removed="handleRemovePhoto($event)"
                @uploaded="$emit('fileUpload', $event)"
                @fromGallery="$emit('addFileFromGallery', $event)"
                @fromCampaigns="$emit('addFileFromCampaign', $event)"
            />
        </template>
    </div>
</template>

<script>
import FormPhotosUploader from '@/components/project/components/form/photos/FormPhotosUploader.vue'
import PossibleValuesAdderGroupColorValues from '@/components/templates/possible-values/PossibleValuesAdderGroupColorValues.vue'
import { SHAPE } from '@/components/templates/possible-values/possible-values-shape'

export default {
    name: 'PossibleValuesAdder',
    components: {
        PossibleValuesAdderGroupColorValues,
        FormPhotosUploader,
    },
    props: {
        values: {
            type: [Array, Object],
            default: () => [],
        },
        narrowedFontNames: {
            type: Array,
            default: () => [],
        },
        shapeName: {
            type: String,
        },
        shapeType: {
            type: String,
        },
    },
    data() {
        return {
            editableValues: [],
            newValue: '',
        }
    },
    computed: {
        SHAPE() {
            return SHAPE
        },
    },
    methods: {
        handleValueUpdate(idx, value) {
            this.editableValues[idx] = value
            this.valuesUpdated()
        },
        handleUpdateColorValues(object) {
            this.editableValues = object
            this.valuesUpdated()
        },
        handleRemoveValue(idx) {
            this.editableValues.splice(idx, 1)
            this.valuesUpdated()
        },
        handleAddValue() {
            if (this.newValue.trim()) {
                this.editableValues.push(this.newValue)
                this.newValue = ''
                this.valuesUpdated()
            }
        },
        valuesUpdated() {
            this.$emit('update', this.editableValues)
        },
        fontDisplay(fontName) {
            return fontName.replace(/"/g, '')
        },
        handleRemovePhoto({ file }) {
            const idx = this.editableValues.findIndex(f => f.uid === file.uid)
            this.editableValues.splice(idx, 1)
            const filePaths = this.editableValues.map(file => file.uid)
            this.$emit('update', filePaths)
        },
    },
    watch: {
        values: {
            immediate: true,
            handler(newVal) {
                this.editableValues = newVal
            },
        },
    },
}
</script>

<style lang="less">
.default-value-input-box .ant-input-group-addon {
    border-color: @gray-5;
}
</style>

<style scoped lang="less">
button.ant-btn-link {
    background-color: transparent !important;
    border: 0;
}
.bordered-link {
    border: 1px solid @gray-5 !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 5px 18px;
    height: 100%;
}
</style>
