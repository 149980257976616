import axios from 'axios'

const fontFetcher = axios.create();

fontFetcher.interceptors.response.use(r => r.data)

export const fontImporterService = {
	importFont(url){
		return fontFetcher.get(url)
	}
}
