import { render, staticRenderFns } from "./TempCard.vue?vue&type=template&id=1bbdaf76&scoped=true"
import script from "./TempCard.vue?vue&type=script&lang=js"
export * from "./TempCard.vue?vue&type=script&lang=js"
import style0 from "./TempCard.vue?vue&type=style&index=0&id=1bbdaf76&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bbdaf76",
  null
  
)

export default component.exports