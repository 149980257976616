var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"project__content"},[_c('div',{staticClass:"column-wrapper",class:{
      'edition--extended': !_vm.panelsVisibility['graphics'] && _vm.panelsVisibility['edition'],
      'edition--visible': _vm.panelsVisibility['graphics'] && _vm.panelsVisibility['edition'],
    }},[_c('a-button',{staticClass:"action",class:{'action--disabled': !_vm.panelsVisibility['edition'] },attrs:{"type":"button"},on:{"click":() => _vm.changePanelVisibility('edition')}},[(_vm.panelsVisibility['edition'])?_c('span',[_c('a-icon',{attrs:{"type":"eye"}})],1):_vm._e(),(!_vm.panelsVisibility['edition'])?_c('span',[_c('a-icon',{attrs:{"type":"eye-invisible"}})],1):_vm._e()]),_c('div',{staticClass:"column-content",class:{
        'edition--visible': _vm.panelsVisibility['graphics'] && _vm.panelsVisibility['edition'],
      }},[(_vm.connectionId)?_c('s-edition',{attrs:{"loading":_vm.areTemplatesLoading,"connectionId":_vm.connectionId,"projectId":_vm.projectId,"selected":!!_vm.connectionId,"currentLink":{},"templateModels":_vm.templateModels,"templateModelsForProducts":_vm.templateModelsForProducts,"visibility":_vm.panelsVisibility}}):_vm._e()],1)],1),_c('div',{staticClass:"column-wrapper column-wrapper--right",class:{
          'graphics--extended': _vm.panelsVisibility['graphics'] && !_vm.panelsVisibility['edition'],
          'graphics--visible': _vm.panelsVisibility['graphics'] && _vm.panelsVisibility['edition']
      }},[_c('a-button',{staticClass:"action",class:{'action--disabled': !_vm.panelsVisibility['graphics'] },attrs:{"type":"button"},on:{"click":() => _vm.changePanelVisibility('graphics')}},[(_vm.panelsVisibility['graphics'])?_c('span',[_c('a-icon',{attrs:{"type":"eye"}})],1):_vm._e(),(!_vm.panelsVisibility['graphics'])?_c('span',[_c('a-icon',{attrs:{"type":"eye-invisible"}})],1):_vm._e()]),_c('div',{staticClass:"column-content",class:{
          'graphics--visible': _vm.panelsVisibility['graphics'] && _vm.panelsVisibility['edition']
        }},[(_vm.connectionId)?_c('s-graphics',{attrs:{"loading":_vm.areCreationsLoading,"selected":!!_vm.connectionId,"connectionId":_vm.connectionId,"creations":_vm.creationModels,"visibility":_vm.panelsVisibility},on:{"exportSelectedGraphics":_vm.exportSelectedGraphics,"exportAllCreations":_vm.exportAllCreations,"exportAllLinks":_vm.exportAllLinks}}):_vm._e()],1)],1),_c('s-download-modal',{attrs:{"visible":_vm.showDownloadModal,"projectName":_vm.currentSelectedProject.name,"modal-title":_vm.downloadModalProps.title,"action-btn-text":_vm.downloadModalProps.actionText},on:{"closeDownloadModal":function($event){_vm.showDownloadModal = false},"startDownloading":_vm.startDownload}}),_c('section',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_c('div',{ref:"mountPoint"})])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }