<template>
  <a-layout-header class="sub_header">
    <menu-list
        :links="links"
    />
    <a-input-search
        class="header__search"
        style="margin-left: auto"
        enter-button
        placeholder="Search"
        v-model="searchText"
        @input="handleSearch($event)"
        :allowClear="true"
    />
    <a-dropdown>
      <a-button>
        Sort By
        <a-icon type="down"/>
      </a-button>
      <template #overlay>
        <a-menu >
          <a-menu-item @click="handleSortBy(value)" v-for="{name, value} of orderFields" :key="value">
            <p>
              {{name}}
              <sort-icon :fieldName="value" :currentField="order.current" :direction="order.direction"/>
            </p>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
    <a-button v-if="isGeneralUseView && hasPermission(allPermissions.GALLERY_CREATE)"  type="primary" @click="$emit('addNewGalleryItem')">
      <a-icon type="upload"/>
      Upload image(s)
    </a-button>
    <a-button type="primary" v-if="isCampaignsView && hasPermission(allPermissions.GALLERY_CAMPAIGN_CREATE_EDIT)" @click="$emit('addNewCampaign')">
      <a-icon type="plus"/>
      New campaign
    </a-button>
  </a-layout-header>
</template>

<script>


import MenuList from '@/components/shared/general/MenuList.vue';
import { debounce } from '@/view-helpers/debouncer';
import {mapActions, mapGetters} from "vuex";
import SortIcon from "@/components/shared/SortIcon.vue";

export default {
  name: 'GalleryPageSubHeader',
  components: {SortIcon, MenuList },
  props: {
    sortByOptions: {
      type: Object,
      default: () => ({
        current: 'created_at',
        direction: 'asc'
      })
    }
  },
  data() {
    return {
      searchText: '',
      previousSearchText: '',
      order: {
        current: 'created_at',
        direction: 'asc'
      }
    }
  },
  computed: {
    ...mapGetters('user', ['hasPermission', 'allPermissions']),
    links() {
        const baseLinks = [
          {name: 'General Use', to: '/gallery', activeExact: true},
          {name: 'Campaign', to: '/gallery/campaigns'},
        ]
        if(this.hasPermission(this.allPermissions.GALLERY_ITEM_ARCHIVE)) {
          baseLinks.push({name: 'Archived', to: '/gallery/archived'})
        }
        return baseLinks;
    },
    orderFields() {
       return [
         {name: 'Name', value: 'name'},
         {name: 'Creation date', value: 'created_at'},
         {name: 'Edition date', value: 'updated_at'},
       ]
    },
    isGeneralUseView() {
      return Boolean(this.$route.name === 'gallery')
    },
    isCampaignsView() {
      return Boolean(this.$route.name === 'gallery-campaigns')
    }
  },
  methods: {
    ...mapActions(['showAlertError', 'showAlertSuccess']),
    handleSearch: debounce(function(timer) {
      this.timeout = timer;
      if(this.previousSearchText !== this.searchText) {  // search distinct until changed
        this.$emit('searchText', this.searchText);
      }
      this.previousSearchText = this.searchText;
    }),
    handleSortBy(field) {
      if(this.order.current === field) {
        this.order.direction = this.order.direction === 'asc' ? 'desc' : 'asc';
      }
      this.order.current = field;
      this.$emit('sortBy', {...this.order});
    }
  },
  beforeDestroy() {
    if( this.timeout ) {
      clearTimeout(this.timeout);
    }
  },
  watch: {
    sortByOptions: {
      immediate: true,
      handler(newValue) {
        this.order = newValue;
      }
    }
  }
}
</script>


<style scoped lang="less">
   .sub_header {
     background-color: @gray-2;
     border-bottom: solid 1px @gray-4;
     padding: 0;
     padding-right: 24px;
     display: flex;
     justify-content: space-between;
     align-items: center;
     gap: 1em;
   }
</style>
