/**
 *  This is a dynamic version of safearea.less sizes of areas adoption:
 *
 *  static example for size 100x100:
 *  // 100x100
 * [data-id="100x100"] [ref="template"]:before,
 * [data-id="100x100"] [ref="template"]:after {
 *   width: 8px;
 * }
 * [data-id="100x100"] > div:before,
 * [data-id="100x100"] > div:after {
 *   height: 8px; left: 8px; right: 8px;
 * }
 *
 * */

const AREAS_ID = 'safe-areas'

export const safeAreasCssLoader = {
    reloadAreas(safeAreas = []) {
        removeIfAlreadyAppendedStyle()
        const cssRules = safeAreas.map(toCssRulesMapper)
        const style = document.createElement('style')
        style.setAttribute('id', AREAS_ID)
        style.append(...cssRules)
        document.head.appendChild(style)
    },
}

function removeIfAlreadyAppendedStyle() {
    const safeAreasStyles = document.querySelector(`#${AREAS_ID}`)
    if (safeAreasStyles) {
        safeAreasStyles.remove()
    }
}

function toCssRulesMapper(safeArea) {
    const { size, cuts = {} } = safeArea
    const { left = 0, top = 0, right = 0, bottom = 0, pushBottom } = cuts
    const SIZE_LEFT_RIGHT = `[data-id="${size}"] [ref="template"]`
    const SIZE_TOP_BOTTOM = `[data-id="${size}"] > div`
    let rule = ''
    if (!size) {
        return ''
    }
    if (left) {
        rule += `${SIZE_LEFT_RIGHT}:before {
          width: ${left}px;
        }\n`
    }
    if (right) {
        rule += `${SIZE_LEFT_RIGHT}:after {
          width: ${right}px;
        }\n`
    }
    if (top) {
        rule += `${SIZE_TOP_BOTTOM}:before {
          height: ${top}px; left: ${left}px; right: ${right}px;
        }\n`
    }
    if (bottom) {
        rule += `${SIZE_TOP_BOTTOM}:after {
          height: ${bottom}px; left: ${left}px; right: ${right}px;
          ${pushBottom ? `bottom: ${pushBottom}px;` : ''}
        }\n`
    }
    return rule
}
