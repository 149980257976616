<template>
  <div class="env-marker" v-if="showMarker">
    <div>
      <a-tag :color="markerColor" class="env-marker__tag">{{markerText}}</a-tag>
    </div>
    <div class="env-marker__info">
      <div>v{{packageVersion}}</div><div>build: #{{buildNo}}</div>
    </div>
  </div>
</template>

<script>
const ENV_MAPPING = {
  stage: { text: '⚠ STAGING', color: '#fdd75e'},
  demo: { text: '🚀 DEMONSTRATION', color: '#2db7f5'},
  test:  { text: '🧪 TEST', color: '#f13232'},
  production: { text: '', color: '' },
  ['undefined']: { text: '', color: '' },
}

export default {
  name: 'EnvironmentMarker',
  computed: {
    envName() {
      return process.env.VUE_APP_ENV_INFO
    },
    showMarker() {
      return this.envName && this.envName !== 'production';
    },
    markerColor() {
      return ENV_MAPPING[this.envName].color;
    },
    markerText() {
      return ENV_MAPPING[this.envName].text;
    },
    packageVersion() {
      return process.env.VUE_APP_VERSION;
    },
    buildNo() {
      return process.env.VUE_APP_BUILD_NO || 0;
    }
  }
}
</script>
<style scoped lang="less">
   .env-marker {
     display: flex;
     align-items: center;
     margin-left: 20px;
     &__tag {
       font-size: 16px;
       padding: 6px;
       color: black;
     }
     &__info {
       font-size: 12px;
       div {
         margin: 0;
         line-height: 12px;
       }
     }
   }
</style>
