<template>
  <div class="img_icon" v-if="expired.icon" :title="file.computedName">
    <a-icon theme="filled" :type="expired.icon" :style="expired.style"  />
  </div>
</template>

<script>

import {mapFileInfoToExpirationInfo} from "@/components/gallery/model/gallery-item";

export default {
  name: "IconExpirationForFile",
  props: {
    file: {
      type: Object,
      required: true
    }
  },
  computed: {
    expired() {
      return mapFileInfoToExpirationInfo(this.file);
    }
  }
}
</script>



<style scoped lang="less">
   .img_icon {
     width: 20px;
     height: 20px;
     display: flex;
     justify-content: center;
     align-items: center;
     background-color: fade(@gray-2, 40%);
     border-radius: 1em;
   }
</style>
