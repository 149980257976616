<template>
    <div style="padding: 1em" >
      <div class="not-found-box" v-if="noCampaignFound">
        <empty-box :text="`No campaign found for serach criteria ' ${searchText} '`"/>
      </div>
      <empty-box v-if="!hasCampaigns && !searchText && !isLoading" text="No campaign yet..."/>
      <a-row :gutter="[16, 16]" class="scroll" style="height: calc(100vh - (128px + 16px));">
        <a-col :xs="24" :md="12" :xl="8" :xxl="6" v-for="campaign of campaigns" :key="campaign.id">
          <campaign-card :campaign="campaign" @campaignChosen="handleCampaignChosen($event)"/>
        </a-col>
      </a-row>
    </div>
</template>

<script>
import {galleryService} from "@/services/gallery.service";
import CampaignCard from "@/components/gallery/components/CampaignCard.vue";
import EmptyBox from "@/components/layout/Empty.vue";

export default {
  name: 'CampaignsPage',
  components: { CampaignCard, EmptyBox },
  props: {
    newCreatedCampaign: {
      type: Object,
      default: () => ({})
    },
    searchText: {
      type: String,
      default: ''
    },
    sortBy: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      campaigns: [],
      isLoading: true,
      currentPage: 0,
      totalItems: 0,
      req: {
        getAllCampaigns: galleryService.getAllCampaigns()
      }
    }
  },
  mounted() {
    this.loadCampaigns();
  },
  beforeDestroy() {
    this.req.getAllCampaigns.cancel()
  },
  computed: {
    campaignId() {
      return this.$route.params.campaignId
    },
    campaignPageSize() {
      return 50;
    },
    noCampaignFound() {
      return this.searchText && !this.isLoading && this.campaigns.length === 0
    },
    hasCampaigns() {
      return this.campaigns.length > 0
    }
  },
  methods: {
    async loadCampaigns() {
      this.isLoading = true;
      this.req.getAllCampaigns = galleryService.getAllCampaigns(
          this.searchText,
          {page: this.currentPage, limit: this.campaignPageSize},
          {orderBy: this.sortBy.current, orderDir: this.sortBy.direction }
      );
      try {
        const { results, total } = await this.req.getAllCampaigns.call();
        this.campaigns = results
        this.totalItems = total;
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false;
      }
    },
    handleCampaignChosen(campaign) {
      this.$router.push({ path: `campaigns/${campaign.id}` })
    }
  },
  watch: {
    newCreatedCampaign() {
      this.loadCampaigns()
    },
    searchText() {
      this.loadCampaigns()
    },
    sortBy() {
      this.loadCampaigns()
    }
  }
}
</script>

<style scoped lang="less">
   .not-found-box {
     text-align: center;
     padding: 2em;
     font-size: 18px;
   }
</style>
