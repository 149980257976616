import { fontImporterService } from '@/templating/fonts/font-importer-service'

const isImportRule = r => r instanceof CSSImportRule;
const isFontFaceRule = r => r instanceof CSSFontFaceRule;
const isGoogleApiOrTypekitImport = (r) => {
	const isGoogleApi = r.href.includes('https://fonts.googleapis.com');
	const isTypekitApi = r.href.includes('https://use.typekit.net');
	return isGoogleApi || isTypekitApi;
}
const skipNormal = v => v !== 'normal';
const pluckHref = ({href}) => href;
const skipLinkAlreadyOnPage = href => !document.querySelector(`link[href="${href}"]`)
const sortFontAlphabeticalOrder = (fontA, fontB) => {
	const font1 = fontA.replace(/"/g, '').toUpperCase();
	const font2 = fontB.replace(/"/g, '').toUpperCase();
	return (font1 < font2) ? -1 : (font1 > font2) ? 1 : 0;
}

export class FontExtractor {

	constructor (cssStyleSheet) {
		this.importRules = [];
		this.usedFonts = {};
		if(cssStyleSheet instanceof CSSStyleSheet) {
			this.importRules = Array.from(cssStyleSheet.cssRules)
															.filter(isImportRule)
															.filter(isGoogleApiOrTypekitImport)
			this.importRulesEmpty = this.importRules.length === 0;
		}
	}

	async extractFonts() {
		if(this.importRulesEmpty) {
			this.makeUsedFontsFallback();
		}
		const fontStyles = await Promise.all(this.importRules.map(({href}) => fontImporterService.importFont(href)));
		const fontFaceRulesList = [];
		for(const cssStyle of fontStyles) {
			const style = document.createElement('style');
			style.innerHTML = String(cssStyle);
			document.head.appendChild(style);
			const rules = Array.from(style.sheet.cssRules).filter(isFontFaceRule);
			fontFaceRulesList.push(...rules);
			style.remove();
		}
		this.mapFontFaceRulesToFontInfo(fontFaceRulesList);
		return {
			names: Object.keys(this.usedFonts).sort(sortFontAlphabeticalOrder),
			list: this.convertSetsToArrays(this.usedFonts)
		};
	}

	get hasFonts() {
		return Object.keys(this.usedFonts).length > 0;
	}

	convertSetsToArrays(usedFonts) {
		const list = {};
		for(const [key, value] of Object.entries(usedFonts)) {
			list[key] = {
				fontWeight: Array.from(value.fontWeight),
				fontStyle: Array.from(value.fontStyle).filter(skipNormal),
			}
		}
		return list;
	}

	mapFontFaceRulesToFontInfo(fontFaceRulesList) {
		this.usedFonts = {};
		for(const {style} of fontFaceRulesList) {
			const {fontFamily, fontWeight, fontStyle} = style;
			if(fontFamily && !this.usedFonts[fontFamily]) {
				this.usedFonts[fontFamily] = {
					fontWeight: new Set(),
					fontStyle: new Set(),
				};
			}
			if(fontFamily) {
				this.usedFonts[fontFamily].fontWeight.add(fontWeight)
				this.usedFonts[fontFamily].fontStyle.add(fontStyle)
			}
		}
	}

	makeUsedFontsFallback() {
		this.importRules = [
			{
				href: 'https://fonts.googleapis.com/css2?family=Berkshire+Swash&family=Inter:wght@100;300;400;500;700;900&family=Lato:wght@100;300;400;700;900&family=Montserrat:wght@100;300;400;500;600;700;800;900&family=Open+Sans:wght@300;400;600;700;800&family=Poppins:wght@100;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&family=Roboto+Condensed:wght@300;400;700&family=Rubik:wght@300;400;500;600;700;800;900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,700&family=Anton&display=swap'
			},
			{
				href: 'https://fonts.googleapis.com/css2?family=Khand:wght@300;400;500;600;700&display=swap'
			},
			{
				href: 'https://use.typekit.net/buf5bnq.css'
			}
		]
		// add to document if needed:
		this.addFallbackStyleSheetsToHeadIfNotPresent();
	}

	addFallbackStyleSheetsToHeadIfNotPresent() {
		const links = this.importRules
				.map(pluckHref)
				.filter(skipLinkAlreadyOnPage)
				.map(href => {
					const link = document.createElement('link');
					link.rel = 'stylesheet';
					link.href = href;
					return link;
				})
		if(links.length === 0) {
			return;
		}
		const $head = document.querySelector('head');
		if($head) {
			$head.append(...links)
		}
	}
}
