<template>
  <a-popover
      trigger="click"
      title="Are you sure?"
      v-model="visible"
  >
    <template #content  >
      <div class="confirm__warning">All data of this user will be lost</div>
      <div class="confirm__content">
        To confirm, type user e-mail address:
        <a-input :auto-focus="true" v-model="email"></a-input>
      </div>
      <div class="confirm__controls">
        <a-button @click="visible = false">Cancel</a-button>
        <a-button
            type="primary"
            :disabled="confirmationEmail !== email"
            @click="confirmRemoval()"
        >
          Delete user
        </a-button>
      </div>
    </template>
     <slot />
  </a-popover>
</template>

<script>
export default {
  name: 'ConfirmUserRemoval',
  props: {
    confirmationEmail: {
      type: String
    }
  },
  data() {
    return {
      email: '',
      visible: false
    }
  },
  methods: {
    confirmRemoval() {
      this.$emit('removalConfirmed', this.email);
      this.email = '';
      this.visible = false;
    }
  }
};
</script>

<style scoped lang="less">
  .confirm {
    &__warning {
      color: #b93030;
      font-size: 1.2em;
      margin: .25em 2px;
    }
    &__content {
      width: 240px
    }
    &__controls {
      display: flex;
      justify-content: space-between;
      margin: .6em 0;
    }
  }
</style>
