import { TControl } from '@/templating/TControl'
import { sanitizeUrl } from '@/templating/templating-helpers'

export class TemplateMask extends TControl {
    static $selector = '[data-mask]'
    urlPattern = /(url\("?|"?\);?$)/g

    constructor(ref, baseUrl) {
        super(ref, 'mask')
        this.id = this.ref.getAttribute(TemplateMask.$selector.slice(1, -1))
        this.baseUrl = baseUrl
        this.calculateURL()
    }

    calculateURL() {
        const computedStyle = getComputedStyle(this.ref)
        const maskImage = computedStyle.webkitMaskImage
        this.url = maskImage.replace(this.urlPattern, '')
    }

    get url() {
        const { webkitMaskImage } = this.ref.style
        const address = webkitMaskImage.replace(this.urlPattern, '')
        return sanitizeUrl(address, this.baseUrl)
    }

    set url(value) {
        const sanitizedURL = sanitizeUrl(value, this.baseUrl)
        this.ref.style.webkitMaskImage = `url("${sanitizedURL}")`
    }
}
