<template>
    <a-modal
        :visible="visible"
        :title="modalTitle"
        @cancel="closeModal()"
    >
      <a-form>
        <p class="form__label">Alt attribute</p>
        <a-input class="form__input" placeholder="alt attribute" v-model="alt"/>
        <p class="form__label">Choose file format</p>
        <a-select
          class="edition__select"
          placeholder="Format"
          v-model="fileType"
        >
          <a-select-option v-for="fileType in fileTypes" :key="fileType.name">
            {{ fileType.name }}
          </a-select-option>
        </a-select>
        <p class="form__label">Quality</p>
        <a-row>
          <a-col :span="16">
            <a-slider v-model="quality" :min="1" :max="100" :step="1" />
          </a-col>
          <a-col :span="6">
            <a-input-number
                v-model="quality"
                :min="1"
                :max="100"
                :step="1"
                style="margin-left: 16px"
            />
          </a-col>
          <a-col :span="2">%</a-col>
        </a-row>
      </a-form>
      <template slot="footer">
          <a-button key="back" type="default" @click="closeModal()">
            Close
          </a-button>
          <a-button key="submit" type="primary" @click="startDownloading()">
            {{ actionBtnText }}
          </a-button>
      </template>
    </a-modal>
</template>

<script>
const FILETYPE = {
  JPG: 'jpg',
  PNG: 'png'
}

export default {
  name: 'DownloadModal',
  props: {
    projectName: String,
    visible: {
      type: Boolean,
      default: false
    },
    // @Business: different text and action call - when Download and Export to CIM
    modalTitle: {
      type: String,
      default: 'Download Settings'
    },
    actionBtnText: {
      type: String,
      default: 'Download'
    }
  },
  data(){
      return {
        fileType: FILETYPE.PNG,
        alt: this.projectName,
        quality: 100
      }
  },
  computed: {
    fileTypes(){
      return [
        { name: FILETYPE.JPG },
        { name: FILETYPE.PNG }
      ]
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeDownloadModal');
    },
    startDownloading() {
      const {alt, fileType, quality} = this;
      this.$emit('startDownloading', { alt, fileType, quality })
    }
  },
  watch:{
    projectName: {
      immediate: true,
      handler(newProjectName) {
        this.alt = newProjectName
      }
    }
  }
}
</script>
