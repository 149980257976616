<template>
  <div>
    <template v-for="(value, index) of mappedValues">
      <span class="preview-font" :key="index+value" v-if="shapeType === SHAPE.font" :style="{fontFamily: value}">{{value}}</span>
      <span :key="index+value" v-if="[SHAPE.color, SHAPE.alphaColor].includes(shapeType)" >
          <span class="preview-color" :style="{backgroundColor: value}" :title="value" />
      </span>
      <span :key="index+value" v-if="shapeType=== SHAPE.text" style="margin-right: 5px">
          <span v-html="value"></span>;
        </span>
      <img class="preview-img" :key="index+value" v-if="shapeType === SHAPE.image" :src="getTemplateImagePath(value)" />
    </template>
  </div>
</template>

<script>
import { SHAPE } from '@/components/templates/possible-values/possible-values-shape';
import { templateService } from '@/services/template.service';
import { sanitizeUrl } from '@/templating/templating-helpers';

export default {
  name: 'PossibleValuesPreviewTableCellGroupListed',
  props: {
    shapeType: {
      type: String,
      required: true
    },
    values: {
      type: [Array, Object],
      default: () => []
    },
    templateId: {
      type: Number,
      required: true
    }
  },
  computed: {
    mappedValues() {
      if(Array.isArray(this.values)) {
        return this.values
      }
      return  this.values?.values || [];
    },
    SHAPE() {
      return SHAPE;
    }
  },
  methods: {
    getTemplateImagePath(partialPath) {
      const fileUrl = templateService.getTemplateFileUrl(this.templateId);
      return sanitizeUrl(partialPath, fileUrl);
    }
  }
};
</script>

<style scoped>
  .preview-img {
    width: 50px;
    margin-right: 2px;
  }
  .preview-font {
    margin-right: 10px;
  }
  .preview-color {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px 5px 0;
    vertical-align: middle;
  }
</style>
