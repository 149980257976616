import { CreationModel } from '@/components/project/model/CreationModel';
import { TemplateModel } from '@/components/project/model/TemplateModel';
import { templateService } from '@/services/template.service';

export const modelProvider = {
	makeTemplateModel,
	makeCreationModel,
	extendTemplateModelForProduct,
	loadCreationModelsForConnection
}

function makeTemplateModel(template, {$mountPoint, connectionSettings, productId, connectionId}) {
	const {id, settings, name = '', description = '', type = 'generic', possibleValues, frozenFields } = template || {};
	const {call, cancel} = templateService.provideTemplateModel( id, $mountPoint, settings, connectionSettings, possibleValues, frozenFields  );
	return {
		call: async () => {
			const model = await call();
			model.name = name;
			model.description = description;
			model.type = type;
			model.connectionId = connectionId;
			model.productId = productId;
			model.applySettings(connectionSettings);
			return model;
		},
		cancel
	};
}

function makeCreationModel(creation, {templateModel, productId, connectionId, connectionSettings }) {
	const creationModel = new CreationModel(creation.name, creation.id, creation.size);
	creationModel.template = templateModel;
	creationModel.productId = productId;
	creationModel.connectionId = connectionId;

	creationModel.setSettings(creation.settings);
	creationModel.applySettings(connectionSettings)
	return creationModel
}

function extendTemplateModelForProduct(templateId, {templateModel, productId, connectionId, connectionSettings}) {
	// Make a copy of already existing TemplateModel:
	const newTemplateModel = Object.assign(new TemplateModel(templateId), templateModel, {productId, connectionId});
	newTemplateModel.applySettings(connectionSettings);
	return newTemplateModel;
}

function loadCreationModelsForConnection(connection, templateModel) {
	const {id, product_id: productId, creations, settings: connectionSettings} = connection;
	const creationModels = [];

	for(const creation of creations) {
		const creationModel = makeCreationModel(creation, {
			connectionId: id,
			connectionSettings,
			productId,
			templateModel
		})
		creationModels.push(creationModel);
	}
	return creationModels;
}
