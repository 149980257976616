export const makeSizeFormValidators = () => [
    {
        required: true,
        message: 'This field is required in NxN format',
        trigger: 'change',
    },
    {
        validator: (rule, value, callback) => {
            if (value) {
                const sizeFormat = /^\d+x\d+$/
                if (!sizeFormat.test(value)) {
                    return callback('Size format: NxN where N is a number')
                }
            }
            callback()
        },
    },
]
