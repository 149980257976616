<template>
    <a-modal
        v-model="visible"
        :maskClosable="false"
        @cancel="$emit('closeModal')"
    >
        <template #title>
            <div class="modal-top">
                {{ modalTitle }}
            </div>
        </template>
        <a-form-model
            class="form"
            ref="formRef"
            :model="formModel"
            :rules="rules"
        >
            <a-form-model-item prop="name">
                <p class="form__label">Campaign name</p>
                <a-input
                    class="form__input"
                    placeholder="Campaign name"
                    v-model="formModel.name"
                    @keyup.enter="handleAddOrEditClick()"
                />
            </a-form-model-item>
            <a-form-model-item prop="tags">
                <p class="form__label">Campaign hashtags</p>
                <a-select
                    class="form__input"
                    placeholder="Hashtags..."
                    mode="tags"
                    :token-separators="[',', ' ']"
                    v-model="formModel.tags"
                >
                    <a-select-option
                        v-for="{ name } in possibleTags"
                        :key="name"
                    >
                        {{ name }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
        </a-form-model>
        <gallery-items-form
            v-model="galleryItemFormModel"
            expires-at-label="Campaign expires at"
            :should-have-at-least-one-file="false"
            :skip-metadata-option="true"
            :can-add-tags="false"
            :can-upload-photos="false"
            @formRefReady="handleItemsFormRef($event)"
        />
        <a-checkbox v-model="formModel.inheritDates" v-if="isInEditMode">
            <a-icon type="warning" theme="twoTone" two-tone-color="#594214" />
            Update ALL photos <em>expires at</em> inside this campaign
            <code>"inherit"</code>
        </a-checkbox>
        <template #footer>
            <a-button key="back" type="default" @click="$emit('closeModal')">
                Cancel
            </a-button>
            <a-button
                key="submit"
                type="primary"
                :loading="isFormSent"
                @click="handleAddOrEditClick()"
            >
                {{ isInEditMode ? 'Update' : 'Add' }}
            </a-button>
        </template>
    </a-modal>
</template>

<script>
import { galleryService } from '@/services/gallery.service'
import { mapActions } from 'vuex'
import GalleryItemsForm from '@/components/gallery/components/form/GalleryItemsForm.vue'
import {
    normalizeExpiresAt,
    sendGalleryItemsService,
} from '@/components/gallery/service/send-gallery-items.service'

export default {
    name: 'NewOrUpdateCampaignModal',
    components: { GalleryItemsForm },
    props: {
        editModel: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            visible: true,
            isFormSent: false,
            formModel: {
                name: '',
                tags: [],
                inheritDates: false,
            },
            galleryItemFormModel: {
                hasFiles: false,
                expirationType: 'SET',
                files: [],
            },
            name: '',
            tags: [],
            possibleTags: [],
            galleryItemsFormRef: null,
        }
    },
    async mounted() {
        try {
            this.possibleTags = await galleryService.getAllPossibleTags()
            // eslint-disable-next-line no-empty
        } catch {}
    },
    computed: {
        rules() {
            return {
                name: [{ required: true, message: 'This field is required' }],
                tags: [],
            }
        },
        isInEditMode() {
            return Boolean(this.editModel)
        },
        modalTitle() {
            return this.isInEditMode ? 'Edit campaign' : 'New campaign'
        },
    },
    methods: {
        ...mapActions(['showAlertError', 'showAlertSuccess']),
        handleItemsFormRef(ref) {
            this.galleryItemsFormRef = ref
        },
        validateGalleryItemsAsPromise() {
            return (
                Promise.all([
                    this.galleryItemsFormRef.validate(),
                    this.$refs.formRef.validate(),
                ]) // eslint-disable-next-line no-unused-vars
                    .then(_ => true)
                    // eslint-disable-next-line no-unused-vars
                    .catch(_ => false)
            )
        },
        async handleCampaignUpdate() {
            try {
                const { expiresAt, expirationType } = this.galleryItemFormModel
                const computedExpiresAt = normalizeExpiresAt(
                    expiresAt,
                    expirationType
                )
                const editedCampaign = await galleryService.patchCampaign(
                    this.formModel.id,
                    { ...this.formModel, expiresAt: computedExpiresAt }
                )
                this.$emit('editCampaign', editedCampaign)
            } catch (e) {
                this.showAlertError(e.message)
            }
        },
        async handleAddOrEditClick() {
            const allValid = await this.validateGalleryItemsAsPromise()
            if (!allValid) {
                return
            }
            if (this.isInEditMode) {
                return this.handleCampaignUpdate()
            }
            try {
                this.isFormSent = true
                const { expiresAt, expirationType } = this.galleryItemFormModel
                const computedExpiresAt = normalizeExpiresAt(
                    expiresAt,
                    expirationType
                )
                const campaign = await galleryService.makeCampaign({
                    ...this.formModel,
                    expiresAt: computedExpiresAt,
                })
                if (this.galleryItemFormModel.hasFiles) {
                    const {
                        sentFilesNames,
                        notSendFilesNames,
                        notSendFiles,
                    } = await sendGalleryItemsService.uploadFiles({
                        ...this.galleryItemFormModel,
                        tags: [],
                        campaignId: campaign.id,
                    })
                    sentFilesNames &&
                        this.showAlertSuccess(
                            `Photo: ${sentFilesNames} uploaded.`
                        )
                    notSendFilesNames &&
                        this.showAlertError(
                            `Upload error: ${notSendFilesNames}`
                        )
                    this.formModel.files = notSendFiles
                }
                this.$emit('newCampaignAdded', campaign)
                this.$emit('closeModal')
            } catch (err) {
                await this.showAlertError(err.message)
            } finally {
                this.isFormSent = false
            }
        },
    },
    watch: {
        editModel: {
            immediate: true,
            handler(newEdition) {
                if (newEdition) {
                    this.formModel = { ...newEdition, inheritDates: false }
                    if (this.formModel.tags) {
                        this.formModel.tags = this.formModel.tags.map(
                            ({ name }) => name
                        )
                    }
                    if (newEdition.expiresAt === null) {
                        this.galleryItemFormModel.expirationType = 'NEVER'
                        this.galleryItemFormModel.expiresAt = null
                    }
                    if (newEdition.expiresAt) {
                        this.galleryItemFormModel.expirationType = 'SET'
                        this.galleryItemFormModel.expiresAt =
                            newEdition.expiresAt
                    }
                }
            },
        },
    },
}
</script>

<style scoped lang="less">
.form__is-test {
    font-size: x-small;
}

.form__checkbox {
    font-size: x-small;
}

.external-campaign-check {
    margin: 0;
    padding: 0;
}

.modal-top {
    display: flex;
    justify-content: space-between;
    margin-right: 1.5em;
    align-items: center;
}
</style>
