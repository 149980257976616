import { TemplateModel } from '@/components/project/model/TemplateModel'
import {
    LANG_FALLBACK,
    LANG_LOCALES,
} from '@/components/shared/supported-languages'
import { SHAPE_GROUP_GROUP_KEYS } from '@/components/templates/possible-values/possible-values-shape'
import { axInstance } from './ax-instance'

const makeBasePossibleValuesURI = templateId =>
    `/templates/${templateId}/possible-values`

const allLanguages = [LANG_FALLBACK, ...Object.keys(LANG_LOCALES)]
const hasValues = obj => Object.values(obj).length > 0

export const templatePossibleValuesService = {
    getValues(templateId) {
        return axInstance.get(makeBasePossibleValuesURI(templateId))
    },
    changeCssVariableNames(templateId, displayNames) {
        return axInstance.patch(
            makeBasePossibleValuesURI(templateId) + '/variable-display-names',
            { displayNames }
        )
    },
    saveForLanguage(templateId, lang, values) {
        return axInstance.put(
            makeBasePossibleValuesURI(templateId),
            { values },
            { params: { lang } }
        )
    },
    uploadImg(templateId, fileData) {
        const fileForm = new FormData()
        fileForm.append('file', fileData)

        return axInstance.post(
            makeBasePossibleValuesURI(templateId) + '/file-upload',
            fileForm,
            {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            }
        )
    },
    extractValueByLanguage({ type, id, baseValues = {} }) {
        const possibleValues = {}
        for (const [lang, values] of Object.entries(baseValues)) {
            let value = values?.[type]
            if (id) {
                value = values?.[type]?.[id]
            }
            if (value) {
                possibleValues[lang] = value
            }
        }
        return possibleValues
    },
    getPossibleValuesForShape({ values, lang }) {
        const val = values?.[lang]
        const fallbackValues = values?.[LANG_FALLBACK]
        return val || fallbackValues
    },
    extractInTemplateDefaultValues(templateModel = new TemplateModel()) {
        const { templateHolderControls } = templateModel
        if (
            !templateHolderControls ||
            Object.keys(templateHolderControls).length === 0
        ) {
            return null
        }
        const defaultValues = {
            [LANG_FALLBACK]: {},
            ...allLanguages.reduce((collector, key) => {
                collector[key] = {}
                return collector
            }, {}),
        }
        let anyValuesExtracted = false
        for (const key of SHAPE_GROUP_GROUP_KEYS.filter(
            k => k !== 'cssVariables'
        )) {
            const dataObject = templateModel.templateHolderControls[key] || {}
            for (const [id, control] of Object.entries(dataObject)) {
                if (!control.possibleValuesProvider) {
                    return
                }
                for (const lang of allLanguages) {
                    const possibleValues =
                        lang === LANG_FALLBACK
                            ? control.possibleValuesProvider.fallbackValues()
                            : control.possibleValuesProvider.langValues(lang)
                    if (hasValues(possibleValues)) {
                        anyValuesExtracted = true
                        if (!defaultValues[lang][key]) {
                            defaultValues[lang][key] = {}
                        }
                        defaultValues[lang][key][
                            id
                        ] = inTemplateToServerSideKeyMapper(possibleValues)
                    }
                }
            }
        }
        return anyValuesExtracted ? defaultValues : null
    },
}

// Map "text" to "value"
function inTemplateToServerSideKeyMapper(valueObj) {
    if (!valueObj || typeof valueObj !== 'object') {
        return valueObj
    }
    const resultObj = { ...valueObj }
    if (valueObj.text) {
        resultObj.value = valueObj.text
        delete resultObj.text
    }
    return resultObj
}
