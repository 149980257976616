const MAX_TEXT_LEN = 30;
const ELLIPSIS_SING = '...';

/**
 * <div v-ellipsis="2">Hello World<div>
 *   ----- will do: --------->
 * <div v-ellipsis="2" title="Hello World">He...<div>
 *
 * Cut textContent of base element or baseText provided by config object
 *
 * usage:
 *  v-ellipsis
 *  v-ellipsis="40"
 *
 * or:
 *  v-ellipsis="{ maxLen: 30, baseText: model.text, suffix: 'some text after ...' }"
 * */
const ellipsisDirective = {
	inserted (el, {value /* { maxLen = 0, baseText = '', suffix = ''} */}) {
		const maxLen = typeof value === 'number' ? value : (value?.maxLen || MAX_TEXT_LEN);
		const suffix = value?.suffix || '';
		const originalText = value?.baseText || el.textContent;
		if(originalText.length > maxLen) {
			el.textContent = originalText.slice(0, maxLen) + ELLIPSIS_SING + suffix;
			el.setAttribute('title', originalText);
		}
	}
}

export const EllipsisDirective = {
	install(Vue) {
		Vue.directive('ellipsis', ellipsisDirective)
	}
}
