<template>
    <div class="empty-card">
        <span class="empty-card__plus">
            <a-icon type="plus"/>
        </span>
        Add template
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less">

</style>
