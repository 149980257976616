<template>
  <ul class="menu_list">
    <li v-for="{to, name, activeExact = false} of links" :key="name">
      <router-link :to="to" active-class="menu_item__active" class="menu_item" :exact="activeExact">
        {{name}}
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'MenuList',
  props: {
    links: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="less">

.menu_list {
  display: flex;
  gap: 2em;
  margin-left: 24px;
  .menu_item {
    padding: 23px 10px;
    color: @gray-10;
    &:hover {
      color: @primary-6;
    }
    &__active {
      color: @primary-6;
      border-bottom: 2px solid @primary-6;
    }
  }
}

</style>
