import Purifier from 'html-purify'

export const htmlPurifier = new Purifier({
	whitelistTags: ['span', 'div', 'b', 'i', 'em', 'strong', 'br', 'mark', 'ul', 'ol', 'li'],
	whitelistAttributes: ['class', 'style'],
	enableTagBalancing: false // do not put </> auto close tag (needed to handle data-hide properly).
})
// Facade:
export function registerPurifyAttribute(attribute) {
	htmlPurifier.attributesWhitelist.push(attribute)
}

export function rgb2hex(rgb) {
	if (!rgb.includes('rgb')) {
		return rgb;
	}

	if(rgb.includes('rgba')) {
		const colorNumbers = (rgb.match(/\d/g) || []).map(Number);
		const hasZerosOnly = colorNumbers.every(e => e === 0);
		const has4Digits = colorNumbers.length  === 4;
		if(has4Digits && hasZerosOnly) {
			return 'transparent';
		}
	}
	try {
		const [ , r, g, b, a ] = rgb.match( /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*\.*\d{0,2}))?\)$/ );
		const hex = x => parseInt(x).toString(16).padStart(2, '0');
		return `#${hex( r )}${hex( g )}${hex( b )}${a ? hex(a * 255) : ''}`;
	} catch ( e ) {
		console.error('Cannot convert', rgb, 'to proper hex color');
		return rgb;
	}
}

export function isUrlAbsolute(url = '') {
	return /^http(s?):\/\//.test(url)
}

export function sanitizeUrl(url, baseUrl) {
	// pass through when falsy (e.g. empty string)
	if (!url) {
		return url;
	}
	if(isUrlAbsolute(url) || url.startsWith('data:image')) {
		return url;
	}
	return baseUrl + '/' + url.replace(/^(\.?)\//, '');
}

/*
* An URL from:
*  \something\like/that ->
* to:
*  /something/like/that
* */
export function normalizeUrl(url) {
	return url.replace(/\\/g, '/');
}

export function isNumeric(value) {
	if(value === ''){
		return false;
	}
	const probablyNumber = Number(value);
	return !Number.isNaN(probablyNumber);
}

export function isPercent(value) {
	return typeof value === 'string' && value.endsWith('%');
}

export function isCoverOrContain(value) {
	return (value === 'cover' || value === 'contain')
}

export function undefinedToNull(value) {
	return value === undefined ? null : value;
}
