<template>
    <div class="scale-stepper">
        <a-button @click="changeSize(-step)" type="default">
            <a-icon type="minus"/>
        </a-button>
        <p> {{scalePercent}}% </p>
        <a-button @click="changeSize(step)" type="default">
            <a-icon type="plus"/>
        </a-button>
    </div>
</template>

<script>
export default {
	name: 'ScaleStepper',
  props: {
		value: {
			type: Number,
      default: 1
    },
    max: {
       type: Number,
       default: 2.0
    },
    min: {
      type: Number,
      default: 0.5
    },
    step: {
      type: Number,
      default: 0.1
    }
  },
  data() {
		return {
			scale: 1
    }
  },
  computed: {
		scalePercent() {
			return Math.round(this.scale * 100);
		},
  },
  methods: {
		changeSize(diff) {
			const newSize = Number(parseFloat(this.scale + diff).toFixed(1));
			if (newSize >= this.min && newSize < (this.max + this.step) ){
				this.updateScale(newSize);
			}
    },
    updateScale(v) {
			this.scale = v;
			this.$emit('input', this.scale)
    }
  },
  watch: {
		value: {
			immediate: true,
      handler(v) {
				if(this.scale !== v) {
					this.updateScale(v);
        }
      }
    }
  }
};
</script>

<style scoped>
  .scale-stepper {
      display: flex;
      align-items: center;
      gap: 0.6em;
  }
</style>
