<template>
    <div class="expire-wrapper">
      <div class="expire-marker" :class="decorationClass">
        {{itemText}} {{formattedDate}}
      </div>
    </div>
</template>

<script>
import {hasGalleryItemExpired, ITEM_EXPIRE} from "@/components/gallery/model/gallery-item";
import {dateFormatter} from "@/view-helpers/date-formatter";

export default {
  name: "ExpirationMarker",
  props: {
    expiredAt: {
      type: [Date, String, null],
      default: null
    }
  },
  computed: {
    itemStatus() {
      return hasGalleryItemExpired(this.expiredAt)
    },
    itemText() {
      const { itemStatus } = this;
      const { EXPIRED, SOON_EXPIRES } = ITEM_EXPIRE;
      return itemStatus === EXPIRED
            ? 'Expired'
            : itemStatus === SOON_EXPIRES
              ? 'Expires soon'
              : '';
    },
    decorationClass() {
      return `expire-marker__${this.itemStatus.toLowerCase()}`
    },
    formattedDate() {
      if(this.itemStatus === ITEM_EXPIRE.NEVER_EXPIRED){
        return `Never`
      }
      return dateFormatter(this.expiredAt, dateFormatter.SHORT_DATE_FORMAT)
    }
  }
}
</script>



<style scoped lang="less">
   .expire-wrapper {
     display: flex;
     align-items: center;
     justify-content: flex-start;
   }
   .expire-marker {
     padding: 1px 8px;
     border: 1px solid @gray-3;
     font-weight: 400;
     font-size: 12px;
     line-height: 20px;
     &__never {
       background-color: @gray-2;
       border: 1px solid @gray-5;
       color: @gray-9
     }
     &__expired {
       background-color: @red-1;
       border: 1px solid @red-3;
       color: @red-6;
     }
     &__not_expired {
       background-color: @green-1;
       border: 1px solid @green-3;
       color: @green-6
     }
     &__soon_expires {
       background-color: @gold-1;
       border: 1px solid @gold-3;
       color: @gold-6
     }
   }
</style>
