<template>
    <div class="templates">
        <div class="title">
            <h3>Templates</h3>
            <a-button type="dashed" @click="addTemplateModal = true">
                <a-icon type="plus" />
                Add
            </a-button>
        </div>
        <div class="links__search">
            <a-input-search enter-button placeholder="Search" v-model="query" />
        </div>
        <s-empty v-if="query && !filteredTemplates.length"></s-empty>
        <ul class="links__items scroll" style="height: calc(100vh - 348px)">
            <s-template-list-item
                v-for="template in filteredTemplates"
                :template="template"
                class="links__item"
                :class="{ 'is-selected': template.id === selectionId }"
                :key="template.id"
                :canSelect="
                    template.type === 'generic' ? canSelectGeneric : true
                "
                @selectTemplate="$emit('selectTemplate', template)"
                @deleteTemplate="$emit('deleteTemplate', template.id)"
            />
        </ul>
        <s-add-template
            v-if="addTemplateModal"
            @addTemplates="addTemplates"
            @closeAddTemplateModal="addTemplateModal = false"
        />
    </div>
</template>

<script>
import Empty from '../../layout/Empty'
import TemplateListItem from './TemplateListItem'
import AddTemplate from './AddTemplate'

export default {
    name: 'TemplateList',
    props: {
        selectionId: Number,
        templates: Array,
        canSelectGeneric: Boolean,
    },
    components: {
        STemplateListItem: TemplateListItem,
        SAddTemplate: AddTemplate,
        SEmpty: Empty,
    },
    data() {
        return {
            query: '',
            addTemplateModal: false,
        }
    },
    computed: {
        filteredTemplates() {
            return this.query
                ? this.templates.filter(
                      (p) =>
                          p.name
                              .toLowerCase()
                              .indexOf(this.query.toLowerCase()) !== -1
                  )
                : this.templates
        },
    },
    methods: {
        addTemplates({ templatesIds, genericLang }) {
            this.addTemplateModal = false
            this.$emit('addTemplates', { templatesIds, genericLang })
        },
    },
}
</script>

<style lang="less">
.templates {
    width: 25%;
    border-right: solid 1px @gray-4;
}
</style>
