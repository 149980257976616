import { pseudoJSONToObjectParse } from '@/templating/pseudo-json-to-object'
import { isPercent } from '@/templating/templating-helpers'

const PERCENT_OF_VALUE = 100
const DEFAULT_LINE_HEIGHT_FACTOR = 1.5
const WIDTH_PIXEL_TOLERANCE = 1
const HEIGHT_PIXEL_TOLERANCE = 6

export class MaxLenNormalizer {
    static TERMS_EXCLUDED_FROM_LIMIT = /&nbsp;/g

    strategies = {
        fontSize: this.fireFontSizeShrinkStrategy.bind(this), // {'max':12,'trim':'fontSize','to':'15rem'}
        scale: this.fireScaleStrategy.bind(this), // {'max':12,'trim':'scale','to':80}
        cut: this.fireCutStrategy.bind(this), // {'max':12,'trim':'cut'}
        fluent: this.fireFluentStrategy.bind(this), // { 'trim':'fluent', 'to': 20, 'lineFactor': 1 }
        // or: { 'trim':'fluent', 'to': '80%', 'lineFactor': 1 }
    }

    origins = {
        fontSize: [],
        lineHeight: [],
    }

    ORIGIN_ENUM = Object.keys(this.origins).reduce((acc, key) => {
        acc[key] = key
        return acc
    }, {})

    constructor(ref, pseudoJSONConfig, lang = 'pl') {
        this.ref = ref
        this.setup = pseudoJSONToObjectParse(pseudoJSONConfig)
        this.changeLanguage(lang)
        this.baseFontSize = 0
    }

    calculateComputedBaseFontSize() {
        if (!this.baseFontSize) {
            const { fontSize } = getComputedStyle(this.ref)
            this.baseFontSize = parseFloat(fontSize || 0)
        }
    }

    changeLanguage(lang) {
        this.config = this.extractConfig(lang)
        this.bringBackAllOrigins()
    }

    extractConfig(setupLang) {
        const setups = Array.isArray(this.setup) ? this.setup : [this.setup]
        const globalConfig = []
        for (const setup of setups) {
            const { max, trim = '', lang, ...restOptions } = setup || {}
            const hasLang = (lang || '')
                .toLowerCase()
                .split(/[;, ]/)
                .includes(setupLang)
            if (!lang || hasLang) {
                globalConfig.push({ max, trim, ...restOptions })
            }
        }
        // ORDER is CRUCIAL: (from lowest to highest max)
        globalConfig.sort((a, b) => a.max - b.max)
        return globalConfig ? globalConfig : []
    }

    fire() {
        const result = []
        let anyFired = false
        for (const { max, trim, ...restConfig } of this.config) {
            if (typeof this.strategies[trim] === 'function') {
                result.push(this.strategies[trim](max, restConfig))
                anyFired = true
            }
        }
        const noOneSucceeded = !result.includes(true)
        if (anyFired && noOneSucceeded) {
            this.bringBackAllOrigins()
        }
    }

    getLengthDiff(maxLength) {
        const excludedTerms =
            this.ref.innerHTML.match(
                MaxLenNormalizer.TERMS_EXCLUDED_FROM_LIMIT,
                ''
            ) || []
        // Excluded terms are computed in HTML, so we have to count them as -1 for textContent length
        const computedTextLength =
            this.ref.textContent.length - excludedTerms.length
        return computedTextLength - maxLength
    }

    keepOrigin(styleKey) {
        const value = this.ref.style[styleKey]
        // Save origin only if it is empty (not already saved).
        if (!this.isOriginSaved(styleKey)) {
            this.origins[styleKey].push(value)
        }
    }

    bringBackOrigin(styleKey) {
        const [originalValue = ''] = this.origins[styleKey] || []
        this.ref.style[styleKey] = originalValue
    }

    isOriginSaved(styleKey) {
        return this.origins[styleKey].length > 0
    }

    bringBackAllOrigins() {
        Object.keys(this.ORIGIN_ENUM)
            .filter(this.isOriginSaved.bind(this))
            .forEach(this.bringBackOrigin.bind(this))
    }

    fireFontSizeShrinkStrategy(maxLength, { to }) {
        const lengthDiff = this.getLengthDiff(maxLength)
        const { fontSize } = this.ORIGIN_ENUM
        if (lengthDiff > 0) {
            const value = typeof to === 'number' ? `${to}px` : to
            this.keepOrigin(fontSize)
            this.ref.style[fontSize] = value
            return true
        }
        return false
    }

    fireScaleStrategy(maxLength, { to }) {
        this.calculateComputedBaseFontSize()
        const numericValue = parseFloat(to)
        if (!Number.isNaN(numericValue)) {
            const scaleFactor = numericValue / PERCENT_OF_VALUE
            const scaledValue = this.baseFontSize * scaleFactor
            // Scale strategy suppose to behave same as shrink strategy when we know the "to" value:
            return this.fireFontSizeShrinkStrategy(maxLength, {
                to: scaledValue,
            })
        }
        return false
    }

    fireCutStrategy(maxLength) {
        const lengthDiff = this.getLengthDiff(maxLength)
        if (lengthDiff > 0) {
            this.ref.innerHTML = this.ref.textContent.slice(0, maxLength)
            return true
        }
        return false
    }

    calculateFluentMinimalFontSize(to) {
        const toNumeric = parseFloat(to)
        if (isPercent(to)) {
            const minPxValue = (toNumeric / 100) * this.baseFontSize
            return Math.floor(minPxValue)
        }
        return toNumeric
    }

    fireFluentStrategy(
        // eslint-disable-next-line no-unused-vars
        maxLength = 0,
        { to, lineFactor = DEFAULT_LINE_HEIGHT_FACTOR }
    ) {
        this.calculateComputedBaseFontSize()
        const minFontSize = this.calculateFluentMinimalFontSize(to)
        this.bringBackAllOrigins()
        const getSize = () => {
            const { width, height } = this.ref.getBoundingClientRect()
            return { width, height }
        }
        const { width, height } = getSize()
        if (!(width && height)) {
            return false
        }
        this.keepOrigin(this.ORIGIN_ENUM.fontSize)
        this.keepOrigin(this.ORIGIN_ENUM.lineHeight)
        const getCurrentFontSize = () =>
            parseFloat(getComputedStyle(this.ref)[this.ORIGIN_ENUM.fontSize])
        const getScrollDimensions = () => [
            this.ref.scrollWidth,
            this.ref.scrollHeight,
        ]
        const computedTextOutOfBound = () => {
            const { width, height } = getSize()
            const [sWidth, sHeight] = getScrollDimensions()
            return (
                sWidth - width > WIDTH_PIXEL_TOLERANCE ||
                sHeight - height > HEIGHT_PIXEL_TOLERANCE
            )
        }
        let currentFontSize = getCurrentFontSize()
        const isFired = computedTextOutOfBound()
        while (computedTextOutOfBound() && currentFontSize > minFontSize) {
            currentFontSize = getCurrentFontSize() - 1
            this.ref.style.fontSize = `${currentFontSize}px`
            if (lineFactor !== false && !Number.isNaN(Number(lineFactor))) {
                this.ref.style.lineHeight = `${currentFontSize * lineFactor}px`
            }
        }
        return isFired
    }
}
