export function debounce(baseFn, debounceTime = 300) {
	let timer;
	return function (...args) {
		if(timer) {
			clearTimeout(timer);
		}
		timer = setTimeout(() => {
			baseFn.call(this, timer, ...args)
		}, debounceTime);
	}
}
