import { decorateMovableWithBtnControl } from '@/templating/movables/movable-mover-contols'
import Moveable from 'moveable'
import { TControl } from '@/templating/TControl'
import { ownerWrapper } from '@/templating/movables/movable-gui-helpers'
import { fireOnWindowLoadEventOnce } from '@/templating/page-events/listeners'
import { selectCurrentToMoveByArrows } from './movables/move-by-arrows.js'

const moveByArrowsInstanceWrapper = thisInstance =>
    selectCurrentToMoveByArrows(thisInstance, (x, y) => {
        const { transform } = thisInstance
        thisInstance.transform = transform.replace(/(?!=matrix)\(.+\)/g, m => {
            const [a, b, c, d, tx, ty] = m
                .replace(/[()]/g, '')
                .split(',')
                .map(Number)
            return `(${a}, ${b}, ${c}, ${d}, ${tx + x}, ${ty + y})`
        })
    })

export class TemplateMoveable extends TControl {
    static $selector = '[data-moveable]'
    static freezable = ['move']
    // static currentSelectedMoveable = this

    moveEnabled = true

    constructor(ref, container) {
        super(ref, 'moveable')
        this.container = container
        this.id = this.ref.getAttribute(TemplateMoveable.$selector.slice(1, -1))
    }

    get transform() {
        return getComputedStyle(this.ref).transform
    }
    set transform(value) {
        this.ref.style.transform = value
        this.updateMovableBox()
    }

    setupReactivity() {
        super.isReactive = true
        const ownerDiv = ownerWrapper()
        this.moveable = new Moveable(ownerDiv, {
            target: this.ref,
            origin: false,
            draggable: true,
            scalable: true,
            keepRatio: true,
        })

        const applyTransform = ({ target, transform }) => {
            if (this.moveEnabled) {
                target.style.transform = transform
            }
        }
        const dispatchTransform = ({
            target: {
                style: { transform },
            },
        }) => this.dispatch(this.id, 'transform', transform)

        this.moveable.on({
            drag: applyTransform,
            scale: applyTransform,
            dragEnd: dispatchTransform,
            scaleEnd: dispatchTransform,
        })

        decorateMovableWithBtnControl({
            movable: this.moveable,
            container: ownerDiv,
            events: ['drag', 'scale'],
            iconColor: '#4af',
            targetName: this.id,
            targetControl: this,
            onMoveByArrows: () => moveByArrowsInstanceWrapper(this),
        })

        // @Fix: movable need to be "refreshed" if load event happens after setupReactivity fired
        fireOnWindowLoadEventOnce(() => this.updateMovableBox())

        // Enable to move by arrows
        this.ref.addEventListener('click', () => {
            moveByArrowsInstanceWrapper(this)
        })
    }

    updateMovableBox() {
        if (this.isReactive && this.moveable) {
            this.moveable.updateRect()
        }
    }

    onDestroy() {
        if (this.moveable) {
            this.moveable.destroy()
        }
    }
}
