<template>
  <a-row class="renewable-select-group">
    <a-col :span="23">
      <a-select
          class="renewable-select-group__select"
          ref="selectField"
          :show-search="canEdit"
          v-model="controlledValue"
          :options="optionList"
          @change="handleSelectChange($event)"
          @select="handleSelected($event)"
      >
        <slot></slot>
      </a-select>
    </a-col>
    <a-col :span="1">
      <a-button
          class="renewable-select-group__reset"
          title="Restore default value"
          @click="$emit('reset')"
      >
        <a-icon type="undo" />
      </a-button>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: 'RenewableSelect',
  props: {
    value: {
      type: [String, Number],
      required: false
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array ,
      default: () => []
    }
  },
  data() {
    return {
      controlledValue: this.value
    }
  },
  mounted() {
    if(!this.canEdit) {
      return;
    }
    // @Business: code is needed to handle EDGE CASE: Select box, with input (Editable ComboBox)
    const searchField = this.$refs.selectField.$el.querySelector('input.ant-select-search__field');
    if(!searchField) {
      return;
    }
    searchField.addEventListener('input', (ev) => {
      const { value } = ev.target;
      this.controlledValue = value;
      this.handleSelectChange(value);
    })
    searchField.addEventListener('keydown', (ev) => {
      if(ev.key === 'Enter') {
        this.handleSelectChange(this.controlledValue);
        ev.target.blur()
      }
    })
  },
  computed: {
    optionList() {
      if(this.options.length > 0) {
        const hasCurrent = this.options.includes(this.controlledValue);
        return (hasCurrent ? this.options : [this.controlledValue, ...this.options]).map(value => ({value, label: value}))
      }
      // Use <slot> instead (if needed):
      return undefined;
    }
  },
  methods: {
    handleSelectChange(value) {
      this.$emit('change', value);
      this.$emit('input', value);
    },
    handleSelected(e) {
      this.$emit('select', e)
    }
  },
  watch: {
    value: {
      handler(newValue) {
        this.controlledValue = newValue
      },
      immediate: true
    }
  }
}
</script>

<style lang="less">
  .renewable-select-group {
    .renewable-select-group__select {
      .ant-select-selection {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
      }
    }
    .renewable-select-group__reset {
      height: 32px;
      padding: 4px 10px 2px 2px;
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

</style>
