import {galleryService} from "@/services/gallery.service";

export const normalizeExpiresAt = (expiresAt, expirationType) => {
    if(expirationType !== 'NEVER') {
        if(expiresAt !== null && expiresAt.toDate) {
            return expiresAt.toDate()
        } else if (expiresAt instanceof Date) {
            return expiresAt
        } else if (typeof expiresAt === 'string') {
            const makeDate = new Date(expiresAt);
            return makeDate.toString() === 'Invalid Date' ? null : makeDate;
        }
    }
    return null;
}

const nameTransform = (data) => data.map(({name}) => name).join(', ');


export const sendGalleryItemsService = {
    async updateItem(itemId, {tags, expiresAt, expirationType, name}) {
        const computedExpiresAt = normalizeExpiresAt(expiresAt, expirationType)
        return galleryService.patchItem(itemId, { name, tags, expiresAt: computedExpiresAt, expirationType })
    },
    async uploadFiles({tags, expiresAt, expirationType, files, campaignId}) {
        const computedExpiresAt = normalizeExpiresAt(expiresAt, expirationType)
        const sentFiles = [];
        const notSendFiles = [];
        let lastError;
        for( const file of files) {
            try {
                await galleryService.uploadItem(file.originFileObj, { name: file.computedName, tags, expiresAt: computedExpiresAt, expirationType, campaignId })
                sentFiles.push(file);
            } catch (err) {
                notSendFiles.push(file);
                lastError = err
            }
        }
        return {
            sentFilesNames: nameTransform(sentFiles),
            notSendFilesNames: nameTransform(notSendFiles),
            sentFiles,
            notSendFiles,
            lastError
        }
    }
}
