<template>
    <a-modal
        v-model="visible"
        title="Information"
        @cancel="hideProjectInfoModal()"
    >
        <p class="form__label">Project description</p>
        <a-textarea
            class="form__input"
            placeholder="Add project description"
            :autoFocus="true"
            :rows="3"
            v-model="projectDescription"
            @keydown.ctrl.enter="handleDataSave()"
        />
        <template slot="footer">
            <a-button key="back" type="default" @click="hideProjectInfoModal()">
              Cancel
            </a-button>
            <a-button key="submit" type="primary" @click="handleDataSave()">
              Save
            </a-button>
        </template>

    </a-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data(){
      return {
        visible: true,
        projectDescription: ''
      }
  },
  computed: {
    ...mapGetters("uiState", ["currentSelectedProject"])
  },
  methods: {
    ...mapActions("uiState", ["hideProjectInfoModal", "setCurrentProject"]),
    ...mapActions("projects", ["setProjectDescription"]),
    ...mapActions(["showAlertError"]),
    async handleDataSave () {
      const { id } = this.currentSelectedProject;
      try {
        const { description } = await this.setProjectDescription( { id, description: this.projectDescription } );
        await this.setCurrentProject({...this.currentSelectedProject, description});
        this.hideProjectInfoModal();
      } catch (e) {
        console.error(e);
        this.showAlertError('Failed to update project description')
      }
    }
  },
  mounted () {
    this.projectDescription = this.currentSelectedProject.description;
  }
}
</script>
