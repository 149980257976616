import { registerPurifyAttribute } from '@/templating/templating-helpers';

export class DataHideHtmlTextWrapper {

	static DATA_HIDE_ATTR = 'data-hide'
	#hiddenElements = []
	#pureTextWithoutHiddenDOM = ''

	constructor(text) {
		this.text = text
	}

	#makeHelperDOM = (innerHTML = '') => {
		const div = document.createElement('div')
		div.innerHTML = innerHTML
		return div
	}

	#addHiddenElement = (hiddenText) => {
		this.#hiddenElements.push(hiddenText)
	}

	#getHiddenDOMMatchers = (baseElement) => {
		return Array.from(baseElement.querySelectorAll(`[${DataHideHtmlTextWrapper.DATA_HIDE_ATTR}]`))
			.map(el => new RegExp(`<${el.tagName}\u0020+${DataHideHtmlTextWrapper.DATA_HIDE_ATTR}.*?>`, 'i'))
	}

	#removeHiddenObjects = (textValue) => {
		const holder = this.#makeHelperDOM(textValue);
		const matchers = this.#getHiddenDOMMatchers(holder)
		let pureText = textValue
		// Strip text from <TAG data-hide> elements...
		// One by one to preserve hidden element order !
		for(const match of matchers) {
			const [ hiddenText ] = pureText.match(match)
			this.#addHiddenElement(hiddenText)
			pureText = pureText.replace(hiddenText, '')
		}
		return pureText
	}

	set text(value) {
		this.#pureTextWithoutHiddenDOM = this.#removeHiddenObjects(value)
	}

	get text() {
		return this.#pureTextWithoutHiddenDOM || ''
	}

	get fullText() {
		if(!this.#hiddenElements.length) {
			return this.text
		}
		let theFullText = ''
		// Unwrap hidden elements:
		for (const element of this.#hiddenElements) {
			theFullText += element
		}
		return theFullText + this.text
	}
}

registerPurifyAttribute(DataHideHtmlTextWrapper.DATA_HIDE_ATTR)
