import { settingsCascade } from './settings-cascade'

export class TemplateModel {
	id = 0
	baseHTML = ''
	absoluteUrl = ''
	name = ''
	description = ''
	type = ''
	cssClass = ''
	settings = {}
	cleanTemplateSettings = {}
	connectionId = 0
	productId = 0
	templateHolderControls = {}
	usedFontsMetadata = {}
	possibleValues = {}
	frozenFields = {}
	detachTemplateStyles = () => {}
	changeLanguage = () => {}

	constructor (id = 0) {
		this.id = id
	}

	applySettings(connectionSettings = {}) {
		//const previousLangToken = this.badgesLangToken;
		this.settings = settingsCascade(this.cleanTemplateSettings, connectionSettings);
		//TODO: check logic - it is not working with this condition :(
		//if(this.badgesLangToken !== previousLangToken) {
			// When language of the badges actually changed...
			// Fire change-lang event for connected .ref's within templateHolderControls
			this.changeLanguage(this.badgesLangToken);
		//}
	}

	async extractTemplateHolderMeta(templateHolder) {
		this.templateHolderControls = templateHolder.controls;
		this.cssClass = templateHolder.getTemplateClass();
		this.baseHTML = templateHolder.getTemplateHolderAsTxt();
		this.usedFontsMetadata = Object.freeze(await templateHolder.extractFontInfo());
		this.detachTemplateStyles = () => templateHolder.detachStyles();
		this.changeLanguage = lang => templateHolder.changeBadgesLangSideEffect(lang);
	}

	get hasCSSVariables() {
		return Boolean(this.templateHolderControls.cssVariables)
	}

	get cssVariables() {
		return this.hasCSSVariables ?
			this.settings.cssVariables
			:
			{}
	}

	get badgesLangToken() {
		return this.settings?.badges?.badges?.lang || 'pl';
	}

	get hasBadges() {
		return Boolean(this.templateHolderControls?.badges?.badges)
	}

	get hasCta() {
		return Boolean(this.templateHolderControls?.cta?.cta)
	}

	setCssVariable(name, value) {
		if(this.hasCSSVariables) {
			this.templateHolderControls.cssVariables[name] = value;
		}
	}
}
