<template>
    <div class="card" :style="archivedStyle">
        <div class="card__top">
            <div class="card__img" :style="imgStyle" />
        </div>
        <div class="card__bottom">
            <div v-if="isTestTemplate || isArchivedTemplate || isFavourite" style="display: flex; justify-content: flex-end">
              <a-tag v-if="isTestTemplate" color="red">Test</a-tag>
              <a-tag v-if="isArchivedTemplate" color="gray">Archived</a-tag>
              <a-icon v-if="isFavourite" type="star" theme="filled" />
            </div>
            <p class="card__title"> {{ temp.name }} </p>
            <div class="card__keywords" v-if="temp.keywords.length > 0">
              <a-tag color="#108ee9" v-for="{name} of temp.keywords" :key="name">
                {{name}}
              </a-tag>
            </div>
          <div class="card__info">Category: <em>{{ temp.category && temp.category.name }}</em> </div>
          <div class="card__info">Template description: <p>{{ temp.description }}</p></div>
          <a-dropdown class="card__options" v-if="canEdit">
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
              <a-icon type="more"/>
            </a>
            <a-menu slot="overlay">
              <a-menu-item @click="$emit('makrFavourite', !isFavourite)">
                      <span>
                        <a-icon type="star" :theme="!isFavourite ? 'filled' : 'outlined'" />
                        {{ isFavourite ? 'Unmark' : 'Mark' }} favourite
                      </span>
              </a-menu-item>
              <a-menu-item v-if="canPublish && isTestTemplate" @click="$emit('publishTemplate')">
                <span><a-icon type="check-square" /> Publish</span>
              </a-menu-item>
              <a-menu-item v-if="canEdit"  @click="$emit('renameTemplate')">
                <span><a-icon type="font-colors" /> Rename</span>
              </a-menu-item>
              <a-menu-item v-if="canEdit && !isArchivedTemplate"  @click="$emit('setDefaultValues')">
                <span><a-icon type="edit" /> Possible values</span>
              </a-menu-item>
              <a-menu-item v-if="canEdit && !isArchivedTemplate"  @click="$emit('cloneTemplate')">
                <span><a-icon type="copy" /> Clone as test</span>
              </a-menu-item>
              <a-menu-item v-if="canDeactivate && isActiveTemplate" @click="$emit('archiveTemplate')">
                <span><a-icon type="container" /> Archive</span>
              </a-menu-item>
              <a-menu-item v-if="canDeactivate && isArchivedTemplate" @click="$emit('unArchiveTemplate')">
                <span><a-icon type="folder-open" /> Unarchive</span>
              </a-menu-item>
              <a-menu-item v-if="canEdit"  @click="$emit('downloadZip')">
                <span><a-icon type="download" /> Download as .zip</span>
              </a-menu-item>
              <a-menu-item v-if="canRemove"  @click="$emit('deleteTemplate')">
                <span class="danger"><a-icon type="delete" /> Delete</span>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
          <a-dropdown class="card__options" v-else>
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
              <a-icon type="more"/>
            </a>
            <a-menu slot="overlay">
              <a-menu-item @click="$emit('makrFavourite', !isFavourite)">
                      <span>
                        <a-icon type="star" :theme="!isFavourite ? 'filled' : 'outlined'" />
                        {{ isFavourite ? 'Unmark' : 'Mark' }} favourite
                      </span>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
    </div>
</template>

<script>
import { resolveTemplateGraphicUrl } from '@/view-helpers/template-preview-resolver'

export default {
    name: 'TempCard',
    props: {
      temp: Object,
      isFavourite: {
        type: Boolean,
        default: false
      },
      canEdit: {
        type: Boolean,
        default: false
      },
      canDeactivate: {
        type: Boolean,
        default: false
      },
      canPublish: {
        type: Boolean,
        default: false
      },
      canRemove: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      isTestTemplate() {
        return Boolean(this.temp?.is_test)
      },
      isActiveTemplate() {
        return !this.temp?.is_test && this.temp.status === 'ACTIVE';
      },
      isArchivedTemplate() {
        return this.temp.status === 'INACTIVE';
      },
      archivedStyle() {
        return this.isArchivedTemplate ? { opacity: 0.6 } : {};
      },
      imgStyle() {
        const {id, thumbnail, updated_at} = this.temp;
        return { backgroundImage: resolveTemplateGraphicUrl(id, thumbnail, updated_at) };
      }
    }
}
</script>

<style scoped lang="less">
.card__keywords {
  margin-bottom: 10px;
}
</style>
