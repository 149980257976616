import { isNumeric, isPercent } from '@/templating/templating-helpers'

export function photoLoader(url, currentWidth, container) {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.addEventListener('load', () => {
			let {width, height} = img;
			const imageRatio = width / height;
			// If currentWidth is not "cover" (or other sting value)
			// - we should count from it's present value !!!
			if(isNumeric(currentWidth)) {
				width = currentWidth;
				height = currentWidth / imageRatio;
			} else if(isPercent(currentWidth)) {
				const { offsetWidth } = container;
				const percent = parseFloat(currentWidth)/100;
				width = offsetWidth * percent;
				height = width / imageRatio;
			} else {
				const { offsetWidth, offsetHeight } = container;
				const coverRatio = offsetWidth / offsetHeight;
				const scale = imageRatio >= coverRatio ?
					offsetHeight / height
					:
					offsetWidth / width
				width *= scale;
				height *= scale;
			}

			resolve({ width, height, imageRatio })
		})
		img.addEventListener('error', reject)
		img.src = url;
	})
}
