<template>
  <a-row :gutter="12" style="width: 100%" >
    <a-col v-for="prop of dataShapeProperties" :key="prop" :span="24 / dataShapeProperties.length">
      <possible-values-adder
          :values="mapValues(dataValues[prop], getShapeType(prop))"
          :shape-name="prop"
          :shape-type="getShapeType(prop)"
          :narrowed-font-names="fontNames"
          @update="handleValueUpdated({prop, values: $event})"
          @fileUpload="handleFileUpload({prop, value: $event})"
          @addFileFromGallery="handleAddFileFromGallery({prop, value: $event, source: '__gallery_item'})"
          @addFileFromCampaign="handleAddFileFromGallery({prop, value: $event, source: '__gallery_campaign'})"
      />
    </a-col>
  </a-row>
</template>

<script>
import { SHAPE } from '@/components/templates/possible-values/possible-values-shape';
import PossibleValuesAdder from '@/components/templates/possible-values/PossibleValuesAdder';
import { templatePossibleValuesService } from '@/services/template-possible-values.service';
import { templateService } from '@/services/template.service';
import { sanitizeUrl } from '@/templating/templating-helpers';
import { mapActions } from 'vuex';

export default {
  name: 'PossibleValuesDivisor' ,
  components: {
    PossibleValuesAdder
  },
  props: {
    dataValues: {
      type: Object,
      default: () => ({})
    },
    dataShape: {
      type: Object,
      default: () => ({})
    },
    fontNames: {
      type: Array,
      default: () => []
    },
    fieldName: {
      type: String
    },
    templateId: {
      type: [String, Number]
    }
  },
  computed: {
    dataShapeProperties() {
      return Object.keys(this.dataShape)
    }
  },
  methods: {
    ...mapActions(['showAlertError']),
    mapValues(values, type) {
      if(type === SHAPE.image && Array.isArray(values)) {
         return values.map(this.convertToFileEntry)
      }
      return values;
    },
    convertToFileEntry(path) {
      const fileUrl = templateService.getTemplateFileUrl(this.templateId);
      return {
        uid: path,
        status: 'done',
        name: path,
        url: sanitizeUrl(path, fileUrl)
      };
    },
    handleValueUpdated({prop, values}) {
      const currentValueObject = {
        ...this.dataValues,
        [prop]: values
      }
      if(values.length === 0) {
        delete currentValueObject[prop];
      }
      this.$emit('update', currentValueObject)
    },
    async handleFileUpload({prop, value}) {
      try {
        const { originFileObj } = value;
        const { path } = await templatePossibleValuesService.uploadImg(this.templateId, originFileObj)
        const currentValueObject = {
          ...this.dataValues
        }
        if(!currentValueObject[prop]) {
          currentValueObject[prop] = [path];
        } else {
          currentValueObject[prop].push(path)
        }
        this.$emit('update', currentValueObject)
      } catch( e ) {
        this.showAlertError(e.message);
      }
    },
    async handleAddFileFromGallery({prop, value, source}) {
      const currentValueObject = {
        ...this.dataValues
      }
      if(!currentValueObject[prop]) {
        currentValueObject[prop] = [];
      }
      const mappedNew = value.map(id => `${source}/${id}`);
      currentValueObject[prop] = Array.from(new Set([...currentValueObject[prop], ...mappedNew]));
      this.$emit('update', currentValueObject);
    },
    getShapeType(prop) {
      if(this.dataShape[prop] === SHAPE.cssVar) {
        const [, cssShape = SHAPE.text] = this.fieldName.replace('--','').split('-')
        const returnVal = [SHAPE.color, SHAPE.font].includes(cssShape) ? cssShape : SHAPE.text
        // CSS vars should have alpha channel:
        return returnVal === SHAPE.color ? SHAPE.alphaColor : returnVal;
      }
      return this.dataShape[prop]
    }
  }
};
</script>

<style scoped>

</style>
