<template>
    <div class="project">
        <s-sidebar v-if="projectId" />
        <s-creations />
        <router-view></router-view>
        <invisible-render-queue />
    </div>
</template>

<script>
import InvisibleRenderQueue from '@/components/project/components/InvisibleRenderQueue'
import { CONNECTION_ALL_TO_ALL } from '@/routes-specials'
import { useSelectedCreations } from '@/store/selected-creations'
import { mapActions, mapGetters } from 'vuex'
import { mapActions as mapPActions } from 'pinia'
import Sidebar from './components/Sidebar'
import Creations from './components/Creations'
import { templateInProjectService } from '@/services/template-in-project.service'
import { useTemplateBaseFilesCache } from '@/store/modules/template-base-files.store'

export default {
    name: 'ProjectPage',
    components: {
        InvisibleRenderQueue,
        SSidebar: Sidebar,
        SCreations: Creations,
    },
    computed: {
        ...mapGetters('currentProject', [
            'projectId',
            'hasAllToAll',
            'templates',
        ]),
    },
    async mounted() {
        const { projectId } = this.$route.params
        await Promise.all([
            this.loadProject(projectId),
            this.loadDefaultBadges(),
        ])
        if (
            !this.$route.params.creationId &&
            !this.$route.params.connectionId
        ) {
            if (this.hasAllToAll) {
                await this.$router.push({
                    name: 'project-connection',
                    params: { projectId, connectionId: CONNECTION_ALL_TO_ALL },
                })
            }
        }
        this.$nextTick(async () => {
            const templateIds = this.templates.map(t => t.id)
            if (templateIds.length) {
                try {
                    const result = await templateInProjectService.getTemplateBaseFiles(
                        templateIds
                    )
                    await this.loadFilesForTemplateIds(result)
                    // eslint-disable-next-line no-empty
                } catch {}
            }
        })
    },
    beforeDestroy() {
        this.deselectAll()
    },
    methods: {
        ...mapActions('currentProject', ['loadProject', 'loadDefaultBadges']),
        ...mapPActions(useSelectedCreations, ['deselectAll']),
        ...mapPActions(useTemplateBaseFilesCache, ['loadFilesForTemplateIds']),
    },
}
</script>

<style lang="less">
.project {
    &__content {
        padding-top: 16px;
        margin-left: 60px;
        display: flex;
    }
    .ant-upload-list-picture-card .ant-upload-list-item-actions {
        z-index: 2;
    }
}
</style>
