var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"items"},[_vm._m(0),_c('div',{staticClass:"links__search"},[_c('a-input-search',{attrs:{"enter-button":"","placeholder":"Search"},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),(_vm.filteredConnections.length === 0 && !_vm.showAllToAll)?_c('s-empty'):_c('ul',{staticClass:"links__items"},[(_vm.showAllToAll)?_c('li',{staticClass:"links__item"},[_c('div',{staticClass:"items__name"},[_vm._v("All templates x All products")]),_c('a-dropdown',[_c('a',{staticClass:"ant-dropdown-link",on:{"click":function($event){$event.preventDefault();}}},[_c('a-icon',{attrs:{"type":"more"}})],1),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},[_c('a-menu-item',{on:{"click":function($event){return _vm.$emit('editLink', _vm.CONNECTION_ALL_TO_ALL)}}},[_c('span',[_c('a-icon',{attrs:{"type":"caret-right"}}),_vm._v(" Edit")],1)]),_c('a-menu-item',{on:{"click":function($event){return _vm.$emit('resetSettings', {
                                connectionId: _vm.CONNECTION_ALL_TO_ALL,
                            })}}},[_c('span',{staticClass:"danger"},[_c('a-icon',{attrs:{"type":"sync"}}),_vm._v(" Reset all")],1)])],1)],1)],1):_vm._e(),_vm._l((_vm.filteredConnections),function(connection,idx){return _c('s-connection-list-item',{key:connection.id,staticClass:"links__item",attrs:{"connectionCount":idx + 1,"productId":connection.product_id,"name":connection.name,"currency":_vm.getProductMeta(connection).currency,"lang":_vm.getProductMeta(connection).lang ||
                    _vm.getConnectionMeta(connection).lang},on:{"editLink":function($event){return _vm.$emit('editLink', connection.id)},"renameLink":function($event){return _vm.$emit('renameLink', {
                    connectionId: connection.id,
                    ...$event,
                })},"resetSettings":function($event){return _vm.$emit('resetSettings', {
                    connectionId: connection.id,
                })},"deleteLink":function($event){return _vm.$emit('deleteLink', connection.id)},"moveSettings":function($event){return _vm.$emit('moveSettings', connection.id)},"toggleSelect":function($event){return _vm.toggleSelectLink(connection.id)}}})})],2)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h3',[_vm._v("Bindings")])])
}]

export { render, staticRenderFns }