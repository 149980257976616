import { axInstance } from '@/services/ax-instance'

const BASE_TEMPLATE_IN_PROJECT = '/templates/in-project'

export const templateInProjectService = {
    getTemplateBaseFiles(templateIds) {
        return axInstance
            .put(`${BASE_TEMPLATE_IN_PROJECT}/expired-base-files`, {
                templateIds,
            })
            .catch(e => {
                console.error(
                    `getting gallery items from cache error: ${e.message}`
                )
            })
    },
    updateGraphicFocalPoint(graphicId, focalPoint) {
        return axInstance.patch(
            `${BASE_TEMPLATE_IN_PROJECT}/graphics/${graphicId}`,
            { focalPoint }
        )
    },
}
