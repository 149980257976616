
export function calculateDiscount(oldPrice = 1, newPrice = 0) {
	oldPrice = oldPrice.toString().replace(',', '.');
	newPrice = newPrice.toString().replace(',', '.');

	const floatOldPrice = parseFloat(oldPrice);
	const floatNewPrice = parseFloat(newPrice);
	const calculation = ((floatNewPrice - floatOldPrice)/floatOldPrice * 100) || 0;
	let mathAction = 'ceil';
	// #Fix: RUND DOWN behavior depends on sign (-). In JS we got workaround like:
	if(calculation > 0) {
		mathAction = 'floor'
	}
	const result = Math[mathAction](calculation);
	return result === Infinity || Number.isNaN(result) ? 0 : (result.toString().replace('.', ',') || '0');
}

// #WARN: this calculation is not as precise as might wanted !
// There is no way to bring back actual price from ROUNDED DOWN percent in calc above !
export function calculateOldPrice(newPrice, discount) {
	newPrice = newPrice.toString().replace(',', '.');
	const floatNewPrice = parseFloat(newPrice);
	const floatDiscount = parseFloat(discount);
	const result = Math.ceil((floatNewPrice * 100) / (100 + floatDiscount));
	return result === Infinity  || Number.isNaN(result) ? '0' : (result.toString().replace('.', ',') || '0');
}
