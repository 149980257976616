import { axInstance, cancelableRequest } from './ax-instance'

const BASE_PRODUCT_URI = '/products';

const getProductPhotoUrl = (productId) => `${process.env.VUE_APP_API_URL}${BASE_PRODUCT_URI}/${productId}/photos`;

function add(offer_id) {
	return axInstance.post(BASE_PRODUCT_URI, { offer_id });
}

function all(searchQuery, order = [], page = 0, limit = 40) {
	let searchParam = {}
	if(Object.keys(searchQuery).length) {
		searchParam = searchQuery
	}
	let orderParam = {}
	if(order.length > 0) {
		const [orderBy, orderDir] = order;
		orderParam = {
			orderBy,
			orderDir
		}
	}
	return axInstance.get(BASE_PRODUCT_URI, { params: { page, limit, ...searchParam, ...orderParam }})
}

function update(productId) {
	return axInstance.put(`${BASE_PRODUCT_URI}/${productId}`, { productId })
}

function badges(productId) {
	return cancelableRequest(({cancelToken}) => axInstance.get(`${BASE_PRODUCT_URI}/${productId}/badges`, {cancelToken}))
}

function photos(productId) {
	return cancelableRequest(({cancelToken}) => axInstance.get(`${BASE_PRODUCT_URI}/${productId}/photos`, {cancelToken}))
}

async function addPhoto(productId, photo, type) {
	return axInstance.post(`${BASE_PRODUCT_URI}/${productId}/photos` + (type ? `?type=${type}` : ''), photo, {
    headers: {
			'content-type': 'multipart/form-data'
    }
  })
}

async function removePhoto(productId, photoId) {
	return axInstance.delete(BASE_PRODUCT_URI + '/' + productId + '/photos/' + photoId)
}

export const productService = {
	add,
	all,
	update,
	photos,
	addPhoto,
	removePhoto,
	getProductPhotoUrl,
	badges
}
