<template>
  <div class="edition__product">
    <a-form-model v-if="productControl" :model="selProduct">
      <template v-if="renderIf.hasName">
        <p class="form__label" >Product name</p>
        <a-form-model-item prop="name">
          <renewable-input
              class="edition__input"
              placeholder="Product name"
              v-model="selProduct.name"
              @change="handleValueChange('name', $event)"
              @reset="resetValueOf('name')"
          />
        </a-form-model-item>
      </template>

      <div class="edition__row" v-if="renderIf.hasPriceMain">
        <div class="edition__row-item">
          <p class="form__label">Price</p>
          <a-form-model-item prop="price-main">
            <renewable-input
                class="edition__input"
                placeholder="Price"
                v-model="selProduct['price-main']"
                @input="handlePriceInput"
                @change="handleValueChange('price-main', $event)"
                @reset="resetValueOf(['price-main', 'price-rest'])"
            />
          </a-form-model-item>
        </div>

        <div class="edition__row-item" v-if="renderIf.hasPriceRest">
          <p class="form__label">Price (after decimal point)</p>
          <a-form-model-item prop="price-rest">
            <a-input
                class="edition__input"
                placeholder="Price"
                v-model="selProduct['price-rest']"
                @input="handlePriceInput"
                @change="handleValueChange('price-rest', $event)"
            />
          </a-form-model-item>
        </div>
      </div>

      <div class="edition__row" v-if="renderIf.hasDiscount">
        <div class="edition__row-item">
          <p class="form__label">Previous price</p>
          <a-form-model-item prop="price">
            <renewable-input
                class="edition__input"
                placeholder="Previous price"
                v-model="selProduct.price"
                @input="handlePriceInput"
                @reset="resetValueOf('price')"
            />
          </a-form-model-item>
        </div>

        <div class="edition__row-item" v-if="renderIf.hasDiscount">
          <p class="form__label">Discount (%)</p>
          <a-form-model-item prop="discount">
            <a-input
                class="edition__input"
                placeholder="Discount"
                v-model="selProduct.discount"
                @input="handleDiscountInput"
            />
          </a-form-model-item>
        </div>

      </div>

      <div class="edition__label-item" v-if="renderIf.hasPriceFromTo">
					<div>
						<p class="form__label">Price modifier (Price from ... / up to x%)</p>
						<div>
							<a-form-model-item prop="priceModifier">
                <renewable-select
                    placeholder="Price type"
                    v-model="priceModifier"
                    @change="handleValueChange('class$$priceModifier$$classes', {target: {value: $event}})"
                >
                  <a-select-option
                      v-for="type in priceModifiersClasses"
                      :key="type.class"
                  >
                    {{ type.name }}
                  </a-select-option>
                </renewable-select>
							</a-form-model-item>
						</div>
					</div>
				</div>

      <form-photos
          v-if="renderIf.hasImg"
          :loading="isPhotosLoading"
          :file-list="productImgFileList"
          :can-set-to-empty="false"
          :can-add-form-gallery="false"
          :template-url="template.absoluteUrl"
          label="Product photo"
          description=""
          loading-text="Loading photos…"
          @switchPhoto="changePhoto('url', $event)"
          @removePhoto="deletePhoto($event, 'product')"
          @uploadPhoto="uploadPhoto($event, 'product')"
      ></form-photos>

      <form-photos
          v-if="renderIf.hasVendorImg"
          :loading="isPhotosLoading"
          :file-list="partnerImgFileList"
          :can-set-to-empty="true"
          :can-add-form-gallery="false"
          :template-url="template.absoluteUrl"
          label="Vendor logo"
          description=""
          loading-text="Loading photos..."
          @switchPhoto="changePhoto('partnerUrl', $event)"
          @removePhoto="deletePhoto($event, 'partner')"
          @uploadPhoto="uploadPhoto($event, 'partner')"
          @emptyPhoto="emptyPhoto()"
      ></form-photos>

      <template v-if="renderIf.hasBadges">
        <inline-preloader v-if="isBadgesLoading" text="Loading badges..." />
        <div v-if="getBadges() && !isBadgesLoading">
          <form-badges
            :lang="badgesLang"
            :currentBadges="(getBadges() && getBadges().list) || []"
            :possibleBadges="loadedBadges"
            :discountBadgeData="discountData"
            @change="fireBadgesChange($event)"
            @langChange="fireBadgesLangChange($event)"
           />
        </div>
      </template>
    </a-form-model>
    <section ref="mountPoint" v-show="false"></section>
  </div>
</template>

<script>
import { productFormRenderHelper } from '@/components/project/components/product-form-render-helper';
import { calculateDiscount, calculateOldPrice } from '@/components/project/model/discount-counter'
import { TemplateProduct } from '@/templating/TemplateProduct';
import { mapActions, mapGetters } from 'vuex'
import RenewableInput from '@/components/shared/form/RenewableInput'
import RenewableSelect from '@/components/shared/form/RenewableSelect'
import FormPhotos from '@/components/project/components/form/photos/FormPhotos.vue'
import FormBadges from '@/components/project/components/form/FormBadges'
import InlinePreloader from '@/components/project/components/InlinePreloader'
import { TemplateModel } from '@/components/project/model/TemplateModel'
import { normalizeUrl, sanitizeUrl } from '@/templating/templating-helpers'
import { productService } from '@/services/product.service'
import { SPECIAL_TYPE } from '../model/special-badges'
import {onlyUniqueProductBadgesFilter} from "@/components/project/model/only-unique-product-badges-filter";

const { DATA_FIELDS } = TemplateProduct

export default {
  name: 'ProductForm',
  components: {
    FormPhotos,
    FormBadges,
    RenewableInput,
    RenewableSelect,
    InlinePreloader
  },
  props: {
    productId: {
      type: Number,
      required: true
    },
    template: TemplateModel,
    controlsSettings: {
      type: Object,
      default: () => {}
    },
    productData: {
      type: Object,
      default: () => {}
    },
    hasMoreThanOneProductTemplates: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selProduct: {},
      resetFieldsQueue: [],
      priceModifier: '',
      productImgFileList: [],
      partnerImgFileList: [],
      chosenBadges: [],
      loadedPhotos: [],
      badges: [],
      isBadgesLoading: false,
      isPhotosLoading: false,
      req: {
        loadBadges: productService.badges(0),
        loadPhotos: productService.photos(0),
      }
    }
  },
  computed: {
    ...mapGetters('currentProject', ['allProjectBadges']),
    priceModifiersClasses() {
      return [
        { name: 'None', class: '' },
        { name: 'Prices from', class: 'settings--price-from' },
        { name: 'Price percentage up to …', class: 'settings--price-to' }
      ]
    },
    templateProduct() {
      return this.template?.templateHolderControls?.product?.product
    },
    renderIf() {
      return productFormRenderHelper(this.templateProduct, this.template.hasBadges, this.hasMoreThanOneProductTemplates)
    },
    productControl() {
      return this.controlsSettings?.product?.product
    },
    discountData() {
      const productHasDiscount = Object.keys(this.selProduct || {}).includes(DATA_FIELDS.DISCOUNT);
      if(productHasDiscount) {
         return {label: this.selProduct.price, label_two: this.selProduct.discount}
      }
      return null;
    },
    badgesLang() {
      return this.controlsSettings?.badges?.badges?.lang
    },
    loadedBadges() {
      // Merge all badges (allProject + product)
      // Filter Project badges to not "duplicate" badges from product
      return [
        ...this.badges,
        ...this.allProjectBadges(this.badgesLang).filter(onlyUniqueProductBadgesFilter(this.badges))
      ]
    }
  },
  methods: {
    ...mapActions('currentProject', ['convertBadgesToDifferentLang']),
    getSetting(type, id) {
      return this.controlsSettings[type]?.[id]
    },
    css(id) {
      return this.getSetting('classes', id)
    },
    fireBadgesChange({action, badges}) {
      const key = action;
      if(key === 'list') {
        this.chosenBadges = badges;
      }
      this.fireChange('badges', 'badges', key, badges)
    },
    async fireBadgesLangChange({badges, lang}) {
      const mappedBadges = await this.convertBadgesToDifferentLang({badges, lang});
      this.fireChange('cta', 'cta', 'lang', lang)
      // order of this fireChanges matters:
      this.fireChange('badges', 'badges', 'lang', lang)
      this.fireChange('badges', 'badges', 'list', mappedBadges)
      this.chosenBadges = mappedBadges;
    },
    fireChange ( id = 'product', type = 'product', key, value ) {
      const action = { id, type, key, value }
      this.$emit('controlUpdated', action);
      this.updateHolderByProductId( {...action, productId: this.productId} )
    },
    resetValueOf(keyOrKeys) {
      let keys = keyOrKeys;
      if(!Array.isArray(keyOrKeys)) {
        keys = [keyOrKeys]
      }
      keys.forEach(key => {
        this.selProduct[key] = null;
        this.resetFieldsQueue.push(key);
      })
      this.$emit('controlUpdated', {id: 'product', type: 'product', key: 'productData', value: this.selProduct});
    },
    getFileListSource(type) {
      return (type === 'partner') ? 'partnerImgFileList' : 'productImgFileList';
    },
    async uploadPhoto(file, type) {
      const photo = new FormData();
      photo.append('photo', file.originFileObj)
      try {
        const newPhoto = await productService.addPhoto(this.productId, photo, type)
        this.pushPhoto(newPhoto, type)
      } catch (e) {
        console.error('Unable to add photo for', this.productId)
      }
    },
    pushPhoto({type, id, url}) {
      const source = this.getFileListSource(type);
      this[source].push({
          uid: id,
          status: 'done',
          name: 'img',
          url: sanitizeUrl(url, productService.getProductPhotoUrl(this.productId))
      })
    },
    changePhoto(key = 'url', file) {
      this.fireChange('product', 'product', key, normalizeUrl(file.url))
    },
		emptyPhoto(key = 'partnerUrl') {
			this.fireChange('product', 'product', key, '');
		},
    async deletePhoto({ file, fileList }, type) {
      try {
        const photoId = file.uid;
        const removedPhoto = await productService.removePhoto(this.productId, photoId);
        if (removedPhoto) {
          const source = this.getFileListSource(type);
          this[source] = fileList;
        }
      } catch (e) {
        console.error('cannot remove photo', e);
      }
    },
    getControl ( type, id ) {
      return this.controlsSettings[ type ]?.[ id ]
    },
    getBadges () {
      return this.getControl('badges', 'badges')
    },
    initChosenBadges() {
      this.chosenBadges = (this.getBadges() || {}).list || [];
    },
    ...mapActions('connectionTemplate', ['updateHolderByProductId']),
    handlePriceInput() {
      const { oldPrice, fullPrice } = getProductFullPriceDetails(this.selProduct)
      this.selProduct.discount = calculateDiscount(oldPrice, fullPrice) + '%';
      this.fireChange('product', 'product', 'productData',  this.selProduct)
      this.updateDiscountBadgePriceAndDiscount();
    },
    handleDiscountInput() {
      const { fullPrice, discount } = getProductFullPriceDetails(this.selProduct);
      this.selProduct.price = calculateOldPrice(fullPrice, discount);
      this.fireChange('product', 'product', 'productData',  this.selProduct)
      this.updateDiscountBadgePriceAndDiscount();
    },
    updateDiscountBadgePriceAndDiscount() {
      const discountBadge = this.chosenBadges.find(b => b.type === SPECIAL_TYPE.DISCOUNT);
      if(discountBadge) {
        discountBadge.label = this.selProduct.price;
        discountBadge.label_two = this.selProduct.discount;
        this.fireBadgesChange({action: 'list', badges: [...this.chosenBadges]})
      }
    },
    handleValueChange(key, { target: {value} }) {
      const [id, fieldKey, type] = key.split('$$');

      if (fieldKey) {
        this.fireChange(id, type, fieldKey, value);
      } else {
        this.selProduct[key] = value;
        this.fireChange('product', 'product', 'productData',  this.selProduct)
      }
    },
    checkResetQueue(key) {
      if(this.resetFieldsQueue.includes(key)) {
        this.resetFieldsQueue = this.resetFieldsQueue.filter(k => k !== key);
        if([DATA_FIELDS.PRICE, DATA_FIELDS.PRICE_REST, DATA_FIELDS.PRICE_MAIN].includes(key)){
          this.handlePriceInput();
        } else {
          this.updateHolderByProductId({id: 'product', type: 'product', key: 'productData', value: this.selProduct, productId: this.productId})
        }
      }
    }
  },
  async mounted () {
    this.req.loadBadges = productService.badges( this.productId );
    this.req.loadPhotos = productService.photos( this.productId );

    try {
      this.isBadgesLoading = true;
      this.badges = await this.req.loadBadges.call();
      // Update discount badge only if badges are loaded:
    } catch (e) {
      // console.error(e);
    } finally {
      this.isBadgesLoading = false;
    }
    try {
      this.isPhotosLoading = true;
      const photos = await this.req.loadPhotos.call();
      (photos || []).forEach(this.pushPhoto);
    } catch (err) {
      // console.error(err);
    } finally {
      this.isPhotosLoading = false;
    }

    this.priceModifier = this.css('class') && this.css('class').priceModifier;
    this.initChosenBadges();
  },
  beforeDestroy () {
    this.req.loadBadges.cancel();
    this.req.loadPhotos.cancel();
  },
  watch: {
    productData: {
      handler(newProduct) {
        // Initial data mapping + after field reset!
        Object.entries( newProduct || {} )
            .filter( ( [ key ] ) => !this.selProduct?.[ key ] ) // if value unset
            .forEach( ( [ key, value ] ) => {
              this.$set( this.selProduct, key, value )
              this.checkResetQueue(key);
            } )
      },
      deep: true,
      immediate: true
    }
  }
}

/*
* Helper function to determine fullPrice + oldPrice + discount
* */
function getProductFullPriceDetails(product = {}) {
  const { 'price-main':mainPrice, 'price-rest':restPrice, discount, price } = product;
  const restPriceAsNumbers = ((restPrice || '').match(/\d+/g) || [])[0] || '0';
  const fullPrice = [parseInt(mainPrice), restPriceAsNumbers].join('.');
  return { fullPrice, discount, oldPrice: price}
}


</script>

<style scoped>

</style>
