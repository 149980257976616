// MUTATING functions for DOM creation templates

export function modifyTemplateSize(creationDOM, {width = 100, height = 100} = {}) {
	creationDOM.style.width = `${width}px`;
	creationDOM.style.height = `${height}px`;
}

export function modifyTemplateCloneIndex(creationDOM, index = 0) {
	// @Business: Adapt Cloned CSS index if present and non-zero
	if(index) {
		creationDOM.dataset.clone = String(index);
	}
}

const VERTICAL_RATIO_BELOW = 0.85;
const HORIZONTAL_RATIO_ABOVE = 1.4;
export function modifyTemplateOrientationClass(creationDOM, {width = 100, height = 100} = {}) {
	// @Business: Adapt Orientation class based on ratio (0.85 - 1.4 square)
	const calcRatio = width / height;
	let orientationClassName = 'square';
	if(calcRatio < VERTICAL_RATIO_BELOW) {
		orientationClassName = 'vertical';
	} else if(calcRatio > HORIZONTAL_RATIO_ABOVE) {
		orientationClassName = 'horizontal';
	}
	creationDOM.classList.add(orientationClassName);
}
