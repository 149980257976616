import { fileDownloadService } from '@/services/file-download.service'
import { connectionService } from '@/services/project-connection.service'
import { templateHoldersService } from '@/services/template-holders.service'
import { useSelectedCreations } from '@/store/selected-creations'
import JSZip from 'jszip'
import FileSaver from 'file-saver'

const CREATIONS_TO_RENDER_AHEAD = 3
const CREATIONS_LIMIT_EXPORT_TO_CIM = 50

const clientDownload = (render, filename, ext) => {
    const mimeType = ext === 'jpg' ? 'image/jpeg' : 'image/png'
    fileDownloadService.saveFile(render, filename, ext, mimeType)
}

const assureCreationMountedOnDOM = function*(creations) {
    const selectedCreationsStore = useSelectedCreations()
    for (const creation of creations) {
        let [template] =
            templateHoldersService.getTemplateHoldersByCreationId(
                creation.id
            ) || []
        if (!template) {
            yield () => selectedCreationsStore.addCreationToRender(creation)
        } else {
            yield template
        }
    }
}

const renderAhead = (
    creations,
    amountOfCreationsToRenderAhead = CREATIONS_TO_RENDER_AHEAD
) => {
    const iterOverTemplates = assureCreationMountedOnDOM(creations)
    return async () => {
        let ex = amountOfCreationsToRenderAhead
        do {
            const { value, done } = iterOverTemplates.next()
            if (done) {
                return
            }
            if (typeof value === 'function') {
                const template = await value()
                await template.assureMountedOnDOM()
            } else {
                await value.assureMountedOnDOM()
            }
            ex--
        } while (ex)
    }
}

const getCompressedHTMlForCreation = creationId => {
    let [template] =
        templateHoldersService.getTemplateHoldersByCreationId(creationId) || []
    if (!template) {
        // If the template is NOT available - that means, we need to ask another store: (invisible rendering)
        template = useSelectedCreations().renderedTemplateByCreationId(
            creationId
        )
    }
    return templateHoldersService.getTemplateHolderHTML(template)
}

async function start({
    creations = [],
    exportToCim = false,
    projectId,
    projectName,
    onSingleExported = () => {},
    alt,
    fileType,
    quality,
}) {
    const downloadAsZip = creations.length > 1
    let counter = 1
    if (exportToCim && creations.length > CREATIONS_LIMIT_EXPORT_TO_CIM) {
        throw new Error(
            `Graphics export limit: ${CREATIONS_LIMIT_EXPORT_TO_CIM}.`
        )
    }

    const zip = new JSZip()
    const domMountAhead = renderAhead(creations)
    // Wait for first 3 creations render:
    await domMountAhead()
    for (const creation of creations) {
        // optimistic NON-BLOCKING (render 3 creations is faster than send them via HTTP)
        domMountAhead().then(() => {})
        // This function can be optimized by memoization per single "start" function run.
        const { binary, name, extension } = await downloadSingle(creation, {
            projectId,
            exportToCim,
            alt,
            fileType,
            quality,
        })
        const filename = `${name}.${extension}`

        if (!exportToCim) {
            if (downloadAsZip) {
                zip.file(filename, binary, { binary: true })
                onSingleExported(
                    `Banner exported ${counter}/${creations.length} ${name}`
                )
                counter += 1
            } else {
                clientDownload(binary, name, extension)
            }
        }
    }

    if (downloadAsZip) {
        if (exportToCim) return false
        return zip.generateAsync({ type: 'blob' }).then(blob => {
            FileSaver.saveAs(blob, `${projectName}.zip`)
            return 'All graphics have been exported'
        })
    }

    return ''
}

async function downloadSingle(
    creationModel,
    { projectId, exportToCim, alt, fileType, quality }
) {
    const styles = await templateHoldersService.getGlobalStylesByTemplateId(
        creationModel.template.cssClass
    )
    const { id: creationId, width, height } = creationModel
    const html = getCompressedHTMlForCreation(creationId)
    const graphicUrl = window.location.href + `/creation/${creationId}`
    const response = await connectionService.renderCreation(
        { projectId, creationId },
        { html, styles, graphicUrl, exportToCim, alt, fileType, quality },
        { width, height }
    )
    const binary = response.data
    let name = response.headers['content-disposition']
        .split('filename=')[1]
        .replace(/['"]/g, '')
    const extension = name.split('.')[1]
    name = name.split('.')[0]

    return { binary, name, extension }
}

export const creationsDownloadService = {
    start,
}
