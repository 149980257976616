<template>
    <a-modal
        v-model="visible"
        title="Project Badges"
        @cancel="$emit('closeAddCustomBadgeModal')"
        :width="940"
    >
      <section class="flex">
        <div style="width: 60%">
          <h4>New badge:</h4>
          <s-custom-badge-modal-addition-form @add-new-badge="handleAddNewBadge($event)" :error-message="addBadgeError" />
        </div>
        <div style="width: 40%">
          <h4>Currently added:</h4>
          <ul class="links__items">
            <li class="links__item" v-for="badge in projectBadges" :key="badge.id">
              <span class="links__item-name">{{badge.name}}</span>
              <div class="label" v-html="makeStandardBadge(badge)"></div>
            </li>
          </ul>
        </div>
      </section>

      <template slot="footer">
          <a-button key="back" type="default" @click="$emit('closeAddCustomBadgeModal')">
              Close
          </a-button>
      </template>

    </a-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { projectService } from '@/services/project.service'
import { makeStandardBadge } from '@/templating/badge-rederer'
import CustomBadgeModalAdditionForm from '@/components/links/components/CustomBadgeModalAdditionForm'

export default {
    name: 'CustomBadgeModal',
    components: {
      SCustomBadgeModalAdditionForm: CustomBadgeModalAdditionForm
    },
    data(){
      return {
        visible: true,
        addBadgeError: ''
      }
    },
    computed: {
        ...mapGetters("currentProject", ["projectId", "projectBadges"])
    },
    methods: {
      ...mapActions("currentProject", ["addProjectCustomBadge"]),
      makeStandardBadge: makeStandardBadge,
      async handleAddNewBadge({values, resetForm}) {
        this.addBadgeError = '';
        try {
          const newBadge = await projectService.addNewBadge( this.projectId, values );
          this.addProjectCustomBadge(newBadge);
          resetForm();
        } catch (err) {
          this.addBadgeError = err.message;
        }
      }
    }
}
</script>

<style lang="less">
.links__items .label .links__item-name {
  color: #fff;
}
</style>
