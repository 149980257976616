import { render, staticRenderFns } from "./ReportsPage.vue?vue&type=template&id=3446522f&scoped=true"
import script from "./ReportsPage.vue?vue&type=script&lang=js"
export * from "./ReportsPage.vue?vue&type=script&lang=js"
import style0 from "./ReportsPage.vue?vue&type=style&index=0&id=3446522f&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3446522f",
  null
  
)

export default component.exports