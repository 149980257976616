import { TControl } from '@/templating/TControl'

const languageMap = {
    pl: 'Sprawdź',
    en: 'See',
    uk: 'Переглянути',
    cs: 'Objevovat',
    sk: 'Objevovať',
}

export class TemplateCta extends TControl {
    static $selector = '[data-cta]'
    static ctaTextSelector = '.cta__text'

    constructor(ref) {
        super(ref, 'cta')
        this.id = 'cta'
        this.$ctaText = ref.querySelector(TemplateCta.ctaTextSelector)
    }

    set lang(name) {
        this.changeLanguage(name)
        if (this.$ctaText) {
            this.$ctaText.innerText = languageMap[name] || languageMap.en
        }
    }

    get lang() {
        return this.langToken || 'pl'
    }
}
