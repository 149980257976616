import { defineStore } from 'pinia'
import Vue from 'vue'
import { useGalleryCache } from '@/store/modules/gallery-cache.store'

export const useSelectedCreations = defineStore('selected-creations', {
    state: () => ({
        creationModelsByConnectionId: {},
        renderQueue: [],
        renderedTemplatesByCreationId: {},
    }),
    getters: {
        selectedConnectionCreations: state => connectionId =>
            state.creationModelsByConnectionId[connectionId] || [],
        selectedCreationIds: state => connectionId =>
            state.selectedConnectionCreations(connectionId).map(c => c.id),
        isCreationIdSelected: state => (connectionId, creationId) =>
            state.selectedCreationIds(connectionId).includes(creationId),
        countConnectionCreations: state => connectionId =>
            state.selectedConnectionCreations(connectionId).length,
        allSelectedCreations: state =>
            Object.values(state.creationModelsByConnectionId).flat(),
        allSelectedCreationsCount: state => state.allSelectedCreations.length,
        renderedTemplateByCreationId: state => creationId =>
            state.renderedTemplatesByCreationId[creationId],
    },
    actions: {
        toggleModelSelection({ connectionId, creationModel }) {
            if (!this.creationModelsByConnectionId[connectionId]) {
                Vue.set(this.creationModelsByConnectionId, connectionId, [
                    creationModel,
                ])
                return
            }
            const collection = this.creationModelsByConnectionId[connectionId]
            const modelIdx = collection.findIndex(
                model => model.id === creationModel.id
            )
            if (modelIdx !== -1) {
                collection.splice(modelIdx, 1)
            } else if (
                !useGalleryCache().hasAnyArchivedItem(creationModel.settings) ||
                !creationModel.isDisabled
            ) {
                // @Business: check if creation not using expired / deleted gallery item.
                collection.push(creationModel)
            }
        },
        selectManyModels({ connectionId, creationModels }) {
            const { hasAnyArchivedItem } = useGalleryCache()
            // @Business: check if creation not using expired / deleted gallery item.
            Vue.set(
                this.creationModelsByConnectionId,
                connectionId,
                [...creationModels]
                    .filter(cm => !hasAnyArchivedItem(cm.settings))
                    .filter(cm => !cm.isDisabled)
            )
        },
        deselectManyModels({ connectionId }) {
            Vue.set(this.creationModelsByConnectionId, connectionId, [])
        },
        deselectAll() {
            this.creationModelsByConnectionId = {}
            this.clearRenderQueue()
        },
        clearRenderQueue() {
            this.renderQueue = []
            this.renderedTemplatesByCreationId = {}
        },
        addCreationToRender(creationModel) {
            return new Promise(resolve => {
                this.renderQueue.push({
                    creationModel,
                    renderCallback: template => {
                        this.renderedTemplatesByCreationId[
                            creationModel.id
                        ] = template
                        resolve(template)
                    },
                })
            })
        },
    },
})
